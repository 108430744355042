import React from 'react';

import PropTypes from 'prop-types';

import {Text} from '@renofi/components-internal';
import {basic80} from '@renofi/theme';

import {ListItemTitleWrapper} from './styled';

const ListItemTitle = ({title, subtitle, wrapItems}) => {
  return (
    <ListItemTitleWrapper mb={12} wrapItems={wrapItems}>
      <Text color={basic80} fontWeight="bold" fontSize={16} pr="4px">
        {title}
      </Text>
      <Text fontSize={14}>({subtitle})</Text>
    </ListItemTitleWrapper>
  );
};

ListItemTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  wrapItems: PropTypes.bool,
};

ListItemTitle.defaultProps = {
  wrapItems: true,
};

export default ListItemTitle;
