import styled from '@emotion/styled';

import {Box} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';

export const Wrapper = styled(Box)(
  {
    width: '50%',
    [maxSmall]: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 16,
    },
  },
  ({even, mt}) => ({
    paddingLeft: even ? 0 : 8,
    paddingRight: even ? 8 : 0,
    marginTop: mt || 24,
  }),
);
