import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';
import {basic20, warningDark} from '@renofi/theme';

import {BADGE_VARIANTS} from './BorrowerDetails/constants';

export const Wrapper = styled(Flex)({
  width: '100%',
  flexDirection: 'column',
});

export const TaskWrapper = styled(Flex)({
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [maxSmall]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
});

export const Card = styled(Flex)(({variant}) => ({
  borderRadius: 8,
  flexDirection: 'column',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: variant === BADGE_VARIANTS.completed ? basic20 : warningDark,
  padding: 16,
  marginTop: 16,
  paddingBottom: 12,
  [maxSmall]: {
    width: '100%',
  },
}));
