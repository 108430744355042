import {gql} from '@apollo/client';

import {note} from '../../partials';

export default gql`
  query notesSearch(
    $filter: NotesSearchFilterInputObject
    $limit: Int
    $page: Int
    $query: String
    $sort: NotesSearchSortInputObject
  ) {
    notesSearch(
      filter: $filter
      limit: $limit
      page: $page
      query: $query
      sort: $sort
    ) {
      collection {
        ${note}
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
        facets {
          author {
            count
            user {
              email
              fullName
              id
            }
          }
          mentionedUser {
            count
            user {
              email
              fullName
              id
            }
          }
          renofiAdvisor {
            count
            user {
              email
              fullName
              id
            }
          }
        }
      }
    }
  }
`;
