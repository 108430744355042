import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../useMutation';
import {DECLINE_PRODUCT_CONFIRMATION} from '../../mutations';

export default function useDeclineProductConfirmation({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [declineProductConfirmation, {data, loading, error}] = useMutation(
    DECLINE_PRODUCT_CONFIRMATION,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['declineProductConfirmation'], data);
  return {declineProductConfirmation, response, loading, error};
}
