import React from 'react';

import welcomeIcon from '../icons/welcome.svg';

import {WelcomeTitle, WelcomeText, WelcomeWrapper} from './styled';

const Welcome = () => {
  return (
    <WelcomeWrapper>
      <WelcomeTitle>Welcome to RenoFi</WelcomeTitle>
      <img src={welcomeIcon} alt="Welcome" />
      <WelcomeText>
        We’ve made you a personalized to-do list for the essential information
        we need from you. First, let’s answer a few basic questions to get you
        started on the right track.
      </WelcomeText>
      <WelcomeText>
        Don’t worry! You’ll have the opportunity to edit this information later
        or by talking to your advisor.
      </WelcomeText>
    </WelcomeWrapper>
  );
};

export default Welcome;
