import React, {useContext, useState} from 'react';

import PropTypes from 'prop-types';
import lazy from 'lib/asyncComponent';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import {useCurrentTask} from 'modules/project/hooks';

import {ConfirmCloseTaskModal} from '@renofi/components-internal';
import {
  useMarkTaskAsOutstanding,
  useSoftCreditCheckFinishedByPrimaryApplicant,
} from '@renofi/graphql';
import {useNotifications} from '@renofi/utilities/src/hooks';
import logger from '@renofi/utilities/src/logger';
import {validateUuidProp} from '@renofi/utilities/src/react';
import {hasCoBorrowerInProject} from '@renofi/utilities/src/coborrower';

const BorrowersWizard = lazy(
  () =>
    import(
      /* webpackChunkName: "borrowers-details-wizard" */ './SoftCreditWizard'
    ),
);
const Wizard = ({task, onWizardClose, project}) => {
  const {softCreditCheckFinishedByPrimaryApplicant, loading: isFinishing} =
    useSoftCreditCheckFinishedByPrimaryApplicant();
  const {loading, markTaskAsOutstanding} = useMarkTaskAsOutstanding();
  const {addNotification} = useNotifications();
  const {refetchProjectTasks} = useContext(ProjectTasksContext);
  const {softCreditCheck} = task || {};
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isFinalWizardStep, setIsFinalWizardStep] = useState(false);
  const {sendEvent} = useCurrentTask({taskId: task?.id});
  const hasCoBorrower = hasCoBorrowerInProject(project);

  const handleFinish = async () => {
    try {
      await softCreditCheckFinishedByPrimaryApplicant({
        variables: {
          softCreditCheckId: softCreditCheck?.id,
        },
      });
      refetchProjectTasks();
      addNotification({
        variant: 'success',
        content: 'The Soft credit check finished successfully!',
        type: 'snackbar',
      });
      sendEvent('Secure/Task-Finished');
      onWizardClose();
    } catch (error) {
      addNotification({
        variant: 'danger',
        content: 'Failed to finish the Soft credit check!',
        type: 'snackbar',
      });
      logger.error(
        'Soft credit check finished by primary applicant error:',
        error,
      );
    }
  };

  const handleClose = ({completed, isFinalStep}) => {
    setIsFinalWizardStep(isFinalStep);

    if (completed) {
      if (isFinalStep) {
        handleFinish();
      }
      onWizardClose();
      return;
    }

    setShowConfirmation(true);
  };

  const onRejectConfirmation = () => {
    setShowConfirmation(false);
  };

  const onAcceptConfirmation = async () => {
    if (isFinalWizardStep) {
      handleFinish();
    }

    await markTaskAsOutstanding({variables: {taskId: task.id}});
    refetchProjectTasks();
    setShowConfirmation(false);
    onWizardClose();
  };

  return (
    <>
      <BorrowersWizard
        task={task}
        onClose={handleClose}
        onHandleFinish={handleFinish}
        softCreditCheck={softCreditCheck}
        isFinishedByPrimaryApplicantLoading={isFinishing}
        hasCoBorrower={hasCoBorrower}
      />
      <ConfirmCloseTaskModal
        loading={loading}
        onCancelConfirm={onRejectConfirmation}
        onConfirm={onAcceptConfirmation}
        show={showConfirmation}
      />
    </>
  );
};

Wizard.propTypes = {
  onWizardClose: PropTypes.func,
  project: PropTypes.object.isRequired,
  task: PropTypes.shape({
    taskType: PropTypes.string,
    id: validateUuidProp,
    softCreditCheck: PropTypes.shape({
      id: validateUuidProp,
    }),
  }),
};

export default Wizard;
