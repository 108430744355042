import React from 'react';

const MultiUnitHome = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 0H0V18H2V14H4V18H14V0ZM6 2H2V6H6V2ZM2 8H6V12H2V8ZM8 2H12V6H8V2ZM12 8H8V12H12V8Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default MultiUnitHome;
