import React from 'react';

import PropTypes from 'prop-types';

import {Flex, ModalWithButtons, Text} from '@renofi/components-internal';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

const ConfirmRemoveAsset = ({
  firstName,
  assetType,
  loading,
  onAccept,
  onClose,
}) => {
  const label = humanizeSnakeCase(assetType) || 'asset type';

  return (
    <ModalWithButtons
      acceptLabel="Yes, remove"
      pb={64}
      loading={loading}
      show
      rejectLabel="No, cancel"
      onClose={() => onClose(null)}
      onAccept={onAccept}
      onReject={() => onClose(null)}>
      <Flex p={24} flexDirection="column">
        <Text fontSize={24} fontWeight="bold" textAlign="center">
          Are you sure you want to remove {label} as an asset?
        </Text>
        <Text fontSize={16} mt={3} textAlign="center">
          {label} will not be considered in determining {firstName}'s
          qualification for this loan.
        </Text>
      </Flex>
    </ModalWithButtons>
  );
};

ConfirmRemoveAsset.propTypes = {
  firstName: PropTypes.string.isRequired,
  assetType: PropTypes.string,
  loading: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmRemoveAsset;
