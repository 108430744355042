import React, {useCallback, useEffect, useRef, useState} from 'react';

import PropTypes from 'prop-types';
import rejectContractorDocsByTask from 'lib/rejectContractorDocsByTask';
import {TaskItem} from 'modules/layout';
import {TASK_STATUSES, useCurrentTask} from 'modules/project';

import {usePrevious} from '@renofi/utilities/src/hooks';
import {DocumentViewer} from '@renofi/components-internal';
import {validateUuidProp} from '@renofi/utilities/src/react';

import AddEditTaskButton from '../../../AddEditTaskButton';
import {Wrapper, TaskWrapper} from '../../../styled';
import CompletedTaskDocumentsAlert from '../CompletedTaskDocumentsAlert';
import DocumentList from '../DocumentList';

const WizardUploadTask = ({
  customAddEditButton: CustomAddEditButton,
  extra: ExtraDetails,
  extraDetailsProps = {},
  skipExtraDetails,
  onRemoveFile,
  onShowRejection,
  onTaskComplete,
  showDetails = false,
  showTaskDocuments = true,
  task,
  wizard: Wizard,
  wizardProps = {},
  children = null,
  ...props
}) => {
  const {canHaveDocument, id, status, title} = task;
  const documents = rejectContractorDocsByTask(task);
  const ref = useRef();
  const prevStatus = usePrevious(status);
  const [currentDocumentId, setCurrentDocumentId] = useState();

  const facet = props.facet || task.facet;
  const {isCompleted} = useCurrentTask({taskId: id, facet});
  const Button = CustomAddEditButton || AddEditTaskButton;

  const statusChangeCallback = useCallback(() => {
    if ([TASK_STATUSES.processing, TASK_STATUSES.completed].includes(status)) {
      onTaskComplete(id);
    }
  }, [id, status]);

  useEffect(() => {
    if (status !== prevStatus) {
      statusChangeCallback();
    }
  }, [status, prevStatus]);

  return (
    <>
      <TaskItem
        button={
          <Button
            taskId={id}
            facet={facet}
            wizard={Wizard}
            wizardProps={wizardProps}
          />
        }
        skipExtraDetails={skipExtraDetails}
        id={id}
        task={task}>
        <Wrapper>
          <TaskWrapper>
            {showDetails && (
              <>
                {Boolean(ExtraDetails) && (
                  <ExtraDetails
                    onClickDocument={setCurrentDocumentId}
                    task={task}
                    {...extraDetailsProps}
                  />
                )}
              </>
            )}
            {showTaskDocuments && (
              <>
                <DocumentList
                  documents={documents}
                  onClick={setCurrentDocumentId}
                  onRemoveFile={onRemoveFile}
                  onShowRejection={onShowRejection}
                />
                {canHaveDocument && isCompleted && (
                  <CompletedTaskDocumentsAlert taskTitle={title} />
                )}
              </>
            )}

            {children}
          </TaskWrapper>
        </Wrapper>
      </TaskItem>

      {currentDocumentId && (
        <DocumentViewer
          hasControls
          selectable
          itemId={currentDocumentId}
          visible={Boolean(currentDocumentId)}
          documents={documents}
          onPrev={setCurrentDocumentId}
          onNext={setCurrentDocumentId}
          onClose={() => setCurrentDocumentId(null)}
          containerRef={ref}
          onClick={(event) => event.stopPropagation()}
          showCloseButton
          showBackButton={false}
        />
      )}
    </>
  );
};

WizardUploadTask.propTypes = {
  customAddEditButton: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.any,
  ]),
  extra: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  facet: PropTypes.string,
  children: PropTypes.node,
  onRemoveFile: PropTypes.func,
  skipExtraDetails: PropTypes.bool,
  extraDetailsProps: PropTypes.object,
  onShowRejection: PropTypes.func,
  onTaskComplete: PropTypes.func.isRequired,
  showCoBorrowerIcon: PropTypes.bool,
  showDetails: PropTypes.bool,
  showTaskDocuments: PropTypes.bool,
  task: PropTypes.shape({
    allDocumentsReviewed: PropTypes.bool,
    canHaveDocument: PropTypes.bool,
    details: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        id: validateUuidProp,
      }),
    ),
    facet: PropTypes.string,
    id: validateUuidProp,
    status: PropTypes.string,
    title: PropTypes.string,
  }),
  wizard: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  wizardProps: PropTypes.object,
};

export default WizardUploadTask;
