import {gql} from '@apollo/client';

import {
  additionalDocumentRequest,
  assetDetails,
  contractor,
  productDetailsConfirmation,
  advisorFinalCallSchedule,
  certificateAcknowledgement,
} from '@renofi/graphql/src/partials';

export default gql`
  mutation createTasks($projectId: ID!, $tasks: [CreateTaskInputObject!]!) {
    createTasks(projectId: $projectId, tasks: $tasks){
      tasks {
        additionalDocumentRequest {
          ${additionalDocumentRequest}
        }
        allDocumentsAccepted
        allDocumentsReviewed
        appraisalPropertyPhotos {
          id
        }
        assetDetails {
          ${assetDetails}
        }
        canHaveDocument
        completedAt
        contractorInformations {
          ${contractor}
          duplicateOfContractor {
            id
            businessName
            state
            website
            zipCode
          }
          gcddReview {
            contractor {
              id
            }
            id
          }
        }
        documents {
          id
          documentType
          fileName
          objectName
          status
        }
        facet
        id
        informationKind
        status
        taskType
        title
        productDetailsConfirmation {
          ${productDetailsConfirmation}
        }
        advisorFinalCallSchedule {
          ${advisorFinalCallSchedule}
        }
        certificateAcknowledgement {
          ${certificateAcknowledgement}
        }
        realEstateOwned {
          additionalProperties {
            id
          }
          soldProperties {
            id
          }
          subjectProperty {
            id
          }
        }
      }
    }
  }
`;
