import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';

export const Container = styled(Flex)({
  cursor: 'pointer',
});

export const TimeLeft = styled('div')({
  color: basic55,
  fontSize: 12,
  fontStyle: 'italic',
  lineHeight: '20px',
  marginTop: 8,
});
