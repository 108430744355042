import React, {useEffect, useMemo, useRef, useState} from 'react';

import PropTypes from 'prop-types';
import {propOr, omit} from 'ramda';

import {small} from '@renofi/theme/src/breakpoints';

import Modal from '../Modal';
import Progress from '../Progress';

import WizardFooter from './WizardFooter';
import {
  Container,
  Content,
  Panel,
  footerCss,
  DEFAULT_MODAL_WIDTH,
} from './styled';

const Wizard = ({
  children,
  clickBackdropToClose = false,
  disableEscape = false,
  steps,
  onClose,
  width: modalWidth,
  onRemoveStep,
  onSelectStep,
  panelWidth,
  showProgress = true,
  ...props
}) => {
  const [forceProgress, setForceProgress] = useState(false);
  const ref = useRef(null);

  const modalProps = omit(
    ['onNext', 'onPrev', 'onStart', 'onFinish', 'loading', 'start', 'final'],
    props,
  );

  useEffect(() => {
    const observer = new ResizeObserver(([{target}]) => {
      const bounds = target.getBoundingClientRect();
      const width = propOr(null, 'width', bounds);
      if (width > small) {
        setForceProgress(false);
      }
    });
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  const progressData = useMemo(() => {
    return steps.map(({complete, current, ...step}) => ({
      ...step,
      current,
      complete: complete && !current,
      simple: true,
      clickable: true,
    }));
  }, [steps]);

  return (
    <Modal
      lock
      p={0}
      clickBackdropToClose={clickBackdropToClose}
      disableEscape={disableEscape}
      width={modalWidth || DEFAULT_MODAL_WIDTH}
      onClose={onClose}
      {...modalProps}
      footerCss={footerCss}
      className="wizard"
      footer={
        <WizardFooter
          forceProgress={forceProgress}
          setForceProgress={setForceProgress}
          onClose={onClose}
          {...props}
        />
      }>
      <Container css={props?.containerCss} ref={ref}>
        {showProgress && (
          <Panel force={forceProgress} panelWidth={panelWidth}>
            <Progress
              flat
              data={progressData}
              onRemoveItem={onRemoveStep}
              onSelectItem={onSelectStep}
            />
          </Panel>
        )}
        <Content>{children}</Content>
      </Container>
    </Modal>
  );
};

Wizard.propTypes = {
  clickBackdropToClose: PropTypes.bool,
  disableEscape: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  final: PropTypes.bool.isRequired,
  finalLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  prevLabel: PropTypes.string,
  start: PropTypes.bool,
  startLabel: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      complete: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      current: PropTypes.bool,
      info: PropTypes.string,
      removable: PropTypes.bool,
      key: PropTypes.string,
      path: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
    }),
  ),
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  onStart: PropTypes.func,
  handleSaveAndFinish: PropTypes.func,
  onRemoveStep: PropTypes.func,
  onSelectStep: PropTypes.func,
  width: PropTypes.number,
  panelWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withBackOnFinal: PropTypes.bool,
  hidePrev: PropTypes.bool,
  showNext: PropTypes.bool,
  showProgress: PropTypes.bool,
  tooltipText: PropTypes.node,
  finalButtonVariant: PropTypes.string,
  containerCss: PropTypes.object,
};

export default React.memo(Wizard);
