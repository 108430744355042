import React from 'react';

import PropTypes from 'prop-types';

import {Text, Flex} from '@renofi/components-internal';
import {basic80, successLight} from '@renofi/theme/src/colors';
import {CheckGreen} from '@renofi/icons/src';

function ContractItem({text}) {
  return (
    <Flex alignItems="center">
      <CheckGreen color={successLight} height={18} width={18} />
      <Text color={basic80} ml={2}>
        {text}
      </Text>
    </Flex>
  );
}

ContractItem.propTypes = {
  text: PropTypes.string,
};

export default ContractItem;
