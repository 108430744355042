import * as React from 'react';

export default () => (
  <svg width="39" height="39" viewBox="0 0 39 39" fill="none">
    <circle cx="19.3889" cy="19.3098" r="3.39862" fill="#FF5253" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6353 10.9678H15.1387C13.9029 10.9678 12.8215 11.354 12.1263 12.0492C11.4311 12.7443 11.0449 13.8257 11.0449 15.0616V23.5581C11.0449 24.794 11.4311 25.8754 12.2035 26.6478C12.976 27.3429 13.9801 27.7292 15.216 27.7292H23.6353C24.8711 27.7292 25.9525 27.3429 26.6477 26.6478C27.4201 25.9526 27.8063 24.8712 27.8063 23.6354V15.1388C27.8063 13.9029 27.4201 12.8988 26.7249 12.1264C25.9525 11.354 24.9484 10.9678 23.6353 10.9678ZM19.387 24.5623C16.4518 24.5623 14.1346 22.1678 14.1346 19.3098C14.1346 16.3747 16.5291 14.0574 19.387 14.0574C22.2449 14.0574 24.7166 16.3747 24.7166 19.3098C24.7166 22.245 22.3222 24.5623 19.387 24.5623ZM23.6353 13.8257C23.6353 14.5209 24.176 15.0616 24.8711 15.0616C25.5663 15.0616 26.107 14.5209 26.107 13.8257C26.107 13.1305 25.5663 12.5898 24.8711 12.5898C24.176 12.5898 23.6353 13.1305 23.6353 13.8257Z"
      fill="#FF5253"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.541016 19.1557C0.541016 8.65085 9.03757 0.154297 19.5424 0.154297C30.0472 0.154297 38.621 8.65085 38.5438 19.1557C38.5438 29.6605 30.0472 38.1571 19.5424 38.1571C9.03757 38.1571 0.541016 29.6605 0.541016 19.1557ZM27.9617 28.0384C29.0431 26.9571 29.661 25.4122 29.661 23.6357V15.1391C29.661 13.3626 29.1203 11.895 28.0389 10.8136C26.9576 9.73223 25.49 9.1143 23.7134 9.1143H15.2169C11.5865 9.1143 9.19205 11.586 9.19205 15.1391V23.6357C9.19205 25.4122 9.73274 26.9571 10.8914 28.0384C11.9727 29.1198 13.5176 29.6605 15.2169 29.6605H23.6362C25.3355 29.6605 26.8803 29.1198 27.9617 28.0384Z"
      fill="#FF5253"
    />
  </svg>
);
