import {last, split} from 'ramda';
import {validate} from 'uuid';

export const STATUS_PENDING = 'pendingReview';
export const STATUS_COMPLETED = 'completed';
export const STATUS_OUTSTANDING = 'outstanding';
export const STATUS_REJECTED = 'rejected';

export const TASK_CATEGORIES = {
  ADDITIONAL_DOCUMENTS: 'additional_documents',
  PRODUCT: 'product_confirmation',
  RENOVATION: 'renovation',
  CONTRACTOR: 'contractor',
  APPRAISAL: 'appraisal',
  INSURANCE: 'insurance',
  BORROWERS: 'borrower_information',
  ELIGIBILITY: 'eligibility',
};

export const TASK_STATUSES = {
  outstanding: 'outstanding',
  completed: 'completed',
  processing: 'processing',
};

export const CARDS_INFO = [
  {
    id: TASK_CATEGORIES.ADDITIONAL_DOCUMENTS,
    title: 'Additional documents required',
    content:
      'Additional documents are required for your RenoFi loan application. Please upload these documents to avoid any unnecessary delays with your loan application.',
  },
  {
    id: TASK_CATEGORIES.PRODUCT,
    title: 'Product details',
    content:
      'Review and confirm that your loan type and details are ready to share with the lender.',
  },
  {
    id: TASK_CATEGORIES.CONTRACTOR,
    title: ({history} = {}) => {
      const {pathname = ''} = history?.location || {};
      const path = last(split('/', pathname));

      return validate(path) || path === 'new'
        ? 'Add a contractor'
        : 'Contractor due diligence';
    },
    content:
      'Contractor due diligence is RenoFi’s process of vetting and verifying the contractor(s) you’ve chosen to complete your renovation project. We will require their contacts details before we can begin.',
  },
  {
    id: TASK_CATEGORIES.BORROWERS,
    title: 'Borrowers',
    content:
      'Lenders will assess the ability to repay the loan by reviewing assets, income, employment history, residence history, personal information, government ID, and credit history.',
  },
  {
    id: TASK_CATEGORIES.RENOVATION,
    title: 'Renovation',
    content:
      "In order to fully understand the project's scope, we request that you provide us with any renovation plans you have and a copy of the contract between you and your contractor(s), which should include a detailed cost estimate. We will also schedule a thorough appraisal of your property, to help us determine the current and future value of your home.",
  },
  {
    id: TASK_CATEGORIES.ELIGIBILITY,
    title: 'Income and assets',
    content:
      'Please provide details of your employment history and documents about your income, assets, debt, and other information that will allow us to determine your eligibility for a new RenoFi loan.',
  },
];

export const STATUS_INTRO_COMMON =
  'Next up, we need you to complete several tasks listed below. RenoFi will then review these tasks and mark them as completed if everything is in order';
export const STATUS_INTRO_COMPLETED =
  'You have 0 outstanding tasks to complete.';
export const STATUS_INTRO_LENDER_SUFFIX =
  ' before you can formally apply with the Lender.';

export const TASK_ID_KEY = 'engagement:firstUpload:taskId';
