import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme';

const SvgPencil = ({color = basic20, ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          id="pencil"
          d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
          fill={color}
        />
      </g>
    </svg>
  );
};

SvgPencil.propTypes = {
  color: PropTypes.string,
};
export default SvgPencil;
