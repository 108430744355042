import {gql} from '@apollo/client';

import {loanTypeValue} from '../../partials';

export default gql`
  query loanTypeValues {
    loanTypeValues {
      ${loanTypeValue}
    }
  }
`;
