import {documentWithAllProps} from '../document';

export default `
annualInsurancePremium
annualMortgageTaxes
coborrowerOwned
documents {
  ${documentWithAllProps}
}
documentIds
existingMortgageLoan
homeownersAssociationFee
homeownersAssociationFeesIncluded
homeownersAssociationFeesExist
id
mortgageAccountNumber
mortgageBalance
mortgageCompany
mortgageCompanyOther
mortgageInterestRate
mortgageMonthlyPayment
mortgageStatementDate
primaryBorrowerOwned
propertyType
taskId
taxAndInsuranceIncluded
yearPurchased
`;
