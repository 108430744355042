import React from 'react';

import PropTypes from 'prop-types';
import {isNil} from 'ramda';

import {ButtonGroup, Box} from '@renofi/components-internal';

import {Content, Title} from '../styled';

const MortgageDetailsStep = ({
  onChange,
  address,
  existingMortgageLoan,
  taxAndInsuranceIncluded,
  homeownersAssociationFeesIncluded,
  homeownersAssociationFeesExist,
}) => {
  const isTaxAndInsuranceIncluded = !isNil(taxAndInsuranceIncluded);

  function onChangeValue(key, newValue) {
    return onChange(key, newValue);
  }

  return (
    <Content>
      <Title>
        Is there an existing <strong>mortgage loan</strong> for the {address}{' '}
        property?
      </Title>
      <Box>
        <ButtonGroup
          buttonsWidth={260}
          value={existingMortgageLoan}
          onChange={(value) => {
            onChangeValue('existingMortgageLoan', value);
          }}
        />
      </Box>

      {existingMortgageLoan ? (
        <Box>
          <Title>
            Are <strong>taxes & insurance</strong> included in the monthly
            mortgage payment?
          </Title>
          <ButtonGroup
            buttonsWidth={260}
            value={taxAndInsuranceIncluded}
            onChange={(value) => {
              onChangeValue('taxAndInsuranceIncluded', value);
            }}
          />
        </Box>
      ) : null}

      {isTaxAndInsuranceIncluded ? (
        <>
          <Box>
            <Title>
              Are there <strong>homeowners association fees</strong> (HOA) on
              the property?
            </Title>
            <ButtonGroup
              buttonsWidth={260}
              value={homeownersAssociationFeesExist}
              onChange={(value) => {
                onChangeValue('homeownersAssociationFeesExist', value);
              }}
            />
          </Box>

          {existingMortgageLoan && homeownersAssociationFeesExist ? (
            <Box>
              <Title>
                Are <strong>homeowners association fees</strong> (HOA) included
                in the monthly mortgage payment?
              </Title>
              <ButtonGroup
                buttonsWidth={260}
                value={homeownersAssociationFeesIncluded}
                onChange={(value) => {
                  onChangeValue('homeownersAssociationFeesIncluded', value);
                }}
              />
            </Box>
          ) : null}
        </>
      ) : null}
    </Content>
  );
};

MortgageDetailsStep.propTypes = {
  onChange: PropTypes.func.isRequired,
  address: PropTypes.string,
  existingMortgageLoan: PropTypes.bool,
  taxAndInsuranceIncluded: PropTypes.bool,
  homeownersAssociationFeesExist: PropTypes.bool,
  homeownersAssociationFeesIncluded: PropTypes.bool,
};

export default MortgageDetailsStep;
