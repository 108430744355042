import {formatDate} from './formatDate';

export const diffMonths = (dateTo, dateFrom) => {
  const diff =
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear());

  if (diff <= 0) {
    return `${Math.abs(diff)} months behind`;
  }

  return `${diff} months ahead`;
};

export const addMonths = (numOfMonths, date = new Date()) => {
  const dateCopy = new Date(date.getTime());
  dateCopy.setMonth(dateCopy.getMonth() + numOfMonths);
  return formatDate(dateCopy);
};
