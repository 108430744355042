import React from 'react';

const Condo = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H14V18H0V0ZM2 2H4V4H2V2ZM4 6H2V8H4V6ZM2 10H4V12H2V10ZM4 14H2V16H4V14ZM6 6H8V8H6V6ZM8 2H6V4H8V2ZM10 6H12V8H10V6ZM8 10H6V12H8V10ZM6 14H8V16H6V14ZM12 2H10V4H12V2ZM10 10H12V12H10V10ZM12 14H10V16H12V14Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default Condo;
