import React from 'react';

import PropTypes from 'prop-types';

import Flex from '@renofi/components-internal/src/Flex';
import SvgCross from '@renofi/icons/src/Cross';
import SideMenuIcon from '@renofi/icons/src/SideMenu';
import {mobile} from '@renofi/theme';

import Logo from './components/Logo';
import {
  StyledDrawer,
  ContentWrapper,
  LogoSection,
  CloseIcon,
  SideMenuButton,
} from './FixedDrawer.styles';

const FixedDrawer = ({
  children,
  lender,
  open = true,
  onClose,
  onOpen,
  top = 0,
  ...props
}) => {
  return (
    <Flex flexDirection="column" alignItems="center">
      <StyledDrawer
        open={open}
        show={open}
        p={20}
        fixed
        top={top}
        min={mobile}
        {...props}>
        <ContentWrapper>
          <LogoSection>
            {open && (
              <CloseIcon onClick={onClose}>
                <SvgCross />
              </CloseIcon>
            )}
            {!open && (
              <SideMenuButton onClick={onOpen}>
                <SideMenuIcon />
              </SideMenuButton>
            )}
            <Logo lender={lender} />
          </LogoSection>
          {children}
        </ContentWrapper>
      </StyledDrawer>
    </Flex>
  );
};

FixedDrawer.propTypes = {
  lender: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  children: PropTypes.node,
  top: PropTypes.number,
};

export default FixedDrawer;
