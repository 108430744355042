import styled from '@emotion/styled';

import {Flex, Text} from '@renofi/components-internal';
import {
  basic80,
  successExtraLight,
  successDark,
} from '@renofi/theme/src/colors';
import CommonCheckIcon from '@renofi/icons/src/DoubleCheck';

export const Card = styled(Flex)(
  {
    borderRadius: 8,
    flexDirection: 'column',
    padding: 16,
    marginTop: 12,
    '.reviewed-text': {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  ({success}) => ({
    backgroundColor: success ? successExtraLight : '#FAFAFA',
    border: success ? `1px solid ${successDark}` : '1px solid #E9E9E9',
  }),
);

export const Container = styled(Flex)({
  fontSize: 14,
  lineHeight: '24px',
  flexWrap: 'wrap',
});

export const Item = styled(Flex)({});

export const Label = styled(Text)({
  color: basic80,
});

export const BorrowerLabel = styled.div({
  fontSize: 16,
  lineHeight: '24px',
  color: basic80,
});

export const Value = styled.span({
  color: '#00A0FF',
  maxWidth: 200,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const DoubleCheckIcon = styled(CommonCheckIcon)({
  width: 16,
  height: 16,
  marginRight: 4,
});
