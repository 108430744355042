import React from 'react';

import PropTypes from 'prop-types';
import {ManageDocuments} from 'modules/tasks-wizard';

import {Box} from '@renofi/components-internal';
import {sendEvent} from '@renofi/utilities/src/analytics/utils';

import {GOV_ID_EVENTS} from '../constants';
import {PageWrapper, PageTitle} from '../styled';

import DescriptionCard from './DescriptionCard';

const UploadIdPage = ({
  borrowerName,
  selectedIdType,
  borrowerDocuments,
  govIdDetails,
  taskId,
}) => {
  const {borrowerRole = 'borrower'} = govIdDetails || {};
  const isPrimaryBorrower = borrowerRole === 'borrower';

  const onAcceptedFiles = (files) => {
    const eventName = isPrimaryBorrower
      ? GOV_ID_EVENTS.BORROWER_DOC_UPLOAD
      : GOV_ID_EVENTS.CO_BORROWER_DOC_UPLOAD;
    const ids = files.map((d) => d.id);
    sendEvent(`Secure/${eventName}`, {ids});
  };

  const mainLabel = `Upload a copy of ${
    borrowerName || 'borrower'
  }'s ${selectedIdType?.label.toLowerCase()}`;

  return (
    <PageWrapper mb={70}>
      <PageTitle>{mainLabel}</PageTitle>
      <DescriptionCard selectedIdType={selectedIdType} />

      <Box mt={24}>
        <ManageDocuments
          customDocuments={borrowerDocuments}
          customMutationParams={{borrowerRole}}
          minimize
          facet="eligibility"
          onUploadComplete={onAcceptedFiles}
          taskId={taskId}
        />
      </Box>
    </PageWrapper>
  );
};

UploadIdPage.propTypes = {
  selectedIdType: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  setWizardIsLoading: PropTypes.func,
  borrowerName: PropTypes.string,
  borrowerDocuments: PropTypes.array,
  isUploadPage: PropTypes.bool,
  govIdDetails: PropTypes.object,
  taskId: PropTypes.string.isRequired,
};

export default UploadIdPage;
