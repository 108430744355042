export default function useTogglePropertySteps({isSubjectProperty} = {}) {
  return (toggleStep, route, property) => {
    const {
      existingMortgageLoan,
      taxAndInsuranceIncluded,
      homeownersAssociationFeesIncluded,
      homeownersAssociationFeesExist,
      isLandLoan,
    } = property || {};

    toggleStep(
      `${route}/mortgage-statement`,
      existingMortgageLoan === undefined || existingMortgageLoan === true,
    );
    toggleStep(
      `${route}/insurance`,
      (isSubjectProperty && !isLandLoan) ||
        (!isSubjectProperty &&
          (taxAndInsuranceIncluded === false ||
            existingMortgageLoan === false)),
    );
    toggleStep(
      `${route}/tax`,
      taxAndInsuranceIncluded === false || existingMortgageLoan === false,
    );
    toggleStep(
      `${route}/fees`,
      homeownersAssociationFeesExist === true &&
        homeownersAssociationFeesIncluded === false,
    );
  };
}
