import {useEffect} from 'react';

import {showSurvey} from '@renofi/utilities/src/analytics';
import {getFullName} from '@renofi/utilities/src/data';
import {useToggledBorrowerProject} from '@renofi/graphql';

const Delighted = () => {
  const {project} = useToggledBorrowerProject();
  const {borrowersAndPropertyInformation: propertyInfo, id} = project || {};

  useEffect(() => {
    if (propertyInfo?.applicantSubmittedAt) {
      showSurvey({
        id: id,
        email: project.borrower?.email,
        name: getFullName(propertyInfo),
        stageName: project.stageName,
        uploadedDocumentsCount: project.documents?.length,
        loanType: project.loanType,
      });
    }
  }, [propertyInfo?.applicantSubmittedAt]);

  return null;
};

export default Delighted;
