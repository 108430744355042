import {FIVE_MINUTES} from './constants';

export const getRecordData = ({
  renovationStartedOn,
  expectedRenovationStart,
  loanClosedOn,
}) => {
  if (!renovationStartedOn && !expectedRenovationStart && !loanClosedOn) {
    return null;
  }

  if (renovationStartedOn) {
    return {
      label: 'Renovation Started',
      value: renovationStartedOn,
    };
  }

  if (expectedRenovationStart) {
    return {
      label: 'Expected Renovation Start',
      value: expectedRenovationStart,
    };
  }

  return {
    label: 'Loan Closed',
    value: loanClosedOn,
  };
};

export const isFresh = (value) => {
  const date = new Date(value);
  const curr = Date.now();
  const diff = curr - date.getTime();
  return diff < FIVE_MINUTES;
};
