import React, {useContext} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import {
  WizardUploadTask,
  useApplicationState,
  useTaskHandlers,
} from 'modules/project';
import {FacetIntro} from 'modules/layout';

import {Alert} from '@renofi/components-internal';
import Card from '@renofi/components-internal/src/next/Card';

import AdditionalDocumentsWizard from './components/Wizard';

const AdditionalDocuments = ({onTaskComplete, projectId}) => {
  const {facet} = useParams();
  const {additionalDocuments} = useContext(ProjectTasksContext);

  const {onShowPrompt} = useTaskHandlers({
    tasks: additionalDocuments,
    projectId,
    facet,
  });
  const {onShowRejection} = useApplicationState();

  return (
    <Card p={0}>
      <FacetIntro>
        <Alert>
          Additional documents are required for your RenoFi loan application.
          Please upload these documents to avoid any unnecessary delays with
          your loan application.
        </Alert>
      </FacetIntro>

      {additionalDocuments.map((task) => {
        return (
          <WizardUploadTask
            key={task.id}
            onRemoveFile={(value) =>
              onShowPrompt({documentId: value, taskId: task.id})
            }
            onShowRejection={(documentId) =>
              onShowRejection({documentId, taskId: task.id})
            }
            onTaskComplete={onTaskComplete}
            showDetails
            task={task}
            wizard={AdditionalDocumentsWizard}
            wizardProps={{
              header: 'Additional documents',
            }}
          />
        );
      })}
    </Card>
  );
};

AdditionalDocuments.propTypes = {
  onTaskComplete: PropTypes.func,
  projectId: PropTypes.string.isRequired,
};

export default AdditionalDocuments;
