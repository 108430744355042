import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {SUBMIT_RESIDENTIAL_ADDRESS} from '../../../mutations/renovation';

export default function useSubmitResidentialAddress({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitResidentialAddress, {data, loading, error}] = useMutation(
    SUBMIT_RESIDENTIAL_ADDRESS,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = propOr(null, ['submitResidentialAddress'], data);
  return {submitResidentialAddress, response, loading, error};
}
