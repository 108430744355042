import React, {useState, useEffect, useRef} from 'react';

import PropTypes from 'prop-types';
import {all, is, isNil, range, update} from 'ramda';

import {Field, Wrapper} from './styled';

const isNumeric = (value) => value && !isNaN(value);

const CodeField = ({size = 4, failure, success, onChange, onComplete}) => {
  const empty = range(0, size);
  const refs = empty.map(() => useRef(null));
  const [values, setValues] = useState(empty.map(() => ''));

  useEffect(() => {
    if (all(isNumeric, values) && values.length === size) {
      onComplete(values.join(''));
    }
  }, [values]);

  function isNextEmpty(index) {
    return !values[index + 1];
  }

  function isLast(index) {
    return index >= size - 1;
  }

  function setValue(value, index) {
    setValues(update(index, isNil(value) ? '' : value.toString(), values));
  }

  function onInputChange(e, index) {
    const value = Number(e.target.value);
    if (!is(Number)(value) || isNaN(value)) return;

    setValue(value, index);
    e.target.blur();
    onChange(values.join(''));

    if (isNextEmpty(index) && !isLast(index)) {
      setTimeout(() => {
        refs[index + 1].current.focus();
      });
    }
  }

  return (
    <Wrapper size={size}>
      {refs.map((ref, index) => (
        <Field
          ref={ref}
          key={index}
          failure={failure}
          success={success}
          empty={!values[index]}
          value={values[index]}
          onChange={(e) => onInputChange(e, index)}
          onFocus={() => {
            setValue('', index);
          }}
          maxLength={1}
          minLength={1}
          max={9}
          min={0}
          inputmode="numeric"
          pattern="[0-9]"
        />
      ))}
    </Wrapper>
  );
};

CodeField.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  success: PropTypes.bool,
  failure: PropTypes.bool,
  size: PropTypes.number,
};

export default CodeField;
