export const ADD_EDIT_BUTTON_DEFAULT = 'View details';

export const ADD_EDIT_BUTTON_SUFFIX_TYPES = {
  ack_delta: false,
  additional_documents: 'document',
  additional_eligibility_evidence: 'evidence',
  advisor_final_call_schedule: 'schedule',
  appraisal_comparable_sales: 'comparable sales',
  appraisal_property_details: 'property details',
  appraisal_property_photos: 'property photos',
  appraisal_recent_transactions: 'details',
  appraisal_renovation_details: 'renovation details',
  contractor_info: 'contractor details',
  employment_history: 'employment details',
  government_issued_id: 'ID',
  home_insurance: 'proof of insurance',
  income: 'income',
  insurance_coverage_proof: 'proof of coverage',
  mortgage_statement: 'statements',
  product_details_confirmation: '',
  renovation_contract: 'contract',
  renovation_plan: 'renovation docs',
  sms_consent_renovation_progress: false,
  soft_credit_check: '',
  tax_returns_docs: 'returns',
  identity_verification: 'Verify Identity',
};
