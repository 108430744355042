import styled from '@emotion/styled';

import {Text, Box, Flex, Button} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';
import {basic55, renofiBlue, basic80} from '@renofi/theme/src/colors';

export const Wrapper = styled(Flex)({
  flexDirection: 'column',
  alignItems: 'center',
  padding: '60px 40px 60px 40px',
  [maxSmall]: {
    padding: '60px 25px 40px 25px',
  },
});

export const WelcomeWrapper = styled(Flex)({
  flexDirection: 'column',
  alignItems: 'center',
  padding: 40,
  [maxSmall]: {
    padding: '40px 25px',
  },
});

export const ButtonWrapper = styled(Flex)({
  display: 'flex',
  width: '40%',
  maxWidth: '240px',
  marginTop: 50,
  [maxSmall]: {
    width: '85%',
    maxWidth: '85%',
  },
});

export const WelcomeTitle = styled(Box)({
  fontSize: 32,
  lineHeight: '38px',
  color: renofiBlue,
  marginBottom: 32,
  [maxSmall]: {
    fontSize: 28,
  },
});

export const WelcomeText = styled(Text)({
  lineHeight: '28px',
  fontSize: 20,
  color: basic55,
  marginTop: 25,
  textAlign: 'center',
});

export const MainContent = styled(Box)({
  fontSize: 24,
  lineHeight: '36px',
  color: basic80,
  marginBottom: 20,
  [maxSmall]: {
    textAlign: 'center',
  },
});

export const AdditionalContent = styled(Box)({
  fontSize: 18,
  lineHeight: '28px',
  color: basic80,
});

export const StyledActionButton = styled(Button)(({position}) => ({
  width: '50%',
  height: 45,
  borderRadius: 0,
  borderColor: basic55,
  ':hover': {
    color: basic80,
    borderColor: basic55,
  },
  ...(position === 'left'
    ? {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderRightWidth: 0,
        ':hover': {
          borderColor: basic55,
          borderRightWidth: 0,
          color: basic80,
          [maxSmall]: {
            ':hover': {
              backgroundColor: '#fff !important',
            },
          },
        },
      }
    : {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      }),
  [maxSmall]: {
    ':hover': {
      cursor: 'pointer !important',
      backgroundColor: '#fff !important',
      color: '#737373 !important',
    },
    ':disabled:hover': {
      cursor: 'pointer !important',
      backgroundColor: '#E5E5E5 !important',
      color: '#737373 !important',
    },
  },
}));
