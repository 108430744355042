import React, {useContext} from 'react';

import PropTypes from 'prop-types';
import lazy from 'lib/asyncComponent';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import {TasksWizardContext} from 'modules/tasks-wizard';
import {TASK_CATEGORIES} from 'modules/project';

import {Loader} from '@renofi/components-internal';
import {useToggledBorrowerProject} from '@renofi/graphql';

const Wizard = lazy(
  () => import(/* webpackChunkName: "Wizard" */ './components/Wizard'),
);

const IncomeSourcesWizard = ({task, onClose}) => {
  const facet = TASK_CATEGORIES.ELIGIBILITY;
  const {projectId} = useToggledBorrowerProject();
  const {loading} = useContext(ProjectTasksContext);

  if (loading) {
    return <Loader label={false} />;
  }

  return (
    <TasksWizardContext.Consumer>
      {({dirty, setDirty}) => {
        const props = {
          facet,
          task,
          taskId: task?.id,
          projectId,
          onClose,
          dirty,
          onFinish: () => setDirty(false),
          onChange: () => setDirty(true),
        };

        return task && <Wizard {...props} />;
      }}
    </TasksWizardContext.Consumer>
  );
};

IncomeSourcesWizard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default IncomeSourcesWizard;
