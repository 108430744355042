import React, {Fragment, useState} from 'react';

import PropTypes from 'prop-types';
import {isEmpty, pathOr, uniq} from 'ramda';

import {basic55} from '@renofi/theme/src';
import {Checkbox, Text, Box, Flex} from '@renofi/components-internal';
import {useNotifications} from '@renofi/utilities/src/hooks';
import {getDocumentTypeByAssetType} from '@renofi/modules-internal';
import {
  useDeleteAssetDetail,
  useRemoveDocument,
  GET_PROJECT_TASKS,
} from '@renofi/graphql';

import ConfirmRemoveAsset from '../components/ConfirmRemoveAsset';
import {Content, Title} from '../styled';

const Assets = ({
  assets,
  setAssets,
  formData,
  taskDocuments,
  setFormData,
  setLoading,
  borrowerRole,
  borrowerName,
  coborrowerName,
  projectId,
  onChange,
  setCoBorrowerAssets,
}) => {
  const {addNotification} = useNotifications();
  const {removeDocument, loading: isRemovingDoc} = useRemoveDocument();
  const {deleteAssetDetail, loading: isDeletingAsset} = useDeleteAssetDetail({
    refetchQueries: [
      {
        query: GET_PROJECT_TASKS,
        variables: {projectId},
      },
    ],
  });

  const [assetsToRemove, setAssetsToRemove] = useState([]);
  const [showAssetRemovalConfirmation, setShowAssetRemovalConfirmation] =
    useState(false);
  const firstName = borrowerRole === 'borrower' ? borrowerName : coborrowerName;

  function onModalClose() {
    setAssetsToRemove([]);
    setShowAssetRemovalConfirmation(false);
  }

  async function removeAssets(assetsToRemove) {
    const response = await Promise.all(
      assetsToRemove.map(async (assetDetail) => {
        const response = await deleteAssetDetail({
          variables: {
            id: assetDetail.id,
          },
        });

        return pathOr(null, ['data', 'deleteAssetDetail', 'success'], response);
      }),
    );

    return response;
  }

  async function removeAssetRelatedDocuments(
    removedAssets,
    shouldRemoveCoBorrowerDocs,
  ) {
    const documentTypesToRemove = uniq(
      removedAssets.map(({assetType}) => {
        const documentType = getDocumentTypeByAssetType(assetType);
        return documentType;
      }),
    );

    const docIdsToRemove = documentTypesToRemove.reduce(
      (memo, documentType) => {
        const docToRemove = taskDocuments.filter((doc) => {
          return (
            (shouldRemoveCoBorrowerDocs || doc.borrowerRole === borrowerRole) &&
            doc.documentType === documentType
          );
        });

        docToRemove.forEach((doc) => {
          memo.push(doc.id);
        });

        return memo;
      },
      [],
    );

    if (isEmpty(docIdsToRemove)) {
      return;
    }

    const response = await Promise.all(
      docIdsToRemove.map(async (docId) => {
        const response = await removeDocument({
          variables: {
            id: docId,
          },
        });

        return pathOr(null, ['data', 'removeDocument', 'success'], response);
      }),
    );

    return response;
  }

  const updateAssets = (key, checked) => {
    setAssets({
      ...assets,
      [key]: {
        ...assets[key],
        checked,
      },
    });
  };

  const onCheckboxClick = (key) => {
    onChange();
    const newValue = !assets[key].checked;

    if (newValue) {
      updateAssets(key, newValue);
      return;
    }

    const assetsForRemoval = formData.filter((asset) => {
      return (
        asset.assetType === key &&
        asset.borrowerRole === borrowerRole &&
        !asset.isNew
      );
    });

    if (isEmpty(assetsForRemoval)) {
      updateAssets(key, newValue);
      return;
    }

    setAssetsToRemove(assetsForRemoval);
    setShowAssetRemovalConfirmation(true);
  };

  const onConfirmRemoval = async () => {
    if (isEmpty(assetsToRemove)) {
      onModalClose();
      return;
    }

    try {
      setLoading(true);

      const uncheckedAssetType = assetsToRemove[0].assetType;
      const hasCoBorrowerAccounts = formData.some((assetDetail) => {
        return (
          assetDetail.borrowerRole === 'coborrower' &&
          assetDetail.assetType === uncheckedAssetType
        );
      });

      //checks if Coborrower asset is checkedo only because of joint account
      const shouldRemoveCoborrowerDocs =
        borrowerRole === 'borrower' &&
        assetsToRemove.some(({jointAccount}) => jointAccount) &&
        !hasCoBorrowerAccounts;

      await removeAssetRelatedDocuments(
        assetsToRemove,
        shouldRemoveCoborrowerDocs,
      );
      await removeAssets(assetsToRemove);

      updateAssets(uncheckedAssetType, false);

      //Unchecks Coborrower asset if it only has jointAccount
      if (shouldRemoveCoborrowerDocs) {
        setCoBorrowerAssets({
          ...assets,
          [uncheckedAssetType]: {
            ...assets[uncheckedAssetType],
            checked: false,
          },
        });
      }

      const newFormData = formData.filter((asset) => {
        return (
          asset.borrowerRole !== borrowerRole ||
          asset.assetType !== uncheckedAssetType
        );
      });

      setFormData(newFormData);

      onModalClose();
    } catch {
      addNotification({
        variant: 'danger',
        content: 'Failed to delete asset details',
        type: 'snackbar',
      });
    } finally {
      setLoading(false);
    }
  };

  const loading = isRemovingDoc || isDeletingAsset;
  const isCoBorrower = borrowerRole === 'coborrower';

  return (
    <>
      <Content mb={50}>
        <Title mb={3}>Select {firstName}’s assets below</Title>

        <Text mb={3} color={basic55}>
          Only select assets that you want considered in determining {firstName}
          ’s qualification for this loan.
        </Text>

        <Box>
          {Object.values(assets).map(({key, label, checked}) => {
            const isDisabled =
              isCoBorrower &&
              formData.some(
                (asset) =>
                  asset.assetType === key &&
                  asset.jointAccount &&
                  asset.borrowerRole === 'borrower',
              );

            return (
              <Fragment key={key}>
                <Flex flexDirection="row" mb={10} width="100%">
                  <Checkbox
                    width={1}
                    button
                    disabled={isDisabled}
                    checked={checked}
                    onChange={() => onCheckboxClick(key)}
                    label={label}
                  />
                </Flex>
                {isDisabled ? (
                  <Text ml="20px" fontSize={12}>
                    Selected by {borrowerName} as a shared asset
                  </Text>
                ) : null}
              </Fragment>
            );
          })}
        </Box>
      </Content>
      {showAssetRemovalConfirmation && !isEmpty(assetsToRemove) ? (
        <ConfirmRemoveAsset
          assetType={assetsToRemove[0].assetType}
          onClose={onModalClose}
          firstName={firstName}
          onAccept={onConfirmRemoval}
          loading={loading}
        />
      ) : null}
    </>
  );
};

Assets.propTypes = {
  borrower: PropTypes.object,
  assets: PropTypes.object,
  setAssets: PropTypes.func,
  formData: PropTypes.array,
  taskDocuments: PropTypes.array,
  setFormData: PropTypes.func,
  setLoading: PropTypes.func,
  borrowerRole: PropTypes.string,
  borrowerName: PropTypes.string,
  coborrowerName: PropTypes.string,
  projectId: PropTypes.string,
  onChange: PropTypes.func,
  setCoBorrowerAssets: PropTypes.func,
};

PropTypes.defaultProps = {
  setCoBorrowerAssets: () => {},
};

export default Assets;
