import {documentWithAllProps} from '../document';

export default `
annualInsurancePremium
annualMortgageTaxes
annualPrivateMortgageInsurance
city
coborrowerOwned
county
currentOccupancy
currentOccupancyOther
documents {
  ${documentWithAllProps}
}
documentIds
existingMortgageLoan
homeownersAssociationFee
homeownersAssociationFeesIncluded
homeownersAssociationFeesExist
id
intendedOccupancy
marketValue
mortgageAccountNumber
mortgageBalance
mortgageBalanceSecondLien
mortgageBalanceThirdLien
mortgageCompany
mortgageCompanyOther
mortgageInterestRate
mortgageMonthlyPayment
mortgageStatementDate
primaryBorrowerOwned
propertyStatus
propertyType
rentalIncome
state
streetAddressOne
streetAddressTwo
taskId
taxAndInsuranceIncluded
yearPurchased
zipCode
`;
