export const K1_FORM_DOC_TYPE = 'k1_forms';
export const TAX_RETURNS_DOC_TYPE = 'tax_returns';

export const EMPLOYMENT_INCOME_TYPE = 'employment';
export const OTHER_INCOME_TYPE = 'other';
export const NO_INCOME_TYPE = 'no_income';
export const RENTAL_INCOME_TYPE = 'rental_income';
export const SELF_EMPLOYMENT_TYPE = 'self_employment';
export const EXPIRABLE_INCOME_TYPES = [
  'child_support',
  'alimony',
  'ira_401k_or_403b',
  'notes_receivable',
  'public_assistance',
  'royalty_payments',
];

export const IGNORE_INCOME_TYPES = [
  'bonus',
  EMPLOYMENT_INCOME_TYPE,
  'overtime',
  SELF_EMPLOYMENT_TYPE,
];

export const INCOME_SOURCES_DOCUMENT_MAP = {
  alimony: ['divorce_decree'],
  automobile_allowance: [],
  capital_gains: ['capital_gains_income', TAX_RETURNS_DOC_TYPE],
  child_support: ['child_support_income', TAX_RETURNS_DOC_TYPE],
  disability: ['disability_income'],
  [EMPLOYMENT_INCOME_TYPE]: ['pay_stubs', 'w2_forms'],
  foster_care: ['foster_care_income'],
  housing_or_parsonage: ['housing_or_parsonage_income'],
  interests_and_dividends: ['interest_and_dividends_statements'],
  ira_401k_or_403b: ['ira_401k_403_statements'],
  military: ['military_award_letter', 'form_1099r'],
  notes_receivable: [
    TAX_RETURNS_DOC_TYPE,
    K1_FORM_DOC_TYPE,
    'notes_receivable',
  ],
  [OTHER_INCOME_TYPE]: ['other_income'],
  pension_or_annuity: ['form_1099r'],
  public_assistance: ['public_assistance_documentation'],
  [RENTAL_INCOME_TYPE]: [TAX_RETURNS_DOC_TYPE, 'lease_agreement'],
  royalty_payments: [TAX_RETURNS_DOC_TYPE],
  [SELF_EMPLOYMENT_TYPE]: [TAX_RETURNS_DOC_TYPE],
  social_security: ['ss_award_letter', 'form_1099ssa'],
  trust: ['trust_income'],
  unemployment_benefits: ['form_1040'],
  [NO_INCOME_TYPE]: [],
};

export const DOCUMENT_TYPES_NICKNAME_MAP = {
  form_1040: 'Schedule 1/1040',
  form_1099r: '1099R form',
  form_1099ssa: '1099SSA form',
  pay_stubs: 'Pay stubs',
  pay_stub_year_end: 'Year end pay stubs',
  w2_forms: 'W2 forms',
  tax_returns: 'Taxes and income',
  lease_agreement: 'Lease agreement',
  [K1_FORM_DOC_TYPE]: 'K1 form(s)',
  interest_and_dividends_statements: 'Asset statements',
  military_award_letter: 'Award letter',
  ss_award_letter: 'Award letter',
  ira_401k_403_statements: 'Bank/Retirement statements',
  divorce_decree: 'Divorce decree',
  trust_income: 'Trust document',
  public_assistance_documentation: 'Benefit letter',
  notes_receivable: 'Proof of note',
  foster_care_income: 'Verification letter',
  disability_income: 'Eligibility letter',
  child_support_income: 'Court order etc',
  capital_gains_income: 'Continuance',
  other_income: 'Proof of other income',

  business_asset_statement: 'Business',
  checking_asset_statement: 'Checking',
  money_market_fund_asset_statement: 'Money market fund',
  other_liquid_asset_statement: 'Other liquid',
  retirement_asset_statement: 'Retirement',
  savings_asset_statement: 'Savings',
  stocks_asset_statement: 'Stocks',
};

export const BORROWER_ROLES = {
  BORROWER: 'borrower',
  COBORROWER: 'coborrower',
};

export const taskStatuses = {
  OUTSTANDING: 'outstanding',
  COMPLETED: 'completed',
  PROCESSING: 'processing',
};
