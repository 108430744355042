import smsConsentRenovationProgress from '../smsConsentRenovationProgress';

import lender from './lender';
import projectUser from './projectUser';
import productDetailsConfirmation from './productDetailsConfirmation';
import borrower from './borrower';
import borrowersAndPropertyInformation from './borrowersAndPropertyInformation';

import {applicant} from './index';

export default `
    id
    city
    renofiAdvisor {
      ${projectUser}
    }
    primaryApplicant {
      ${applicant}
    }
    coborrower {
      ${applicant}
    }
    borrowersAndPropertyInformation {
      ${borrowersAndPropertyInformation}
    }
    createdAt
    documents {
      checksum
      createdAt
      fileName
      id
      documentType
      objectName
      pdfObjectName
      rejectionReason
      shareWithLenderAllowed
      shareable
      source
      status
    }
    estimatedRenovationHomeValue
    fastTracked
    lender {
      ${lender}
    }
    raas
    loanValue
    loanType
    loanTypeEnum {
      crmName
      isPersonal
      isCompBid
      isRenofiLoan
      name
    }
    matchedToLenderCrmId
    owner {
      ${projectUser}
    }
    borrower {
      ${borrower}
      fullName
    }
    borrowersAndPropertyInformation {
      ${borrowersAndPropertyInformation}
    }
    positionedInterestRate
    projectAssociate {
      ${projectUser}
    }
    renovationReadyFile {
      lenderId
      lender {
        ${lender}
      }
      applicationProcess
      createdAt
      loanApplicationStatus
      transferredAt
    }
    renovationUnderwritingRequestId
    renovationUnderwritingRequest {
      id
      lenderId
      lender {
        ${lender}
      }
    }
    smsConsentRenovationProgress {
      ${smsConsentRenovationProgress}
    }
    tasks {
      borrowerFinishedAt
      status
      taskType
      title
      productDetailsConfirmation {
        ${productDetailsConfirmation}
      }
    }
    timeline {
      tpoRegistrationCompletedAt
      appraisalRequestedAt
      appraisalReportReviewFinishedAt
      completionCertificateRequestedAt
      contractorsReadyForContactAt
      renovationCompletedOn
    }
    renovationCost
    peakCltvRatio
    state
    county
    zipCode
    status
    stageName
    streetAddressOne
    streetAddressTwo
    updatedAt
    leadScenarioId
`;
