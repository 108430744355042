import styled from '@emotion/styled';

import {Flex, Text} from '@renofi/components-internal';
import {basic20} from '@renofi/theme/src';

export const Content = styled(Flex)({
  padding: '24px 20px 24px 22px',
  flexDirection: 'column',
  minHeight: 490,
});

export const CheckboxWrapper = styled(Flex)({
  marginTop: 12,
  padding: '16px 12px 12px 12px',
  border: `solid 1px ${basic20}`,
  borderRadius: 4,
  cursor: 'pointer',
});

export const CheckboxLabel = styled(Flex)({
  fontSize: 15,
  marginLeft: '8px',
  marginTop: '-1px',
});

export const Title = styled(Text)({
  marginBottom: 24,
  fontSize: 24,
  lineHeight: '29px',
});
