import React, {useRef} from 'react';

import PropTypes from 'prop-types';
import {propOr, pathOr} from 'ramda';

import ClickOutside from '../ClickOutside';

import {Heading, Items} from './styled';

function getScrollTop() {
  const pageYOffset = propOr(null, 'pageYOffset', window);
  const scrollTop = pathOr(null, ['documentElement', 'scrollTop'], document);

  if (pageYOffset) {
    return pageYOffset;
  }

  if (scrollTop) {
    return scrollTop;
  }

  return 0;
}

const List = ({
  fixed,
  label,
  local,
  position,
  rect,
  heading,
  children,
  onClose,
  overflow,
}) => {
  const clickOutsideRef = useRef(null);
  const {x, y, height, width} = rect;
  const screenWidth = document.documentElement.clientWidth;
  const left = x < screenWidth / 2;
  const scrollTop = fixed ? 0 : getScrollTop();
  const horizontalValue = left ? {left: x} : {right: screenWidth - (x + width)};

  return (
    <ClickOutside ref={clickOutsideRef} onClickOutside={onClose}>
      <Items
        onClick={onClose}
        fixed={fixed}
        cover={!label}
        local={local}
        top={y + height + 4 + scrollTop}
        width={Math.max(width, 120)}
        position={position}
        overflow={overflow}
        {...horizontalValue}>
        {heading ? <Heading>{heading}</Heading> : null}
        {children}
      </Items>
    </ClickOutside>
  );
};

List.propTypes = {
  fixed: PropTypes.bool,
  overflow: PropTypes.string,
  label: PropTypes.string,
  local: PropTypes.bool,
  position: PropTypes.string,
  rect: PropTypes.object,
  heading: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default List;
