import {isEmpty} from 'ramda';

export const isStepComplete = (formData, assetType, borrowerRole) => {
  let accounts = formData.filter((assetDetail) => {
    return (
      assetDetail.assetType === assetType &&
      assetDetail.borrowerRole === borrowerRole
    );
  });

  if (borrowerRole === 'coborrower') {
    const jointAccounts = formData.filter((assetDetail) => {
      return (
        assetDetail.assetType === assetType &&
        assetDetail.borrowerRole === 'borrower' &&
        assetDetail.jointAccount
      );
    });

    accounts = accounts.concat(jointAccounts);
  }

  if (isEmpty(accounts)) {
    return false;
  }

  return accounts.every(
    ({
      financialInstitution,
      accountHolderName,
      description,
      accountNumber,
      cashOrMarketValue,
    }) => {
      const isBusinessNameValid = assetType !== 'business' || accountHolderName;

      return Boolean(
        financialInstitution &&
          isBusinessNameValid &&
          description &&
          accountNumber &&
          cashOrMarketValue,
      );
    },
  );
};
