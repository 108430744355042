import logger from '../../logger';
import noop from '../../noop';

import useChannel from './useChannel';

export default function useDocumentPdfObjectNameUpdated({
  projectId,
  onUpdate = noop,
}) {
  useChannel({
    channelName: `document_pdf_object_name_updated--project=${projectId}`,
    onUpdate: (event) => {
      logger.debug('DOCUMENT_PDF_OBJECT_NAME_UPDATED', event);
      onUpdate(event);
    },
  });

  return {};
}
