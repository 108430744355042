import styled from '@emotion/styled';

import Flex from '../Flex';

export const Wrapper = styled(Flex)({
  alignItems: 'flex-end',
});

export const Trigger = styled(Flex)(
  {
    borderRadius: '50%',
    flexShrink: 0,
    flexGrow: 0,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontSize: 36,
    lineHeight: 1,
    userSelect: 'none',
  },
  ({disabled, large}) => ({
    width: large ? 44 : 30,
    height: large ? 44 : 30,
    backgroundColor: 'transparent',
    cursor: disabled ? 'default' : 'pointer',
    pointerEvents: disabled ? 'none' : 'auto',
  }),
);
