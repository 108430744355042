import React from 'react';

const TasksIcon = (props) => (
  <svg
    width={25}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      className="first"
      d="M.667 0h13.714v2.286H.667V0ZM.667 4.571h13.714v2.286H.667V4.571ZM.667 11.429H9.81V9.143H.667v2.286ZM24.667 8l-1.714-1.714-6.275 6.285-3.44-3.428-1.714 1.714L16.678 16l7.989-8Z"
      fill="currentcolor"
    />
    <path
      className="second"
      d="m24.667 8-1.714-1.714-6.274 6.285-3.44-3.428-1.715 1.714L16.68 16l7.988-8Z"
      fill="currentcolor"
    />
  </svg>
);

export default TasksIcon;
