import {isEmpty} from 'ramda';
import {hasPasswordProtectedDocs} from 'modules/tasks-wizard';

import {
  getDocumentTypeByAssetType,
  ASSET_TYPES,
  BORROWER_ROLES,
} from '@renofi/modules-internal';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import {getCoBorrowerFullName, getFullName} from '@renofi/utilities/src/data';

import {isStepComplete} from './validation';

const maybePluralize = (count, noun, suffix = 's') => {
  if (count === 0) {
    return '';
  }

  return `${count} ${noun}${count !== 1 ? suffix : ''}`;
};

function getAccountsInfo(formData, assetType, borrowerRole) {
  const accounts = formData.filter(
    (assetDetail) =>
      assetDetail.assetType === assetType &&
      assetDetail.borrowerRole === borrowerRole &&
      !assetDetail.isNew,
  );

  const jointAccounts = formData.filter(
    (assetDetail) =>
      assetDetail.assetType === assetType && assetDetail.jointAccount,
  );

  const assetsAmount = accounts.length;
  const jointsAmount = jointAccounts.length;

  const assets = maybePluralize(assetsAmount, 'asset');
  const joints = maybePluralize(jointsAmount, 'joint');

  if (jointsAmount > 0) {
    const jointAssets = maybePluralize(jointsAmount, 'joint asset');

    if (borrowerRole === BORROWER_ROLES.COBORROWER && assetsAmount > 0) {
      return `${assets} inc. ${joints}`;
    }

    return jointAssets;
  }

  return assets;
}

function getInfoForStatements(taskDocuments, documentType, borrowerRole) {
  const sourceDocs = taskDocuments.filter(
    (doc) =>
      doc.borrowerRole === borrowerRole && doc.documentType === documentType,
  );

  if (sourceDocs.length === 0) {
    return '';
  }

  return `${sourceDocs.length} files`;
}

function shouldHideCoBorrowerStatements(formData, assetType) {
  const hasJointAccount = formData.some(
    ({borrowerRole, jointAccount, ...assetDetail}) => {
      return (
        assetDetail.assetType === assetType &&
        borrowerRole === BORROWER_ROLES.BORROWER &&
        jointAccount
      );
    },
  );

  const coBorrowersAssets = formData.filter(
    ({borrowerRole, ...assetDetail}) => {
      return (
        assetDetail.assetType === assetType &&
        borrowerRole === BORROWER_ROLES.COBORROWER
      );
    },
  );

  return hasJointAccount && isEmpty(coBorrowersAssets);
}

function generateAssetsSteps({
  path = 'primary-borrower',
  stepId,
  assets,
  borrowerRole,
  formData,
}) {
  const assetsSteps = Object.values(ASSET_TYPES).map((assetType) => {
    const id = `${path}/${assetType}`;

    return {
      id,
      value: humanizeSnakeCase(assetType),
      current: stepId === id,
      hidden: !assets[assetType].checked,
      complete: isStepComplete(formData, assetType, borrowerRole),
      assetType,
      info: getAccountsInfo(formData, assetType, borrowerRole),
      borrowerRole,
    };
  });

  return assetsSteps;
}

function generateStatementsSteps({
  path = 'primary-borrower',
  stepId,
  assets,
  taskDocuments,
  borrowerRole,
  formData = [],
}) {
  const statementsSteps = Object.values(ASSET_TYPES).map((assetType) => {
    const id = `${path}/${assetType}-statements`;
    const value = `${humanizeSnakeCase(assetType)} statements`;

    const isHiddenForCoBorrower =
      borrowerRole === BORROWER_ROLES.COBORROWER &&
      shouldHideCoBorrowerStatements(formData, assetType);

    const documentType = getDocumentTypeByAssetType(assetType);

    return {
      id,
      value,
      current: stepId === id,
      hidden: !assets[assetType].checked || isHiddenForCoBorrower,
      complete: isStatementsComplete(taskDocuments, documentType, borrowerRole),
      assetType,
      info: getInfoForStatements(taskDocuments, documentType, borrowerRole),
      borrowerRole,
    };
  });

  return statementsSteps;
}

function isStatementsComplete(taskDocuments, documentType, borrowerRole) {
  const documents = taskDocuments.filter(
    (doc) =>
      doc.borrowerRole === borrowerRole && doc.documentType === documentType,
  );

  return Boolean(documents.length) && !hasPasswordProtectedDocs(documents);
}

export const getBorrowerSteps = ({
  borrower = {},
  stepId,
  assets,
  formData,
  taskDocuments,
}) => {
  const numberOfBorrowerAssets = Object.values(assets).filter(
    ({checked}) => checked,
  ).length;

  const borrowerName = getFullName(borrower);

  const assetSteps = generateAssetsSteps({
    path: 'primary-borrower',
    stepId,
    assets,
    borrowerRole: BORROWER_ROLES.BORROWER,
    formData,
  });

  const statementsSteps = generateStatementsSteps({
    path: 'primary-borrower',
    stepId,
    assets,
    taskDocuments,
    borrowerRole: BORROWER_ROLES.BORROWER,
  });

  const isAssetsComplete = numberOfBorrowerAssets > 0;
  const isRootComplete =
    isAssetsComplete &&
    assetSteps.filter(({hidden}) => !hidden).every(({complete}) => complete) &&
    statementsSteps
      .filter(({hidden}) => !hidden)
      .every(({complete}) => complete);

  return {
    complete: isRootComplete,
    current: stepId.includes('primary-borrower'),
    id: 'primary-borrower',
    value: 'Primary borrower',
    path: 'primary-borrower',
    flat: false,
    info: borrowerName,
    borrowerRole: BORROWER_ROLES.BORROWER,
    isRoot: true,
    children: [
      {
        id: 'primary-borrower/assets',
        index: 0,
        value: 'Assets',
        current: stepId === 'primary-borrower/assets',
        hidden: false,
        info: `${numberOfBorrowerAssets} types`,
        complete: isAssetsComplete,
        borrowerRole: BORROWER_ROLES.BORROWER,
      },
      ...assetSteps,
      ...statementsSteps,
    ],
  };
};

export const getCoBorrowerSteps = ({
  borrowersInfo = {},
  stepId,
  assets,
  formData,
  taskDocuments,
  hasCoBorrower,
}) => {
  const numberOfCoBorrowerAssets = Object.values(assets).filter(
    ({checked}) => checked,
  ).length;
  const coBorrowerName = getCoBorrowerFullName(borrowersInfo);

  const assetSteps = generateAssetsSteps({
    path: 'co-borrower',
    stepId,
    assets,
    borrowerRole: BORROWER_ROLES.COBORROWER,
    formData,
  });

  const statementsSteps = generateStatementsSteps({
    path: 'co-borrower',
    stepId,
    assets,
    taskDocuments,
    borrowerRole: BORROWER_ROLES.COBORROWER,
    formData,
  });

  const isAssetsComplete = numberOfCoBorrowerAssets > 0;
  const isRootComplete =
    isAssetsComplete &&
    assetSteps.filter(({hidden}) => !hidden).every(({complete}) => complete) &&
    statementsSteps
      .filter(({hidden}) => !hidden)
      .every(({complete}) => complete);

  return {
    complete: isRootComplete,
    id: 'co-borrower',
    value: 'Co-borrower',
    path: 'co-borrower',
    flat: false,
    info: hasCoBorrower ? coBorrowerName : '',
    hidden: !hasCoBorrower,
    borrowerRole: BORROWER_ROLES.COBORROWER,
    isRoot: true,
    children: [
      {
        id: 'co-borrower/assets',
        index: 0,
        value: 'Assets',
        current: stepId === 'co-borrower/assets',
        hidden: !hasCoBorrower,
        info: `${numberOfCoBorrowerAssets} types`,
        complete: isAssetsComplete,
        borrowerRole: BORROWER_ROLES.COBORROWER,
      },
      ...assetSteps,
      ...statementsSteps,
    ],
  };
};
