import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import noop from '@renofi/utilities/src/noop';
import {isEmail} from '@renofi/utilities/src/validators';
import FieldFrame from '@renofi/components-internal/src/FieldFrame';

import {Input} from './styled';

const INVALID_MSG = 'Invalid email';

const EmailField = ({
  active,
  autoComplete = 'email',
  defaultValue,
  disabled,
  name,
  placeholder,
  value: currentValue,
  onBlur = noop,
  onChange = noop,
  onFocus = noop,
  tabIndex,
  small,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(currentValue || defaultValue || '');
  const [dirty, setDirty] = useState(Boolean(value));
  const [error, setError] = useState(null);

  useEffect(() => {
    const val = currentValue || defaultValue || '';
    if (active) {
      setValue(val);
    }
    setError(!isEmail(val) && val !== '' ? INVALID_MSG : null);
  }, [dirty, defaultValue, currentValue]);

  const changeHandler = useCallback((event) => {
    const {value: val} = event.target;
    const isValidEmail = isEmail(val);

    setValue(val);
    setError(!isValidEmail ? INVALID_MSG : null);
    onChange(val, isValidEmail);
  });

  const blurHandler = (event) => {
    setFocus(false);
    setDirty(true);
    onBlur(event);
  };

  const focusHandler = (event) => {
    setFocus(true);
    onFocus(event);
  };

  return (
    <FieldFrame
      inputValue={value}
      small={small}
      disabled={disabled}
      error={dirty && error}
      focused={focus}
      leftIcon={small ? undefined : 'email'}
      name={name}
      {...props}>
      <Input
        autoComplete={autoComplete}
        disabled={disabled}
        id={name}
        type="email"
        value={value}
        placeholder={placeholder}
        onChange={changeHandler}
        onBlur={blurHandler}
        onFocus={focusHandler}
        tabIndex={tabIndex}
      />
    </FieldFrame>
  );
};

EmailField.propTypes = {
  active: PropTypes.bool,
  small: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  tabIndex: PropTypes.number,
};

export default EmailField;
