import {
  assoc,
  either,
  find,
  isEmpty,
  isNil,
  pick,
  pipe,
  propEq,
  propOr,
} from 'ramda';

import {
  hasUrlProtocol,
  isEmail,
  isUrl,
  isZipCode,
} from '@renofi/utilities/src/validators';

import {PICKED_PROPS} from './constants';

const NEW_GC_KEYS = ['businessName', 'email', 'phoneNumber', 'streetAddress'];

const isNullish = either(isNil, isEmpty);

export const getContractorById = (id) =>
  pipe(propOr([], 'contractorInformations'), find(propEq('id', id)));

export const getContractorProps = (contractor = {}) => {
  const {website} = contractor || {};
  const hasProtocol = Boolean(!website || hasUrlProtocol(website));

  return pipe(
    pick(PICKED_PROPS),
    assoc('website', hasProtocol ? website : `http://${website}`),
  )(contractor);
};

export const hasContractorChanged = (prev, next) => {
  return NEW_GC_KEYS.every((key) => {
    const prevValue = prev[key]?.toLowerCase();
    const nextValue = next[key]?.toLowerCase();

    return nextValue !== prevValue;
  }, []);
};

export const hasMissingFields = ({
  contractor = {},
  isEmailValid = false,
  isPhoneNumberValid = false,
} = {}) =>
  PICKED_PROPS.filter((k) => {
    const value = contractor[k];
    switch (k) {
      case 'website':
        // Is optional => only validate if a value is given
        return Boolean(value) && !isUrl(value);
      case 'email':
        return !value || !isEmail(value) || !isEmailValid;
      case 'estimatedCostOfWork':
        return Number(value) <= 0;
      case 'phoneNumber':
        return !isPhoneNumberValid;
      case 'zipCode':
        return !isZipCode(value);
      default:
        return isNullish(value);
    }
  });

export const getRequiredMessage = (key, fields) => {
  return fields.includes(key) ? 'This field is required' : null;
};
