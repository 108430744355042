import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const SvgClock = ({color = basic20, width = 24, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12 1.25c5.914 0 10.75 4.836 10.75 10.75S17.914 22.75 12 22.75 1.25 17.914 1.25 12 6.086 1.25 12 1.25zm0 1.5c-5.086 0-9.25 4.164-9.25 9.25s4.164 9.25 9.25 9.25 9.25-4.164 9.25-9.25S17.086 2.75 12 2.75zM12.5 7v5.2l4.5 2.7-.8 1.3L11 13V7h1.5z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgClock.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

export default SvgClock;
