import isFunction from '@renofi/utilities/src/isFunction';

import {CARDS_INFO} from '../../../project/components/Project/constants';

export const getPageTitleByFacet = ({facet, history, params}) => {
  const task = CARDS_INFO.find(({id}) => id === facet);
  if (!task?.title) {
    return 'Unknown';
  }

  return isFunction(task.title) ? task.title({history, params}) : task.title;
};
