import styled from '@emotion/styled';

import {Flex, Box, Text} from '@renofi/components-internal';
import {maxSmall, minSmall} from '@renofi/theme/src/breakpoints';
import {basic03, basic80} from '@renofi/theme/src/colors';

export const Wrapper = styled(Flex)({
  borderRadius: 8,
  gap: 24,

  [maxSmall]: {
    flexDirection: 'column',
  },
  [minSmall]: {
    flexDirection: 'row',
  },
});

export const ContractInfo = styled(Box)({
  backgroundColor: basic03,
  padding: '20px 20px 38px 20px',
  [maxSmall]: {
    flex: 1,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  [minSmall]: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

export const Title = styled(Text)({
  color: basic80,
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 'bold',
});
