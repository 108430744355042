import React from 'react';

import PropTypes from 'prop-types';

import AddressForm from '../AddressForm';
import {Content, Title} from '../styled';

const PropertyAddressStep = ({onChange, property, title}) => {
  return (
    <Content>
      <Title>{title}</Title>
      <AddressForm value={property} onChange={onChange} />
    </Content>
  );
};

PropertyAddressStep.propTypes = {
  property: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChange: PropTypes.func,
};

export default PropertyAddressStep;
