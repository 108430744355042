import styled from '@emotion/styled';

import {Flex, Text} from '@renofi/components-internal';
import {isMobile, maxSmall} from '@renofi/theme/src/breakpoints';
import {basic80, actionRegular, successDark} from '@renofi/theme/src/colors';

export const Card = styled(Flex)(
  {
    borderRadius: 8,
    flexDirection: 'column',
    marginTop: 16,
    padding: 16,
    backgroundColor: '#FAFAFA',
    border: '1px solid #E9E9E9',
  },
  ({width, ml, mr}) => ({
    width: isMobile() ? '100%' : width,
    marginLeft: isMobile() ? 0 : ml,
    marginRight: isMobile() ? 0 : mr,
    [maxSmall]: {
      marginLeft: 0,
      width: '100%',
      marginRight: 0,
    },
  }),
);

export const Value = styled(Text)({
  color: actionRegular,
  fontSize: 14,
  lineHeight: '24px',
  marginLeft: 5,
  marginRight: 5,
});

export const EmailValue = styled(Text)({
  color: actionRegular,
  fontSize: 14,
  lineHeight: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const Label = styled(Text)({
  color: basic80,
  fontSize: 14,
  lineHeight: '24px',
});

export const ItemLeft = styled(Flex)({
  marginBottom: 5,
  width: isMobile() ? '100%' : '60%',
  ...(isMobile() ? {flexWrap: 'wrap'} : {}),
  [maxSmall]: {
    flexWrap: 'wrap',
    width: '100%',
  },
});

export const ItemRight = styled(Flex)({
  marginBottom: 5,
  width: isMobile() ? '100%' : '40%',
  ...(isMobile() ? {flexWrap: 'wrap'} : {}),
  [maxSmall]: {
    flexWrap: 'wrap',
    width: '100%',
  },
});

export const ItemsWrapper = styled(Flex)({
  flexDirection: isMobile() ? 'column' : 'row',
  [maxSmall]: {
    flexDirection: 'column',
  },
});

export const Wrapper = styled(Flex)({
  flexDirection: isMobile() ? 'column' : 'row',
  [maxSmall]: {
    flexDirection: 'column',
  },
});

export const SuccessMessage = styled(Flex)({
  backgroundColor: 'rgba(231,249,233, 0.6)',
  color: successDark,
  borderRadius: 8,
  padding: '18px 16px',
  marginTop: 10,
  marginBottom: 20,
});
