import React from 'react';

const InvestmentProperty = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_4566_202024"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4566_202024)">
        <path
          d="M4 21V9L12 3L20 9V21H4ZM6 19H18V10L12 5.5L6 10V19Z"
          fill="#CCCCCC"
        />
        <path
          d="M11.7502 17H12.4752V16.2C12.9419 16.1333 13.3377 15.9417 13.6627 15.625C13.9877 15.3083 14.1502 14.9 14.1502 14.4C14.1502 13.9667 13.9835 13.6042 13.6502 13.3125C13.3169 13.0208 12.9335 12.7833 12.5002 12.6V10.75C12.6669 10.8 12.8044 10.8833 12.9127 11C13.021 11.1167 13.1002 11.2583 13.1502 11.425L14.0502 11.05C13.9335 10.7 13.7335 10.4208 13.4502 10.2125C13.1669 10.0042 12.8502 9.86667 12.5002 9.8V9H11.7502V9.775C11.2835 9.825 10.8877 9.99583 10.5627 10.2875C10.2377 10.5792 10.0752 10.9667 10.0752 11.45C10.0752 11.9 10.246 12.275 10.5877 12.575C10.9294 12.875 11.3169 13.1167 11.7502 13.3V15.275C11.4835 15.1917 11.2585 15.05 11.0752 14.85C10.8919 14.65 10.7669 14.4167 10.7002 14.15L9.8252 14.525C9.95853 14.9917 10.1919 15.375 10.5252 15.675C10.8585 15.975 11.2669 16.1583 11.7502 16.225V17ZM12.5002 15.25V13.6C12.6835 13.6833 12.846 13.7833 12.9877 13.9C13.1294 14.0167 13.2002 14.1917 13.2002 14.425C13.2002 14.6917 13.1335 14.8792 13.0002 14.9875C12.8669 15.0958 12.7002 15.1833 12.5002 15.25ZM11.7502 12.275C11.5669 12.1917 11.4002 12.0917 11.2502 11.975C11.1002 11.8583 11.0252 11.6833 11.0252 11.45C11.0252 11.2167 11.1002 11.0458 11.2502 10.9375C11.4002 10.8292 11.5669 10.7583 11.7502 10.725V12.275Z"
          fill="#CCCCCC"
        />
      </g>
    </svg>
  );
};

export default InvestmentProperty;
