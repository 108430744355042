import {
  STATUS_INTRO_COMMON,
  STATUS_INTRO_LENDER_SUFFIX,
  STATUS_INTRO_COMPLETED,
} from '../constants';

export {default as getTasksInfo} from './getTasksInfo';

export const getWelcomeStatusContent = (user, isRaas) => {
  return {
    title: `Welcome, ${user?.firstName || ''}!`,
    content: `${STATUS_INTRO_COMMON}${
      isRaas ? '.' : STATUS_INTRO_LENDER_SUFFIX
    }`,
  };
};

export const getCompletedStatusContent = (user) => {
  return {
    title: `Nice work, ${user?.firstName}! You’ve completed all your tasks!`,
    content: STATUS_INTRO_COMPLETED,
  };
};

export const getNewTasksStatusContent = (user, outstandingTasksCount) => {
  return {
    title: `${user?.firstName}, you’ve got new tasks to complete!`,
    content: `You have ${outstandingTasksCount} outstanding tasks to complete.`,
  };
};
