import React from 'react';

const Townhouse = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_4614_239946"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25">
        <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4614_239946)">
        <path
          d="M1 20.5V10.5L7 4.5L13 10.5V20.5H1ZM3 18.5H6V15.5H8V18.5H11V11.325L7 7.325L3 11.325V18.5ZM6 13.5V11.5H8V13.5H6Z"
          fill="#CCCCCC"
        />
        <path
          d="M11 20.5V10.5L17 4.5L23 10.5V20.5H11ZM13 18.5H16V15.5H18V18.5H21V11.325L17 7.325L13 11.325V18.5ZM16 13.5V11.5H18V13.5H16Z"
          fill="#CCCCCC"
        />
      </g>
    </svg>
  );
};

export default Townhouse;
