import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const SvgContract = ({color = basic20, width = 24, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g id="perm_contact_calendar">
        <mask
          id="mask0_532_101248"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={width}
          height={width}>
          <rect id="Bounding box" width={width} height={width} fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_532_101248)">
          <path
            id="perm_contact_calendar_2"
            d="M12 18C11.0667 18 10.175 18.1458 9.325 18.4375C8.475 18.7292 7.7 19.1667 7 19.75V20H17V19.75C16.3 19.1667 15.525 18.7292 14.675 18.4375C13.825 18.1458 12.9333 18 12 18ZM5 18.85C5.9 17.9667 6.94583 17.2708 8.1375 16.7625C9.32917 16.2542 10.6167 16 12 16C13.3833 16 14.6708 16.2542 15.8625 16.7625C17.0542 17.2708 18.1 17.9667 19 18.85V6H5V18.85ZM12 14C11.0333 14 10.2083 13.6583 9.525 12.975C8.84167 12.2917 8.5 11.4667 8.5 10.5C8.5 9.53333 8.84167 8.70833 9.525 8.025C10.2083 7.34167 11.0333 7 12 7C12.9667 7 13.7917 7.34167 14.475 8.025C15.1583 8.70833 15.5 9.53333 15.5 10.5C15.5 11.4667 15.1583 12.2917 14.475 12.975C13.7917 13.6583 12.9667 14 12 14ZM12 12C12.4167 12 12.7708 11.8542 13.0625 11.5625C13.3542 11.2708 13.5 10.9167 13.5 10.5C13.5 10.0833 13.3542 9.72917 13.0625 9.4375C12.7708 9.14583 12.4167 9 12 9C11.5833 9 11.2292 9.14583 10.9375 9.4375C10.6458 9.72917 10.5 10.0833 10.5 10.5C10.5 10.9167 10.6458 11.2708 10.9375 11.5625C11.2292 11.8542 11.5833 12 12 12ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

SvgContract.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

export default SvgContract;
