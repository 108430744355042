export const SERVICING_FEE_TOOLTIP =
  'A monthly fee charged by the lender until the renovation is complete. Once your renovation is officially complete and the Certificate of Completion has been delivered, the monthly fee stops.';
export const DTI_TOOLTIP =
  'Debt-to-Income ratio compares how much you owe each month to how much you earn.';
export const TITLE_HOLDERS_TOOLTIP =
  'A title holder is a person(s) that has ownership rights over the property.';
export const APPRAISED_VALUE_TOOLTIP =
  'The appraised value is the assessed value of a property based on a given point in time by an appraiser.';
export const AGREEMENT_VALIDAITON_TOOLTIP =
  'You must enter the last 4 digits for all applicants to consent to the agreement and finish the task.';
export const SCHEDULE_CALL_VALIDAITON_TOOLTIP =
  'Schedule a final review call to complete this task and continue.';

export const PRODUCT_CONFIRMATION_TASK_TYPES = {
  product_details_confirmation: 'review your loan type & details',
  certificate_acknowledgement: 'read the agreement',
  advisor_final_call_schedule: 'schedule a call',
};

export const SECTION_TYPE = {
  personal: 'personal_details',
  property: 'property_details',
  loan: 'loan_details',
};

export const FIRST_STEP_PATH = 'step-1';
export const SECOND_STEP_PATH = 'step-2';
export const THIRD_STEP_PATH = 'step-3';

export const PRODUCT_DETAILS_TASK_TYPE = 'product_details_confirmation';
export const AGREEMENT_TASK_TYPE = 'certificate_acknowledgement';
export const ADVISOR_CALL_TASK_TYPE = 'advisor_final_call_schedule';

export const SSN_SUFFIX_LENGTH = 4;
