import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_APPRAISAL_ORDERS} from '../../queries';

export default function useAppraisalOrders({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
}) {
  const response = useToggledQuery({
    lazy,
    query: GET_APPRAISAL_ORDERS,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const appraisalOrders = propOr([], 'appraisalOrdersByProjectId', data);

  return {error, fetch, appraisalOrders, loading};
}
