import React, {useState} from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Checkbox,
  FileType as CommonFileType,
  Flex,
} from '@renofi/components-internal';
import noop from '@renofi/utilities/src/noop';

const FileType = ({
  fileName,
  canSelect = false,
  documentId,
  isFileSelected,
  onFileSelect = noop,
}) => {
  const [hover, setHover] = useState(false);

  const onMouseEnter = () => {
    if (canSelect) {
      setHover(true);
    }
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      css={{width: 32, height: 32}}
      onClick={(e) => e.stopPropagation()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => setHover(false)}>
      {hover || isFileSelected ? (
        <Box width={32}>
          <Checkbox
            css={{
              position: 'relative',
              left: 8,
              top: 3,
            }}
            checked={isFileSelected}
            onChange={() => onFileSelect(documentId)}
          />
        </Box>
      ) : (
        <CommonFileType fileName={fileName} />
      )}
    </Flex>
  );
};

FileType.propTypes = {
  fileName: PropTypes.string.isRequired,
  documentId: PropTypes.string,
  internal: PropTypes.bool,
  isFileSelected: PropTypes.bool,
  onFileSelect: PropTypes.func,
};

export default FileType;
