export const getPropertyFullAddress = (place) => {
  const details = [
    place?.address || place?.streetAddressOne,
    place?.secondAddress || place?.streetAddressTwo,
    place?.city,
    place?.state,
    place?.zipCode,
  ].filter(Boolean);

  return details?.join(', ');
};
