import {gql} from '@apollo/client';

export default gql`
  mutation swapBorrowersOnProject($projectId: ID!) {
    swapBorrowersOnProject(projectId: $projectId) {
      project {
        id
      }
    }
  }
`;
