/* eslint-disable */
export default function initDelighted() {
  if(process.env.REACT_APP_ENV !== 'production') {
    window.delighted = {
      survey: () => {}
    };
    return;
  }

  !(function (e, t, r, n) {
    if (!e[n]) {
      for (var a = (e[n] = []), i = ["survey", "reset", "config", "init", "set", "get", "event", "identify", "track", "page", "screen", "group", "alias"], s = 0; s < i.length; s++) {
        var c = i[s];
        a[c] =
          a[c] ||
          (function (e) {
            return function () {
              var t = Array.prototype.slice.call(arguments);
              a.push([e, t]);
            };
          })(c);
      }
      a.SNIPPET_VERSION = "1.0.1";
      var o = t.createElement("script");
      (o.type = "text/javascript"), (o.async = !0), (o.src = "https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/" + r + "/" + n + ".js");
      var p = t.getElementsByTagName("script")[0];
      p.parentNode.insertBefore(o, p);
    }
  })(window, document, "aorg1wFPNDtVus7t", "delightedNps");

}

export const showSurvey = ({id, email, name, stageName, uploadedDocumentsCount, loanType}) => {
  if (window.delighted) {
    window.delighted.survey({
      id,
      email,
      name,
      createdAt: new Date(),
      properties: {
        Stage: stageName,
        'Uploaded Documents Count': uploadedDocumentsCount,
        'Loan Type': loanType,
      }
    });
  }
};
