import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {isMobile} from '@renofi/theme/src/breakpoints';
import {sendEvent} from '@renofi/utilities/src/analytics/utils';

import {STEP_MAPPER} from '../constants';

import BaseContent from './BaseContent';
import {getPropertyDetails} from './utils';

const PropertyTitle = ({place, setStep, update}) => {
  const onAccept = async () => {
    await update({
      borrowerIsOnTitle: true,
    });
    setStep(STEP_MAPPER.TypeOfHome);
    sendEvent('Secure/Onboarding-User-Title-Selected');
  };

  const onDecline = async () => {
    await update({
      borrowerIsOnTitle: false,
    });
    setStep(STEP_MAPPER.TypeOfHome);
    sendEvent('Secure/Onboarding-User-Title-Selected');
  };

  const details = getPropertyDetails(place);

  useEffect(() => {
    sendEvent('Secure/Onboarding-User-Title-Presented');
  }, []);

  return (
    <BaseContent
      mainContent={'Are you on the property title?'}
      sideContent={details}
      onAccept={onAccept}
      onDecline={onDecline}
      sideContentCss={{textAlign: isMobile() ? 'center' : 'left'}}
    />
  );
};

PropertyTitle.propTypes = {
  place: PropTypes.object,
  setStep: PropTypes.func,
  update: PropTypes.func,
};

export default PropertyTitle;
