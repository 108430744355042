import React from 'react';

import {omit} from 'ramda';
import PropTypes from 'prop-types';

import {
  SelectField,
  Flex,
  Text,
  Box,
  Textarea,
  TextField,
} from '@renofi/components-internal';
import {
  realEstateOwnedPropertyTypes,
  currentOccupancyTypes,
  propertyStatusTypes,
} from '@renofi/utilities/src/enumTypes';

import {Content, Title} from '../styled';
import ApplicantsSelect from '../ApplicantsSelect';

const NOT_OCCUPIED = 'not_occupied';
const OTHER = 'other';

const AdditionalPropertyDetailsStep = ({
  onChange,
  property,
  borrower,
  coborrower,
  borrowersInfo,
}) => {
  const {
    propertyType,
    currentOccupancy,
    currentOccupancyOther,
    intendedOccupancy,
    propertyStatus,
    rentalIncome,
    marketValue,
    yearPurchased,
    streetAddressOne,
    coborrowerOwned,
    primaryBorrowerOwned,
  } = property || {};
  const showIntended = currentOccupancy === NOT_OCCUPIED;
  const showRentalIncome = propertyStatus === 'rental' && !showIntended;

  return (
    <Content>
      <Title>
        Now, provide <strong>details</strong> for the {streetAddressOne}{' '}
        property.
      </Title>

      <Flex>
        <Box flex={1} mr={3}>
          <SelectField
            name="propertyType"
            value={propertyType}
            label="Property type"
            options={realEstateOwnedPropertyTypes}
            onChange={(value) => onChange({propertyType: value})}
          />
        </Box>

        <Box flex={1}>
          <SelectField
            name="currentOccupancy"
            value={currentOccupancy}
            label="Current occupancy"
            options={currentOccupancyTypes}
            onChange={(value) => onChange({currentOccupancy: value})}
          />
        </Box>
      </Flex>

      {currentOccupancy === OTHER ? (
        <Flex>
          <Box flex={1}>
            <Textarea
              rows={3}
              value={currentOccupancyOther}
              label="If other, provide details"
            />
          </Box>
        </Flex>
      ) : null}

      <Flex>
        {showIntended ? (
          <Box width={1 / 2} mr={3}>
            <SelectField
              name="intendedOccupancy"
              value={intendedOccupancy}
              label="Intended Occupancy"
              options={omit([NOT_OCCUPIED, OTHER], currentOccupancyTypes)}
              onChange={(value) => onChange({intendedOccupancy: value})}
            />
          </Box>
        ) : null}

        <Box width={1 / 2} pr={showIntended || showRentalIncome ? 0 : 2}>
          <SelectField
            name="propertyStatus"
            value={propertyStatus}
            label="Status of property"
            options={propertyStatusTypes}
            onChange={(value) => onChange({propertyStatus: value})}
          />
        </Box>

        {showRentalIncome ? (
          <Box width={1 / 2} ml={3}>
            <TextField
              money
              showIconDivider
              help="/month"
              leftIcon="dollar"
              name="rentalIncome"
              value={rentalIncome}
              label="Rental income"
              onChange={(value) => {
                return onChange({rentalIncome: value === '' ? null : value});
              }}
            />
          </Box>
        ) : null}
      </Flex>

      <Flex>
        <Box flex={1} mr={3}>
          <TextField
            money
            showIconDivider
            leftIcon="dollar"
            name="marketValue"
            value={marketValue}
            label="Current market value"
            onChange={(value) => onChange({marketValue: value})}
          />
        </Box>

        <Box flex={1}>
          <TextField
            numeric
            pattern="[0-9]+"
            showIconDivider
            leftIcon="calendar"
            name="yearPurchased"
            value={yearPurchased}
            label="Year purchased"
            onChange={(value) => onChange({yearPurchased: value})}
          />
        </Box>
      </Flex>

      {borrowersInfo?.coborrowerIsPresent ? (
        <>
          <Text fontSize={20} mb={16}>
            Ownership
          </Text>
          <ApplicantsSelect
            onChange={onChange}
            borrower={borrower}
            coborrower={coborrower}
            coborrowerOwned={coborrowerOwned}
            primaryBorrowerOwned={primaryBorrowerOwned}
            borrowersInfo={borrowersInfo}
          />
        </>
      ) : null}
    </Content>
  );
};

AdditionalPropertyDetailsStep.propTypes = {
  property: PropTypes.object,
  formData: PropTypes.object,
  onChange: PropTypes.func,
  borrower: PropTypes.object,
  coborrower: PropTypes.object,
  borrowersInfo: PropTypes.object,
};

export default AdditionalPropertyDetailsStep;
