import {gql} from '@apollo/client';

export default gql`
  query emailDeliveryStatus($emails: [String!]!) {
    emailDeliveryStatus(emails: $emails) {
      id
      success
    }
  }
`;
