import {isNumber} from 'lodash';

export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
};

export const getNewSortDirection = ({sortColumn, sortDirection, column}) => {
  if (sortColumn !== column || sortDirection !== SORT_DIRECTIONS.ASC) {
    return SORT_DIRECTIONS.ASC;
  }

  return SORT_DIRECTIONS.DESC;
};

export function sortTable(array, getKey, sortDirection) {
  return array.toSorted((a, b) => {
    const keyA = getKey(a);
    const keyB = getKey(b);
    return sortDirection === 'asc'
      ? compare(a[keyA], b[keyB])
      : compare(b[keyB], a[keyA]);
  });
}

export function compare(a = 0, b = 0) {
  if (isNumber(a) && isNumber(b)) return a - b;
  return String(a)?.localeCompare(String(b));
}
