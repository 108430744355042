import React from 'react';

import PropTypes from 'prop-types';

import {DateFormat, TimeAgo} from '@renofi/components-internal';
import capitalize from '@renofi/utilities/src/capitalize';

const PastFutureDate = ({value}) => {
  if (!value) return 'TBA';
  const now = new Date();
  const date = new Date(value);

  if (now > date) {
    return <TimeAgo timePrefix="at" formatter={capitalize} date={date} />;
  }

  return <DateFormat value={date} />;
};

PastFutureDate.propTypes = {
  value: PropTypes.string,
};

export default PastFutureDate;
