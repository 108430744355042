import {gql} from '@apollo/client';

import {
  additionalProperty,
  advisorFinalCallSchedule,
  borrowersAndPropertyInformation,
  certificateAcknowledgement,
  contractor,
  documentWithAllProps,
  employmentHistory,
  incomeSource,
  payStatementInformation,
  personalInfoAndResidency,
  productDetailsConfirmation,
  smsConsentRenovationProgress,
  softCreditCheck,
  soldProperty,
  subjectProperty,
} from '../../partials';

export default gql`
  mutation markTaskDataAsReadyForProcessing($taskId: ID!) {
    markTaskDataAsReadyForProcessing(taskId: $taskId) {
      task {
        id
        advisorFinalCallSchedule {
          ${advisorFinalCallSchedule}
        }
        borrowerFinishedAt
        borrowersAndPropertyInformation{
          ${borrowersAndPropertyInformation}
        }
        certificateAcknowledgement {
          ${certificateAcknowledgement}
        }
        contractor {
          ${contractor}
        }
        contractorInformations {
          ${contractor}
        }
        dataSubmittedAt
        documents {
          ${documentWithAllProps}
          addedById
          acceptedById
          passwordProtectedFile
          rejectedById
          addedBy {
            id
            fullName
            firstName
            lastName
          }
          acceptedBy {
            id
            firstName
            lastName
          }
          rejectedBy {
            id
            firstName
            lastName
          }
        }
        employmentHistories {
          ${employmentHistory}
        }
        facet
        incomeSources {
          ${incomeSource}
        }
        payStatementInformations {
          ${payStatementInformation}
        }
        personalInfoAndResidency {
          ${personalInfoAndResidency}
        }
        productDetailsConfirmation {
          ${productDetailsConfirmation}
        }
        project {
          id
        }
        realEstateOwned {
          additionalProperties {
            ${additionalProperty}
          }
          additionalPropertyNumber
          additionalPropertyPresent
          id
          soldProperties {
            ${soldProperty}
          }
          soldPropertyNumber
          soldPropertyPresent
          subjectProperty {
            ${subjectProperty}
          }
          taskId
        }
        requirements
        softCreditCheck {
          ${softCreditCheck}
        }
        smsConsentRenovationProgress {
          ${smsConsentRenovationProgress}
        }
        status
        identityVerifications {
          borrowerRole
          status
        }
      }
    }
  }
`;
