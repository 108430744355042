import {makeVar, useReactiveVar} from '@apollo/client';

const showModalVar = makeVar(false);

export default function useConfirmEditModal() {
  const show = useReactiveVar(showModalVar);

  const setShowConfirmEditModal = (value) => {
    showModalVar(value);
  };

  return {show, setShowConfirmEditModal};
}
