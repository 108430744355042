import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {SUBMIT_CONTRACTOR_INFORMATION} from '../mutations';

import useMutation from './useMutation';

export default function useSubmitContractorInformation({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitContractorInformation, {data, loading, error}] = useMutation(
    SUBMIT_CONTRACTOR_INFORMATION,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(
    null,
    ['submitContractorInformation', 'contractor'],
    data,
  );

  return {submitContractorInformation, response, loading, error};
}
