import {isNil} from 'ramda';

const CONDITIONAL_VALUES = [
  ['appraisedValue', 'Appraisal value'],
  ['estimatedRenovationHomeValue', 'Estimated ARV'],
  ['currentHomeValue', 'Current home value'],
];

export default (details = {}) =>
  CONDITIONAL_VALUES.find(([key]) => !isNil(details[key])) || [];
