import * as React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.578125 19.826C0.578125 8.94758 9.39813 0.125977 20.2781 0.125977C31.1573 0.125977 39.9781 8.94758 39.9781 19.826C39.9781 30.706 31.1581 39.5244 20.2781 39.5244C9.39813 39.5244 0.578125 30.706 0.578125 19.826ZM22.0805 19.7436H25.4917L25.9357 15.4492H22.0813V12.9188C22.0813 11.9716 22.7093 11.75 23.1533 11.75H25.8717V7.57798L22.1277 7.56358C17.9701 7.56358 17.0245 10.6732 17.0245 12.6652V15.4484H14.6197V19.7436H17.0245V31.9092H22.0805V19.7436Z"
      fill="#FF5253"
    />
  </svg>
);
