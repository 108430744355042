import React from 'react';

const SvgComponent = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1622 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1.127 83.33v17.108S298.934 105 447.813 105c148.88 0 346.73-4.562 479.937-4.562 133.21 0 280.13 3.421 483.86 3.421h244.75s-26.48-122.655-171.78-101.36c-145.3 21.294-216.27 80.831-705.119 20.275C290.609-37.783 1.127 83.33 1.127 83.33Z"
      fill="#E5F5FF"
    />
    <path stroke="#D8D8D8" strokeWidth={1.126} d="M0 104.437h1622" />
    <path
      d="M721.746 75.714c-145.157 0-225.572 5.3-255.421 5.3-29.849 0-44.492-4.737-103.065-4.737-32.385 0-59.433 1.793-83.618 3.396-19.557 1.296-37.242 2.468-54.364 2.468-17.453 0-36.309-.984-56.835-2.055-24.516-1.28-51.414-2.683-81.148-2.683C32.665 77.403 0 81.346 0 81.346v5.632s46.182-5.632 87.295-5.632c20.487 0 44.47 1.398 68.534 2.801 24.228 1.413 48.54 2.83 69.449 2.83 41.374 0 95.515-1.893 137.074-3.347l.908-.031c41.677-1.458 56.883 2.252 103.065 2.252 46.182 0 152.136-3.965 255.421-2.43l2.251.033c102.995 1.53 237.17 3.524 339.703 3.524 51.82 0 112.07-.993 172.12-1.981 59.63-.983 119.07-1.962 169.84-1.962 101.89 0 216.34 3.943 216.34 3.943v-5.929s-80.95-4.15-216.34-4.15c-73.69 0-140.35 1.478-201.1 2.824-50.87 1.128-97.6 2.163-140.86 2.163-42.43 0-86.259-1.233-134.725-2.598-59.938-1.687-126.97-3.574-207.229-3.574Z"
      fill="#CFEED3"
    />
    <g opacity={0.8}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M466.325 80.205s-36.582-6.08-101.471-4.89c-64.889 1.192-95.733 4.746-95.733 4.746v.574h197.204v-.43Z"
        fill="#E7F9E9"
      />
      <rect
        width={0.758}
        height={8.032}
        rx={0.379}
        transform="matrix(-1 0 0 1 1549.72 73.592)"
        fill="#FFCFCF"
      />
      <ellipse
        rx={3.16}
        ry={3.239}
        transform="matrix(-1 0 0 1 1549.08 71.908)"
        fill="#A6DAAC"
      />
      <rect
        width={0.758}
        height={8.032}
        rx={0.379}
        transform="matrix(-1 0 0 1 1438.2 71.34)"
        fill="#FFCFCF"
      />
      <ellipse
        rx={3.16}
        ry={3.239}
        transform="matrix(-1 0 0 1 1437.57 69.655)"
        fill="#A6DAAC"
      />
      <rect
        width={1.154}
        height={12.223}
        rx={0.577}
        transform="matrix(-1 0 0 1 1449.19 69.401)"
        fill="#FFCFCF"
      />
      <ellipse
        rx={4.809}
        ry={4.928}
        transform="matrix(-1 0 0 1 1448.23 66.838)"
        fill="#A6DAAC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 80.183s36.583-3.662 101.472-2.782c64.889.88 169.425 2.676 169.425 2.676v.424s-32.103 1.408-52.941 1.408-54.436-1.664-116.484-2.816C39.424 77.942 0 81.91 0 81.91v-1.726Z"
        fill="#E7F9E9"
      />
      <rect
        width={0.946}
        height={10.019}
        rx={0.473}
        transform="matrix(-1 0 0 1 94.842 69.352)"
        fill="#FFCFCF"
      />
      <ellipse
        rx={3.942}
        ry={4.04}
        transform="matrix(-1 0 0 1 94.053 67.25)"
        fill="#A6DAAC"
      />
      <rect
        width={0.946}
        height={10.019}
        rx={0.473}
        transform="matrix(-1 0 0 1 193.964 72.73)"
        fill="#FFCFCF"
      />
      <ellipse
        rx={3.942}
        ry={4.04}
        transform="matrix(-1 0 0 1 193.176 70.63)"
        fill="#A6DAAC"
      />
      <rect
        width={0.946}
        height={10.019}
        rx={0.473}
        transform="matrix(-1 0 0 1 302.097 76.11)"
        fill="#FFCFCF"
      />
      <ellipse
        rx={3.942}
        ry={4.04}
        transform="matrix(-1 0 0 1 301.309 74.009)"
        fill="#A6DAAC"
      />
      <rect
        width={0.946}
        height={10.019}
        rx={0.473}
        transform="matrix(-1 0 0 1 419.242 71.604)"
        fill="#FFCFCF"
      />
      <ellipse
        rx={3.942}
        ry={4.04}
        transform="matrix(-1 0 0 1 418.454 69.503)"
        fill="#A6DAAC"
      />
      <rect
        width={0.946}
        height={10.019}
        rx={0.473}
        transform="matrix(-1 0 0 1 617.486 69.352)"
        fill="#FFCFCF"
      />
      <ellipse
        rx={3.942}
        ry={4.04}
        transform="matrix(-1 0 0 1 616.698 67.25)"
        fill="#A6DAAC"
      />
      <rect
        width={0.946}
        height={10.019}
        rx={0.473}
        transform="matrix(-1 0 0 1 1218.98 70.478)"
        fill="#FFCFCF"
      />
      <ellipse
        rx={3.942}
        ry={4.04}
        transform="matrix(-1 0 0 1 1218.19 68.377)"
        fill="#A6DAAC"
      />
      <rect
        width={0.946}
        height={10.019}
        rx={0.473}
        transform="matrix(-1 0 0 1 1574.92 69.352)"
        fill="#FFCFCF"
      />
      <ellipse
        rx={3.942}
        ry={4.04}
        transform="matrix(-1 0 0 1 1574.13 67.25)"
        fill="#A6DAAC"
      />
      <rect
        width={0.749}
        height={7.929}
        rx={0.374}
        transform="matrix(-1 0 0 1 28.524 72.569)"
        fill="#FFCFCF"
      />
      <ellipse
        rx={3.12}
        ry={3.197}
        transform="matrix(-1 0 0 1 27.9 70.906)"
        fill="#A6DAAC"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m310.452 85.279-.003-15.738c0-.518.212-.997.557-1.256l6.891-3.639a.895.895 0 0 1 1.113 0l7.153 3.64c.345.258.557.737.557 1.255v15.737l-16.268.001ZM283.441 85.28l-.002-8.608c0-.283.119-.545.312-.686l3.859-1.99a.511.511 0 0 1 .624 0l4.005 1.99c.193.141.312.403.312.686v8.606l-9.11.001ZM337.508 85.28l-.002-8.608c0-.283.119-.545.312-.686l3.859-1.99a.511.511 0 0 1 .624 0l4.005 1.99c.193.141.312.403.312.686v8.606l-9.11.001ZM400.585 85.28l-.001-8.608c0-.283.119-.545.312-.686l3.859-1.99a.51.51 0 0 1 .623 0l4.006 1.99c.193.141.312.403.312.686v8.606l-9.111.001ZM129.518 82.375l-.001-9.162c0-.412.169-.794.445-1l5.513-2.9a.719.719 0 0 1 .891 0l5.722 2.9c.276.206.445.588.445 1v9.161l-13.015.001ZM143.512 82.376l-.002-11.622c0-.493.208-.95.546-1.197l6.753-3.468a.897.897 0 0 1 1.091 0l7.01 3.469c.338.247.546.703.546 1.196v11.62l-15.944.002ZM69.634 81.1l.005-24.346c0-.8-.45-1.541-1.18-1.942l-14.609-5.63c-.73-.4-1.63-.4-2.36 0l-15.165 5.63c-.73.4-1.18 1.141-1.18 1.942v25.718s8.432-.563 17.232-.563c8.8 0 17.257-.81 17.257-.81Z"
      fill="#CCC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m1486.2 84-.01-25.676c0-1.326.72-2.55 1.88-3.214l23.15-9.312a3.769 3.769 0 0 1 3.74 0L1539 55.11a3.721 3.721 0 0 1 1.87 3.214v25.673l-54.67.004Z"
      fill="#B0B0B0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m1462.96 84.153-.01-43.526c0-1.432.77-2.756 2.02-3.473l24.94-10.064a4.05 4.05 0 0 1 4.03 0l25.89 10.064a4.006 4.006 0 0 1 2.02 3.473V84.15l-58.89.004ZM1090.32 86.405V70.667c0-.518.21-.996.56-1.255l6.89-3.64a.89.89 0 0 1 1.11 0l7.16 3.64c.34.259.55.737.55 1.255v15.737l-16.27.001ZM1124.64 86.405V70.667c0-.518-.21-.996-.56-1.255l-6.89-3.64a.89.89 0 0 0-1.11 0l-7.16 3.64c-.34.259-.55.737-.55 1.255v15.737l16.27.001ZM1063.31 86.406v-8.607c0-.283.12-.545.31-.687l3.86-1.99a.52.52 0 0 1 .63 0l4 1.99c.19.142.31.404.31.687v8.606h-9.11ZM1151.65 86.406v-8.607c0-.283-.12-.545-.31-.687l-3.86-1.99a.52.52 0 0 0-.63 0l-4 1.99c-.2.142-.31.404-.31.687v8.606h9.11Z"
      fill="#CCC"
    />
    <path
      d="M1622 85.908s-107.47-3.422-252.63-3.422-249.84 3.422-344.75 3.422c-94.908 0-235.878-4.562-341.955-4.562-106.076 0-216.34 4.562-216.34 4.562L449.992 105H1622V85.908Z"
      fill="#E7F9E9"
    />
    <path
      d="M0 85.851s43.366-5.632 101.938-5.632 100.812 5.632 139.109 5.632c38.297 0 95.18-4.505 137.983-4.505s87.295 4.505 87.295 4.505V105H0V85.851Z"
      fill="#E7F9E9"
    />
  </svg>
);

export default SvgComponent;
