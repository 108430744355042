import React, {memo} from 'react';

import PropTypes from 'prop-types';
import EmploymentHistoryWizard from 'modules/project/components/BorrowerDetails/components/EmploymentHistoryWizard';
import GovIdWizard from 'modules/project/components/BorrowerDetails/components/GovIdWizard';
import IncomeSourcesWizard from 'modules/project/components/BorrowerDetails/components/IncomeSourcesWizard';

import {taskTypes} from '@renofi/utilities/src/enums';

const CustomWizard = ({onClose, task}) => {
  const {id: taskId, taskType} = task;

  switch (taskType) {
    case taskTypes.government_issued_id:
      return <GovIdWizard onClose={onClose} taskId={taskId} {...task} />;
    case taskTypes.employment_history:
      return <EmploymentHistoryWizard onClose={onClose} task={task} />;
    case taskTypes.income:
      return <IncomeSourcesWizard onClose={onClose} task={task} />;
    default:
      return null;
  }
};

CustomWizard.propTypes = {
  onClose: PropTypes.func.isRequired,
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    taskType: PropTypes.string.isRequired,
  }),
};

export default memo(CustomWizard);
