import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme';

const SvgTrash = ({color = basic20, width = 24, ...props}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4.708 20.333a2.424 2.424 0 0 0 2.417 2.417h9.667a2.424 2.424 0 0 0 2.416-2.417v-14.5h-14.5v14.5zm2.973-8.603 1.704-1.704 2.573 2.562 2.562-2.562 1.704 1.704-2.562 2.562 2.562 2.561-1.704 1.704-2.562-2.562-2.561 2.562-1.704-1.704 2.562-2.561L7.68 11.73zm8.507-9.522L14.979 1H8.938L7.729 2.208H3.5v2.417h16.917V2.208h-4.23z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgTrash.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

export default SvgTrash;
