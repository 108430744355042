import styled from '@emotion/styled';

import {
  successDark,
  successExtraLight,
  warningDark,
  warningExtraLight,
} from '@renofi/theme/src/colors';
import {Flex} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme';

export const Order = styled(Flex)(({isCompleted}) => ({
  gap: 10,
  alignItems: 'center',
  padding: 16,

  position: 'relative',
  top: 1, // cheating, to overlap the top grey border in 1st Appraisal task

  lineHeight: '18px',
  fontSize: 14,
  background: isCompleted ? successExtraLight : warningExtraLight,
  color: isCompleted ? successDark : warningDark,
  [maxSmall]: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 16,
  },
}));

export const Separator = styled.div(({isCompleted}) => ({
  height: 24,
  width: 1,
  marginLeft: 4,
  marginRight: 4,
  background: isCompleted ? successDark : warningDark,
  opacity: 0.2,
  [maxSmall]: {
    display: 'none',
  },
}));

export const gridStyles = {
  marginTop: 16,
  display: 'grid',
  gridGap: 16,
  gridTemplateColumns: ['100%', '1fr 1fr'],
};
