import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme/src/breakpoints';
import {default as CommonButton} from '@renofi/components-internal/src/Button';

export const Button = styled(CommonButton)({
  [maxSmall]: {
    display: 'block',
    width: '100%',
    height: 'auto',
    borderRadius: 22,
    fontSize: 14,
    lineHeight: '21px',
    minHeight: '44px',
    marginBottom: '10px',
  },
});
