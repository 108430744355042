import {identity, memoizeWith} from 'ramda';

const getFormatter = memoizeWith(
  identity,
  (fractional) =>
    new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: fractional ? 2 : 0,
      maximumFractionDigits: fractional ? 2 : 0,
    }),
);

export default (value, fractional = false) =>
  getFormatter(fractional).format(value);
