import styled from '@emotion/styled';

import {Text} from '@renofi/components-internal';
import {basic80} from '@renofi/theme/src';

export const Title = styled(Text)({
  fontSize: 24,
  lineHeight: '29px',
  color: basic80,
});
