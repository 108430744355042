import {gql} from '@apollo/client';

export default gql`
  query projectActions($projectId: ID!) {
    projectActions(projectId: $projectId) {
      id
      type
      createdAt
      renovationUpdateReminderId
      note {
        id
        title
      }
      task {
        id
        title
      }
      document {
        id
        fileName
        documentType
      }
      performedBy {
        id
        email
        firstName
        lastName
      }
    }
  }
`;
