import React, {useEffect, useRef, useState} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import InfoIcon from '@renofi/icons/src/Info';
import {
  DEFAULT_PLACE_FIELDS,
  DEFAULT_TYPES,
  getPlacePredictions,
} from '@renofi/utilities/src/places';
import useDebounce from '@renofi/utilities/src/useDebounce';
import noop from '@renofi/utilities/src/noop';

import Box from '../Box';
import Link from '../Link';
import TextField from '../TextField';
import Portal from '../Portal';

import {Address, City, Container, Nothing, Street, Wrapper} from './styled';
import {getPlaceById, mapPredictions} from './utils';

const AddressField = ({
  active,
  help = true,
  place,
  placeFields = DEFAULT_PLACE_FIELDS,
  placeTypes = DEFAULT_TYPES,
  primary,
  onChange = noop,
  onClickOutside: onBlur = noop,
  onManual = noop,
  onSelect = noop,
  placeholder = 'Search address',
  value,
  ...props
}) => {
  const [displayValue, setDisplayValue] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const ref = useRef();

  useEffect(() => {
    const handleKeyUp = (e) => {
      const {target} = e;
      const isTab = e.key === 'Tab';
      const isInput = ref?.current?.contains(target);

      // If we "tabbed into" input, show Portl
      if (isTab && isInput) {
        setShow(true);
        // Any other tab keyStroke can be considered a "blur"
      } else if (isTab) {
        setShow(false);
      }
    };
    document.addEventListener('keyup', handleKeyUp, true);
    return () => {
      document.removeEventListener('keyup', handleKeyUp, true);
    };
  }, []);

  useEffect(() => {
    const address = propOr('', 'address', place);
    setDisplayValue(address || value || '');
  }, [place, value]);

  useEffect(() => {
    (async () => {
      const address = propOr('', 'address', place);
      const result = await getPlacePredictions(address, placeTypes);

      setPredictions(mapPredictions(result));
    })();
  }, []);

  const onClickManual = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(false);
    onManual(displayValue);
  };

  const onClickOutside = () => {
    onBlur(displayValue);
    setShow(false);
  };

  const onChangeValue = useDebounce(async (newValue) => {
    setDisplayValue(newValue);
    const result = await getPlacePredictions(newValue, placeTypes);
    const places = mapPredictions(result);

    onChange(newValue);
    setPredictions(places);
    setSelectedId(places[0]?.placeId);
  }, 200);

  const onEnter = () => {
    onSelectPlace(selectedId);
  };

  const onArrowDown = () => {
    if (!selectedId) {
      return setSelectedId(predictions[0]?.placeId);
    }

    const index = predictions.findIndex((p) => p.placeId === selectedId);
    const newIndex = index === predictions.length - 1 ? 0 : index + 1;
    setSelectedId(predictions[newIndex]?.placeId);
  };

  const onArrowUp = () => {
    if (!selectedId) {
      return setSelectedId(predictions[0]?.placeId);
    }

    const index = predictions.findIndex((p) => p.placeId === selectedId);
    const newIndex = index === 0 ? predictions.length - 1 : index - 1;
    setSelectedId(predictions[newIndex]?.placeId);
  };

  const onKeyUp = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (predictions.length) {
      setShow(true);
    }
    switch (e.key) {
      case 'ArrowUp':
        return onArrowUp();
      case 'ArrowDown':
        return onArrowDown();
      case 'Enter':
        return onEnter();
      default:
        return null;
    }
  };

  const onSelectPlace = async (newSelectedId) => {
    setSelectedId(newSelectedId);
    const place = await getPlaceById(newSelectedId, placeFields);

    setShow(false);
    onSelect(place);
  };

  return (
    <Wrapper>
      <div ref={ref}>
        <TextField
          {...props}
          placeholder={placeholder}
          autoComplete="none"
          active={active}
          primary={primary}
          onChange={onChangeValue}
          onFocus={() => setShow(true)}
          value={displayValue}
          onKeyUp={onKeyUp}
        />
      </div>
      {show && (
        <Portal onClickOutside={onClickOutside} ref={ref}>
          <Container>
            {predictions.map(({placeId, primary, secondary}) => (
              <Address
                key={placeId}
                active={selectedId === placeId}
                onClick={() => onSelectPlace(placeId)}>
                <Street>{primary}</Street>
                <City>{secondary}</City>
              </Address>
            ))}
            {help && (
              <Nothing>
                <InfoIcon width={16} height={16} />
                <Box ml="8px">
                  Can’t find your address?{' '}
                  <Link onClick={onClickManual}>
                    Add address details manually
                  </Link>
                </Box>
              </Nothing>
            )}
          </Container>
        </Portal>
      )}
    </Wrapper>
  );
};

AddressField.propTypes = {
  help: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  value: PropTypes.string,
  placeFields: PropTypes.arrayOf(PropTypes.string),
  placeTypes: PropTypes.arrayOf(PropTypes.string),
  place: PropTypes.shape({
    address: PropTypes.string,
  }),
  primary: PropTypes.bool,
  placeholder: PropTypes.string,
  onClickOutside: PropTypes.func,
  onManual: PropTypes.func,
  onSelect: PropTypes.func,
  tabIndex: PropTypes.number,
};

export default AddressField;
