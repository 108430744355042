import additionalProperty from './additionalProperty';
import soldProperty from './soldProperty';
import subjectProperty from './subjectProperty';

export default `
additionalProperties {
  ${additionalProperty}
}
additionalPropertyNumber
additionalPropertyPresent
id
soldProperties {
  ${soldProperty}
}
soldPropertyNumber
soldPropertyPresent
subjectProperty {
  ${subjectProperty}
}
  taskId
`;
