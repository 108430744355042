import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {isNil} from 'ramda';

import {ButtonGroup, Increment, Box} from '@renofi/components-internal';

import {Content, Title} from '../styled';

const PropertyCount = ({value, onChange, title, subtitle}) => {
  const [hasAdditional, setHasAdditional] = useState(valueToBool());

  function onToggle(valueSelected) {
    setHasAdditional(valueSelected);
    if (valueSelected) {
      onChange(value || 1);
    } else {
      onChange(0);
    }
  }

  function valueToBool() {
    if (isNil(value)) return value;
    return value > 0;
  }

  return (
    <Content>
      <Box>
        <Title>{title}</Title>
        <ButtonGroup
          buttonsWidth={260}
          value={hasAdditional}
          onChange={onToggle}
        />
      </Box>

      {hasAdditional ? (
        <Box>
          <Title>{subtitle}</Title>
          <Box width={200}>
            <Increment
              min={1}
              max={20}
              name="property-count"
              value={String(value)}
              onChange={(value) => onChange(Number(value))}
            />
          </Box>
        </Box>
      ) : null}
    </Content>
  );
};

PropertyCount.propTypes = {
  onChange: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
};

export default PropertyCount;
