import {gql} from '@apollo/client';

export default gql`
  mutation markAppraisalReviewAsFinished(
    $appraisalOrderId: ID
    $documentId: ID!
    $id: ID!
  ) {
    markAppraisalReviewAsFinished(
      appraisalOrderId: $appraisalOrderId
      documentId: $documentId
      id: $id
    ) {
      projectTimestamps {
        id
        appraisalReportReviewFinishedAt
      }
    }
  }
`;
