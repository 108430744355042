export default function () {
  const firstRound = [];
  const secondRound = [];

  if (!window.fetch) {
    firstRound.push(
      import(/* webpackChunkName: "polyfill-fetch" */ 'whatwg-fetch'),
    );
  }

  if (!window.Intl) {
    firstRound.push(import(/* webpackChunkName: "polyfill-intl" */ 'intl'));
    secondRound.push(
      import(
        /* webpackChunkName: "polyfill-intl" */ 'intl/locale-data/jsonp/en.js'
      ),
    );
  }

  if (!(window.Intl && window.Intl.RelativeTimeFormat)) {
    firstRound.push(
      import(
        /* webpackChunkName: "polyfill-intl-relative" */ '@formatjs/intl-relativetimeformat/polyfill'
      ),
    );
    secondRound.push(
      import(
        /* webpackChunkName: "polyfill-intl-relative" */ '@formatjs/intl-relativetimeformat/locale-data/en'
      ),
    );
  }

  if (!(window.Intl && window.Intl.ListFormat)) {
    firstRound.push(
      import(/* webpackChunkName: "intl-list-format" */ 'intl-list-format'),
    );

    secondRound.push(
      import(/* webpackChunkName: "intl-list-format" */ 'intl-list-format'),
    );
  }

  if (!(window.Intl && window.Intl.PluralRules)) {
    firstRound.push(
      import(
        /* webpackChunkName: "polyfill-intl-plural" */ '@formatjs/intl-pluralrules/polyfill'
      ),
    );
    secondRound.push(
      import(
        /* webpackChunkName: "polyfill-intl-plural" */ '@formatjs/intl-pluralrules/locale-data/en'
      ),
    );
  }

  if (!Array.prototype.toSorted) {
    firstRound.push(
      import(
        /* webpackChunkName: "array-prototype-tosorted" */ 'array.prototype.tosorted'
      ),
    );

    secondRound.push(
      import(
        /* webpackChunkName: "array-prototype-tosorted" */ 'array.prototype.tosorted'
      ),
    );
  }

  return {firstRound, secondRound};
}
