import {reject} from 'lodash';

import useToggledQuery from '../../useToggledQuery';
import {GET_ENUMS} from '../../../queries';

export default function useEnums({lazy = false, ...options} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_ENUMS,
    options,
  });
  const {data, error, loading, fetch} = response;

  const enums = data?.enums || {};
  // Ignore unclassified as it's 100% internal and users shouldn't
  // be able or allowed to change a document-type to this one
  const documentTypes = enums?.documentTypes || [];
  const incomeTypes = enums?.incomeTypes || [];

  return {
    fetch,
    documentTypes: reject(documentTypes, {value: 'unclassified'}),
    enums,
    incomeTypes,
    loading,
    error,
  };
}
