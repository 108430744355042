import {keys, without} from 'ramda';

export const EMPTY_CONTRACTOR = {
  businessName: '',
  city: '',
  contactName: '',
  email: '',
  estimatedCostOfWork: 0,
  phoneNumber: '',
  scopeOfWork: '',
  state: '',
  streetAddress: '',
  website: '',
  zipCode: '',
};

export const PICKED_PROPS = keys(EMPTY_CONTRACTOR);

export const REQUIRED_FIELDS = without(['website'], PICKED_PROPS);

export const NEW_CONTRACTOR = 'new';

export const UUID_V4_REGEX =
  '[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}';
