import React from 'react';

import PropTypes from 'prop-types';
import {isEmpty, propOr} from 'ramda';
import {
  ChecklistContent,
  ChecklistWrapper,
  ChecklistItem,
} from 'modules/layout/components/ChecklistItem';

import {Link, Text, Box, Flex} from '@renofi/components-internal';
import {basic80, basic55, basic75} from '@renofi/theme/src/colors';
import {sendEvent} from '@renofi/utilities/src/analytics';
import {useToggledBorrowerProject} from '@renofi/graphql';

import contractorIcon from './icons/contractor.svg';
import sampleDocIcon from './icons/sample-doc.svg';
import {Wrapper, ContractInfo, Title} from './styled';
import {
  CONTRACTOR_REQUIREMENTS_HREF,
  SAMPLE_CONTRACT_DOCUMENT_HREF,
  SAMPLE_COST_ESTIMATED_DOCUMENT_HREF,
  EVENT_OPTIONS,
} from './constants';

function ChecklistHelpWidget({task}) {
  const documents = propOr([], 'documents', task);
  const hasUploadedDocs = !isEmpty(documents);
  const {project} = useToggledBorrowerProject();
  const isPersonalLoan = project?.loanType === 'Personal Loan';

  if (hasUploadedDocs) {
    return null;
  }

  const onContractDocumentClick = () => {
    sendEvent('Blueprint/Sample-Contract-Document-Click', EVENT_OPTIONS);
  };

  const onCostEstimatedDocumentClick = () => {
    sendEvent('Blueprint/Sample-Cost-Estimated-Document-Click', EVENT_OPTIONS);
  };

  return (
    <Wrapper p={0}>
      <ChecklistContent>
        <Title mb={24}>What should the contract include?</Title>
        <ChecklistWrapper mt={2} color={basic55} flexDirection="column">
          <Box>
            <ChecklistItem text="A detailed cost estimate" />
            <Text color={basic75} mt={2} fontStyle="italic" fontSize={12}>
              This can be a separate document
            </Text>
          </Box>
          <ChecklistItem text="Timelines and duration." />
          <ChecklistItem text="Change order procedure." />
          <ChecklistItem text="Guarantee/Warranty" />
          <ChecklistItem text="Contact info for contractor and homeowner" />
          <ChecklistItem text="Terms of payment/payment schedule" />
          <ChecklistItem text="Clear description of work." />
        </ChecklistWrapper>
      </ChecklistContent>

      <ContractInfo>
        {!isPersonalLoan ? (
          <Box mb={24}>
            <Title fontWeight="bold" color={basic80} mb={12} fontSize={16}>
              Before you upload
            </Title>
            <Text color={basic80} mb={12}>
              Please read the following article before uploading your contract
              to get an understanding of what it should include:
            </Text>
            <Link blank href={CONTRACTOR_REQUIREMENTS_HREF}>
              <Flex alignItems="center" css={{gap: 3}}>
                <img src={contractorIcon} />
                <Text>
                  What do I need from my Contractor to apply for a RenoFi loan?
                </Text>
              </Flex>
            </Link>
          </Box>
        ) : null}

        <Box>
          <Title mb={12}>Sample docs</Title>
          <Text color={basic80} mb={12}>
            It’s also worth reviewing our sample contract and cost estimate to
            get a better understanding of our requirements.
          </Text>
          <Box mb={12}>
            <Link
              blank
              href={SAMPLE_CONTRACT_DOCUMENT_HREF}
              onClick={onContractDocumentClick}>
              <Flex alignItems="center" css={{gap: 3}}>
                <img src={sampleDocIcon} />
                <Text>Sample contract document</Text>
              </Flex>
            </Link>
          </Box>
          <Link
            blank
            href={SAMPLE_COST_ESTIMATED_DOCUMENT_HREF}
            onClick={onCostEstimatedDocumentClick}>
            <Flex alignItems="center" css={{gap: 3}}>
              <img src={sampleDocIcon} />
              <Text>Sample cost estimated document</Text>
            </Flex>
          </Link>
        </Box>
      </ContractInfo>
    </Wrapper>
  );
}

ChecklistHelpWidget.propTypes = {
  task: PropTypes.shape({
    documents: PropTypes.array.isRequired,
  }),
};

export default ChecklistHelpWidget;
