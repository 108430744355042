import {
  clearBorrower,
  initAccessibe,
  initSentry,
  initDelighted,
} from '@renofi/utilities/src/analytics';
import initAnalytics from '@renofi/utilities/src/segment';
import isImpersonation from '@renofi/utilities/src/isImpersonation';
import {initLogRocket} from '@renofi/utilities/src/logrocket2';
import googlePlaces from '@renofi/utilities/src/googlePlaces';

export default async ({config, storage}) => {
  const {
    DEVELOPMENT,
    GOOGLE_MAPS_API_KEY,
    PRODUCTION,
    LOGROCKET_ID,
    SEGMENT_KEY: segmentKey,
    SEGMENT_URL: baseUrl,
  } = config;
  const impersonation = isImpersonation(storage);

  if (GOOGLE_MAPS_API_KEY) {
    googlePlaces(GOOGLE_MAPS_API_KEY);
  }

  window.addEventListener('beforeunload', () => {
    clearBorrower();
  });

  if (impersonation) return;

  if (!DEVELOPMENT && segmentKey) {
    initAnalytics({
      segmentKey,
      baseUrl,
      options: {
        integrations: {
          All: true,
          'Facebook Pixel': false,
          'Bing Ads': false,
          AutopilotHQ: false,
          'AdWords Remarketing Lists': false,
        },
      },
    });
  }

  if (PRODUCTION) {
    initLogRocket(LOGROCKET_ID);
    initSentry();
    initAccessibe();
    initDelighted();
  }
};
