import React from 'react';

const SingleFamilyHome = () => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 16.5H5V10.5H11V16.5H14V7.5L8 3L2 7.5V16.5ZM0 18.5V6.5L8 0.5L16 6.5V18.5H9V12.5H7V18.5H0Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default SingleFamilyHome;
