import React from 'react';

const DesignIcon = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="9 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <mask
      id="mask0_392_13625"
      style={{maskType: 'alpha'}}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32">
      <rect width="32" height="32" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_392_13625)">
      <path
        className="first"
        d="M9.00033 28L8.66699 25.0667L12.467 14.6C12.8003 14.9111 13.1614 15.1722 13.5503 15.3833C13.9392 15.5944 14.3559 15.7556 14.8003 15.8667L11.1337 25.9333L9.00033 28ZM23.0003 28L20.867 25.9333L17.2003 15.8667C17.6448 15.7556 18.0614 15.5944 18.4503 15.3833C18.8392 15.1722 19.2003 14.9111 19.5337 14.6L23.3337 25.0667L23.0003 28ZM16.0003 14.6667C14.8892 14.6667 13.9448 14.2778 13.167 13.5C12.3892 12.7222 12.0003 11.7778 12.0003 10.6667C12.0003 9.8 12.2503 9.02778 12.7503 8.35C13.2503 7.67222 13.8892 7.2 14.667 6.93333V4H17.3337V6.93333C18.1114 7.2 18.7503 7.67222 19.2503 8.35C19.7503 9.02778 20.0003 9.8 20.0003 10.6667C20.0003 11.7778 19.6114 12.7222 18.8337 13.5C18.0559 14.2778 17.1114 14.6667 16.0003 14.6667ZM16.0003 12C16.3781 12 16.6948 11.8722 16.9503 11.6167C17.2059 11.3611 17.3337 11.0444 17.3337 10.6667C17.3337 10.2889 17.2059 9.97222 16.9503 9.71667C16.6948 9.46111 16.3781 9.33333 16.0003 9.33333C15.6225 9.33333 15.3059 9.46111 15.0503 9.71667C14.7948 9.97222 14.667 10.2889 14.667 10.6667C14.667 11.0444 14.7948 11.3611 15.0503 11.6167C15.3059 11.8722 15.6225 12 16.0003 12Z"
        fill="currentColor"
      />
      <path
        className="second"
        d="M23.0002 28L20.8669 25.9333L17.2002 15.8666C17.6446 15.7555 18.0613 15.5944 18.4502 15.3833C18.8391 15.1722 19.2002 14.9111 19.5335 14.6L23.3335 25.0666L23.0002 28Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default DesignIcon;
