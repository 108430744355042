export const getDisplayValue = (dob) => {
  if (!dob) {
    return null;
  }
  const [year, month, day] = dob.split('-');
  return [month, day, year].join('/');
};

export const setUpstreamValue = (dob) => {
  if (!dob) {
    return null;
  }

  const [month, day, year] = dob.split('/');
  return [year, month, day].join('-');
};
