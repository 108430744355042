import React from 'react';

import PropTypes from 'prop-types';

import {Label, Box, Checkbox} from '@renofi/components-internal';

import {CheckboxLabel, CheckboxWrapper} from '../styled';

const ApplicantsSelect = ({
  onChange,
  primaryBorrowerOwned,
  coborrowerOwned,
  borrowersInfo,
}) => {
  const borrowerName = `${borrowersInfo?.firstName} ${borrowersInfo?.lastName}`;
  const coborrowerName = `${borrowersInfo?.coborrowerFirstName} ${borrowersInfo?.coborrowerLastName}`;

  return (
    <Box>
      <Label>Which of the applicants below owns this property?</Label>
      <CheckboxWrapper
        data-testid="primary-borrower-owned"
        onClick={() =>
          onChange({
            primaryBorrowerOwned: !primaryBorrowerOwned,
          })
        }>
        <Checkbox checked={primaryBorrowerOwned} />
        <CheckboxLabel>{borrowerName}</CheckboxLabel>
      </CheckboxWrapper>

      <CheckboxWrapper
        data-testid="coborrower-owned"
        onClick={() => onChange({coborrowerOwned: !coborrowerOwned})}>
        <Checkbox checked={coborrowerOwned} />
        <CheckboxLabel>{coborrowerName}</CheckboxLabel>
      </CheckboxWrapper>
    </Box>
  );
};

ApplicantsSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  primaryBorrowerOwned: PropTypes.bool,
  coborrowerOwned: PropTypes.bool,
  borrowersInfo: PropTypes.object,
};

export default ApplicantsSelect;
