import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {SUBMIT_GOV_ID_DETAILS} from '../mutations';

import useMutation from './useMutation';

export default function useSubmitGovInformation({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitGovIdDetails, {data, loading, error}] = useMutation(
    SUBMIT_GOV_ID_DETAILS,
    {
      onCompleted,
      refetchQueries,
    },
  );

  const response = pathOr(null, ['submitGovIdDetails'], data);
  return {submitGovIdDetails, response, loading, error};
}
