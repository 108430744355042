import React, {useState, useEffect, useMemo} from 'react';

import {pathOr, propOr} from 'ramda';
import {useParams} from 'react-router-dom';
import {PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL} from 'config';
import ChangePhone from 'modules/phone';

import {
  Box,
  Container,
  Flex,
  Link,
  Loader,
  PageTitle,
  Text,
} from '@renofi/components-internal';
import {
  useToggledBorrowerProject,
  useGetUser,
  useProjectTimeline,
  useProjectRenovationUpdatesV2,
  useUpdateSmsConsentRenovationProgress,
} from '@renofi/graphql/src/hooks';
import {basic55} from '@renofi/theme/src/colors';
import Phone from '@renofi/icons/src/Phone';
import {RENOVATION_UPDATE_KINDS} from '@renofi/modules-internal';

import Timeline from '../Timeline';

import {Checkbox, Complete, Consent, Message, StyledBox} from './styled';

const Renovation = () => {
  const [phoneNumberModal, setPhoneNumberModal] = useState(false);
  const [smsConsentVisible, setSmsConsentVisible] = useState(false);
  const {projectId} = useParams();

  const {user} = useGetUser();
  const {fetch: fetchBorrowerProjects, project} = useToggledBorrowerProject();

  const {updateSmsConsentRenovationProgress} =
    useUpdateSmsConsentRenovationProgress();

  const {timeline: projectTimeline, loading: loadingTimeline} =
    useProjectTimeline({
      skip: !projectId,
      variables: {id: projectId},
    });
  const {renovationUpdates, loading: loadingUpdates} =
    useProjectRenovationUpdatesV2({
      skip: !projectId,
      variables: {id: projectId},
    });

  useEffect(() => {
    const isSmsConsentAccepted = pathOr(
      false,
      ['smsConsentRenovationProgress', 'accepted'],
      project,
    );
    setSmsConsentVisible(!isSmsConsentAccepted);
  }, [project]);

  const smsConsentProgress = project?.smsConsentRenovationProgress || {};
  const smsConsentAccepted = propOr(false, 'accepted', smsConsentProgress);
  const phoneNumber = smsConsentProgress?.phoneNumber || user?.phoneNumber;
  const isLoading = loadingTimeline || loadingUpdates;

  const {
    renovationCompletedOn,
    completionCertificateRequestedAt,
    loanClosedOn,
  } = projectTimeline || {};

  const disabled =
    !loanClosedOn ||
    ['preparing', 'in_progress', 'with_lender'].includes(project?.status);

  const complete = useMemo(() => {
    const hasRenovationCompleteUpdate = renovationUpdates.some(
      ({kind}) => kind === RENOVATION_UPDATE_KINDS.renovation_completed,
    );

    const isCompleted =
      Boolean(renovationCompletedOn || completionCertificateRequestedAt) ||
      hasRenovationCompleteUpdate;

    return !disabled && isCompleted;
  }, [
    disabled,
    renovationCompletedOn,
    completionCertificateRequestedAt,
    renovationUpdates,
  ]);

  const onHideModal = () => {
    setPhoneNumberModal(false);
  };

  const onChangeSmsConsent = async (value) => {
    await updateSmsConsentRenovationProgress({
      variables: {
        projectId,
        accepted: value,
        phoneNumber,
      },
    });
    fetchBorrowerProjects();
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Container narrow mt={16} data-testid="renovation">
        <PageTitle mb={3} backButton={false} text="Renovate" />

        {Boolean(projectId) && (
          <Consent head="Let's stay in touch!" visible={smsConsentVisible}>
            <StyledBox width={640}>
              Let's stay in close touch as you move forward with the renovation
              so we can help you if any issues come or you have questions. By
              checking the “I agree and consent” box below, you agree and
              consent by electronic signature to receive calls and SMS, MMS and
              other text messages, including those made using an automatic
              telephone dialing system, artificial voice, or prerecorded
              message, from RenoFi or any of its agents or representatives at
              the phone number you have provided even if that number is
              registered on a corporate, state, or national do not call
              registry. You understand and agree that your consent to such calls
              and messages is not required to use RenoFi's services and that you
              may contact RenoFi at{' '}
              <Link blank href="mailto:sayhi@renofi.com">
                sayhi@renofi.com
              </Link>{' '}
              or 1-855-736-6341 about its services at any time. You also
              understand that you may revoke your consent at any time.
            </StyledBox>
            <Flex
              css={{gap: 4}}
              mt={16}
              alignItems="center"
              fontSize={14}
              color={basic55}>
              Your number: <Phone height={20} width={20} /> {phoneNumber}
              <Link onClick={() => setPhoneNumberModal(true)}>Change</Link>
            </Flex>
            <StyledBox width={640}>
              <Checkbox
                accepted={smsConsentAccepted}
                my={16}
                checked={smsConsentAccepted}
                onChange={onChangeSmsConsent}
                label={
                  <Text fontSize={16} ml={2}>
                    I agree to receive calls and text messages from RenoFi. View
                    RenoFi's{' '}
                    <Link blank href={TERMS_OF_SERVICE_URL}>
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link blank href={PRIVACY_POLICY_URL}>
                      Privacy Policy
                    </Link>{' '}
                    here.
                  </Text>
                }
              />
            </StyledBox>
          </Consent>
        )}
        <Box>
          {disabled && (
            <Message head="We can't wait to see your renovation underway!">
              <StyledBox width={660}>
                After closing the loan we'll periodically check with you if the
                renovation is running smooth and if anything occurs.
              </StyledBox>
            </Message>
          )}
          {complete && (
            <Complete head="We like what you did with your home!">
              <StyledBox width={700}>
                Renovation completed! You ordered the certificate of completion
                - the appraiser will reach out to you directly to schedule the
                day and time that works best for you.
              </StyledBox>
            </Complete>
          )}
          <Timeline
            smsConsentVisible={smsConsentVisible}
            handleShowSmsConsent={() => setSmsConsentVisible(true)}
          />
        </Box>
      </Container>
      {phoneNumberModal && (
        <ChangePhone phoneNumber={phoneNumber} onClose={onHideModal} />
      )}
    </>
  );
};

export default Renovation;
