import React from 'react';

import PropTypes from 'prop-types';

import {DateFormat, TimeAgo} from '@renofi/components-internal';
import capitalize from '@renofi/utilities/src/capitalize';

import {TWO_DAYS} from '../constants';

const FormattedDate = ({value}) => {
  const date = new Date(value);
  const curr = Date.now();
  const diff = curr - date.getTime();

  return diff > TWO_DAYS ? (
    <DateFormat long time={false} value={date} />
  ) : (
    <TimeAgo date={date} unit="day" time={false} formatter={capitalize} />
  );
};

FormattedDate.propTypes = {
  value: PropTypes.string,
};

export default FormattedDate;
