import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {MARK_TASK_FINISHED_BY_BORROWER} from '../../../mutations';
import {useUpdateCachedTask} from '../../../utils';

export default function useMarkTaskFinishedByBorrower({
  onCompleted = noop,
  refetchQueries = [],
  updateCache = true,
} = {}) {
  const updateCachedTask = useUpdateCachedTask();
  const [mutation, {data, loading, error}] = useMutation(
    MARK_TASK_FINISHED_BY_BORROWER,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = data?.markTaskFinishedByBorrower || null;

  const markTaskFinishedByBorrower = async (args = {}) => {
    const rsp = await mutation(args);
    const task = rsp?.data?.markTaskFinishedByBorrower?.task;
    const projectId = task?.project?.id;

    if (Boolean(rsp?.errors) || !updateCache || !task || !projectId) {
      return rsp;
    }

    await updateCachedTask({projectId, task});

    return rsp;
  };

  return {markTaskFinishedByBorrower, response, loading, error};
}
