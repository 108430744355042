import {isEmpty} from 'ramda';
import {filterOutstanding} from 'lib/projectTasks';

import isFunction from '@renofi/utilities/src/isFunction';

import {
  STATUS_PENDING,
  STATUS_COMPLETED,
  STATUS_OUTSTANDING,
  CARDS_INFO,
  TASK_CATEGORIES,
} from '../constants';

function checkOutstanding(tasks) {
  if (!tasks || isEmpty(tasks)) {
    return {status: '', tasksCount: 0};
  }

  const outstandingTasks = filterOutstanding(tasks);
  const completedTasks = tasks.filter(
    (word) => word.status === STATUS_COMPLETED,
  );

  if (tasks.length === completedTasks.length) {
    return {status: STATUS_COMPLETED, tasksCount: 0};
  }

  if (outstandingTasks.length > 0) {
    return {status: STATUS_OUTSTANDING, tasksCount: outstandingTasks.length};
  }

  return {status: STATUS_PENDING, tasksCount: 0};
}

export default function getTasksInfo({
  additionalDocuments = [],
  renovation = [],
  contractor = [],
  appraisal = [],
  insurance = [],
  eligibility = [],
  productConfirmation = [],
  borrowerInformation = [],
  isRaas = false,
  hasCoBorrower = false,
}) {
  const tasksInfo = CARDS_INFO.reduce((cards, category) => {
    switch (category.id) {
      case TASK_CATEGORIES.ADDITIONAL_DOCUMENTS:
        cards.push(extendCategory(category, additionalDocuments, isRaas));
        return cards;
      // As appraisla is now in renovation UI, include appraisal
      case TASK_CATEGORIES.RENOVATION:
        cards.push(
          extendCategory(
            category,
            [...appraisal, ...insurance, ...renovation],
            isRaas,
          ),
        );
        return cards;
      case TASK_CATEGORIES.CONTRACTOR:
        cards.push(extendCategory(category, contractor, isRaas));
        return cards;
      case TASK_CATEGORIES.PRODUCT:
        cards.push(extendCategory(category, productConfirmation, isRaas));
        return cards;
      case TASK_CATEGORIES.BORROWERS:
        if (hasCoBorrower) {
          category.icon = 'team';
        }
        cards.push(
          extendCategory(
            category,
            [...borrowerInformation, ...eligibility],
            isRaas,
          ),
        );
        return cards;
      default:
        return cards;
    }
  }, []);
  const allTasks = [
    ...renovation,
    ...contractor,
    ...appraisal,
    ...insurance,
    ...eligibility,
    ...productConfirmation,
    ...borrowerInformation,
  ];

  const lastSubmittedTaskAt = Math.max(
    ...allTasks.map((task) => new Date(task.submittedAt)),
  );
  const outstandingTasks = allTasks.filter(
    (task) => task?.status === STATUS_OUTSTANDING,
  );
  const newTasks = outstandingTasks.filter((task) => !task.anyDocumentRejected);
  const hasNewTaskAfterCompletion =
    newTasks.length > 0 &&
    newTasks?.every(
      (task) => new Date(task.createdAt) > new Date(lastSubmittedTaskAt),
    );

  return {
    tasksInfo,
    hasNewTaskAfterCompletion,
    allTasksCount: allTasks.length,
    outstandingTasksCount: outstandingTasks.length,
  };
}

function extendCategory(category, tasks, isRaas) {
  const {content, ...restOfCategory} = category;
  const badge = checkOutstanding(tasks);

  return {
    ...restOfCategory,
    badge,
    content: isFunction(content) ? content({isRaas}) : content,
    tasksLength: tasks?.length,
  };
}
