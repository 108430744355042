import {map, pathOr, pipe, propOr} from 'ramda';

import {compose} from '@renofi/recompose/src';

import {GET_SUPPORTED_FILE_EXTENSIONS} from '../queries';

import withQuery from './withQuery';

const PREV_PATH = ['previousData', 'supportedFileExtensions'];

export default ({options = {}} = {}) =>
  compose(
    withQuery({
      query: GET_SUPPORTED_FILE_EXTENSIONS,
      props: ({data, ...props}) => {
        const extensions = pipe(
          propOr([], 'supportedFileExtensions'),
          map((ext) => (ext.charAt(0) === '.' ? ext : `.${ext}`)),
        )(data);

        const previous = pathOr([], PREV_PATH, data);

        return {
          supportedFileExtensions: extensions.length
            ? extensions
            : previous.length
            ? previous
            : [],
          ...props,
        };
      },
      options,
    }),
  );
