import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import confetti from 'canvas-confetti';

import {Toast} from '@renofi/components-internal';

import {ProgressImage} from './styled';
import progressImage3 from './images/tasks-consecutive-3.png';
import progressImage6 from './images/tasks-consecutive-6.png';
import progressImage8 from './images/tasks-consecutive-8.png';

const PROGRESS_IMAGES = {
  progressImage3,
  progressImage6,
  progressImage8,
};

const STAGE_TITLE_3 = 'Great progress!';
const STAGE_TITLE_6 = 'Magificent work!';
const STAGE_TITLE_8 = 'Extraordinary feat!';
const STAGE_TITLE_14 = 'Astonishing triumph!';
const STAGE_TITLES = {
  STAGE_TITLE_3,
  STAGE_TITLE_6,
  STAGE_TITLE_8,
  STAGE_TITLE_14,
};

const STAGE_CONTENT_3 = (
  <span>
    You&apos;ve completed <strong>3</strong> tasks in a row!
  </span>
);
const STAGE_CONTENT_6 = (
  <span>
    <strong>6</strong> in a row!
  </span>
);
const STAGE_CONTENT_8 = (
  <span>
    <strong>8</strong> in a row!
  </span>
);
const STAGE_CONTENT_14 = (
  <span>
    Only <strong>3%</strong> complete all tasks in one go!
  </span>
);
const STAGE_CONTENT = {
  STAGE_CONTENT_3,
  STAGE_CONTENT_6,
  STAGE_CONTENT_8,
  STAGE_CONTENT_14,
};

function ProgressToast({stage}) {
  const title = STAGE_TITLES[`STAGE_TITLE_${stage}`];
  const content = STAGE_CONTENT[`STAGE_CONTENT_${stage}`];
  const src = PROGRESS_IMAGES[`progressImage${stage}`];

  useEffect(() => {
    if (stage !== 14) {
      return;
    }

    confetti({
      particleCount: 100,
      spread: 70,
      origin: {y: 1},
    });
  }, []);

  if (!title || !content || !src) {
    return null;
  }

  return (
    <Toast duration={4000} loop={false} title={title}>
      {content}
      <ProgressImage src={src} />
    </Toast>
  );
}

ProgressToast.propTypes = {
  stage: PropTypes.number,
};

export default ProgressToast;
