import {useEffect} from 'react';

import {useUnleashClient} from '@unleash/proxy-client-react';

import {sendEvent} from '@renofi/utilities/src/analytics';

const useFeatureFlagEvents = () => {
  const client = useUnleashClient();

  const reduceFlags = () => {
    return client.getAllToggles().reduce((acc, current) => {
      // 'disabled' is a default variant in Unleash returned if no variants are defined
      if (current.variant.name === 'disabled') {
        return {...acc, [current.name]: current.enabled && 'enabled'};
      }

      return {...acc, [current.name]: current.variant.name};
    }, {});
  };

  useEffect(() => {
    client.once('ready', () => {
      const enabledToggles = reduceFlags();
      sendEvent('Secure/Initial-Feature-Flags-Enabled', {...enabledToggles});
    });
  }, []);

  useEffect(() => {
    client.on('update', () => {
      const enabledToggles = reduceFlags();
      sessionStorage.setItem(
        'sessionFeatureFlags',
        JSON.stringify(enabledToggles),
      );
    });
  }, []);
};

export default useFeatureFlagEvents;
