import {useMemo} from 'react';

import {sendEvent} from '@renofi/utilities/src/analytics';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

export default function useInputFieldAnalytics() {
  const analyticsHandlers = useMemo(() => {
    return {
      onFocus: ({target}) => {
        sendEvent('FieldFocus', {
          text: humanizeSnakeCase(target?.placeholder || target?.name),
          name: target?.name,
        });
      },
      onBlur: ({target}) => {
        sendEvent('FieldBlur', {
          text: humanizeSnakeCase(target?.placeholder || target?.name),
          name: target?.name,
        });
      },
    };
  }, []);

  return [analyticsHandlers];
}
