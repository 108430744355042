import React from 'react';

import PropTypes from 'prop-types';

import {Flex, Box} from '@renofi/components-internal';

import {getContactInformation} from '../../utils';
import ContactInformationGroup from '../ContactInformationGroup';

const ContactInformation = ({lender, isTpo = false}) => {
  const {contactInformation1, contactInformation2} = lender || {};

  const {
    name: contactInfo1Name,
    phone: contactInfo1Phone,
    email: contactInfo1Email,
  } = getContactInformation(contactInformation1) || {};

  const {
    name: contactInfo2Name,
    phone: contactInfo2Phone,
    email: contactInfo2Email,
  } = getContactInformation(contactInformation2) || {};

  return (
    <Flex
      pl={isTpo ? 0 : 16}
      pr={isTpo ? 0 : 16}
      pt={24}
      pb={12}
      flexDirection="column">
      <Flex flexWrap="wrap">
        {contactInformation1 && (
          <Box marginRight={isTpo ? 25 : 0}>
            <ContactInformationGroup
              name={contactInfo1Name}
              email={contactInfo1Email}
              phone={contactInfo1Phone}
              isTpo={isTpo}
            />
          </Box>
        )}
        {contactInformation2 && (
          <Box marginTop={isTpo ? 0 : 20}>
            <ContactInformationGroup
              name={contactInfo2Name}
              email={contactInfo2Email}
              phone={contactInfo2Phone}
              isTpo={isTpo}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

ContactInformation.propTypes = {
  lender: PropTypes.shape({
    contactInformation1: PropTypes.string,
    contactInformation2: PropTypes.string,
  }),
  isTpo: PropTypes.bool,
};

export default ContactInformation;
