import {isNil, clone, isEmpty} from 'ramda';

const INITIAL_ASSETS_STATE = {
  business: {
    key: 'business',
    label: 'Business',
    checked: false,
  },
  checking: {
    key: 'checking',
    label: 'Checking',
    checked: false,
  },
  money_market_fund: {
    key: 'money_market_fund',
    label: 'Money market fund',
    checked: false,
  },
  other_liquid_asset: {
    key: 'other_liquid_asset',
    label: 'Other liquid asset (other bank accounts, etc.)',
    checked: false,
  },
  retirement: {
    key: 'retirement',
    label: 'Retirement (e.g. 401K, IRA)',
    checked: false,
  },
  savings: {
    key: 'savings',
    label: 'Savings',
    checked: false,
  },
  stocks: {
    key: 'stocks',
    label: 'Stocks',
    checked: false,
  },
};

export function initialFormData(task) {
  const {assetDetails} = task;

  if (isNil(assetDetails)) {
    return [];
  }

  return assetDetails.map((assetDetail) => ({
    ...assetDetail,
    isNew: false,
    isDirty: false,
  }));
}

export function generateAssets(task, borrowerRole) {
  const {assetDetails} = task;

  if (isEmpty(assetDetails)) {
    return clone(INITIAL_ASSETS_STATE);
  }

  const assets = Object.values(INITIAL_ASSETS_STATE).reduce((memo, value) => {
    let hasJointAccount = false;

    const isAssetPresent = assetDetails.some(
      (detail) =>
        detail.assetType === value.key && detail.borrowerRole === borrowerRole,
    );

    if (borrowerRole === 'coborrower') {
      hasJointAccount = assetDetails.some(
        (detail) =>
          detail.assetType === value.key &&
          detail.borrowerRole === 'borrower' &&
          detail.jointAccount,
      );
    }

    memo[value.key] = {
      ...value,
      checked: isAssetPresent || hasJointAccount,
    };

    return memo;
  }, {});

  return assets;
}
