export const SMS_CONSENT_RENOVATION_PROGRESS =
  'sms_consent_renovation_progress';
export const RENOVATION_CONTRACT = 'renovation_contract';
const RENOVATION_PLAN = 'renovation_plan';

export const TASK_TYPES = {
  APPRAISAL_RECENT_TRANSACTIONS: 'appraisal_recent_transactions',
  RENOVATION_PLAN: RENOVATION_PLAN,
  RENOVATION_CONTRACT: RENOVATION_CONTRACT,
  SMS_CONSENT_RENOVATION_PROGRESS: SMS_CONSENT_RENOVATION_PROGRESS,
};

export const RECENT_TRANSACTIONS_PROPS = [
  'date',
  'haveAppraisalFile',
  'occurredRecently',
  'value',
];

export const MONTH_OPTIONS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
