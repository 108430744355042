import React from 'react';

import PropTypes from 'prop-types';

import {Container, Text} from '@renofi/components-internal';
import {formatDate} from '@renofi/utilities/src/dates';

import LoanDetails from './components/LoanDetails/Component';
import PersonalDetails from './components/PersonalDetails/Component';
import PropertyDetails from './components/PropertyDetails/Component';
import {Wrapper, SuccessMessage} from './styled';

const SummaryOfInformation = ({
  lenderName,
  createdDate,
  productDetails,
  isTpo = false,
}) => {
  const lenderMatchedDate = formatDate(createdDate);

  if (!productDetails) {
    return null;
  }

  return (
    <Container narrow mt={16} pl={0} pr={0}>
      <Text fontSize={24} fontWeight="bold">
        {isTpo
          ? `Application summary with ${lenderName}`
          : `Summary of information shared with ${lenderName}`}
      </Text>
      <SuccessMessage>
        {isTpo
          ? `Application submitted to ${lenderName} ${lenderMatchedDate}.`
          : `Matched to ${lenderName} ${lenderMatchedDate}. Actual rate is determined
        when you apply with the Lender.`}
      </SuccessMessage>
      <LoanDetails
        lenderName={lenderName}
        productDetailsConfirmation={productDetails?.productDetailsConfirmation}
        isTpo={isTpo}
      />
      <Wrapper>
        <PersonalDetails
          productDetailsConfirmation={
            productDetails?.productDetailsConfirmation
          }
        />
        <PropertyDetails
          productDetailsConfirmation={
            productDetails?.productDetailsConfirmation
          }
        />
      </Wrapper>
    </Container>
  );
};

SummaryOfInformation.propTypes = {
  lenderName: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  productDetails: PropTypes.object.isRequired,
  isTpo: PropTypes.bool,
};

export default SummaryOfInformation;
