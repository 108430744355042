export const taskTypes = {
  ack_delta: 'ack_delta',
  additional_documents: 'additional_documents',
  additional_eligibility_evidence: 'additional_eligibility_evidence',
  advisor_final_call_schedule: 'advisor_final_call_schedule',
  appraisal_comparable_sales: 'appraisal_comparable_sales',
  appraisal_property_details: 'appraisal_property_details',
  appraisal_property_photos: 'appraisal_property_photos',
  appraisal_recent_transactions: 'appraisal_recent_transactions',
  appraisal_renovation_details: 'appraisal_renovation_details',
  assets: 'assets',
  borrowers_onboarding: 'borrowers_onboarding',
  certificate_acknowledgement: 'certificate_acknowledgement',
  contractor_info: 'contractor_info',
  employment_history: 'employment_history',
  government_issued_id: 'government_issued_id',
  home_insurance: 'home_insurance',
  identity_verification: 'identity_verification',
  income: 'income',
  insurance_coverage_proof: 'insurance_coverage_proof',
  mortgage_statement: 'mortgage_statement',
  personal_info_and_residency: 'personal_info_and_residency',
  product_details_confirmation: 'product_details_confirmation',
  real_estate_owned: 'real_estate_owned',
  renovation_contract: 'renovation_contract',
  renovation_plan: 'renovation_plan',
  sms_consent_renovation_progress: 'sms_consent_renovation_progress',
  soft_credit_check: 'soft_credit_check',
  tax_returns_docs: 'tax_returns_docs',
};
