import {makeVar, useReactiveVar} from '@apollo/client';

const showModalVar = makeVar(false);

export default function useShowSavedModal() {
  const show = useReactiveVar(showModalVar);

  const setShowSavedModal = (value) => {
    showModalVar(value);
  };

  return {show, setShowSavedModal};
}
