import {isNil} from 'ramda';

import formatMoney from '@renofi/utilities/src/formatMoney';
import ratioToPercentage from '@renofi/utilities/src/ratioToPercentage';
import formatPhoneNumber from '@renofi/utilities/src/formatPhoneNumber';
import {
  formatInterestRate,
  getConditionalHomeValue,
} from '@renofi/utilities/src/product-confirmation';

import {getOrdinalSuffixForNumber} from '../../../lender/components/Lender/components/SummaryOfInformation/utils';

import {
  ADVISOR_CALL_TASK_TYPE,
  PRODUCT_DETAILS_TASK_TYPE,
  AGREEMENT_TASK_TYPE,
} from './constants';

export const formatAsMoney = (value, defaultValue = '-') => {
  if (!value) return defaultValue;

  return formatMoney(value, {fractional: false});
};

const getLienPositionWithSuffix = (lienPosition) => {
  const lienPositionWithSuffix = `${getOrdinalSuffixForNumber(
    lienPosition,
  )} mortgage`;

  return lienPositionWithSuffix;
};

export const formatLienPosition = (lienPosition) =>
  isNil(lienPosition) ? null : getLienPositionWithSuffix(lienPosition);

export const LOAN_FIELDS = [
  {label: 'Loan type', getValue: (details) => details.loanType},
  {
    label: 'Loan amount',
    getValue: (details) => formatAsMoney(details?.loanAmount),
  },
  {
    label: 'Lender',
    getValue: (details) => details?.lenderName,
  },
  {
    label: 'Lien position',
    getValue: (details) => formatLienPosition(details?.lienPosition),
  },
  {
    label: 'Interest Rate (as low as)',
    getValue: (details) => {
      const {appraisedValue} = details;
      const prefix = 'As low as';
      const suffix = !!appraisedValue ? '(Estimated)' : '';
      const rate = formatInterestRate(
        details?.interestRate,
        details?.interestRateType,
      );
      return [prefix, rate, suffix].join(' ').trim();
    },
  },
  {
    label: 'Applicants',
    getValue: (details) =>
      details.applicants ? details.applicants.join(', ') : '-',
  },
  {
    label: 'Renovation amount',
    getValue: (details) => formatAsMoney(details.renovationAmount),
  },
];

export const PERSONAL_FIELDS = [
  {label: 'Name', getValue: (value) => value.name},
  {
    label: 'Estimated DTI',
    getValue: (value) => ratioToPercentage(value.dtiRatio),
  },
  {label: 'Email', getValue: (value) => value.email},
  {label: 'Phone', getValue: (value) => formatPhoneNumber(value.phone)},
];

export const PROPERTY_FIELDS = [
  (details) => ({
    label: 'Address',
    value: details.address,
  }),
  (details) => ({
    label: 'Title holders',
    value: details.titleHolders || '-',
  }),
  (details) => {
    const condtionalData = getConditionalHomeValue(details);
    const hasConditionalData = Boolean(condtionalData?.length);
    if (!hasConditionalData) {
      return null;
    }
    return {
      label: condtionalData[1],
      value: formatAsMoney(details[condtionalData[0]], 'N/A'),
    };
  },
];

export const isStepCompleted = (value, comment, completed) =>
  value || (value === false && !!comment) || completed;

export const getProductDetailsLabel = (value, comment, completed) => {
  if (value || (completed && !comment)) return 'Confirmed';
  if ((value === false && comment) || (completed && comment))
    return 'Not accurate';

  return null;
};

const PRODUCT_CONFIRMATION_SORT_ORDER = [
  PRODUCT_DETAILS_TASK_TYPE,
  AGREEMENT_TASK_TYPE,
  ADVISOR_CALL_TASK_TYPE,
];

export const sortTasks = (tasks) => {
  const sorted = [...tasks].sort(
    (a, b) =>
      PRODUCT_CONFIRMATION_SORT_ORDER.indexOf(a?.taskType) -
      PRODUCT_CONFIRMATION_SORT_ORDER.indexOf(b?.taskType),
  );

  return sorted;
};

export const getProductDetails = (tasks) => {
  return tasks?.find((task) => task?.taskType === PRODUCT_DETAILS_TASK_TYPE);
};

export const getProductCertificationDetails = (tasks) => {
  return tasks?.find((task) => task?.taskType === AGREEMENT_TASK_TYPE);
};

export const isValidSsn = (ssn) => {
  const ssnRegex = /^\d{4}$/;
  return ssn && ssnRegex.test(ssn);
};
