import {gql} from '@apollo/client';

export default gql`
  query renovationUnderwritingRequests(
    $status: RenovationUnderwritingRequestStatus
  ) {
    renovationUnderwritingRequests(status: $status) {
      id
      assets
      cancelable
      canceledAt
      city
      createdAt
      creditScore
      currentHomeValue
      currentMortgageBalance
      dtiRatio
      firstName
      middleName
      nameSuffix
      intendedStartDate
      lastName
      email
      lenderAccountExecutiveId
      lenderAccountExecutive {
        email
        firstName
        lastName
        phoneNumber
      }
      lenderLoanNumber
      lenderProcessorId
      loanValue
      mloFirstName
      mloLastName
      mloEmail
      mloPhone
      phoneNumber
      postRenovationHomeValue
      project {
        borrower {
          id
          email
          firstName
          lastName
        }
        primaryApplicant {
          id
          email
          fullName
          firstName
          lastName
        }
        id
        invitationSentAt
        tasksOverview {
          documentsUploaded
          lastDocumentUploadedAt
          outstandingNoDocuments
          withDocumentsInReview
          withRejectedDocuments
        }
        timeline {
          advancedToRuAt
          renovationUnderwritingRequestCanceledAt
          renovationUnderwritingRequestCompletedAt
          contractorDueDiligenceRequestedAt
          contractorDueDiligenceReturnedAt
          contractorDueDiligenceCompletedAt
          contractorDueDiligenceCancelledAt
          feasibilityRequestedAt
          feasibilityCompletedAt
          appraisalRequestedAt
          appraisalCompletedAt
        }
        documents {
          fileSizeBytes
          createdAt
          documentType
          fileName
          contentType
          source
          id
        }
      }
      renovationCost
      reserves
      lenderAccountExecutive {
        email
        firstName
        lastName
        phoneNumber
      }
      returnDeadline
      ruStartedAt
      secondMortgageBalance
      state
      status
      streetAddressOne
      streetAddressTwo
      updatedAt
      zipCode
    }
  }
`;
