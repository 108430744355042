import styled from '@emotion/styled';

import {Box, Button as CommonButton, Flex} from '@renofi/components-internal';
import {basic10, maxSmall} from '@renofi/theme';

export const AutocompleteWrapper = styled(Box)({
  borderBottom: `1px solid ${basic10}`,
  marginBottom: 24,
});

export const FieldsWrapper = styled(Flex)({
  gap: 32,
  [maxSmall]: {
    flexDirection: 'column',
    gap: 0,
  },
});

export const FieldBox = styled(Box)({
  width: '50%',
  [maxSmall]: {
    width: '100%',
  },
});

export const Button = styled(CommonButton)({
  [maxSmall]: {
    width: '100%',
    ':last-of-type': {
      marginTop: 24,
    },
  },
});
