import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../useMutation';
import {PRODUCT_CONFIRMATION_FINISHED_BY_APPLICANT} from '../../mutations';

export default function useProductConfirmationFinishedByApplicant({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [productConfirmationFinishedByApplicant, {data, loading, error}] =
    useMutation(PRODUCT_CONFIRMATION_FINISHED_BY_APPLICANT, {
      onCompleted,
      refetchQueries,
    });
  const response = pathOr(
    null,
    ['productConfirmationFinishedByApplicant'],
    data,
  );
  return {productConfirmationFinishedByApplicant, response, loading, error};
}
