import {useState} from 'react';

import {Image} from 'rebass';

import {isMobile as isMobileScreen} from '@renofi/theme';
import {getCookie, setCookie} from '@renofi/utilities/src/cookies';

import RenoFiDesignBody from './RenoFiDesignBody';
import closeIcon from './img/close.svg';
import {CardWrapperMobile} from './styled';

const RENOFI_DESIGN_SHOWN = 'renofi_design_shown';

const RenoFiDesignCardMobile = () => {
  const [hidden, setHidden] = useState(false);
  const isMobile = isMobileScreen();

  if (!isMobile || hidden || getCookie(RENOFI_DESIGN_SHOWN)) return null;

  return (
    <CardWrapperMobile>
      <Image
        data-testid="renofi-design-close-btn"
        onClick={() => {
          setHidden(true);
          setCookie(RENOFI_DESIGN_SHOWN, true);
        }}
        alignSelf="end"
        marginRight={2}
        height={20}
        src={closeIcon}
      />
      <RenoFiDesignBody />
    </CardWrapperMobile>
  );
};

export default RenoFiDesignCardMobile;
