import styled from '@emotion/styled';

import Box from '@renofi/components-internal/src/Box';
import Flex from '@renofi/components-internal/src/Flex';
import Heading from '@renofi/components-internal/src/Heading';
import Text from '@renofi/components-internal/src/Text';
import {
  basic80,
  basic55,
  border,
  successExtraLight,
  successDark,
  basic03,
} from '@renofi/theme/src/colors';

export const Wrapper = styled(Flex)({
  flexDirection: 'column',
});

export const TaskDetailsCard = styled(Flex)(
  {
    borderRadius: 8,
    flexDirection: 'column',
    padding: 16,
    border: '1px solid #E9E9E9',
    marginBottom: 8,
    marginTop: 8,
    fontSize: 14,
  },
  ({success}) => ({
    backgroundColor: success ? successExtraLight : '#FAFAFA',
    borderColor: success ? successExtraLight : '#E9E9E9',
  }),
);

export const Container = styled(Box)(
  ({defaultBackgroundColor, defaultBorderColor, radius, success, hasTabs}) => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: radius ? radius : 4,
    backgroundColor: success ? successExtraLight : defaultBackgroundColor,
    borderColor: success ? successDark : defaultBorderColor,
    ...(hasTabs ? {borderTopLeftRadius: 0} : {}),
  }),
);

export const Header = styled.div({});

export const Content = styled(Text)(
  {
    fontSize: 16,
    lineHeight: '20px',
    color: basic55,
  },
  ({isFullHeight}) => {
    return isFullHeight ? {height: '100%'} : null;
  },
);

export const Title = styled(Heading)(
  {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    minHeight: 60,
    color: basic80,
    fontSize: 18,
    fontWeight: 300,
    lineHeight: 1,
    padding: '0 16px',
    borderBottom: `1px solid ${border}`,
  },
  ({titleBackgroundColor}) => ({
    backgroundColor: titleBackgroundColor || 'transparent',
  }),
);
