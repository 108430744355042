import React, {memo} from 'react';

import PropTypes from 'prop-types';

import {useTheme} from '@renofi/theme/src';

import {Help, Content, Wrapper} from './styled';

const Label = ({
  children,
  labelFor,
  help,
  disabled,
  error,
  small,
  ...props
}) => {
  const {styles} = useTheme('label', {small: true, disabled});
  return (
    <Wrapper small={small}>
      <Content error={error} htmlFor={labelFor} theme={styles} {...props}>
        {children}
      </Content>
      <Help>{help}</Help>
    </Wrapper>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  labelFor: PropTypes.string,
  small: PropTypes.bool,
  help: PropTypes.node,
  theme: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default memo(Label);
