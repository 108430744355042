import {gql} from '@apollo/client';

export default gql`
  mutation submitProjectLoanForm(
    $projectLoanForm: ProjectLoanFormInputObject!
  ) {
    submitProjectLoanForm(projectLoanForm: $projectLoanForm) {
      project {
        id
      }
    }
  }
`;
