import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';

export const Container = styled(Flex)(
  {
    flexDirection: 'column',
    flex: 'auto',
    height: '100%',
  },
  ({css}) => css,
);
