import {isEmpty, isNil} from 'ramda';

import {taskTypes} from '@renofi/utilities/src/enums';

import {SMS_CONSENT_RENOVATION_PROGRESS} from '../components/Renovation/constants';

import {
  ADD_EDIT_BUTTON_DEFAULT,
  ADD_EDIT_BUTTON_SUFFIX_TYPES,
} from './constants';
import useCurrentTask from './useCurrentTask';

const ADD_PREFIX = 'Add';
const CONTINUE_PREFIX = 'Continue';
const EDIT_PREFIX = 'Edit';

const createLabel = (prefix, suffix) => {
  if (prefix === CONTINUE_PREFIX) {
    return prefix;
  }

  if (!suffix) {
    return ADD_EDIT_BUTTON_DEFAULT;
  }
  return [prefix, suffix].join(' ');
};

/**
 * @description Will need updating per non-document Task
 */
const getIsTaskDirty = ({isBorrowerFinished, isOutstanding, task = {}}) => {
  switch (true) {
    case task.taskType === 'appraisal_recent_transactions':
      return (
        !isBorrowerFinished &&
        !isNil(task?.appraisalRecentTransactions?.occurredRecently)
      );
    case task.taskType === taskTypes.government_issued_id:
      return isOutstanding && !isEmpty(task?.governmentIssuedIds);
    case task.canHaveDocument:
      return isOutstanding && Boolean(task?.documents?.length);
    case task.taskType === SMS_CONSENT_RENOVATION_PROGRESS:
      return task.smsConsentRenovationProgress?.accepted;
    case task.taskType === 'income':
      return isOutstanding && !!task.incomeSources?.length;
    case task.taskType === 'employment_history':
      return isOutstanding && !!task.employmentHistories?.length;
    default:
      return false;
  }
};

const getPrefix = ({isBorrowerFinished, isTaskDirty}) => {
  let prefix = isBorrowerFinished ? EDIT_PREFIX : ADD_PREFIX;
  if (isTaskDirty) {
    prefix = CONTINUE_PREFIX;
  }
  return prefix;
};

const useAddEditButtonVariant = ({taskId, facet}) => {
  const data = useCurrentTask({taskId, facet});
  const {isBorrowerFinished, isOutstanding, isProcessing, task = {}} = data;

  const taskType = task?.taskType;

  const isTaskDirty = getIsTaskDirty(data);
  const suffix = ADD_EDIT_BUTTON_SUFFIX_TYPES[taskType];
  let prefix = getPrefix({...data, isTaskDirty});

  if (isBorrowerFinished) {
    return [createLabel(prefix, suffix), 'secondary'];
  }

  return [
    isOutstanding || isProcessing ? createLabel(prefix, suffix) : null,
    'primary',
  ];
};

export default useAddEditButtonVariant;
