import React, {useState} from 'react';

import PropTypes from 'prop-types';

import {Box, Loader} from '@renofi/components-internal';
import {Trash} from '@renofi/icons';
import {basic55} from '@renofi/theme';
import {
  GET_PROJECT_DOCUMENTS,
  GET_PROJECT_TASKS,
  useRemoveDocuments,
} from '@renofi/graphql';
import {useNotifications} from '@renofi/utilities';

import {HoverLink} from '../styled';

const RemoveDocument = ({documentId, onClick, onMouseOver, onMouseOut}) => {
  const [loading, setLoading] = useState(false);
  const {addNotification} = useNotifications();

  const {removeDocuments} = useRemoveDocuments({
    refetchQueries: [GET_PROJECT_TASKS, GET_PROJECT_DOCUMENTS],
  });

  const onDeleteDocuments = async () => {
    const rsp = await removeDocuments({variables: {ids: [documentId]}});
    if (rsp?.data?.removeDocuments?.errors?.length > 0) {
      addNotification({
        delay: 3500,
        type: 'snackbar',
        variant: 'danger',
        content: `Document can not be removed.`,
      });
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Box ml="12px">
          <Loader label="" width={20} innerColor={basic55} />
        </Box>
      ) : (
        <HoverLink
          css={{pointerEvents: 'auto'}}
          onClick={(e) => {
            e.stopPropagation();
            setLoading(true);
            onClick ? onClick(documentId) : onDeleteDocuments();
          }}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}>
          <Trash />
        </HoverLink>
      )}
    </>
  );
};

RemoveDocument.propTypes = {
  documentId: PropTypes.string,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
};

export default RemoveDocument;
