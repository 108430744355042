import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {last, length, pipe, propOr} from 'ramda';

import {Text, TimeAgo} from '@renofi/components-internal';
import {basic55, successRegular} from '@renofi/theme';
import {validateUuidProp} from '@renofi/utilities/src/react';

const getHasDocuments = pipe(propOr([], 'documents'), length, Boolean);

const getSortedRequests = pipe(propOr([], 'contractRequests'));

const ContractorStatus = ({contractor}) => {
  const contractRequests = getSortedRequests(contractor);

  const hasDocuments = getHasDocuments(contractor);

  if (hasDocuments) {
    return <Text color={successRegular}>Documents received</Text>;
  } else if (!contractRequests?.length) {
    return null;
  }

  const lastRequest = last(contractRequests);
  const hasCreatedAt = Boolean(lastRequest?.createdAt);

  return (
    <Fragment>
      <Text>Documents requested from contractor</Text>
      {hasCreatedAt ? (
        <Text color={basic55}>
          <TimeAgo date={lastRequest?.createdAt} time />
        </Text>
      ) : null}
    </Fragment>
  );
};

ContractorStatus.propTypes = {
  contractor: PropTypes.shape({
    contractRequests: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string,
        id: validateUuidProp,
        updatedAt: PropTypes.string,
      }),
    ),
    documents: PropTypes.array,
  }),
};

export default ContractorStatus;
