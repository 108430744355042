import React from 'react';

const SvgComponent = (props) => (
  <svg
    width={20}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 6.3V1h-3v2.6L10 0 0 9h3v8h5v-6h4v6h5V9h3l-3-2.7Z"
      fill="currentcolor"
    />
  </svg>
);

export default SvgComponent;
