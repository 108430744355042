import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {LegalMessage} from './styled';

const getTimeZone = () => Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

const NEW_YORK_TIMEZONE = 'America/New_York';

const NotAuthorizedMessage = ({app, ...rest}) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (app === 'login' || app === 'secure') {
      setShowMessage(true);
      return;
    }

    const timeZone = getTimeZone();

    if (timeZone === NEW_YORK_TIMEZONE) {
      setShowMessage(true);
    }
  }, [app]);

  if (!showMessage) {
    return null;
  }

  return (
    <LegalMessage {...rest}>
      This site is not authorized by the New York State Department of Financial
      Services. No mortgage solicitation activity or loan applications for
      properties located in the State of New York can be facilitated through
      this site.
    </LegalMessage>
  );
};

NotAuthorizedMessage.propTypes = {
  app: PropTypes.string,
};

export default NotAuthorizedMessage;
