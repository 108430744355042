import styled from '@emotion/styled';
import {Content as Container} from 'modules/layout';

import {Flex, Link, Text} from '@renofi/components-internal';
import {
  actionRegular,
  basic15,
  basic55,
  basic80,
  maxSmall,
} from '@renofi/theme';

import picture from './picture.svg';

const BORDER_WIDTH = 4;

export const Wrapper = styled(Flex)({
  flexDirection: 'column',
  position: 'relative',
});

export const Record = styled.div(({final}) => ({
  position: 'relative',
  marginLeft: 16 + (final ? BORDER_WIDTH : 0),
  paddingLeft: 32,
  paddingBottom: final ? 12 : 32,
  borderLeft: final ? 'none' : `solid ${BORDER_WIDTH}px ${basic15}`,

  [maxSmall]: {
    width: '100%',
  },
}));

export const Title = styled(Flex)({
  alignItems: 'center',
  color: basic55,
  fontSize: 14,
  lineHeight: '22px',
  gap: '4px',
});

export const Content = styled(Text)({
  color: basic80,
  fontSize: 16,
  lineHeight: '24px',
});

export const Marker = styled.div({
  position: 'absolute',
  height: 20,
  width: 20,
  left: -12,
  top: 0,
  backgroundColor: '#D8D8D8',
  borderRadius: '50%',
  ':after': {
    content: '""',
    position: 'absolute',
    height: 8,
    width: 8,
    left: 6,
    top: 6,
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
  },
});

export const Picture = styled.div({
  width: 84,
  height: 56,
  backgroundImage: `url('${picture}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  backgroundPosition: 'center center',
  marginRight: 8,
});

export const Photo = styled.img({
  height: 56,
  borderRadius: 6,
  margin: 0,
  padding: 0,
});

export const PhotoLink = styled(Link)({
  height: 60,
  display: 'flex',
  alignItems: 'center',
  marginRight: 8,
  padding: 0,
});

export const Consent = styled(Link)({
  position: 'absolute',
  top: 24,
  right: 24,
  fontSize: 14,
  lineHeight: '24px',
  color: basic55,
  ':hover': {
    color: actionRegular,
  },
});

export const StyledContainer = styled(Container)({
  position: 'relative',
  '@media (max-width: 600px)': {
    '& > h2': {
      marginTop: '30px',
    },
  },
});
