import PropTypes from 'prop-types';

import salesforceUrl from '@renofi/utilities/src/salesforceUrl';

import Link from './Link';

const SalesforceLink = ({
  children,
  salesforceBaseUrl,
  objectType,
  objectId,
  ...props
}) => {
  return (
    <Link
      href={salesforceUrl(salesforceBaseUrl, objectType, objectId)}
      blank
      {...props}>
      {children}
    </Link>
  );
};

SalesforceLink.propTypes = {
  children: PropTypes.any,
  salesforceBaseUrl: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  objectId: PropTypes.string.isRequired,
};

export default SalesforceLink;
