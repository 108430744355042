import {gql} from '@apollo/client';

export default gql`
  query feasibilityReviewRequested(
    $feasibilityReview: EmailPreviewFeasibilityReviewInputObject!
    $project: ProjectInputObject!
    $documents: [EmailPreviewDocumentInputObject!]!
    $propertyPhotos: [PropertyPhotoInputObject!]!
    $primaryApplicant: ProjectApplicantInputObject
  ) {
    feasibilityReviewRequested(
      feasibilityReview: $feasibilityReview
      project: $project
      documents: $documents
      propertyPhotos: $propertyPhotos
      primaryApplicant: $primaryApplicant
    ) {
      content
      subject
    }
  }
`;
