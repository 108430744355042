import React from 'react';

import PropTypes from 'prop-types';

import {ModalWithButtons, Text} from '@renofi/components-internal';

const ConfirmRemoveFileModal = ({
  loading,
  show,
  onAccept,
  onClose,
  onReject,
}) => {
  return (
    <ModalWithButtons
      acceptLabel="Yes, delete"
      acceptVariant="danger"
      pb={64}
      loading={loading}
      show={show}
      header="Delete file"
      rejectLabel="No"
      onClose={onClose}
      onAccept={onAccept}
      onReject={onReject}>
      <Text>Are you sure you want to delete this file?</Text>
    </ModalWithButtons>
  );
};

ConfirmRemoveFileModal.propTypes = {
  loading: PropTypes.bool,
  show: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmRemoveFileModal;
