import {filter, isNil, length, pick, pipe, propOr} from 'ramda';
import {
  RECENT_TRANSACTIONS_PROPS,
  SMS_CONSENT_RENOVATION_PROGRESS,
} from 'modules/project/components/Renovation/constants';

import {isPasswordProtected} from '@renofi/utilities';

const getIsRecentTransactionsDisabled = (task = {}) => {
  const transaction = propOr(null, 'appraisalRecentTransactions', task);
  const occurredRecently = propOr(null, 'occurredRecently', transaction);
  const haveAppraisalFile = propOr(null, 'haveAppraisalFile', transaction);

  if (isNil(occurredRecently)) {
    return true; // If nothing selected yet...
  } else if (occurredRecently === false) {
    return false; // If No recent-transactions anyway, just enable...
  }

  const documents = propOr([], 'documents', task);
  const {date, value} = pick(RECENT_TRANSACTIONS_PROPS, transaction);
  const dataNotFinished = !date || !value || isNil(haveAppraisalFile);

  // If yes to appraisal-file, ensure one given
  // Otherwise validate for non-null of the other values.
  return haveAppraisalFile === true
    ? dataNotFinished || !documents?.length
    : dataNotFinished;
};

export const hasPasswordProtectedDocs = pipe(
  filter(isPasswordProtected),
  length,
  Boolean,
);

export const getIsSetAsFinishedDisabled = (task, otherTruthies = []) => {
  const hasOtherTruthies = Boolean(otherTruthies.length);
  const isAnyOtherTruthyTrue = hasOtherTruthies && otherTruthies.some(Boolean);

  const canHaveDocument = propOr(false, 'canHaveDocument', task);
  const documents = propOr([], 'documents', task);
  const taskType = propOr(null, 'taskType', task);

  if (isAnyOtherTruthyTrue) {
    return true;
  }

  switch (true) {
    case taskType === 'appraisal_recent_transactions':
      return getIsRecentTransactionsDisabled(task);
    case canHaveDocument:
      return canHaveDocument
        ? !documents.length || hasPasswordProtectedDocs(documents)
        : false;
    case taskType === SMS_CONSENT_RENOVATION_PROGRESS:
      return !task?.smsConsentRenovationProgress;
    // ^^^ This is going to need padding out for the other Task types.
    default:
      return false;
  }
};
