import {useContext, useState} from 'react';

import {find, isEmpty, map, pipe, prop, propEq, propOr, reject} from 'ramda';

import {Context as StorageContext} from '@renofi/utilities/src/storage';
import {
  useUploadDocuments,
  useNotifications,
  useUploadFiles,
} from '@renofi/utilities';
import {sendEvent} from '@renofi/utilities/src/analytics/utils';
import {Box, Flex, Text} from '@renofi/components-internal';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

const TASK_ID_KEY = 'engagement:firstUpload:taskId';

const useDocumentHandlers = ({basePath, tasks = [], projectId, facet} = {}) => {
  const storage = useContext(StorageContext);
  const {addNotification} = useNotifications();
  const [docsUploading, setDocsUploading] = useState([]);
  const {isUploading, uploadFiles} = useUploadFiles({validateEncryption: true});
  const {submitUploadedDocuments} = useUploadDocuments({
    basePath,
    projectId,
    facet,
  });

  const firstUploadTaskId = storage?.getItem(TASK_ID_KEY);

  const onAcceptFiles = async ({
    files,
    taskId,
    shouldReturnLatestFiles,
    customMutation,
    customMutationParams,
    customResponsePath,
  }) => {
    const uploadedFiles = await uploadFiles(files);
    setDocsUploading(uploadedFiles);

    return submitDocuments({
      customMutation,
      customMutationParams,
      customResponsePath,
      shouldReturnLatestFiles,
      taskId,
      uploadedFiles,
    });
  };

  const onRejectFiles = (rejectedFiles = []) => {
    const title = rejectedFiles.length > 1 ? 'these files' : 'this file';

    addNotification({
      variant: 'danger',
      content: (
        <Box>
          <Text>There were problems uploading {title}:</Text>
          {rejectedFiles.map(({errors = [], file}) => {
            const error = pipe(
              map(prop('code')),
              map(humanizeSnakeCase),
            )(errors);
            return (
              <Flex mt={2} key={file.name}>
                <Box as="em">{file.name}</Box>
                <Box ml={3}>({error || 'Unknown error'})</Box>
              </Flex>
            );
          })}
        </Box>
      ),
    });
  };

  const removeUpload = (documentId) => {
    setDocsUploading((state) => reject(propEq('id', documentId), state));
  };

  const submitDocuments = async ({
    customMutation,
    customMutationParams,
    customResponsePath,
    shouldReturnLatestFiles,
    taskId,
    uploadedFiles = [],
  }) => {
    const submitted = await submitUploadedDocuments({
      customMutation,
      customMutationParams,
      customResponsePath,
      shouldReturnLatestFiles,
      taskId,
      uploadedFiles,
    });
    const submittedIds = map(prop('id'), submitted);
    setDocsUploading((state) =>
      state.filter(({id}) => !submittedIds.includes(id)),
    );

    if (!isEmpty(submitted)) {
      const taskType = pipe(
        find(propEq('id', taskId)),
        propOr(null, 'taskType'),
      )(tasks);

      submitted.forEach(({fileName, id}) => {
        sendEvent('Secure/Document-Uploaded', {
          documentId: id,
          fileName,
          taskType,
          taskId,
        });
      });
    }
    return submitted;
  };

  return {
    onAcceptFiles,
    onRejectFiles,
    firstUploadTaskId,
    docsUploading,
    loading: isUploading,
    removeUpload,
    submitDocuments,
  };
};

export default useDocumentHandlers;
