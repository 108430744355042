import {pathOr, pipe} from 'ramda';

import {
  JWT_KEY,
  REFRESH_TOKEN_KEY,
  setCookie,
} from '@renofi/utilities/src/cookies';
import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  STORAGE_PREFIX,
  Storage,
  getEngine,
  getImpersonationStorage,
} from '@renofi/utilities/src/storage';
import {getLocation} from '@renofi/utilities/src/window';

import getAuthByQueryString from './getAuthByQueryString';
import getCurrentAuthTokens from './getCurrentAuthTokens';

const getOriginalUserId = pathOr(null, ['decodedJwt', 'originalUserId']);

const refreshPageWithPath = () => {
  const {origin, pathname} = getLocation();
  getLocation().replace([origin, pathname].join(''));
};

export default () => {
  const qStringAuth = getAuthByQueryString();
  const isAuthInQueryString = Boolean(
    qStringAuth?.jwt && qStringAuth?.refreshToken,
  );
  const ifInitImpersonation = pipe(getOriginalUserId, Boolean)(qStringAuth);
  let storage;

  if (ifInitImpersonation) {
    storage = getImpersonationStorage();
    storage.setItem(AUTH_TOKEN, qStringAuth.jwt);
    storage.setItem(REFRESH_TOKEN, qStringAuth.refreshToken);

    refreshPageWithPath();
    return {storage};
  } else if (isAuthInQueryString) {
    setCookie(JWT_KEY, qStringAuth?.jwt);
    setCookie(REFRESH_TOKEN_KEY, qStringAuth?.refreshToken);
    refreshPageWithPath();
    return {storage: new Storage({prefix: STORAGE_PREFIX})};
  }
  const {isImpersonating, jwt, refreshToken} = getCurrentAuthTokens();
  const engine = getEngine(isImpersonating ? 'session' : 'local');

  return {
    storage: new Storage({engine, prefix: STORAGE_PREFIX}),
    jwt,
    refreshToken,
  };
};
