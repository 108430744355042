import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {UPDATE_SOFT_CREDIT_CHECK} from '../../../mutations/renovation';

export default function useUpdateSoftCreditCheck({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [updateSoftCreditCheck, {data, loading, error}] = useMutation(
    UPDATE_SOFT_CREDIT_CHECK,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = propOr(null, ['updateSoftCreditCheck'], data);
  return {updateSoftCreditCheck, response, loading, error};
}
