import styled from '@emotion/styled';

import {Flex, Link, Text} from '@renofi/components-internal';
import {basic55, dangerRegular, successDark} from '@renofi/theme/src/colors';

export const Wrapper = styled(Flex)({
  flexDirection: 'column',
  alignItems: 'center',
});

const getTextColor = ({failure, success}) => {
  if (failure) {
    return dangerRegular;
  }
  if (success) {
    return successDark;
  }
  return basic55;
};

export const Details = styled(Text)(
  {
    fontSize: 14,
    lineHeight: '18px',
    textAlign: 'center',
    color: dangerRegular,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  (props) => ({
    color: getTextColor(props),
  }),
);

export const Action = styled(Link)({}, ({disabled}) => ({
  opacity: disabled ? 0.2 : 1,
  ...(disabled
    ? {
        color: basic55,
        ':href': {color: basic55},
        ':link': {color: basic55},
        ':visited': {color: basic55},
      }
    : {}),
}));
