import {find, isNil, pipe, propOr} from 'ramda';
import {camelCase} from 'change-case';

const isNotNil = (key) => pipe(propOr(null, key), (v) => !isNil(v));

export const findOwner = find(isNotNil('email'));

export const parseImageName = ({name = ''} = {}) =>
  camelCase(name.toLowerCase().replace(/[^a-z0-9\s]/gi, ''));

export const getOwnerPriorities = ({project, isRaas, defaultEmail}) => {
  const projectAssociate = propOr(null, 'projectAssociate', project);
  const renofiAdvisor = propOr(null, 'renofiAdvisor', project);
  const fallback = {
    name: null,
    emailToCase: null,
    email: defaultEmail,
  };

  if (isRaas) {
    return [projectAssociate, renofiAdvisor, fallback];
  }

  return [renofiAdvisor, projectAssociate, fallback];
};
