import React from 'react';

import PropTypes from 'prop-types';

import Assets from '../BorrowerSteps/Assets';

import CoBorrowerStatements from './CoBorrowerStatements';
import TransitionStep from './TransitionStep';
import CoBorrowerAccounts from './CoBorrowerAccounts';

const CoBorrowerSteps = ({
  step,
  borrower,
  assets,
  formData,
  task,
  onAccountChange,
  borrowerRole,
  setFormData,
  hasCoBorrower,
  goToCoBorrowerAssets,
  setLoading,
  setAssets,
  projectId,
  onChange,
  coBorrowerName = '',
}) => {
  const {id: stepId, assetType} = step;
  const {documents: taskDocuments = []} = task;

  if (!stepId.includes('co-borrower')) {
    return null;
  }

  if (stepId === 'co-borrower') {
    return (
      <TransitionStep
        borrowerName={borrower.firstName}
        coBorrowerName={coBorrowerName}
        goToCoBorrowerAssets={goToCoBorrowerAssets}
      />
    );
  }

  if (stepId.includes('assets')) {
    return (
      <Assets
        assets={assets}
        setAssets={setAssets}
        formData={formData}
        taskDocuments={taskDocuments}
        setFormData={setFormData}
        setLoading={setLoading}
        borrowerRole={borrowerRole}
        borrowerName={borrower.firstName}
        coborrowerName={coBorrowerName}
        projectId={projectId}
        onChange={onChange}
      />
    );
  }

  if (stepId.includes('statements')) {
    return (
      <CoBorrowerStatements
        task={task}
        borrowerRole={borrowerRole}
        assetType={assetType}
        borrowerName={coBorrowerName}
        formData={formData}
        onChange={onChange}
      />
    );
  }

  return (
    <CoBorrowerAccounts
      assetType={assetType}
      coBorrowerName={coBorrowerName}
      borrowerRole={borrowerRole}
      formData={formData}
      onChange={onAccountChange}
      setFormData={setFormData}
      hasCoBorrower={hasCoBorrower}
      projectId={projectId}
    />
  );
};

CoBorrowerSteps.propTypes = {
  step: PropTypes.object,
  borrower: PropTypes.object,
  coBorrowerName: PropTypes.string,
  assets: PropTypes.object,
  formData: PropTypes.array,
  onAccountChange: PropTypes.func,
  project: PropTypes.object,
  task: PropTypes.object,
  borrowerRole: PropTypes.string,
  setFormData: PropTypes.func,
  hasCoBorrower: PropTypes.bool,

  goToCoBorrowerAssets: PropTypes.func,
  setLoading: PropTypes.func,
  setAssets: PropTypes.func,
  projectId: PropTypes.string,
  onChange: PropTypes.func,
};

export default CoBorrowerSteps;
