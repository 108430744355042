import {pathOr} from 'ramda';

export const getIntroTextByOrder = (
  {submittedAt, ...appraisalOrder} = {},
  isCompleted,
) => {
  const amcName = pathOr('the appraiser', ['amc', 'name'], appraisalOrder);

  switch (true) {
    case isCompleted:
      return 'Your appraisal report is ready! Please note, any changes to the scope of work, cost estimate, or contractor going forward may impact your RenoFi timeline and incur additional charges.';
    case Boolean(submittedAt):
      return `RenoFi has ordered an appraisal of your property from ${amcName}. Please note, any changes to the scope of work, cost estimate, or contractor going forward may impact your RenoFi timeline and incur additional charges.`;
    default:
      return "As part of the RenoFi process, we'll schedule a thorough appraisal of your property, examining both its interior and exterior. This appraisal will help us determine both the current and future value of       your home.";
  }
};
