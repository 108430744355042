import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const PlusCircle = ({width = 44, color = basic20, ...props}) => {
  return (
    <svg
      fill="none"
      width={width}
      height={width}
      viewBox="0 0 44 44"
      {...props}>
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 22c0 12.15-9.85 22-22 22S0 34.15 0 22 9.85 0 22 0s22 9.85 22 22zm-20-2h8v4h-8v8h-4v-8h-8v-4h8v-8h4v8z"
      />
    </svg>
  );
};

PlusCircle.propTypes = {
  size: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
};

export default PlusCircle;
