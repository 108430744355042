export default `
id
assets
cancelable
city
createdAt
createdById
creditScore
currentHomeValue
currentMortgageBalance
dtiRatio
email
firstName
intendedStartDate
lastName
lenderAccountExecutive {
  email
  firstName
  lastName
  phoneNumber
}
lenderAccountExecutiveId
lenderProcessorId
lenderLoanNumber
lenderId
loanValue
mloEmail
mloFirstName
mloLastName
mloPhone
middleName
nameSuffix
phoneNumber
contractors {
  id
  businessName
  contactName
  phoneNumber
  email
}
project {
  id
  documents {
    fileSizeBytes
    createdAt
    documentType
    fileName
    contentType
    source
    id
  }
}
mloLoanProcessor {
  email
  firstName
  lastName
  phone
}
postRenovationHomeValue
renovationCost
reserves
returnDeadline
ruStartedAt
secondMortgageBalance
state
status
streetAddressOne
streetAddressTwo
updatedAt
zipCode
`;
