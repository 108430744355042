import {documentWithAllProps} from '../document';

export default `
accountNumber
accountHolderName
adjustedCashOrMarketValue
assetType
borrowerRole
cashOrMarketValue
description
documents {
  ${documentWithAllProps}
}
financialInstitution
id
jointAccount
shareWithLender
taskId
`;
