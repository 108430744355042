import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const SvgMinusCircle = ({
  color = basic20,
  height = 24,
  width = 24,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 5.928 5.928 1 12 1s11 4.928 11 11-4.928 11-11 11S1 18.072 1 12zm16.5 1.1h-11v-2.2h11v2.2z"
        fill={color}
      />
    </svg>
  );
};

SvgMinusCircle.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default SvgMinusCircle;
