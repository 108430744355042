import styled from '@emotion/styled';

import {Box, Flex, Text} from '@renofi/components-internal';
import {basic80} from '@renofi/theme/src/colors';

export const PageWrapper = styled(Box)(
  {
    padding: '24px',
  },
  ({mb, relative}) => ({
    marginBottom: mb ? `${mb}px` : '250px',
    position: relative ? 'relative' : 'initial',
  }),
);

export const PageTitle = styled.div({
  fontSize: '24px',
  lineHeight: '29px',
  marginBottom: '21px',
});

export const Heading = styled(Text)({
  width: '100%',
  color: basic80,
  fontSize: 16,
  lineHeight: '20px',
  marginBottom: '8px',
});

export const StyledPlaceholder = styled(Flex)({
  alignItems: 'center',
  lineHeight: '19px',
  columnGap: '12px',
});

export const FormContainer = styled.div({
  '.renofiTextFieldError': {color: '#FF5253'},
});

export const DescriptionContainer = styled(Flex)({
  backgroundColor: '#FFF7E9',
  padding: '18px 20px',
  borderRadius: '8px',
  '.image-container': {
    paddingTop: '5px',
  },
});

export const DescriptionText = styled(Flex)({
  flexDirection: 'column',
  justifyContent: 'center',
  '.title': {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '700',
    color: '#212F49',
    marginBottom: '17px',
  },
});

export const ListContainer = styled(Flex)({
  flexDirection: 'column',
  fontSize: '16px',
  lineHeight: '20px',
  color: '#212F49',
});

export const ListElement = styled(Flex)({
  alignItems: 'flex-start',
  marginBottom: '12px',
  '.item-text': {
    marginLeft: '11px',
  },
});

export const IconContainer = styled(Flex)({
  paddingTop: '2px',
  svg: {
    width: '16px',
    height: '16px',
  },
});

export const SelectFieldWrapper = styled.div({
  '.mainContainer': {
    paddingTop: 'unset',
  },
  '.renofiTextField': {
    marginBottom: 'unset',
    borderBottom: '1px solid #ebebeb',
    color: '#cacaca',
    'input::placeholder': {
      marginBottom: 'unset',
      borderBottom: '1px solid #ebebeb',
      color: '#cacaca',
    },
  },
});

export const IconBox = styled.div({
  position: 'absolute',
  right: 0,
  top: 57,
});

export const IntroBox = styled.div({
  textAlign: 'center',
  '.hide-intro-btn': {
    marginTop: '16px',
  },
  '.intro-sub-item': {
    display: 'block',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#737373',
    marginTop: '16px',
  },
});

export const IntroText = styled.div({
  fontSize: '24px',
  lineHeight: '36px',
  textAlign: 'center',
  marginTop: '130px',
});
