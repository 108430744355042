import React, {useEffect} from 'react';

import {Flex, Text} from '@renofi/components-internal';
import {sendEvent} from '@renofi/utilities/src/analytics/utils';

import BaseContent from './BaseContent';

const PropertyTrust = () => {
  useEffect(() => {
    sendEvent('Secure/Onboarding-User-Trust-Warning-Presented');
  }, []);

  return (
    <BaseContent
      mainContent={
        <Flex flexDirection="column" alignItems="center" px={48}>
          <Text fontSize={24} textAlign="center">
            Properties owned by a trust are not permitted by our lending
            partners. The property must be owned by the borrowers on the loan
            application.
          </Text>
          <Text mt={4} fontSize={24}>
            Your RenoFi Advisor will reach out to discuss your next steps.
          </Text>
        </Flex>
      }
      withButtons={false}
    />
  );
};

export default PropertyTrust;
