import React, {memo, useContext} from 'react';

import PropTypes from 'prop-types';

import alert from '@renofi/icons/src/Alert';
import arrow from '@renofi/icons/src/Arrow';
import bathroom from '@renofi/icons/src/Bathroom';
import basement from '@renofi/icons/src/Basement';
import bedroom from '@renofi/icons/src/Bedroom';
import boot from '@renofi/icons/src/Boot';
import building from '@renofi/icons/src/Building';
import bullet from '@renofi/icons/src/Bullet';
import bulletList from '@renofi/icons/src/BulletList';
import bullhorn from '@renofi/icons/src/Bullhorn';
import car from '@renofi/icons/src/CarIcon';
import camera from '@renofi/icons/src/Camera';
import calendar from '@renofi/icons/src/Calendar';
import calendarMonth from '@renofi/icons/src/CalendarMonth';
import check from '@renofi/icons/src/Check';
import checkCircle from '@renofi/icons/src/CheckCircle';
import comment from '@renofi/icons/src/Comment';
import commentInverse from '@renofi/icons/src/CommentInverse';
import contactOutline from '@renofi/icons/src/ContactOutline';
import contact from '@renofi/icons/src/Contact';
import contract from '@renofi/icons/src/Contract';
import cottage from '@renofi/icons/src/Cottage';
import cross from '@renofi/icons/src/Cross';
import deck from '@renofi/icons/src/Deck';
import description from '@renofi/icons/src/Description';
import desk from '@renofi/icons/src/Desk';
import diningRoom from '@renofi/icons/src/DiningRoom';
import doc from '@renofi/icons/src/Doc';
import document from '@renofi/icons/src/Document';
import dollar from '@renofi/icons/src/Dollar';
import dollarCircle from '@renofi/icons/src/DollarCircle';
import dollarCircleInvert from '@renofi/icons/src/DollarCircleInvert';
import dots from '@renofi/icons/src/Dots';
import download from '@renofi/icons/src/Download';
import driveway from '@renofi/icons/src/Driveway';
import email from '@renofi/icons/src/Email';
import encrypted from '@renofi/icons/src/Encrypted';
import eventAvailable from '@renofi/icons/src/EventAvailable';
import eventDelayed from '@renofi/icons/src/EventDelayed';
import eventUpcoming from '@renofi/icons/src/EventUpcoming';
import expand from '@renofi/icons/src/Expand';
import external from '@renofi/icons/src/External';
import exterior from '@renofi/icons/src/Exterior';
import fire from '@renofi/icons/src/Fire';
import fireplace from '@renofi/icons/src/Fireplace';
import flag from '@renofi/icons/src/Flag';
import flight from '@renofi/icons/src/FlightIcon';
import garage from '@renofi/icons/src/Garage';
import glass from '@renofi/icons/src/Glass';
import helpClinic from '@renofi/icons/src/HelpClinic';
import renovationCompleted from '@renofi/icons/src/RenovationCompleted';
import renovationStartDelayed from '@renofi/icons/src/RenovationStartDelayed';
import hvac from '@renofi/icons/src/Hvac';
import info from '@renofi/icons/src/Info';
import interior from '@renofi/icons/src/Interior';
import kitchen from '@renofi/icons/src/Kitchen';
import landscape from '@renofi/icons/src/Landscape';
import livingRoom from '@renofi/icons/src/LivingRoom';
import locationHome from '@renofi/icons/src/LocationHome';
import lock from '@renofi/icons/src/Lock';
import lender from '@renofi/icons/src/Lender';
import military from '@renofi/icons/src/MilitaryIcon';
import painting from '@renofi/icons/src/Painting';
import patio from '@renofi/icons/src/Patio';
import pencil from '@renofi/icons/src/Pencil';
import pending from '@renofi/icons/src/Pending';
import percent from '@renofi/icons/src/Percent';
import person from '@renofi/icons/src/Person';
import personCircle from '@renofi/icons/src/PersonCircle';
import personClipboard from '@renofi/icons/src/PersonClipboard';
import phone from '@renofi/icons/src/Phone';
import pin from '@renofi/icons/src/PinDropIcon';
import pool from '@renofi/icons/src/Pool';
import porch from '@renofi/icons/src/Porch';
import property from '@renofi/icons/src/Property';
import renofi from '@renofi/icons/src/Renofi';
import restroom from '@renofi/icons/src/Restroom';
import roof from '@renofi/icons/src/Roof';
import siding from '@renofi/icons/src/Siding';
import snow from '@renofi/icons/src/Snow';
import sortArrow from '@renofi/icons/src/SortArrow';
import sortArrowAlt from '@renofi/icons/src/SortArrowAlt';
import speed from '@renofi/icons/src/Speed';
import squareFootage from '@renofi/icons/src/SquareFootage';
import structuralChanges from '@renofi/icons/src/StructuralChanges';
import swipe from '@renofi/icons/src/Swipe';
import team from '@renofi/icons/src/Team';
import trash from '@renofi/icons/src/Trash';
import uploadId from '@renofi/icons/src/UploadId';
import warning from '@renofi/icons/src/Warning';
import washer from '@renofi/icons/src/Washer';
import window from '@renofi/icons/src/Window';
import {Context as ThemeContext, basic55, white} from '@renofi/theme/src';

import Box from './Box';

const icons = {
  alert,
  arrow,
  bathroom,
  basement,
  bedroom,
  boot,
  building,
  bullet,
  bulletList,
  bullhorn,
  car,
  calendar,
  calendarMonth,
  camera,
  check,
  checkCircle,
  comment,
  commentInverse,
  contactOutline,
  contact,
  contract,
  cottage,
  cross,
  deck,
  description,
  desk,
  diningRoom,
  doc,
  document,
  dollar,
  dollarCircle,
  dollarCircleInvert,
  dots,
  download,
  driveway,
  email,
  encrypted,
  eventAvailable,
  eventDelayed,
  eventUpcoming,
  expand,
  external,
  exterior,
  fire,
  fireplace,
  flag,
  flight,
  garage,
  glass,
  helpClinic,
  hvac,
  info,
  interior,
  kitchen,
  landscape,
  lender,
  livingRoom,
  locationHome,
  lock,
  military,
  painting,
  patio,
  pencil,
  pending,
  percent,
  person,
  personCircle,
  personClipboard,
  phone,
  pin,
  pool,
  porch,
  property,
  renofi,
  renovationCompleted,
  renovationStartDelayed,
  restroom,
  roof,
  siding,
  snow,
  sortArrow,
  sortArrowAlt,
  speed,
  squareFootage,
  structuralChanges,
  swipe,
  team,
  trash,
  uploadId,
  warning,
  washer,
  window,
};

const Icon = ({
  color,
  forceEmpty = false,
  name,
  primary,
  size = 20,
  width = size,
  height = size,
  ...restOfProps
}) => {
  const context = useContext(ThemeContext);
  const {theme} = context;

  const Icon = icons[name];
  if (Icon) {
    const props = primary
      ? {color: white}
      : theme === 'dark'
        ? {color: basic55}
        : {color};

    return <Icon height={height} width={width} {...restOfProps} {...props} />;
  }
  return forceEmpty ? <Box css={{width, height}} /> : null;
};

Icon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  name: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  size: PropTypes.number,
  forceEmpty: PropTypes.bool,
  width: PropTypes.number,
};

export default memo(Icon);
