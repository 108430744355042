import React from 'react';

const RowHome = () => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 16.5V6.5L6 0.5L12 6.5V16.5H0ZM2 14.5H5V11.5H7V14.5H10V7.325L6 3.325L2 7.325V14.5ZM5 9.5V7.5H7V9.5H5ZM14 16.5V5.675L8.825 0.5H11.65L16 4.85V16.5H14ZM18 16.5V4.025L14.475 0.5H17.3L20 3.2V16.5H18Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default RowHome;
