import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';

export const AccountWrapper = styled(Flex)({
  padding: '16px',
  flexDirection: 'column',
  backgroundColor: '#F5F6F7',
  marginBottom: '20px',
});
