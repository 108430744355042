import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {TASK_CATEGORIES} from 'modules/project/components/Project/constants';
import {ManageDocuments} from 'modules/tasks-wizard';

import {useSubmitProjectDocument} from '@renofi/graphql';
import {getDocumentTypeByAssetType} from '@renofi/modules-internal';
import {Box, Text} from '@renofi/components-internal';

import StatementsTitle from '../components/StatementsTitle';
import {Content} from '../styled';

const Statements = ({
  assetType,
  formData = [],
  task,
  borrowerRole,
  borrowerName,
  onChange,
}) => {
  const {submitProjectDocument} = useSubmitProjectDocument();
  const documentType = getDocumentTypeByAssetType(assetType);

  const assetData = useMemo(
    () =>
      formData.filter(
        (d) => d.assetType === assetType && d.borrowerRole === borrowerRole,
      ),
    [assetType, borrowerRole, JSON.stringify(formData)],
  );

  return (
    <Content mb={40}>
      <StatementsTitle assetType={assetType} borrowerName={borrowerName} />
      {assetData.map((asset) => {
        const taskAsset = task?.assetDetails?.find((a) => asset.id === a.id);

        return (
          <Box mb={3} key={asset.id}>
            <Text my={2} fontSize={18}>
              {asset?.financialInstitution}
            </Text>
            <ManageDocuments
              customMutation={submitProjectDocument}
              customResponsePath="submitProjectDocument"
              customMutationParams={{
                borrowerRole,
                documentType,
                attachedRecordTarget: {
                  id: asset.id,
                  type: 'asset_detail',
                },
              }}
              customDocuments={taskAsset?.documents || []}
              facet={TASK_CATEGORIES.ELIGIBILITY}
              minimize
              onChange={onChange}
              taskId={task.id}
            />
          </Box>
        );
      })}
    </Content>
  );
};

Statements.propTypes = {
  formData: PropTypes.array,
  taskId: PropTypes.string,
  borrowerRole: PropTypes.string,
  borrowerName: PropTypes.string,
  assetType: PropTypes.string,
  onChange: PropTypes.func,
};

export default Statements;
