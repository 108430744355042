import React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme';

const Swipe = ({color = basic20}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="swipe" opacity="0.3">
        <mask
          id="mask0_425_74684"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24">
          <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_425_74684)">
          <path
            id="swipe_2"
            d="M2 7V2H3.5V4C4.7 3.01667 6.025 2.27067 7.475 1.762C8.925 1.254 10.4333 1 12 1C13.5667 1 15.075 1.254 16.525 1.762C17.975 2.27067 19.3 3.01667 20.5 4V2H22V7H17V5.5H19.9C18.8 4.53333 17.575 3.79167 16.225 3.275C14.875 2.75833 13.4667 2.5 12 2.5C10.5333 2.5 9.125 2.75833 7.775 3.275C6.425 3.79167 5.2 4.53333 4.1 5.5H7V7H2ZM11.2 23C10.9 23 10.596 22.9457 10.288 22.837C9.97933 22.729 9.73333 22.5833 9.55 22.4L5 17.625L5.825 16.775C5.94167 16.6583 6.07933 16.575 6.238 16.525C6.396 16.475 6.56667 16.4667 6.75 16.5L10 17.25V6.5C10 6.08333 10.146 5.72933 10.438 5.438C10.7293 5.146 11.0833 5 11.5 5C11.9167 5 12.2707 5.146 12.562 5.438C12.854 5.72933 13 6.08333 13 6.5V12.5H13.9C14.0333 12.5 14.1833 12.5167 14.35 12.55C14.5167 12.5833 14.6667 12.6333 14.8 12.7L18.9 14.75C19.2833 14.9333 19.575 15.221 19.775 15.613C19.975 16.0043 20.0417 16.4083 19.975 16.825L19.35 21.275C19.2667 21.775 19.0417 22.1873 18.675 22.512C18.3083 22.8373 17.875 23 17.375 23H11.2Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

Swipe.propTypes = {
  color: PropTypes.string,
};

export default Swipe;
