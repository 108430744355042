import React, {useContext} from 'react';

import {Box, Flex, Navbar, Text} from '@renofi/components-internal';
import Cross from '@renofi/icons/src/Cross';
import Person from '@renofi/icons/src/PersonCircle';
import {dangerRegular} from '@renofi/theme/src';
import {useGetUser} from '@renofi/graphql/src/hooks';
import {Context as ConfigContext} from '@renofi/utilities/src/config';
import isImpersonation from '@renofi/utilities/src/isImpersonation';
import {Context as StorageContext} from '@renofi/utilities/src/storage';

const Header = () => {
  const {user} = useGetUser();
  const {LOGIN_FRONTEND_URL} = useContext(ConfigContext);
  const storage = useContext(StorageContext);
  const impersonation = isImpersonation(storage);

  const {email, firstName, lastName} = user || {};

  return (
    <>
      {impersonation && (
        <Navbar
          wide
          fixed
          height={60}
          css={{
            backgroundColor: dangerRegular,
            zIndex: 1001,
          }}>
          <Flex width={1} justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Box mr="8px">
                <Person color="white" />
              </Box>
              <Text>
                Impersonating {firstName} {lastName} ({email})
              </Text>
            </Flex>
            <Box>
              <a href={LOGIN_FRONTEND_URL}>
                <Cross color="white" />
              </a>
            </Box>
          </Flex>
        </Navbar>
      )}
    </>
  );
};

export default Header;
