import styled from '@emotion/styled';

import {Flex, Text} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';
import {
  actionExtraLight,
  basic55,
  basic80,
  border,
} from '@renofi/theme/src/colors';

export const ListBox = styled(Flex)({
  width: '52%',
  borderRadius: 4,
  flexDirection: 'column',
  border: `1px solid ${border}`,
  overflow: 'hidden',
  marginBottom: 24,
  [maxSmall]: {
    width: '100%',
  },
});

export const ListItem = styled(Flex)(
  {
    width: '100%',
    height: 40,
    overflow: 'hidden',
    flexWrap: 'nowrap',
    borderBottom: `1px solid ${border}`,
    alignItems: 'center',
    cursor: 'pointer',
    ':last-of-type': {
      borderBottom: 'none',
    },
    ':hover': {
      color: basic80,
      backgroundColor: actionExtraLight,
    },
  },
  ({active}) => ({
    color: active ? basic80 : basic55,
    backgroundColor: active ? actionExtraLight : 'white',
  }),
);

export const IconBox = styled(Flex)({
  width: 56,
  height: '100%',
  flexGrow: 0,
  alignItems: 'center',
  justifyContent: 'center',
});

export const Label = styled(Text)({
  width: '100%',
  fontSize: 16,
  lineHeight: '20px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});
