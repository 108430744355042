import React from 'react';

import PropTypes from 'prop-types';

import {Button} from '@renofi/components-internal';

import ThumbsIcon from '../icons/ThumbsIcon';
import {PageWrapper, IconBox, IntroBox, IntroText} from '../styled';

const CoBorrowerIntro = ({hideIntro, projectBorrowers}) => {
  const {borrowersAndPropertyInformation: borrowersInfo, borrower} =
    projectBorrowers || {};

  return (
    <PageWrapper mb={170} relative>
      <IconBox>
        <ThumbsIcon />
      </IconBox>
      <IntroBox>
        <IntroText>
          Thanks for adding {borrower?.firstName}’s ID details.
          <br />
          Next, <b>add {borrowersInfo?.coborrowerFirstName}’s ID details</b> to
          complete this task.
        </IntroText>
        <Button className="hide-intro-btn" onClick={hideIntro}>
          {`Add ${borrowersInfo.coborrowerFirstName}’s ID details`}
        </Button>
        <span className="intro-sub-item">
          Both borrowers’ details are required before the task can be reviewed
          by RenoFi.
        </span>
      </IntroBox>
    </PageWrapper>
  );
};

CoBorrowerIntro.propTypes = {
  projectBorrowers: PropTypes.shape({
    borrower: PropTypes.shape({
      firstName: PropTypes.string,
    }),
    borrowersAndPropertyInformation: PropTypes.shape({
      coborrowerFirstName: PropTypes.string,
    }),
  }),
  hideIntro: PropTypes.func,
};
export default CoBorrowerIntro;
