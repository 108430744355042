import React from 'react';

const PrimaryResidence = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_4566_201904"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4566_201904)">
        <path
          d="M4 20V10H1L12 1L23 10H20V11H18V8.475L12 3.575L6 8.475V18H8V20H4ZM14.2 22L10 17.8L11.4 16.4L14.2 19.2L20.1 13.3L21.5 14.7L14.2 22Z"
          fill="#CCCCCC"
        />
      </g>
    </svg>
  );
};

export default PrimaryResidence;
