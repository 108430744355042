import React from 'react';

import PropTypes from 'prop-types';
import {useInputFieldAnalytics} from 'lib/hooks';

import {FullAddressForm} from '@renofi/components-internal';

const AddressForm = ({onChange, value}) => {
  const [analyticsHandlers] = useInputFieldAnalytics();

  function onChangeValue(item) {
    onChange({...value, ...item});
  }

  return (
    <FullAddressForm
      analytics={analyticsHandlers}
      data={value}
      onChange={(key, value) => onChangeValue({[key]: value})}
    />
  );
};

AddressForm.propTypes = {
  value: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    streetAddressOne: PropTypes.string,
    streetAddressTwo: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default AddressForm;
