import React from 'react';

import PropTypes from 'prop-types';
import {pick} from 'ramda';

import {Button as CommonButton} from '@renofi/components-internal';
import {sendEvent} from '@renofi/utilities/src/analytics';

const Button = ({onClick, ...props}) => {
  const onClickButton = (event) => {
    sendEvent('ButtonClick', {
      text: event.target.textContent,
      ...pick(['name'], props),
    });
    if (onClick) {
      onClick(event);
    }
  };
  return <CommonButton onClick={onClickButton} {...props} />;
};

Button.propTypes = {
  onClick: PropTypes.func,
};

export default Button;
