import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {ManageDocuments} from 'modules/tasks-wizard';

import {Alert, Box, Text} from '@renofi/components-internal';

const AdditionalDocumentsWizard = ({details, onChange, taskId, title}) => {
  const {facet} = useParams();

  return (
    <Fragment>
      <Box mb={4}>
        <Text mb={3} fontSize={18}>
          Please upload {title}
        </Text>
        <Alert>{details}</Alert>
      </Box>

      <ManageDocuments facet={facet} onChange={onChange} taskId={taskId} />
    </Fragment>
  );
};

AdditionalDocumentsWizard.propTypes = {
  details: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default AdditionalDocumentsWizard;
