import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {VALIDATE_PHONE_NUMBER} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useValidatePhoneNumber({
  fetchPolicy = 'no-cache',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const {fetch, data, loading, error} = useToggledQuery({
    lazy,
    query: VALIDATE_PHONE_NUMBER,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const number = propOr(null, 'validatePhoneNumber', data);
  const valid = Boolean(number);

  return {
    valid,
    fetch,
    data,
    loading,
    error,
  };
}
