import React from 'react';

import PropTypes from 'prop-types';
import {isEmpty, propOr} from 'ramda';
import {
  ChecklistItem,
  ChecklistWrapper,
  ChecklistContent,
} from 'modules/layout/components/ChecklistItem';

import {Text, Flex} from '@renofi/components-internal';
import {basic80} from '@renofi/theme/src/colors';

function IdentityVerificationHelp({task, ...props}) {
  const documents = propOr([], 'documents', task);
  const hasUploadedDocs = !isEmpty(documents);

  if (hasUploadedDocs) {
    return null;
  }

  return (
    <Flex p={0} {...props}>
      <ChecklistContent>
        <Text mb={16} color={basic80}>
          ID verification is a super easy and fast web based process to protect
          you and the lender from any ID fraud. Please follow the on screen
          prompts and make sure you're at a device with a camera when
          completing.
        </Text>

        <Text mb={16} color={basic80}>
          When prompted to enter the last 4 digits of your social security
          number, take extra care to enter those digits accurately.
        </Text>

        <Text mb={16} color={basic80}>
          Additionally, please be sure of the following to ensure a successful
          verification:
        </Text>

        <ChecklistWrapper flexDirection="column">
          <ChecklistItem text="Be sure the images you upload are clear. Blury or hazy images will trigger a fail." />
          <ChecklistItem text="Be sure docs uploaded are valid and not expired. Expired docs will trigger a fail." />
          <ChecklistItem text="Be sure to use your driver's license or passport. Other documents are not supported at this time and will trigger a fail." />
        </ChecklistWrapper>
      </ChecklistContent>
    </Flex>
  );
}

IdentityVerificationHelp.propTypes = {
  task: PropTypes.shape({
    documents: PropTypes.array.isRequired,
  }),
};

export default IdentityVerificationHelp;
