export const PROPS_TO_PICK = [
  'borrowerIsOnLoan',
  'borrowerIsOnTitle',
  'coborrowerFirstName',
  'coborrowerIsOnTitle',
  'coborrowerLastName',
  'coborrowerMiddleName',
  'coborrowerNameSuffix',
  'propertyCity',
  'propertyCounty',
  'propertyOwnedByTrust',
  'propertyState',
  'propertyStreetAddressOne',
  'propertyStreetAddressTwo',
  'propertyUseType',
  'propertyStructureType',
  'propertyZipCode',
  'reasonForRenovating',
  'renovationTypeDescription',
];

export const STEP_MAPPER = {
  Welcome: 0,
  FilledAddress: 2,
  Address: 2.5,
  PropertyTrust: 3,
  PropertyTrustWarning: 3.5,
  PropertyTitle: 4,
  TypeOfHome: 5,
  PropertyUsage: 5.3,
  TypeOfRenovation: 5.6,
  ReasonForRenovating: 5.9,
  BorrowerOnLoan: 6,
  CoBorrower: 7,
  CoBorrowerProperty: 7.3,
  BorrowerInfo: 7.6,
  Finish: 8,
};
