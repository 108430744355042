import {gql} from '@apollo/client';

import {note} from '../partials';

export default gql`
  mutation createNote(
    $body: String!
    $incomeCommentaryId: ID
    $projectId: ID!
    $title: String!
  ) {
    createNote(
      body: $body
      incomeCommentaryId: $incomeCommentaryId
      projectId: $projectId
      title: $title
    ) {
      note {
        ${note}
      }
    }
  }
`;
