import styled from '@emotion/styled';

import {Flex, Text} from '@renofi/components-internal';
import {
  actionRegular,
  basic03,
  basic10,
  basic80,
} from '@renofi/theme/src/colors';

export const Container = styled(Flex)({
  flexWrap: 'wrap',
  flexDirection: 'column',
  padding: 16,

  borderRadius: 8,
  backgroundColor: basic03,
  border: `1px solid ${basic10}`,
  fontSize: 14,
  lineHeight: '24px',
});

export const Label = styled(Text)({
  color: basic80,
});

export const Value = styled(Text)({
  color: actionRegular,
  maxWidth: 200,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});
