import {v4 as uuid} from 'uuid';

import {
  RENOVATION_UPDATE_KINDS,
  KIND_LABEL_MAP,
  doesKindSupportDate,
} from '@renofi/modules-internal';

export const isSubmitDisabled = ({data, kind}) => {
  const requiresDate = doesKindSupportDate(kind);
  const requiresDetails = kind !== RENOVATION_UPDATE_KINDS.renovation_completed;

  if (requiresDate && !data.date) {
    return true;
  }

  return !kind || (requiresDetails && !data.details);
};

export const getDetailsLabel = (kind) =>
  KIND_LABEL_MAP[kind] || KIND_LABEL_MAP[RENOVATION_UPDATE_KINDS.on_schedule];

export const mapPhotos = (updateId, renovationUpdate) => {
  if (!updateId) {
    return [];
  }

  return renovationUpdate.photos.map(({original, thumbnail}) => ({
    ...original,
    thumbnail: thumbnail.url,
    id: uuid(),
    loading: false,
    fileName: '',
  }));
};
