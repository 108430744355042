import {useEffect, useRef} from 'react';

import {makeVar, useReactiveVar} from '@apollo/client';
import {find, head, pipe, propEq} from 'ramda';
import {useParams} from 'react-router-dom';

import useProject from '../useProject';

import useBorrowerProjects from './useBorrowerProjects';

const activeProjectVar = makeVar(null);

const sortByCreatedDate = (projects = []) =>
  projects.toSorted((left, right) => {
    return new Date(right?.createdAt) - new Date(left?.createdAt);
  });

export default function useToggledBorrowerProject() {
  const {projectId} = useParams();
  const {projectById} = useProject({lazy: true});
  const activeProject = useReactiveVar(activeProjectVar);
  const {current: previousProjectId} = useRef(projectId);

  const fetchProjectByCurrentId = async () => {
    const rsp = await projectById({variables: {id: projectId}});
    if (Boolean(rsp?.errors)) {
      return activeProjectVar(null);
    }

    activeProjectVar(rsp?.data?.projectById || null);
  };

  const {fetch, projects, loading} = useBorrowerProjects({
    onCompleted: (data) => {
      const {borrowerProjects = []} = data || {};

      if (!projectId) {
        return activeProjectVar(
          pipe(sortByCreatedDate, head)(borrowerProjects),
        );
      }

      const active = find(propEq('id', projectId), borrowerProjects);
      if (active) {
        return activeProjectVar(active);
      }

      // Instead, manually fetch based on the given ID
      // (may be that it's a Closed project)
      fetchProjectByCurrentId();
    },
  });

  useEffect(() => {
    if (!loading && previousProjectId !== projectId) {
      fetch();
    }
  }, [loading, previousProjectId, projectId]);

  return {
    project: activeProject,
    projects,
    projectId,
    fetch,
    loading,
  };
}
