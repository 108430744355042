import {Fragment} from 'react';

import PropTypes from 'prop-types';
import {join, map, pick, pipe, propOr} from 'ramda';

import {getFullName} from '@renofi/utilities/src/data';
import {Box, Flex, Text, TaskDetailsCard} from '@renofi/components-internal';
import {basic80} from '@renofi/theme';
import capitalize from '@renofi/utilities/src/capitalize';
import {formatDate} from '@renofi/utilities/src/dates';
import {validateUuidProp} from '@renofi/utilities/src/react';
import noop from '@renofi/utilities/src/noop';

import {taskStatuses} from '../../constants';
import {Label, Value} from '../styled';

import {getValueByBorrower} from './utils';
import ResidenceHistory from './ResidenceHistory';

const CITIZENSHIP_TYPE = {
  us_citizen: 'U.S. citizen',
  non_permanent_resident_alien: 'Non-Permanent resident alien',
  permanent_resident_alien: 'Permanent resident alien',
};
const wrapString = (str = '') => {
  const length = str.length;
  const leftPad = str.padStart(length + 1, '(');
  return leftPad.padEnd(leftPad.length + 1, ')');
};

const BorrowerDetails = ({coborrower, data, onValueClick = noop, status}) => {
  const firstName = getValueByBorrower('firstName', coborrower)(data);
  const lastName = getValueByBorrower('lastName', coborrower)(data);
  const middleName = getValueByBorrower('middleName', coborrower)(data);
  const nameSuffix = getValueByBorrower('nameSuffix', coborrower)(data);
  const fullName = getFullName({firstName, lastName, middleName, nameSuffix});
  const maritalStatus = getValueByBorrower('maritalStatus', coborrower)(data);
  const dateOfBirth = getValueByBorrower('dateOfBirth', coborrower)(data);
  const citizenship = getValueByBorrower('citizenshipType', coborrower)(data);

  const altNamesKey = coborrower
    ? 'coborrowerAlternativeNames'
    : 'borrowerAlternativeNames';
  const alternativeNames = propOr([], altNamesKey, data);
  const hasAltNames = Boolean(alternativeNames?.length);
  const altNamesString = pipe(
    map(pipe(pick(['firstName', 'middleName', 'lastName']), getFullName)),
    join(', '),
  )(alternativeNames);
  const dependents = getValueByBorrower('dependentsAges', coborrower)(data);
  const isCompleted = status === taskStatuses.COMPLETED;
  const onClick = () => {
    onValueClick(coborrower);
  };

  return (
    <Box as="div" mt={3}>
      <Text fontSize={16} color={basic80} mb={2}>
        <strong style={{fontFamily: 'Averta-Bold', marginRight: 4}}>
          {fullName}
        </strong>
        {coborrower ? '(Co-borrower)' : '(Primary borrower)'}
      </Text>

      <TaskDetailsCard success={isCompleted}>
        <Flex flexWrap="wrap">
          <Label>Full legal name:</Label>
          <Value onClick={onClick}>
            {getFullName({firstName, middleName, lastName, nameSuffix})}
          </Value>

          {hasAltNames ? (
            <Fragment>
              <Label>Alternative names:</Label>
              <Value onClick={onClick}>{altNamesString}</Value>
            </Fragment>
          ) : null}

          <Label>Marital status:</Label>
          <Value onClick={onClick}>{capitalize(maritalStatus)}</Value>

          <Label>Dependents:</Label>
          <Value onClick={onClick}>
            <Box as="span" mr={dependents.length > 0 ? 1 : 0}>
              {dependents.length}
            </Box>
            {dependents.length > 0 ? wrapString(dependents.join(', ')) : null}
          </Value>

          <Label>Date of birth:</Label>
          <Value onClick={onClick}>{formatDate(dateOfBirth)}</Value>

          <Label>Citizenship:</Label>
          <Value onClick={onClick}>
            {CITIZENSHIP_TYPE[citizenship] || 'Unknown'}
          </Value>
        </Flex>

        <ResidenceHistory
          coborrower={coborrower}
          data={data}
          onValueClick={onValueClick}
        />
      </TaskDetailsCard>
    </Box>
  );
};

BorrowerDetails.propTypes = {
  coborrower: PropTypes.bool,
  data: PropTypes.shape({
    id: validateUuidProp,
    coborrowerFirstName: PropTypes.string,
    coborrowerLastName: PropTypes.string,
    coborrowerMiddleName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    maritalStatus: PropTypes.string,
  }),
  onValueClick: PropTypes.func,
  status: PropTypes.string,
};

export default BorrowerDetails;
