import {TASK_STATUSES} from '../components/Project/constants';
import {
  CONTINUE_LABELS,
  OUTSTANDING_LABELS,
  PENDING_LABELS,
} from '../components/BorrowerDetails/constants';

export {default as getNextWizardStep} from './getNextWizardStep';
export {default as getWizardLabels} from './getWizardLabels';

export const getTaskButtonLabels = ({status, isContinueTask, taskType}) => {
  const isOutstanding = status === TASK_STATUSES.outstanding;
  const isProcessing = status === TASK_STATUSES.processing;
  let label = OUTSTANDING_LABELS[taskType];

  if (isOutstanding) {
    label = isContinueTask
      ? CONTINUE_LABELS[taskType]
      : OUTSTANDING_LABELS[taskType];
  }

  if (isProcessing) {
    label = PENDING_LABELS[taskType];
  }

  return label;
};
