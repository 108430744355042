import React, {memo, useState} from 'react';

import PropTypes from 'prop-types';
import {isNil} from 'ramda';
import {Route, Switch, useHistory} from 'react-router-dom';
import {ListItem, ItemWrapper, ListItemTitle, TaskItem} from 'modules/layout';
import {useCurrentTask} from 'modules/project';
import {getTaskButtonLabels} from 'modules/project/utils';
import {Wrapper} from 'modules/project/components/styled';
import {DocumentList} from 'modules/project/components/Task';

import {Button, DocumentViewer} from '@renofi/components-internal';
import {useToggledBorrowerProject} from '@renofi/graphql/src/hooks';
import {isMobile} from '@renofi/theme';
import {getFullName, getCoBorrowerFullName} from '@renofi/utilities/src/data';
import {getPropertyFullAddress} from '@renofi/utilities/src/getPropertyFullAddress';

import RealEstateWizard from '../RealEstateWizard';
import {findPropertyDocuments} from '../RealEstateWizard/utils';

import AdditionalPropertiesItems from './AdditionalPropertiesItems';
import SoldPropertiesItems from './SoldPropertiesItems';

const RealEstateTask = ({task}) => {
  const history = useHistory();
  const [currentDocumentId, setCurrentDocumentId] = useState();

  const {isProcessing, sendEvent} = useCurrentTask({
    taskId: task.id,
    facet: task.facet,
  });

  const {project, projectId} = useToggledBorrowerProject();

  const {realEstateOwned, status, taskType} = task;

  const {subjectProperty, additionalProperties, soldProperties} =
    realEstateOwned || {};

  const {
    taxAndInsuranceIncluded,
    existingMortgageLoan,
    homeownersAssociationFeesIncluded,
  } = subjectProperty || {};

  const {borrowersAndPropertyInformation: borrowersInfo} = project || {};

  function getPropertyDocuments() {
    const subjectDocIds = subjectProperty?.documentIds || [];
    const additionalDocIds =
      additionalProperties?.map(({documentIds}) => documentIds) || [];
    const {documents: subjectDocuments} = findPropertyDocuments(
      subjectDocIds,
      task,
    );
    const {documents} = findPropertyDocuments(
      [...subjectDocIds, ...additionalDocIds.flat()],
      task,
    );

    return {documents, subjectDocuments};
  }

  const {documents, subjectDocuments} = getPropertyDocuments();

  const buttonLabel = getTaskButtonLabels({
    status,
    isContinueTask: !isNil(realEstateOwned),
    taskType,
  });
  const borrowerName = getFullName(borrowersInfo);

  const hasCoBorrower = borrowersInfo?.coborrowerIsPresent;
  const coborrowerName = hasCoBorrower
    ? getCoBorrowerFullName(borrowersInfo || {})
    : '';

  function onOpenWizard() {
    sendEvent('Secure/Task-Started');

    history.push(
      `/${projectId}/tasks/borrower_information/real-estate-wizard/subject-property`,
    );
  }

  return (
    <>
      <TaskItem
        button={
          <Button
            block={isMobile()}
            data-testid="reo-wizard-btn"
            medium={isMobile()}
            secondary={isProcessing}
            onClick={onOpenWizard}>
            {buttonLabel}
          </Button>
        }
        task={task}>
        <Wrapper>
          {realEstateOwned ? (
            <>
              <ListItemTitle
                title={getPropertyFullAddress(project)}
                subtitle="Subject property"
                wrapItems={false}
              />

              <ItemWrapper>
                <ListItem title="Outstanding mortgage">
                  {existingMortgageLoan ? 'Yes' : 'No'}
                </ListItem>
                <ListItem title="Taxes and insurance">
                  {taxAndInsuranceIncluded ? 'Yes' : 'No'}
                </ListItem>
                <ListItem skipComma title="Homeowners association fee included">
                  {homeownersAssociationFeesIncluded ? 'Yes' : 'No'}
                </ListItem>
              </ItemWrapper>

              <DocumentList
                documents={subjectDocuments}
                onClick={setCurrentDocumentId}
              />

              <AdditionalPropertiesItems
                onClick={setCurrentDocumentId}
                additionalProperties={additionalProperties}
                task={task}
                borrowerName={borrowerName}
                coborrowerName={coborrowerName}
              />

              <SoldPropertiesItems
                onClick={setCurrentDocumentId}
                soldProperties={soldProperties}
                task={task}
                borrowerName={borrowerName}
                coborrowerName={coborrowerName}
              />
            </>
          ) : null}

          {currentDocumentId && (
            <DocumentViewer
              hasControls
              selectable
              itemId={currentDocumentId}
              visible={!!currentDocumentId}
              documents={documents}
              onClose={() => setCurrentDocumentId(null)}
              showCloseButton
              showBackButton={false}
            />
          )}
        </Wrapper>
      </TaskItem>

      <Switch>
        <Route
          path={`/:projectId/tasks/:facet(borrower_information)/real-estate-wizard/:step?/:propertyId?/:type?`}
          render={() => <RealEstateWizard task={task} project={project} />}
        />
      </Switch>
    </>
  );
};

RealEstateTask.propTypes = {
  task: PropTypes.object.isRequired,
  hasCoBorrower: PropTypes.bool,
};

export default memo(RealEstateTask);
