import React from 'react';

import PropTypes from 'prop-types';

import {Flex, Text} from '@renofi/components-internal';
import Contact from '@renofi/icons/src/Contact';
import {basic88, basic55} from '@renofi/theme/src/colors';

import {
  PhoneIcon,
  EnvelopeIcon,
  LinkWrapper,
  ItemWrapper,
  IconWrapper,
  ItemWrapperBottom,
} from './styled';

const ContactInformationGroup = ({name, email, phone, isTpo = false}) => {
  return (
    <Flex flexDirection="column">
      <ItemWrapper>
        {!isTpo && (
          <IconWrapper>
            <Contact />
          </IconWrapper>
        )}
        <Text
          color={isTpo ? basic55 : basic88}
          fontWeight={isTpo ? 'bold' : 'normal'}>
          {name}
        </Text>
      </ItemWrapper>
      <ItemWrapper>
        {!isTpo && (
          <IconWrapper>
            <EnvelopeIcon />
          </IconWrapper>
        )}
        <LinkWrapper blank href={`mailto:${email}`}>
          <Text>{email}</Text>
        </LinkWrapper>
      </ItemWrapper>
      <ItemWrapperBottom>
        {!isTpo && (
          <>
            <IconWrapper>
              <PhoneIcon />
            </IconWrapper>
            <LinkWrapper blank href={`tel:${phone}`}>
              <Text mt="1px">{phone}</Text>
            </LinkWrapper>
          </>
        )}
        {isTpo && <Text>{phone}</Text>}
      </ItemWrapperBottom>
    </Flex>
  );
};

ContactInformationGroup.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  isTpo: PropTypes.bool,
};

export default ContactInformationGroup;
