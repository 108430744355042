import styled from '@emotion/styled';
import {Content as Card} from 'modules/layout';

import {Text} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';

import girlWithBoxes from './girlWithBoxes.svg';

export const Details = styled(Text)({
  fontSize: 24,
  lineHeight: '28px',
  textAlign: 'center',
  color: basic55,
});

export const Content = styled(Card)({
  minHeight: 500,
  backgroundColor: 'white',
  backgroundImage: `url('${girlWithBoxes}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '361px 249px',
  backgroundPosition: 'center bottom 60px',
});
