export default ({status, canHaveDocument, allDocumentsAccepted} = {}) => {
  if (status === 'outstanding') {
    return 'danger';
  }
  if (status === 'completed') {
    return 'complete';
  }
  if (canHaveDocument && allDocumentsAccepted) {
    return 'success';
  }
  return 'warning';
};
