import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

import {actionDark, actionRegular, basic55} from '@renofi/theme/src/colors';

const CSS_STYLES = [
  {
    textDecoration: 'none',
  },
  ({disabled, hover, dark}) => ({
    cursor: disabled ? 'default' : 'pointer',
    color: disabled ? basic55 : dark ? actionDark : actionRegular,
    pointerEvents: disabled ? 'none' : 'auto',
    ':hover': {
      color: disabled ? basic55 : dark ? actionDark : actionRegular,
      textDecoration: disabled ? 'none' : 'underline',
    },
    ...(hover
      ? {
          color: disabled ? basic55 : dark ? actionDark : actionRegular,
          textDecoration: disabled ? 'none' : 'underline',
        }
      : {}),
  }),
];

export const AnchorLink = styled.a(...CSS_STYLES);

export const RouterLink = styled(Link)(...CSS_STYLES);
