import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../useMutation';
import {CONSENT_LENDER_ACKNOWLEDGEMENT} from '../../mutations';

export default function useConsentLenderAcknowledgement({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [consentLenderAcknowledgement, {data, loading, error}] = useMutation(
    CONSENT_LENDER_ACKNOWLEDGEMENT,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['consentLenderAcknowledgement'], data);
  return {consentLenderAcknowledgement, response, loading, error};
}
