export default `
  id
  applyUrl
  contactInformation1
  contactInformation2
  fastTrackEnabled
  friendlyId
  joinUrl
  logoObjectName
  logoUrl
  name
  tpoEnabled
`;
