import React from 'react';

import PropTypes from 'prop-types';

import ModalWithButtons from '@renofi/components-internal/src/ModalWithButtons';
import Flex from '@renofi/components-internal/src/Flex';
import Text from '@renofi/components-internal/src/Text';
import {basic80} from '@renofi/theme/src/colors';

const ConfirmCloseTaskModal = ({loading, show, onConfirm, onCancelConfirm}) => {
  return (
    <ModalWithButtons
      acceptLabel="Yes, close"
      rejectLabel="No, continue"
      loading={loading}
      show={show}
      onAccept={onConfirm}
      onReject={onCancelConfirm}>
      <Flex flexDirection="column">
        <Text textAlign="center" fontSize={24} fontWeight="bold" my={3}>
          Are you sure you want to close this task without completing it?
        </Text>
        <Text color={basic80} textAlign="center" fontSize={16} my={3}>
          We’ll save your progress, and you can resume at any time, but RenoFi
          can only review completed tasks.
        </Text>
      </Flex>
    </ModalWithButtons>
  );
};

ConfirmCloseTaskModal.propTypes = {
  loading: PropTypes.bool,
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancelConfirm: PropTypes.func.isRequired,
};

export default ConfirmCloseTaskModal;
