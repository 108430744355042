import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme';

export const Wrapper = styled(Flex)({
  flexDirection: 'row',

  [maxSmall]: {
    flexDirection: 'column',
  },
});

export const DocumentWrapper = styled.div(
  {
    width: '50%',
    marginTop: 12,
    [maxSmall]: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 16,
    },
  },
  ({even}) => ({
    paddingLeft: even ? 0 : 8,
    paddingRight: even ? 8 : 0,
  }),
);
