import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {REQUEST_SOFT_CREDIT_CHECK_CONSENT_FROM_CO_BORROWER} from '../../../mutations/renovation';

export default function useRequestSoftCreditCheckConsentFromCoBorrower({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [requestSoftCreditCheckConsentFromCoborrower, {data, loading, error}] =
    useMutation(REQUEST_SOFT_CREDIT_CHECK_CONSENT_FROM_CO_BORROWER, {
      onCompleted,
      refetchQueries,
    });
  const response = pathOr(
    null,
    ['requestSoftCreditCheckConsentFromCoborrower'],
    data,
  );
  return {
    requestSoftCreditCheckConsentFromCoborrower,
    response,
    loading,
    error,
  };
}
