import styled from '@emotion/styled';

import {Box, PageTitle} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';

const TOP_OFFSET_IMPERSONATION = 60;
const TOP_OFFSET_MOBILE = 70;
const TOP_OFFSET_MOBILE_IMPERSONATION =
  TOP_OFFSET_IMPERSONATION + TOP_OFFSET_MOBILE;

export const Blank = styled(Box)(({left}) => ({
  background: '#f5fbfe',
  width: 30,
  height: '100%',
  position: 'relative',
  [left ? 'marginLeft' : 'marginRight']: -30,
  [maxSmall]: {
    display: 'none',
  },
}));

export const Wrapper = styled.div(({impersonation, scrollShadow}) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 100,
  position: 'sticky',
  top: scrollShadow && impersonation ? TOP_OFFSET_IMPERSONATION : 0,
  zIndex: 3,
  background: '#f5fbfe',
  transition: 'all 100ms ease',
  boxShadow: scrollShadow ? '0px 0px 8px rgba(0, 0, 0, 0.5)' : 'none',

  [maxSmall]: {
    width: '100vw',
    height: 70,
    marginLeft: -16,
    left: 0,
    top:
      scrollShadow && impersonation
        ? TOP_OFFSET_MOBILE_IMPERSONATION
        : TOP_OFFSET_MOBILE,
  },
}));

export const Title = styled(PageTitle)({
  width: '100%',
  flex: 1,
  [maxSmall]: {
    marginLeft: 16,
  },
});
