import React from 'react';

import PropTypes from 'prop-types';

import {Text} from '@renofi/components-internal';
import formatPhoneNumber from '@renofi/utilities/src/formatPhoneNumber';
import ratioToPercentage from '@renofi/utilities/src/ratioToPercentage';
import {basic80} from '@renofi/theme/src/colors';

import {
  Card,
  Label,
  Value,
  ItemsWrapper,
  ItemLeft,
  ItemRight,
  EmailValue,
} from '../../styled';

const PersonalDetails = ({productDetailsConfirmation}) => {
  const {name, phone, dtiRatio, email} = productDetailsConfirmation || {};

  const formattedDtiRatio = dtiRatio ? ratioToPercentage(dtiRatio) : '-';
  const formattedPhoneNumber = formatPhoneNumber(phone) || '-';

  return (
    <Card width="50%" mr="8px">
      <Text fontSize={14} mb={1} color={basic80} fontWeight="bold">
        Personal details
      </Text>
      <ItemsWrapper>
        <ItemLeft>
          <Label>Name:</Label>
          <Value>{name || '-'}</Value>
        </ItemLeft>
        <ItemRight>
          <Label>Phone:</Label>
          <Value>{formattedPhoneNumber}</Value>
        </ItemRight>
      </ItemsWrapper>

      <ItemsWrapper>
        <ItemLeft>
          <Label>Email:</Label>
          <EmailValue>{email || '-'}</EmailValue>
        </ItemLeft>
        <ItemRight>
          <Label>Estimated DTI:</Label>
          <Value>{formattedDtiRatio}</Value>
        </ItemRight>
      </ItemsWrapper>
    </Card>
  );
};

PersonalDetails.propTypes = {
  productDetailsConfirmation: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    dtiRatio: PropTypes.number,
    creditScore: PropTypes.string,
  }),
};

export default PersonalDetails;
