import {gql} from '@apollo/client';

import {borrower, applicant, documentWithAllProps} from '../../partials';

export default gql`
  query renovationReadyFileById($id: ID!) {
    renovationReadyFileById(id: $id) {
      id
      createdAt
      friendlyId
      project {
        id
        documents {
          ${documentWithAllProps}
        }
        primaryApplicant {
          ${applicant}
        }
        coborrower {
          ${applicant}
        }
        actualCloseDate
        actualCreditScore
        actualDtiRatio
        appraisalCost
        appraisalManagementCompany
        arvCltvRatio
        borrower {
          ${borrower}
        }
        borrowersOnLoan
        city
        creditLiabilityOverrides {
          creditLiabilityId
          adjustmentReason
        }
        comments {
          id
          body
          createdAt
          source
          subject
          updatedAt
          userId
          user {
            id
            email
            firstName
            lastName
          }
        }
        createdAt
        actualCurrentHomeValue
        statedCurrentHomeValue
        currentMortgageBalance
        estimatedCloseDate
        exceptionRequests {
          id
          compensatingFactors
          comments {
            id
            addedById
            addedBy {
              id
              email
              firstName
              lastName
            }
            createdAt
            message
            source
            subject
            updatedAt
          }
          metrics {
            id
            createdAt
            exceptional
            name
            notes
            updatedAt
            value
          }
          renofiNotes
          renovationScope
          status
          title
        }
        invitationSentAt
        raas
        wholesale
        loanValue
        narrative
        peakCltvRatio
        actualRenovationHomeValue
        estimatedRenovationHomeValue
        positionedInterestRate
        renofiId
        renovationCost
        renovationCostToCurrentPropertyValueRatio
        renovationScope
        renovationUnderwritingRequestId
        renovationUnderwritingRequest {
          id
          email
          firstName
          lastName
          returnDeadline
          createdById
          createdBy {
            id
            email
            firstName
            lastName
          }
        }
        salesforceOpportunityId
        selfEmployed
        selfReportedCreditScoreRange
        selfReportedDtiRatio
        selfReportedAssets
        stageName
        state
        status
        streetAddressOne
        streetAddressTwo
        titleHolders
        updatedAt
        yearsInHome
        zipCode
      }
      renofiProvidedDocuments {
        id
        checksum
        createdAt
        documentType
        fileName
        objectName
        pdfObjectName
        requestIpAddress
        requestIpLocation
        shareWithLenderAllowed
        shareable
        updatedAt
      }
      taskDocuments {
        id
        checksum
        documentType
        createdAt
        fileName
        objectName
        pdfObjectName
        rejectedAt
        rejectedById
        rejectedBy {
          id
          firstName
          lastName
        }
        source
        rejectedBySystem
        rejectionDetails
        rejectionReason
        requestIpAddress
        requestIpLocation
        reviewedAt
        shareWithLenderAllowed
        shareable
        status
        updatedAt
      }
      updatedAt
    }
  }
`;
