export const CONTRACTOR_REQUIREMENTS_HREF = `https://www.renofi.com/learn/what-do-i-need-from-my-contractor-to-apply-for-a-renovation-home-equity-loan/?utm_source=secure&utm_medium=web&utm_campaign=renovation-task`;
export const SAMPLE_CONTRACT_DOCUMENT_HREF =
  'https://d33wubrfki0l68.cloudfront.net/925ee6dda1db6569e7302bb373972f985bd0c462/04e81/resources/contract-example.ece5cb8c17e2814fa879ebb74978402de1ca89f03aefec4f488abea0f135258e.pdf';
export const SAMPLE_COST_ESTIMATED_DOCUMENT_HREF =
  'https://d33wubrfki0l68.cloudfront.net/8d4b1ed619f09d627e4b33151bea691b8fa51ae5/ca697/resources/detailed_cost_estimate_annotated.7481fbd29f0b731e5f62332f539da80f3bf37124ac8ec77bc4cb31cffb04ac4f.pdf';

export const EVENT_OPTIONS = {
  utmSource: 'secure',
  utmMedium: 'web',
  utmCampaign: 'renovation-task',
};
