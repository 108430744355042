import {findIndex, propEq} from 'ramda';

import reduceSteps from './reduceSteps';

export default ({steps, step, next = true}) => {
  const flattened = steps.reduce(reduceSteps, []);

  const currentIndex = findIndex(propEq('id', step), flattened);
  const isLastIndex = currentIndex === flattened.length - 1;
  const isFirstIndex = currentIndex === 0;

  if ((isLastIndex && next) || (isFirstIndex && !next)) {
    return step;
  }

  const newIndex = next ? currentIndex + 1 : currentIndex - 1;

  return flattened[newIndex]?.id;
};
