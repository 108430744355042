import React from 'react';

import {Flex} from '@renofi/components-internal';

import {Content, Marker, Picture, Record, Title} from '../styled';

const Dummy = () => (
  <Record>
    <Marker />
    <Title>Today</Title>
    <Content>
      Here you’ll be able to write renovation update and add photos to provide
      more context about the progress.
      <Flex mt="12px">
        <Picture />
        <Picture />
        <Picture />
      </Flex>
    </Content>
  </Record>
);

export default Dummy;
