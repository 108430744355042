import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/utilities/src/analytics/utils';

import {STEP_MAPPER} from '../constants';

import BaseContent from './BaseContent';

const CoBorrower = ({setStep}) => {
  const onAccept = async () => {
    setStep(STEP_MAPPER.CoBorrowerProperty);
    sendEvent('Secure/Onboarding-Has-Coborrower-Selected');
  };

  const onDecline = async () => {
    setStep(STEP_MAPPER.Finish);
    sendEvent('Secure/Onboarding-Has-Coborrower-Selected');
  };

  useEffect(() => {
    sendEvent('Secure/Onboarding-Has-Coborrower-Presented');
  }, []);

  return (
    <BaseContent
      mainContent={'Will there be a co-borrower on the loan?'}
      onAccept={onAccept}
      onDecline={onDecline}
    />
  );
};

CoBorrower.propTypes = {
  setStep: PropTypes.func,
  update: PropTypes.func,
};

export default CoBorrower;
