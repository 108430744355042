import styled from '@emotion/styled';

import Flex from '@renofi/components-internal/src/Flex';
import {basic03, basic20} from '@renofi/theme/src/colors';

const DEFAULT_OPTION_STYLES = {
  minHeight: 40,
  height: 'auto',
  alignItems: 'center',
  // padding: '0 12px',
  lineHeight: '24px',
};

export const Wrapper = styled(Flex)(
  {
    ...DEFAULT_OPTION_STYLES,
    width: '100%',
    flexShrink: 0,
    cursor: 'pointer',
    fontSize: 14,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    borderTop: `solid 1px ${basic03}`,

    '&.subOption': {
      padding: '5px 12px',
    },
    '&.highLightedSubOption': {
      color: '#FF5253',
      padding: '5px 12px',
    },
  },
  ({disabled, css}) => ({
    color: disabled ? basic20 : 'inherit',
    pointerEvents: disabled ? 'none' : 'auto',
    ...css,
  }),
);
