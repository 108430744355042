import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme';

const SvgDownload = ({color = basic20, title, ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      {title && <title>{title}</title>}
      <path
        d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67 2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgDownload.propTypes = {
  color: PropTypes.string,
  title: PropTypes.node,
};

export default SvgDownload;
