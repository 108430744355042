import React from 'react';

import {Help} from 'modules/layout';

import {Container, Text} from '@renofi/components-internal';

import {Content, Details} from './styled';

export default () => (
  <Container narrow mt={16}>
    <Content
      pt={24}
      pb={60}
      alignItems="center"
      head={
        <Text mt={32}>
          We're unable to find an active renovation project for your account
        </Text>
      }>
      <Details mt={16}>Please contact us for assistance</Details>
    </Content>
    <Help />
  </Container>
);
