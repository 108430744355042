import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Button,
  CodeField,
  Modal,
  Text,
  TextField,
} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';

import Message from '../Message';

import {Action, Details, Wrapper} from './styled';

const ChangePhone = ({
  status,
  verify,
  loading,
  phoneNumber,
  onClose,
  onGoBack,
  onSubmitCode,
  onChangePhoneNumber,
  onSendVerification,
  setPhoneNumberChanged,
}) => {
  const success = status === 'success';
  const failure = status === 'failure';
  useEffect(() => {
    if (!success) return;
    const timeout = setTimeout(() => {
      onClose();
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [success]);

  const handleSubmitCode = async (code) => {
    await onSubmitCode(code);
    setPhoneNumberChanged(true);
  };

  return (
    <Modal
      lock
      show
      pt={24}
      pb={40}
      width={480}
      header="Enter phone number"
      onClose={onClose}>
      {!verify && (
        <form action="" onSubmit={onSendVerification}>
          <Wrapper>
            <Text color={basic55} fontSize={16} lineHeight="24px">
              We will send you a one-time verification code
            </Text>
            <Box width={256} mt={20}>
              <TextField
                phone
                large
                leftIcon="phone"
                value={phoneNumber}
                onChange={onChangePhoneNumber}
              />
            </Box>
            <Box width={256}>
              <Button block large submit loading={loading}>
                Send verification code
              </Button>
            </Box>
          </Wrapper>
        </form>
      )}
      {verify && (
        <Wrapper>
          <Text color={basic55} fontSize={16} lineHeight="24px">
            We sent a verification code to {phoneNumber}
          </Text>
          <Box my={24}>
            <Message />
          </Box>
          <Box css={{position: 'relative'}}>
            <CodeField
              failure={failure}
              success={success}
              onChange={() => {}}
              onComplete={handleSubmitCode}
            />
            {failure && (
              <Details failure>Incorrect code, please try again</Details>
            )}
            {success && <Details success>Phone number verified!</Details>}
          </Box>
          <Box mt={16}>
            <Action
              to=""
              disabled={success}
              onClick={(event) => {
                event.preventDefault();
                onGoBack();
              }}>
              Change phone number
            </Action>
          </Box>
          <Box mt={20}>
            <Action to="" disabled={success} onClick={onSendVerification}>
              Send code again
            </Action>
          </Box>
        </Wrapper>
      )}
    </Modal>
  );
};

ChangePhone.propTypes = {
  verify: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setPhoneNumberChanged: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitCode: PropTypes.func.isRequired,
  onSendVerification: PropTypes.func.isRequired,
  onChangePhoneNumber: PropTypes.func.isRequired,
};

export default ChangePhone;
