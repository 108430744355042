import React, {memo, useEffect, useState} from 'react';

import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {omit, pick} from 'ramda';

import {useKeyHandler} from '@renofi/utilities/src/hooks';

import {Close, Content, Cover, Dialog, Footer, Header, Wrapper} from './styled';

const ORIGNAL_OVERFLOW = document.body.style.overflow;
const PADDING_PROPS = ['p', 'pb', 'pl', 'pr', 'pt', 'px', 'py'];
const SIZE_PROPS = ['height', 'width'];

/**
 * @description Tests show we don't need to use a Portal to render this. Pure CSS
 * Seems to render the Modals over all the content, with no notable exceptions.
 */
const Modal = ({
  clickBackdropToClose = false,
  children,
  disableEscape = false,
  footer,
  footerCss,
  full,
  fullHeight,
  contentCss,
  headerCss,
  header,
  show,
  onClose,
  fixed,
  lock,
  opacity,
  top,
  showHeaderCloseButton = true,
  ...props
}) => {
  const [height, setHeight] = useState(props?.height);
  const [width, setWidth] = useState(props?.width);

  useKeyHandler({
    Escape: () => {
      if (!disableEscape && onClose) {
        onClose();
      }
    },
  });

  useEffect(() => {
    const {innerHeight, innerWidth} = window;
    const {clientHeight, clientWidth} = document.documentElement;

    if (full) {
      setWidth(Math.max(clientWidth, innerWidth || 0));
    }
    if (full || fullHeight) {
      setHeight(Math.max(clientHeight, innerHeight || 0));
    }
  }, [full, fullHeight]);

  useEffect(() => {
    if (lock && show) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = ORIGNAL_OVERFLOW;
    };
  }, [lock, show]);

  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Cover visible={show} opacity={opacity} onClick={() => onClose()} />
      <Wrapper
        top={top}
        lock={lock}
        fixed={fixed}
        visible={show}
        onClick={() => clickBackdropToClose && onClose()}>
        <Dialog
          width={width}
          height={height}
          onClick={(event) => event.stopPropagation()}
          {...omit([...PADDING_PROPS, ...SIZE_PROPS], props)}>
          {header && (
            <Header css={headerCss}>
              <span>{header}</span>
              {showHeaderCloseButton && (
                <Close onClick={onClose} data-testid="modal-close" />
              )}
            </Header>
          )}
          <Content
            hasHeader={Boolean(header)}
            hasFooter={Boolean(footer)}
            p={16}
            style={contentCss}
            {...pick(PADDING_PROPS, props)}>
            {children}
          </Content>
          {footer && <Footer css={footerCss}>{footer}</Footer>}
        </Dialog>
      </Wrapper>
    </>,
    document.getElementById('root') || document.createElement('div'),
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  clickBackdropToClose: PropTypes.bool,
  disableEscape: PropTypes.bool,
  showHeaderCloseButton: PropTypes.bool,
  footer: PropTypes.node,
  footerCss: PropTypes.object,
  contentCss: PropTypes.object,
  headerCss: PropTypes.object,
  header: PropTypes.node,
  fixed: PropTypes.bool,
  lock: PropTypes.bool,
  show: PropTypes.bool,
  width: PropTypes.any,
  height: PropTypes.any,
  opacity: PropTypes.number,
  top: PropTypes.number,
  onClose: PropTypes.func,
  full: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

export default Modal;
