import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {pick, propOr} from 'ramda';
import {useCurrentTask} from 'modules/project';
import {ManageDocuments} from 'modules/tasks-wizard';

import {
  ButtonGroup,
  Box,
  Card,
  Flex,
  MoneyField,
  SelectField,
} from '@renofi/components-internal';
import {useSubmitAppraisalRecentTransactions} from '@renofi/graphql/src/hooks';
import {basic03, basic15} from '@renofi/theme/src/colors';
import useDebounce from '@renofi/utilities/src/useDebounce';

import {MONTH_OPTIONS, RECENT_TRANSACTIONS_PROPS} from '../../../../constants';

const getDateByMonthIndex = (index) => {
  if (!index) return null;
  const month = MONTH_OPTIONS.indexOf(index);
  const now = new Date();
  const diff = index < now.getMonth() ? 0 : 1;
  const year = now.getFullYear() - diff;

  return new Date(year, month, 10);
};

const RecentOccurrence = ({onChange, taskId}) => {
  const [recentTransaction, setRecentTransaction] = useState(null);
  const {task} = useCurrentTask({taskId});
  const {loading, submitAppraisalRecentTransactions} =
    useSubmitAppraisalRecentTransactions();
  const borrowerTaskDocuments = task?.documents?.filter(
    (d) => d.source === 'borrower',
  );

  const debouncedSubmitTransactions = useDebounce(
    (appraisalRecentTransactions) => {
      submitAppraisalRecentTransactions({
        variables: {appraisalRecentTransactions, taskId},
      });
    },
    500,
    [taskId],
  );

  useEffect(() => {
    setRecentTransaction(propOr(null, 'appraisalRecentTransactions', task));
  }, [task]);

  const occurredRecently = recentTransaction?.occurredRecently;
  const date = recentTransaction?.date;
  const month = date ? new Date(date).getMonth() : -1;

  const onChangeValue = useCallback(
    (key, value, force) => {
      const updated = {...recentTransaction, [key]: value};

      onChange();
      setRecentTransaction(updated);
      const appraisalRecentTransactions = pick(
        RECENT_TRANSACTIONS_PROPS,
        updated,
      );

      if (!force) {
        return debouncedSubmitTransactions(appraisalRecentTransactions);
      }

      submitAppraisalRecentTransactions({
        variables: {
          appraisalRecentTransactions,
          taskId,
        },
      });
    },
    [recentTransaction, taskId],
  );

  if (!occurredRecently) {
    return null;
  }

  return (
    <Card css={{backgroundColor: basic03, border: `1px solid ${basic15}`}}>
      <Flex flexDirection="column">
        <Box width={0.8}>
          <SelectField
            disabled={loading}
            value={MONTH_OPTIONS[month]}
            label="What month did the appraisal occur?"
            leftIcon="calendar"
            placeholder="Select month"
            options={MONTH_OPTIONS}
            onChange={(v) =>
              onChangeValue('date', getDateByMonthIndex(v), true)
            }
          />
        </Box>
        <Box width={0.6}>
          <MoneyField
            value={recentTransaction?.value}
            label="What was the appraised value at the time?"
            placeholder="Enter value"
            onChange={(v) => onChangeValue('value', v, false)}
          />
        </Box>
        <Box width={0.5}>
          <ButtonGroup
            disabled={loading}
            value={recentTransaction?.haveAppraisalFile}
            label="Do you have the appraisal file?"
            onChange={(value) =>
              onChangeValue('haveAppraisalFile', value, true)
            }
          />
        </Box>
      </Flex>
      {recentTransaction?.haveAppraisalFile && (
        <ManageDocuments
          taskId={taskId}
          minimize
          onChange={onChange}
          customDocuments={borrowerTaskDocuments}
        />
      )}
    </Card>
  );
};

RecentOccurrence.propTypes = {
  onChange: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
};

export default RecentOccurrence;
