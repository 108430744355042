import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../useMutation';
import {CONFIRM_PRODUCT_CONFIRMATION_PAGE} from '../../mutations';

export default function useConfirmProductConfirmationPage({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [confirmProductConfirmationPage, {data, loading, error}] = useMutation(
    CONFIRM_PRODUCT_CONFIRMATION_PAGE,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['confirmProductConfirmationPage'], data);
  return {confirmProductConfirmationPage, response, loading, error};
}
