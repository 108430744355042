import styled from '@emotion/styled';
import {Content as Card} from 'modules/layout';

import {Box, Text, Flex} from '@renofi/components-internal';
import {basic55, basic80} from '@renofi/theme/src/colors';
import {maxSmall} from '@renofi/theme/src/breakpoints';

import illustration from './illustration.svg';

const basic = {
  fontSize: 16,
  lineHeight: '22px',
  color: basic55,
};

export const Wrapper = styled(Box)(basic, ({disabled}) => ({
  opacity: disabled ? 0.2 : 1,
}));

export const Label = styled(Text)({
  fontSize: 18,
  lineHeight: '23px',
  color: basic55,
});

export const Value = styled(Text)(({small, thin}) => ({
  fontSize: small ? 32 : 40,
  lineHeight: thin ? '40px' : '44px',
  fontWeight: 'bold',
  color: basic80,
  [maxSmall]: {
    fontSize: 32,
    marginBottom: 16,
  },
  '@media (max-width: 1600px)': {
    fontSize: 32,
  },
}));

export const Details = styled(Box)({
  '& p': {
    marginTop: 0,
  },
});

export const Content = styled(Card)(
  {
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('${illustration}')`,
    backgroundSize: '337px 117px',
    backgroundPosition: 'right 75px center',
    [maxSmall]: {
      backgroundImage: 'unset',
    },
  },
  ({hideBackgroundImage}) => ({
    backgroundImage: hideBackgroundImage ? 'none' : `url('${illustration}')`,
  }),
);

export const StyledFlex = styled(Flex)({
  justifyContent: 'space-between',

  [maxSmall]: {
    flexDirection: 'column',
    '& > div': {
      marginLeft: '0',
      width: '100%',
    },
  },
});

export const StyledBox = styled(Box)({
  [maxSmall]: {
    width: '100%',
  },
});

export const StyledTitle = styled.div({
  marginBottom: '32px',
  [maxSmall]: {
    marginBottom: '16px',
  },
});
