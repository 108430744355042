import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';
import {TASK_CATEGORIES, useCurrentTask} from 'modules/project';
import {ManageDocuments} from 'modules/tasks-wizard';

import {Box, Text} from '@renofi/components-internal';
import {basic80, basic55} from '@renofi/theme/src/colors';

const UploadDocumentWizards = ({onChange, taskId}) => {
  const {task} = useCurrentTask({taskId, facet: TASK_CATEGORIES.ELIGIBILITY});
  const title = propOr('Upload documents', 'title', task);
  const details = propOr('Please upload documents task', 'details', task);

  return (
    <Fragment>
      <Box mb={4}>
        <Text mb={21} fontSize={24} color={basic80}>
          {title}
        </Text>
        <Text fontSize={16} color={basic55}>
          {details}
        </Text>
      </Box>
      <ManageDocuments
        facet={TASK_CATEGORIES.ELIGIBILITY}
        onChange={onChange}
        taskId={taskId}
      />
    </Fragment>
  );
};

UploadDocumentWizards.propTypes = {
  onChange: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
};

export default UploadDocumentWizards;
