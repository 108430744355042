import styled from '@emotion/styled';

import {Flex, Text} from '@renofi/components-internal';
import {basic80} from '@renofi/theme/src';

export const Content = styled(Flex)({
  padding: '24px 20px 24px 22px',
  flexDirection: 'column',
  minHeight: 490,
});

export const Title = styled(Text)({
  fontSize: 24,
  lineHeight: '29px',
  color: basic80,
});
