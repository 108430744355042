export const facetsEnum = {
  additional_documents: 'additional_documents',
  appraisal: 'appraisal',
  borrowers_information: 'borrower_information',
  contractor: 'contractor',
  eligibility: 'eligibility',
  insurance: 'insurance',
  product_confirmation: 'product_confirmation',
  renovation: 'renovation',
};

export default facetsEnum;
