import React from 'react';

import PropTypes from 'prop-types';

import {Container, Content, Title} from './styled';

const TextBlock = ({children, title, ...props}) => (
  <Container {...props}>
    {title && <Title>{title}</Title>}
    {Boolean(children) && <Content>{children}</Content>}
  </Container>
);

TextBlock.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
};

export default TextBlock;
