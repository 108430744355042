import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme/src/breakpoints';

export const StyledHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
  [maxSmall]: {
    marginTop: '0px',
  },
});

export const StyledSignOut = styled.div({
  position: 'absolute',
  bottom: 0,
  padding: '20px 22px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  columnGap: '20px',
  width: '100%',
  color: '#212f498c',
  transition: 'all 150ms linear',
  svg: {
    transition: 'color 150ms linear',
    color: '#d3d5db',
  },
  '&:hover': {
    transition: 'all 150ms linear',
    background: '#F5F6F7',
    borderRadius: '8px',
    color: '#212f49',
    svg: {
      '.first': {
        transition: 'color 150ms linear',
        color: '#212F49',
      },
      '.second': {
        transition: 'color 150ms linear',
        color: '#FF5253',
      },
    },
  },
  [maxSmall]: {
    width: '100%',
    marginBottom: 'unset',
    borderTop: '1px solid rgba(0, 96, 153, 0.15)',
    background: '#fff',
    '&:hover': {
      borderRadius: 'unset',
    },
    color: '#212F49',
    svg: {
      '.first': {
        color: '#212F49',
      },
      '.second': {
        color: '#FF5253',
      },
    },
  },
});

export const StyledContainer = styled.div({
  height: 'calc(100% - 140px)',
  overflow: 'auto',
  [maxSmall]: {
    borderRight: '1px solid ',
    marginTop: '73px',
  },
});

export const SignOutIcon = styled.span({
  display: 'flex',
});

export const SignOutLabel = styled.span({
  fontWeight: '400',
  fontSize: '18px',
  [maxSmall]: {
    fontSize: '24px',
  },
});
