import React, {useContext} from 'react';

import PropTypes from 'prop-types';
import lazy from 'lib/asyncComponent';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import {TasksWizardContext} from 'modules/tasks-wizard';
import {TASK_CATEGORIES} from 'modules/project';

import {useToggledBorrowerProject} from '@renofi/graphql';
import {Loader} from '@renofi/components-internal';

const Wizard = lazy(
  () => import(/* webpackChunkName: "Wizard" */ './components/Wizard'),
);

const EmploymentHistoryWizard = ({onClose, task}) => {
  const facet = TASK_CATEGORIES.ELIGIBILITY;
  const {project} = useToggledBorrowerProject();
  const {loading} = useContext(ProjectTasksContext);
  const projectId = project?.id;

  if (loading) {
    return <Loader label={false} />;
  }

  return (
    <TasksWizardContext.Consumer>
      {({dirty, setDirty}) => {
        const props = {
          facet,
          task,
          taskId: task?.id,
          projectId,
          onClose,
          dirty,
          onChange: () => setDirty(true),
        };

        return task && <Wizard {...props} />;
      }}
    </TasksWizardContext.Consumer>
  );
};

EmploymentHistoryWizard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EmploymentHistoryWizard;
