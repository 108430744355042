import styled from '@emotion/styled';

import {Flex, Link} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';

export const Photo = styled.img({
  height: 56,
  borderRadius: 6,
  margin: 0,
  padding: 0,
});

export const PhotoLink = styled(Link)({
  height: 60,
  display: 'flex',
  alignItems: 'center',
  marginRight: 24,
  padding: 0,
  position: 'relative',
});

export const Placeholder = styled(Flex)({
  alignItems: 'center',
  justifyContent: 'center',
  height: 60,
  width: 90,
  marginRight: 24,
});

export const Remove = styled(Flex)({
  alignItems: 'center',
  justifyContent: 'center',
  height: 28,
  width: 28,
  position: 'absolute',
  top: -14,
  right: -14,
  backgroundColor: basic55,
  borderRadius: '50%',
  cursor: 'pointer',
});
