import React from 'react';

import PropTypes from 'prop-types';
import {isNil, mergeRight} from 'ramda';

import {isZipCode} from '@renofi/utilities/src/validators';
import {getStateValueByName} from '@renofi/utilities/src/states';
import noop from '@renofi/utilities/src/noop';

import Flex from '../Flex';
import TextField from '../TextField';
import Box from '../Box';
import StatesField from '../StatesField';
import CountriesField from '../CountriesField/Component';

const DEFAULT_ADDRESS_LABELS = {
  city: 'City',
  country: 'Country',
  state: 'State',
  streetAddressOne: 'Street address',
  streetAddressTwo: 'Street address line 2',
  zipCode: 'Zip code',
};

const getErrorMessage = (key, fields) => {
  return fields.includes(key) ? 'This field is required' : null;
};

const FullAddressForm = ({
  analytics = {},
  data = {},
  defaultCountry = 'US',
  disabled = false,
  enableStateCodeLabels = true,
  errors = [],
  onChange = noop,
  showCountry = false,
  showStreetAddressTwo = true,
  small = false,
  streetAddressKey = 'streetAddressOne',
  ...props
}) => {
  const {city, country, streetAddressTwo, state, zipCode} = data || {};
  const streetAddress = (data && data[streetAddressKey]) || '';
  const labels = mergeRight(DEFAULT_ADDRESS_LABELS, props?.labels || {});
  const statesDisabled =
    disabled || (showCountry && !isNil(country) && country !== 'US');

  return (
    <Flex width="100%" flexDirection="column" {...props}>
      <TextField
        {...analytics}
        active
        disabled={disabled}
        error={getErrorMessage(streetAddressKey, errors)}
        name="streetAddressOne"
        label={labels?.streetAddressOne}
        onChange={(value) => onChange(streetAddressKey, value)}
        small={small}
        value={streetAddress}
      />
      {showStreetAddressTwo && (
        <TextField
          {...analytics}
          active
          disabled={disabled}
          error={getErrorMessage('streetAddressTwo', errors)}
          name="streetAddressTwo"
          label={labels?.streetAddressTwo}
          onChange={(value) => onChange('streetAddressTwo', value)}
          small={small}
          value={streetAddressTwo}
        />
      )}
      <Flex alignItems="center">
        <Box pr={3} width={0.75}>
          <TextField
            {...analytics}
            active
            disabled={disabled}
            error={getErrorMessage('city', errors)}
            name="city"
            label={labels?.city}
            onChange={(value) => onChange('city', value)}
            small={small}
            value={city}
          />
        </Box>
        <Box width={0.35}>
          <StatesField
            {...analytics}
            active
            disabled={statesDisabled}
            enableStateCodeLabels={enableStateCodeLabels}
            error={getErrorMessage('state', errors)}
            label={labels?.state}
            onChange={(value) => onChange('state', value)}
            searchable
            small={small}
            value={getStateValueByName(state)}
          />
        </Box>
      </Flex>
      <Flex alignItems="center" width={1}>
        <Box width={0.3}>
          <TextField
            {...analytics}
            active
            disabled={disabled}
            error={zipCode && !isZipCode(zipCode) && 'Invalid zip code'}
            label={labels?.zipCode}
            name="zipCode"
            value={zipCode || ''}
            small={small}
            onChange={(value) => onChange('zipCode', value)}
          />
        </Box>
        {showCountry && (
          <Box width={0.6} pl={3}>
            <CountriesField
              {...analytics}
              disabled={disabled}
              error={getErrorMessage('country', errors)}
              label={labels?.country}
              placeholder="Select country"
              small={small}
              value={country || defaultCountry}
              onChange={(value) => onChange('country', value)}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

FullAddressForm.propTypes = {
  analytics: PropTypes.object,
  data: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    streetAddressOne: PropTypes.string,
    streetAddressTwo: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  defaultCountry: PropTypes.string,
  disabled: PropTypes.bool,
  enableStateCodeLabels: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  labels: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    streetAddressOne: PropTypes.string,
    streetAddressTwo: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  showCountry: PropTypes.bool,
  showStreetAddressTwo: PropTypes.bool,
  small: PropTypes.bool,
  streetAddressKey: PropTypes.string,
};

export default FullAddressForm;
