import React from 'react';

import PropTypes from 'prop-types';
import {TaskItem} from 'modules/layout';

import AddEditTaskButton from '../../AddEditTaskButton';
import {TASK_TYPES} from '../constants';

import RecentTransactionsWizard from './RecentTransactionsWizard';
import RecentTransactionsTask from './RecentTransactionsTask';

const AppraisalTask = ({task}) => {
  const {facet, id, taskType} = task;
  const isTransactionsTask =
    taskType === TASK_TYPES.APPRAISAL_RECENT_TRANSACTIONS;

  return (
    <TaskItem
      button={
        isTransactionsTask ? (
          <AddEditTaskButton
            facet={facet}
            taskId={id}
            wizard={RecentTransactionsWizard}
          />
        ) : null
      }
      details={
        isTransactionsTask
          ? 'Previous appraisals within the last 12 months can provide valuable information for the Appraiser and can help speed up the process.'
          : null
      }
      task={task}>
      {isTransactionsTask && <RecentTransactionsTask taskId={id} />}
    </TaskItem>
  );
};

AppraisalTask.propTypes = {
  task: PropTypes.shape({
    facet: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    taskType: PropTypes.string.isRequired,
  }),
};

export default AppraisalTask;
