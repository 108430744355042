import React from 'react';

import PropTypes from 'prop-types';
import {ItemWrapper, ListItem, ListItemTitle} from 'modules/layout';
import {DocumentList} from 'modules/project/components/Task';

import {Box} from '@renofi/components-internal';
import formatMoney from '@renofi/utilities/src/formatMoney';
import {
  propertyStatusTypes,
  currentOccupancyTypes,
  realEstateOwnedPropertyTypes,
} from '@renofi/utilities/src/enumTypes';
import {getPropertyFullAddress} from '@renofi/utilities/src/getPropertyFullAddress';

import {findPropertyDocuments} from '../../RealEstateWizard/utils';

const AdditionalPropertiesItems = ({
  additionalProperties = [],
  onClick,
  task,
  borrowerName,
  coborrowerName,
}) => {
  return additionalProperties.map((property) => {
    const {documents} = findPropertyDocuments(property?.documentIds, task);

    return (
      <Box mt={4} key={property.id}>
        <ListItemTitle
          title={getPropertyFullAddress(property)}
          subtitle="Additional property"
          wrapItems={false}
        />
        <ItemWrapper>
          <ListItem title="Outstanding mortgage">
            {property.existingMortgageLoan ? 'Yes' : 'No'}
          </ListItem>
          <ListItem title="Taxes and insurance">
            {property.taxAndInsuranceIncluded ? 'Yes' : 'No'}
          </ListItem>
          <ListItem title="Homeowners association fee included">
            {property.homeownersAssociationFeesIncluded ? 'Yes' : 'No'}
          </ListItem>
          <ListItem title="Property type">
            {realEstateOwnedPropertyTypes[property.propertyType]}
          </ListItem>
          <ListItem title="Current occupancy">
            {currentOccupancyTypes[property.currentOccupancy]}
          </ListItem>
          <ListItem title="Status of property">
            {propertyStatusTypes[property.propertyStatus]}
          </ListItem>
          {property.rentalIncome ? (
            <ListItem title="Rental income">
              {formatMoney(property.rentalIncome, {
                fractional: false,
              })}{' '}
              / month
            </ListItem>
          ) : null}
          <ListItem title="Current market value">
            {formatMoney(property.marketValue, {
              fractional: false,
            })}
          </ListItem>
          <ListItem
            title="Year purchased"
            skipComma={!property.coborrowerOwned}>
            {property.yearPurchased}
          </ListItem>

          {property.coborrowerOwned ? (
            <ListItem title="Ownership" skipComma>
              {property.primaryBorrowerOwned ? `${borrowerName}, ` : ''}
              {coborrowerName}
            </ListItem>
          ) : null}
        </ItemWrapper>

        <DocumentList documents={documents} onClick={onClick} />
      </Box>
    );
  });
};

AdditionalPropertiesItems.propTypes = {
  additionalProperties: PropTypes.array,
  task: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  borrowerName: PropTypes.string,
  coborrowerName: PropTypes.string,
};

export default AdditionalPropertiesItems;
