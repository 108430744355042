import {v4 as uuid} from 'uuid';
import {GET_APPLICATION_STATE} from 'api/queries';

import {
  GET_PROJECT_SUMMARY,
  GET_PROPERTY_DETAILS,
  GET_PROPERTY_PHOTOS,
  GET_RENOVATION_DETAILS,
  GET_RENOVATION_UPDATE,
} from '@renofi/graphql/src/queries';

export default ({client, storage}) => {
  const completed = storage.getItem('review:completed');
  client.writeQuery({
    query: GET_APPLICATION_STATE,
    data: {
      application: {
        __typename: 'Application',
        rejection: {
          __typename: 'Rejection',
          documentId: null,
          taskId: null,
        },
        additionalRequest: {
          __typename: 'AdditionalRequest',
          taskId: null,
        },
        review: {
          __typename: 'Review',
          completed: completed ? new Date(completed) : null,
        },
      },
    },
  });

  client.writeQuery({
    query: GET_PROPERTY_DETAILS,
    data: {
      propertyDetails: {
        __typename: 'PropertyDetails',
        id: uuid(),
        additionalInformation: null,
        additionalInformationPresent: null,
        basementSquareFootage: null,
        basementState: null,
        bedroomsInBasement: null,
        bedroomsTotal: null,
        city: null,
        county: null,
        coolingTypes: null,
        fireplaceTypes: null,
        fullBathroomsInBasement: null,
        fullBathroomsTotal: null,
        garagesAttached: null,
        garagesDetached: null,
        halfBathroomsInBasement: null,
        halfBathroomsTotal: null,
        heatingTypes: null,
        lat: null,
        lng: null,
        mapPhotoUrl: null,
        noteForAppraiser: null,
        noteForAppraiserPresent: null,
        previousRenovationsDescription: null,
        previousRenovationsDescriptionPresent: null,
        propertyType: null,
        propertyTypeOther: null,
        rentalIncome: null,
        rentalIncomeCollectedOnPrimaryResidence: null,
        state: null,
        streetAddressOne: null,
        streetAddressTwo: null,
        streetViewPhotoUrl: null,
        totalSquareFootage: null,
        yearBuilt: null,
        yearPurchased: null,
        zipCode: null,
      },
    },
  });

  client.writeQuery({
    query: GET_PROPERTY_PHOTOS,
    data: {
      propertyPhotos: [],
    },
  });

  client.writeQuery({
    query: GET_RENOVATION_DETAILS,
    data: {
      renovationDetails: [],
    },
  });

  client.writeQuery({
    query: GET_PROJECT_SUMMARY,
    data: {
      projectSummary: {
        tasks: {
          appraisal: [],
          contractor: [],
          eligibility: [],
          insurance: [],
          renovation: [],
          product: [],
        },
      },
    },
  });

  client.writeQuery({
    query: GET_RENOVATION_UPDATE,
    data: {
      renovationUpdate: {
        action: null,
        kind: null,
        data: {
          id: null,
          date: null,
          details: '',
          files: [],
          __typename: 'RenovationUpdateData',
        },
        __typename: 'RenovationUpdateModal',
      },
    },
  });
};
