import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {ManageDocuments} from 'modules/tasks-wizard';

import {Box, Text} from '@renofi/components-internal';

const InsuranceTaskWizard = ({onChange, taskId}) => {
  return (
    <Fragment>
      <Box mb={4}>
        <Text fontSize={18}>
          Please upload proof of your homeowners insurance
        </Text>
      </Box>

      <ManageDocuments onChange={onChange} taskId={taskId} />
    </Fragment>
  );
};

InsuranceTaskWizard.propTypes = {
  onChange: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
};

export default InsuranceTaskWizard;
