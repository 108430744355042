export const SUBJECT_PROPERTY_INPUT_PROPS = [
  'annualInsurancePremium',
  'annualMortgageTaxes',
  'annualPrivateMortgageInsurance',
  'city',
  'coborrowerOwned',
  'county',
  'currentOccupancy',
  'existingMortgageLoan',
  'homeownersAssociationFee',
  'homeownersAssociationFeesExist',
  'homeownersAssociationFeesIncluded',
  'id',
  'mortgageAccountNumber',
  'mortgageBalance',
  'mortgageBalanceSecondLien',
  'mortgageBalanceThirdLien',
  'mortgageCompany',
  'mortgageCompanyOther',
  'mortgageInterestRate',
  'mortgageMonthlyPayment',
  'mortgageStatementDate',
  'primaryBorrowerOwned',
  'propertyType',
  'state',
  'streetAddressOne',
  'streetAddressTwo',
  'taxAndInsuranceIncluded',
  'yearPurchased',
  'zipCode',
];
