export default `
authorId
author {
  id
  email
  firstName
  lastName
  fullName
}
body
pinned
createdAt
editable
id
mentions {
  label
  userEmail
  userId
  userName
}
project {
  id
  primaryApplicant {
    fullName
  }
  coborrower {
    fullName
  }
}
projectId
salesforceAuthorEmail
salesforceAuthorId
salesforceContentDocumentId
salesforceOpportunityId
title
updatedAt
`;
