const PRIMARY_BORROWER_ID = 11;
const CO_BORROWER_ID = 22;

export const TAGS = [
  {
    value: 'select_ID',
    label: 'Select your ID type',
    items: [
      {id: 1, label: 'Driver’s license', value: 'drivers_license', icon: 'car'},
      {id: 2, label: 'Passport', value: 'passport', icon: 'flight'},
      {id: 3, label: 'State ID', value: 'state_id', icon: 'pin'},
    ],
  },
];

export const GOV_ID_TYPES = {
  DRIVER_ID: 1,
  PASSPORT: 2,
  STATE_ID: 3,
};

export const INITIAL_STEPS = [
  {id: 1, value: 'ID type', current: true},
  {id: 2, value: 'ID details'},
  {id: 3, value: 'Upload ID'},
];

export const INITIAL_GROUPED_STEPS = [
  {
    id: PRIMARY_BORROWER_ID,
    value: 'Primary borrower',
    current: true,
    flat: false,
    children: [
      {id: 1, value: 'ID type', current: true},
      {id: 2, value: 'ID details'},
      {id: 3, value: 'Upload ID'},
    ],
  },
  {
    id: CO_BORROWER_ID,
    value: 'Co-borrower',
    flat: false,
    children: [
      {id: 4, value: 'ID type'},
      {id: 5, value: 'ID details'},
      {id: 6, value: 'Upload ID'},
    ],
  },
];

export const STEP_TYPES = {
  ID_TYPE: 1,
  ID_DETAILS: 2,
  UPLOAD_ID: 3,
};

export const CO_BORROWER_STEP_TYPES = {
  ID_TYPE: 1,
  ID_DETAILS: 2,
  UPLOAD_ID: 3,
  CO_ID_TYPE: 4,
  CO_ID_DETAILS: 5,
  CO_UPLOAD_ID: 6,
};

export const INITIAL_ID_DETAILS = {
  idType: '',
  idNumber: '',
  country: 'US',
  state: '',
  issueDate: '',
  expDate: '',
  borrowerRole: 'borrower',
  documentIds: [],
};

export const CO_BORROWER_INIT_DETAILS = {
  idType: '',
  idNumber: '',
  country: 'US',
  state: '',
  issueDate: '',
  expDate: '',
  borrowerRole: 'coborrower',
  documentIds: [],
};

export const BORROWERS_TYPES = {
  PRIMARY_BORROWER: 'borrower',
  CO_BORROWER: 'coborrower',
};

export const GROUPED_STEPS = {
  PRIMARY_BORROWER_STEP: PRIMARY_BORROWER_ID,
  CO_BORROWER_STEP: CO_BORROWER_ID,
};

export const SEARCH_PLACEHOLDER = 'Search or choose from the list below';

export const GOV_ID_EVENTS = {
  BORROWER_DOC_UPLOAD: 'Task-Gov-Doc-Uploaded',
  BORROWER_FORM_SUBMIT: 'Task-Gov-Form-Submitted',
  CO_BORROWER_DOC_UPLOAD: 'Task-Gov-Coborrower-Doc-Uploaded',
  CO_BORROWER_FORM_SUBMIT: 'Task-Gov-Coborrower-Form-Submitted',
};

export const WIZARD_ID_TYPES = [
  {id: PRIMARY_BORROWER_ID, type: 'borrower'},
  {id: CO_BORROWER_ID, type: 'coBorrower'},
];

export const SUCCESS_NOTIFICATION = {
  variant: 'success',
  content: 'ID successfully uploaded',
  type: 'snackbar',
};

export const FAILED_NOTIFICATION = {
  variant: 'danger',
  content: 'Failed to submit the government Id details',
  type: 'snackbar',
};

export const FINISHED_EVENT = {
  name: 'Gov',
  type: 'Eligibility',
};
