import React from 'react';

import PropTypes from 'prop-types';
import {isNil} from 'ramda';

import {Text} from '@renofi/components-internal';
import formatInterestRate from '@renofi/utilities/src/product-confirmation/formatInterestRate';
import formatMoney from '@renofi/utilities/src/formatMoney';
import {basic80} from '@renofi/theme';

import {getOrdinalSuffixForNumber} from '../../utils';
import {
  Card,
  Label,
  Value,
  ItemsWrapper,
  ItemLeft,
  ItemRight,
} from '../../styled';

const MONEY_PARAMS = {fractional: false, defaultValue: 'None'};

const LoanDetails = ({lenderName, productDetailsConfirmation}) => {
  const {
    loanType,
    renovationAmount,
    loanAmount,
    interestRate,
    interestRateType,
    applicants,
    lienPosition,
  } = productDetailsConfirmation || {};
  const hasAppraisedValue = Boolean(productDetailsConfirmation?.appraisedValue);

  const applicantDetails = applicants?.length
    ? applicants?.map((value) => value).join(', ')
    : '-';
  const formattedRenovationAmount = formatMoney(renovationAmount, MONEY_PARAMS);
  const formattedLoanAmount = formatMoney(loanAmount, MONEY_PARAMS);
  const formattedRate = formatInterestRate(interestRate, interestRateType);
  const hasLienPosition = !isNil(lienPosition);
  const getLienPositionWithSuffix = (lienPosition) => {
    const lienPositionWithSuffix = `${getOrdinalSuffixForNumber(
      lienPosition,
    )} mortgage`;

    return lienPositionWithSuffix;
  };
  const formattedLienPosition = hasLienPosition
    ? getLienPositionWithSuffix(lienPosition)
    : '-';

  return (
    <Card>
      <Text fontSize={14} mb={1} color={basic80} fontWeight="bold">
        Loan details
      </Text>
      <ItemsWrapper>
        <ItemLeft>
          <Label>Loan Type:</Label>
          <Value>{loanType}</Value>
        </ItemLeft>
        <ItemRight>
          <Label>Loan amount:</Label>
          <Value>{formattedLoanAmount}</Value>
        </ItemRight>
      </ItemsWrapper>

      <ItemsWrapper>
        <ItemLeft>
          <Label>Lender:</Label>
          <Value>{lenderName || '-'}</Value>
        </ItemLeft>
        <ItemRight>
          <Label>Applicants:</Label>
          <Value>{applicantDetails}</Value>
        </ItemRight>
      </ItemsWrapper>

      <ItemsWrapper>
        <ItemLeft>
          <Label>Interest Rate (as low as):</Label>
          <Value>{formattedRate}</Value>
          {!hasAppraisedValue && <Label>(Estimated)</Label>}
        </ItemLeft>
        {hasLienPosition && (
          <ItemRight>
            <Label>Lien position:</Label>
            <Value>{formattedLienPosition}</Value>
          </ItemRight>
        )}
      </ItemsWrapper>

      <ItemsWrapper>
        <ItemLeft>
          <Label>Renovation amount:</Label>
          <Value>{formattedRenovationAmount}</Value>
        </ItemLeft>
      </ItemsWrapper>
    </Card>
  );
};

LoanDetails.propTypes = {
  productDetailsConfirmation: PropTypes.shape({
    appraisedValue: PropTypes.number,
    applicants: PropTypes.array,
    loanType: PropTypes.string,
    interestRate: PropTypes.number,
    lienPosition: PropTypes.number,
    renovationAmount: PropTypes.number,
    loanAmount: PropTypes.number,
  }),
  lenderName: PropTypes.string.isRequired,
};

export default LoanDetails;
