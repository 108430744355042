import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';

import {AddressField, Text} from '@renofi/components-internal';
import {isMobile} from '@renofi/theme/src/breakpoints';
import {sendEvent} from '@renofi/utilities/src/analytics/utils';
import FullAddressForm from '@renofi/components-internal/src/FullAddressForm';
import {useToggledBorrowerProject} from '@renofi/graphql';

import {transformAddressToDbSchema} from '../utils';

import {getPropertyDetails} from './utils';
import BaseContent from './BaseContent';

const Address = ({info, update}) => {
  const [value, setValue] = useState();
  const [showFullForm, setShowFullForm] = useState(false);
  const details = getPropertyDetails(info);
  const [manualAddress, setManualAddress] = useState({});
  const {project} = useToggledBorrowerProject();
  const content = project?.loanTypeEnum?.isCompBid
    ? 'What is the subject property address?'
    : 'What address is the renovation taking place?';

  useEffect(() => {
    sendEvent('Secure/Onboarding-Property-Input-Presented');
  }, []);

  const handleSelect = (place) => {
    const parsed = transformAddressToDbSchema(place);

    update(parsed);
    setValue(getPropertyDetails(parsed));
  };

  function onAddressChange(label, value) {
    const newValue = {...manualAddress, [label]: value};
    setManualAddress(newValue);
    const parsed = transformAddressToDbSchema({
      ...newValue,
      address: newValue.streetAddressOne,
      secondAddress: newValue.streetAddressTwo,
    });
    update(parsed);
  }

  return (
    <BaseContent
      mainContent={content}
      sideContent={
        <>
          <Text mb={40} height={24} lineHeight="24px" textAlign="center">
            {details}
          </Text>
          {showFullForm ? (
            <FullAddressForm onChange={onAddressChange} data={manualAddress} />
          ) : (
            <AddressField
              onManual={() => setShowFullForm(true)}
              active
              name="address"
              label="Enter and search the property address"
              placeholder=""
              onSelect={handleSelect}
              value={value}
            />
          )}
        </>
      }
      sideContentCss={{width: isMobile() ? '100%' : '50%'}}
      withButtons={false}
    />
  );
};

Address.propTypes = {
  setPlace: PropTypes.func,
  onSelectPlace: PropTypes.func,
  onAddressValueChange: PropTypes.func,
  update: PropTypes.func,
  info: PropTypes.shape({
    propertyCity: PropTypes.string,
    propertyState: PropTypes.string,
    propertyStreetAddressOne: PropTypes.string,
    propertyStreetAddressTwo: PropTypes.string,
    propertyZipCode: PropTypes.string,
  }),
};

export default Address;
