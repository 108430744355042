import * as Sentry from '@sentry/react';

import {IGNORE_ERRORS} from './const';

const APP_ENV = process.env.REACT_APP_ENV;
export default function initSentry() {
  if (APP_ENV !== 'production') return;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_COMMIT_REF,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          'https://api-gateway.renofi.com/graphql',
          /^https:\/\/api-gateway.renofi\.com\/graphql/,
        ],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api-gateway.renofi\.com\/graphql/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: IGNORE_ERRORS,
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
  });
}

export function captureException(...args) {
  Sentry.captureException(...args);
}
