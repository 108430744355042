import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';

import {Flex, Text} from '@renofi/components-internal';
import {actionLight, border} from '@renofi/theme/src/colors';

const bounceOnFilesPicked = keyframes`
  10% {
    transform: translate3d(0, 10px, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -50px, 0);
    opacity: 0;
  }
`;

const hoverBounce = keyframes`
  50% {
    transform: translate3d(0px, 5px, 0);
  }
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
`;

export const DropZoneWrapper = styled(Flex)(({disabled, minimize}) => ({
  width: '100%',
  minHeight: minimize ? 'auto' : 350,
  flexDirection: minimize ? 'row' : 'column',
  flex: minimize ? 0 : 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  border: `2px dashed ${border}`,
  borderRadius: 8,
  transition: 'all 300ms linear',
  marginBottom: 16,
  padding: 16,
  cursor: disabled ? 'none' : 'pointer',
  opacity: disabled ? 0.6 : 1,

  ':hover': {
    borderColor: actionLight,
    backgroundColor: 'rgba(229, 245, 255, 0.6)',
  },
}));

export const Arrow = styled.img(({minimize, hover}) => {
  return {
    position: 'absolute',
    bottom: minimize ? -2 : 0,
    left: minimize ? 24 : 22,
    animation: hover ? `${hoverBounce} 800ms infinite ease-in-out` : 'none',
    animationFillMode: 'both',
  };
});

export const IconWrapper = styled(Flex)(({dropped, minimize}) => ({
  width: 64,
  height: 48,
  marginBottom: minimize ? 0 : 15,
  position: 'relative',
  zoom: minimize ? 0.5 : 1,
  animation: dropped ? `${bounceOnFilesPicked} 500ms ease-in-out` : 'none',
  animationFillMode: 'forwards',
}));

export const TextPrompt = styled(Text)(({minimize}) => ({
  transition: 'font-size 150ms ease-in-out',
  fontSize: minimize ? 14 : 18,
}));
