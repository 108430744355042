import React from 'react';

import {assoc, propOr} from 'ramda';
import {snakeCase} from 'change-case';
import {RENOFI_CONTACT_EMAIL, SCHEDULE_URL} from 'config';

import {useToggledBorrowerProject, useGetUser} from '@renofi/graphql/src/hooks';
import {basic55, basic80, dangerRegular} from '@renofi/theme/src/colors';
import Card from '@renofi/components-internal/src/next/Card';
import {isMobile} from '@renofi/theme/src/breakpoints';
import {Box, Flex, Text} from '@renofi/components-internal';
import RenofiIcon from '@renofi/icons/src/Renofi';
import {openTab} from '@renofi/utilities/src/window';
import queryString from '@renofi/utilities/src/queryString';

import {
  Button,
  Buttons,
  Content,
  Email,
  Headshot,
  IconWrapper,
  Image,
  Title,
} from './styled';
import emailIcon from './images/email.svg';
import phone from './images/phone.svg';
import {
  aliciaKnox,
  brockAntolini,
  chanelMccray,
  connorGarr,
  francieFranks,
  madelynSchmidt,
  matthewWindham,
  sofiaDamico,
  williamWorsley,
} from './images/headshots';
import {findOwner, parseImageName, getOwnerPriorities} from './utils';

const USER_KEYS = ['firstName', 'lastName', 'email'];
const HEADSHOTS = {
  aliciaKnox,
  brockAntolini,
  chanelMccray,
  connorGarr,
  francieFranks,
  madelynSchmidt,
  matthewWindham,
  sofiaDamico,
  williamWorsley,
};
const Help = () => {
  const {user} = useGetUser();
  const {project} = useToggledBorrowerProject();
  const isRaas = propOr(false, 'raas', project);

  const ownerPriorities = getOwnerPriorities({
    project,
    defaultEmail: RENOFI_CONTACT_EMAIL,
    isRaas,
  });
  const owner = findOwner(ownerPriorities);
  const ownerEmail = owner?.emailToCase || owner?.email;

  const fileName = owner.name && parseImageName(owner);
  const headshot = propOr(null, fileName, HEADSHOTS);

  const onScheduleCall = () => {
    const params = USER_KEYS.reduce(
      (obj, key) => assoc(snakeCase(key), user[key], obj),
      {
        phone: user.phoneNumber,
        router: 'ownership-direct-booking-link',
        utm_medium: 'internal',
        utm_source: 'secure',
        utm_campaign: 'Schedule Call Footer',
      },
    );
    const qString = queryString.stringify(params);

    openTab(`${SCHEDULE_URL}?${qString}`);
  };

  return (
    <Card {...(isMobile() ? {p: 16} : {})}>
      <Title>Got questions?</Title>
      <Content>
        <Flex
          {...(isMobile() ? {mb: '24px'} : {})}
          alignItems="center"
          flexGrow={1}>
          <IconWrapper alignItems="center" justifyContent="center">
            {headshot ? (
              <Headshot src={headshot} />
            ) : (
              <RenofiIcon color={dangerRegular} width={32} />
            )}
          </IconWrapper>
          <Box ml={16}>
            <Text fontSize={16} lineHeight="20px" color={basic55}>
              {isRaas || !owner.name
                ? 'Contact the RenoFi team'
                : 'Contact your dedicated RenoFi Advisor today.'}
            </Text>
            <Text fontSize={18} lineHeight="22px" color={basic80}>
              {owner?.name}
            </Text>
          </Box>
        </Flex>
        <Buttons>
          <Email blank href={`mailto:${ownerEmail}`}>
            <Image src={emailIcon} />
            <Text>Email</Text>
          </Email>
          {!isRaas && (
            <Button onClick={onScheduleCall}>
              <Image src={phone} />
              <Text>Schedule a call</Text>
            </Button>
          )}
        </Buttons>
      </Content>
    </Card>
  );
};

export default Help;
