import {flatten, isNil} from 'lodash';

import capitalize from '@renofi/utilities/src/capitalize';

const EMPLOYMENT_INCOME_TYPES = [
  'grossMonthlyIncomeBase',
  'grossMonthlyIncomeBonus',
  'grossMonthlyIncomeCommission',
  'grossMonthlyIncomeMilitaryEntitlements',
  'grossMonthlyIncomeOther',
  'grossMonthlyIncomeOvertime',
];

export const getAllHistoryDocumentIds = ({employmentHistories = []} = {}) =>
  flatten(employmentHistories.map((e) => e.documents.map((d) => d.id)));

export const getHistory =
  (coborrower = false) =>
  (history) =>
    Boolean(history.coborrowerEmploymentHistory) === coborrower;

export const sumAllEmploymentHistoryIncomes = (
  items = [],
  adjusted = false,
) => {
  return items.reduce((t, e) => t + sumAllIncomes(e, adjusted), 0);
};

export const sumAllIncomes = (employmentHistory = {}, adjusted = false) => {
  return EMPLOYMENT_INCOME_TYPES.reduce((total, key) => {
    const finalKey = adjusted ? `adjusted${capitalize(key)}` : key;
    const rawGrossValue = employmentHistory[key] || 0;
    const finalValue = employmentHistory[finalKey];

    return (
      total +
      (Boolean(adjusted && !isNil(finalValue)) ? finalValue : rawGrossValue)
    );
  }, 0);
};
