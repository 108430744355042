import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {TASK_CATEGORIES} from 'modules/project/components/Project/constants';
import {ManageDocuments} from 'modules/tasks-wizard';

import {useSubmitProjectDocument} from '@renofi/graphql';
import {Alert, Box, Text} from '@renofi/components-internal';
import {getDocumentTypeByAssetType} from '@renofi/modules-internal';

import {Content} from '../styled';
import StatementsTitle from '../components/StatementsTitle';

const CoBorrowerStatements = ({
  assetType,
  task,
  borrowerRole,
  borrowerName,
  formData = [],
  onChange,
}) => {
  const {submitProjectDocument} = useSubmitProjectDocument();
  const documentType = getDocumentTypeByAssetType(assetType);

  const assetData = useMemo(
    () =>
      formData.filter(
        (d) => d.assetType === assetType && d.borrowerRole === borrowerRole,
      ),
    [assetType, borrowerRole, JSON.stringify(formData)],
  );

  const jointAccount = useMemo(() => {
    return formData.find((detail) => {
      return (
        detail.assetType === assetType &&
        detail.borrowerRole === 'borrower' &&
        detail.jointAccount
      );
    });
  }, [formData, assetType]);

  const showAlert = useMemo(() => {
    if (!jointAccount) {
      return false;
    }

    const taskDocuments = task?.documents || [];
    const isBorrowerUploadedDocs = taskDocuments.some(
      (doc) =>
        doc.borrowerRole === 'borrower' && doc.documentType === documentType,
    );

    return isBorrowerUploadedDocs;
  }, [jointAccount, documentType, JSON.stringify(task)]);

  return (
    <Content mb={50}>
      <StatementsTitle assetType={assetType} borrowerName={borrowerName} />

      {showAlert ? (
        <Alert variant="info" mb={3}>
          The supporting documentation for {jointAccount.financialInstitution},
          marked as a joint account has already been uploaded.
        </Alert>
      ) : null}

      {assetData.map((asset) => {
        const taskAsset = task?.assetDetails?.find((a) => asset.id === a.id);
        return (
          <Box mb={3} key={asset.id}>
            <Text my={2} fontSize={18}>
              {asset?.financialInstitution}
            </Text>

            <ManageDocuments
              customMutation={submitProjectDocument}
              customResponsePath="submitProjectDocument"
              customMutationParams={{
                borrowerRole,
                documentType,
                attachedRecordTarget: {
                  id: asset.id,
                  type: 'asset_detail',
                },
              }}
              customDocuments={taskAsset?.documents || []}
              facet={TASK_CATEGORIES.ELIGIBILITY}
              minimize
              onChange={onChange}
              taskId={task.id}
            />
          </Box>
        );
      })}
    </Content>
  );
};

CoBorrowerStatements.propTypes = {
  task: PropTypes.object,
  borrowerRole: PropTypes.string,
  borrowerName: PropTypes.string,
  assetType: PropTypes.string,
  formData: PropTypes.array,
  onChange: PropTypes.func,
};

export default CoBorrowerStatements;
