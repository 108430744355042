/*
 * for this to work it requires to include a script in a template:
 * https://maps.googleapis.com/maps/api/js?key=API_KEY&libraries=places
 */

export const DEFAULT_TYPES = ['address'];
export const DEFAULT_PLACE_FIELDS = [
  'address_components',
  'formatted_address',
  'geometry.location',
  'photos',
  'name',
];

let autocompleteInstance;
let placesInstance;

const getAutocompleteService = () => {
  if (!autocompleteInstance) {
    autocompleteInstance = new google.maps.places.AutocompleteService();
  }
  return autocompleteInstance;
};
const getPlacesService = () => {
  if (!placesInstance) {
    placesInstance = new google.maps.places.PlacesService(
      document.createElement('div'),
    );
  }
  return placesInstance;
};

export const getPlacePredictions = async (search, types = DEFAULT_TYPES) => {
  if (!google?.maps) {
    return Promise.reject(new Error('Google maps not available'));
  }
  return new Promise((resolve, reject) => {
    try {
      getAutocompleteService().getPlacePredictions(
        {
          input: search,
          componentRestrictions: {country: 'us'},
          types,
        },
        (predictions, status) => {
          if (
            [
              google.maps.places.PlacesServiceStatus.OK,
              google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
            ].includes(status)
          ) {
            resolve(predictions || []);
          } else {
            reject(new Error(status));
            return;
          }
        },
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const getPlaceDetails = (placeId, fields = DEFAULT_PLACE_FIELDS) => {
  return new Promise((resolve, reject) => {
    if (!google?.maps) {
      return Promise.reject(new Error('Google maps not available'));
    }
    try {
      getPlacesService().getDetails(
        {
          fields,
          placeId,
        },
        (place, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            reject(new Error(status));
          } else {
            resolve(place);
          }
        },
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const getFullResidenceAddress = ({
  streetAddressOne,
  streetAddressTwo,
  city,
  state,
  zipCode,
  country,
  county,
}) => {
  const countryName = country === 'US' ? 'USA' : country;

  return (
    <>
      {streetAddressOne}
      {streetAddressTwo ? `, ${streetAddressTwo}` : ''}, {city}
      {county ? `, ${county}` : ''}
      {state ? `, ${state}` : ''}
      {zipCode ? `, ${zipCode}` : ''} {countryName}
    </>
  );
};
