import styled from '@emotion/styled';

import {Flex, Link} from '@renofi/components-internal';
import Phone from '@renofi/icons/src/Phone';
import Envelope from '@renofi/icons/src/Envelope';

export const PhoneIcon = styled(Phone)({
  width: 18,
  verticalAlign: -12,
});

export const EnvelopeIcon = styled(Envelope)({
  width: 16,
});

export const LinkWrapper = styled(Link)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const ItemWrapper = styled(Flex)({
  marginRight: 12,
  alignItems: 'center',
  marginBottom: 5,
});

export const ItemWrapperBottom = styled(Flex)({
  marginRight: 12,
  alignItems: 'center',
  marginBottom: 5,
  marginTop: -3,
});

export const IconWrapper = styled(Flex)({
  width: 20,
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 10,
});
