export const getPropertyDetails = (place) => {
  const details = [
    place?.propertyStreetAddressOne,
    place?.propertyStreetAddressTwo,
    place?.propertyCity,
    place?.propertyState,
    place?.propertyZipCode,
  ].filter(Boolean);

  return details?.join(', ');
};
