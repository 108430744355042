import React, {useContext, useState} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';
import {FACEBOOK_URL, INSTAGRAM_URL, TWITTER_URL} from 'config';

import {
  Box,
  Container,
  Flex,
  Link,
  Loader,
  PageTitle,
} from '@renofi/components-internal';
import Card from '@renofi/components-internal/src/next/Card';
import {useToggledBorrowerProject} from '@renofi/graphql';
import {Context as StorageContext} from '@renofi/utilities/src/storage';

import Complete from './components/Complete';
import LeaveReview from './components/LeaveReview';
import Facebook from './components/Facebook';
import Instagram from './components/Instagram';
import Twitter from './components/Twitter';
import {Details, Title, StyledFlex, StyledTitle} from './styled';

const Review = () => {
  const {loading, project} = useToggledBorrowerProject();
  const storage = useContext(StorageContext);
  const [complete, setComplete] = useState(
    Boolean(storage.getItem('review:completed')),
  );

  const timeline = propOr(null, 'timeline', project);
  const timelineComplete =
    complete &&
    Boolean(
      timeline?.renovationCompletedOn ||
        timeline?.completionCertificateRequestedAt,
    );

  if (loading) {
    return <Loader />;
  }

  return (
    <Container narrow mt={16}>
      <StyledTitle>
        <PageTitle backButton={false} text="Finish the Journey" />
      </StyledTitle>
      <StyledFlex>
        <Box width={840} flexShrink={0} className="wrapper">
          {timelineComplete ? (
            <Complete />
          ) : (
            <LeaveReview onClick={() => setComplete(true)} />
          )}
        </Box>
        <Flex
          ml={28}
          flexGrow={1}
          flexDirection="column"
          className="innerWrapper">
          <Card py={32} flexGrow={1}>
            <Title>Share your renovation journey</Title>
            <Details mt={28}>
              You can share your renovation journey with friends on social media
              to let them know that renovation dreams do come true with RenoFi!
            </Details>
            <Flex width={1} mt={36} px={16} justifyContent="space-between">
              <Link blank href={FACEBOOK_URL}>
                <Facebook />
              </Link>
              <Link blank href={INSTAGRAM_URL}>
                <Instagram />
              </Link>
              <Link blank href={TWITTER_URL}>
                <Twitter />
              </Link>
            </Flex>
          </Card>
        </Flex>
      </StyledFlex>
    </Container>
  );
};

Review.propTypes = {
  complete: PropTypes.bool,
};

export default Review;
