import React from 'react';

const JourneyIcon = (props) => (
  <svg
    width={16}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      className="first"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.762 0A.762.762 0 0 0 0 .762v16.762a.762.762 0 1 0 1.524 0V.762A.762.762 0 0 0 .762 0Z"
      fill="currentcolor"
    />
    <path
      className="second"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.85 2.13a.385.385 0 0 0-.33-.065c-.024.006-2.353.632-4.153.632-.618 0-1.095-.072-1.413-.215-.381-.171-.43-.398-.43-.577 0-.522-.268-1.397-2.06-1.397-1.962 0-4.805.995-4.924 1.037a.381.381 0 0 0-.254.36v9.143a.38.38 0 0 0 .508.358c.028-.01 2.838-.994 4.67-.994 1.298 0 1.298.478 1.298.635 0 .714.459 1.566 2.646 1.566 1.896 0 4.212-.611 4.309-.637a.38.38 0 0 0 .283-.368V2.433a.38.38 0 0 0-.15-.303Z"
      fill="currentcolor"
    />
  </svg>
);

export default JourneyIcon;
