import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import noop from '@renofi/utilities/src/noop';
import {isPhone} from '@renofi/utilities/src/validators';

import FieldFrame from '../FieldFrame';

import {Input} from './styled';

const INVALID_MSG = 'Invalid phone number';

const PhoneField = ({
  active,
  autoComplete = 'tel',
  canClear = false,
  clearValue = null,
  defaultValue,
  disabled,
  name,
  placeholder,
  value: currentValue,
  onBlur = noop,
  onChange = noop,
  onClear = noop,
  onFocus = noop,
  tabIndex,
  small,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(currentValue || defaultValue || '');
  const [dirty, setDirty] = useState(Boolean(value));
  const [error, setError] = useState(null);

  useEffect(() => {
    const val = currentValue || defaultValue || '';
    if (active) {
      setValue(val);
    }
    setError(!isPhone(val) && val !== '' ? INVALID_MSG : null);
  }, [dirty, defaultValue, currentValue]);

  const changeHandler = useCallback((event) => {
    const val = event.target.value;
    setValue(val);
    setError(!isPhone(val) ? INVALID_MSG : null);
    onChange(val);
  });

  const clearHandler = useCallback(() => {
    setValue(clearValue);
    onClear(clearValue);
  }, [clearValue]);

  const blurHandler = (event) => {
    setFocus(false);
    setDirty(true);
    onBlur(event);
  };
  const focusHandler = (event) => {
    setFocus(true);
    onFocus(event);
  };

  const options = small
    ? {
        blocks: [0, 3, 3, 4],
        delimiters: ['(', ') ', '-', ' '],
        numericOnly: true,
      }
    : {phone: true, phoneRegionCode: 'US'};

  return (
    <FieldFrame
      name={name}
      canClear={canClear}
      disabled={disabled}
      small={small}
      error={dirty && error}
      focused={focus}
      leftIcon={small ? undefined : 'phone'}
      onClear={clearHandler}
      {...props}>
      <Input
        id={name}
        autoComplete={autoComplete}
        disabled={disabled}
        name={name}
        tabIndex={tabIndex}
        type="tel"
        value={value}
        options={options}
        placeholder={small ? '(__) __-____' : placeholder}
        onChange={changeHandler}
        onBlur={blurHandler}
        onFocus={focusHandler}
      />
    </FieldFrame>
  );
};

PhoneField.propTypes = {
  autoComplete: PropTypes.string,
  canClear: PropTypes.bool,
  small: PropTypes.bool,
  clearValue: PropTypes.any,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  tabIndex: PropTypes.number,
};

export default PhoneField;
