import React from 'react';

const RenovateIcon = (props) => (
  <svg
    width={17}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      className="first"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.51 2.549v-.941a.944.944 0 0 0-.942-.941H1.274a.944.944 0 0 0-.941.94v3.765c0 .518.424.942.941.942h11.294a.944.944 0 0 0 .941-.942v-.94h.942v3.764H5.039v10.353c0 .518.423.941.941.941h1.882a.944.944 0 0 0 .942-.941v-8.47h7.529v-7.53H13.51Z"
      fill="currentcolor"
    />
    <path
      className="second"
      d="M13.51 1.608v3.764a.944.944 0 0 1-.942.942H1.274a.944.944 0 0 1-.941-.942V1.608c0-.518.424-.941.941-.941h11.294c.518 0 .941.423.941.94Z"
      fill="currentcolor"
    />
  </svg>
);

export default RenovateIcon;
