import styled from '@emotion/styled';

import {Flex} from '@renofi/components-internal';
import {basic03, maxSmall, minSmall} from '@renofi/theme';

export const ChecklistContent = styled(Flex)({
  backgroundColor: basic03,
  flexDirection: 'column',

  [maxSmall]: {
    minWidth: 'auto',
  },
  [minSmall]: {
    minWidth: 383,
  },
  padding: '20px 20px 30px 20px',

  svg: {
    minWidth: 16,
  },
});

export const ChecklistWrapper = styled(Flex)({
  gap: 12,

  [maxSmall]: {
    position: 'relative',
    flexDirection: 'column',
  },
  [minSmall]: {
    flexDirection: 'column',
  },
});
