import styled from '@emotion/styled';

import {maxSmall, minSmall} from '@renofi/theme/src/breakpoints';
import {Box, Flex, Heading, Link} from '@renofi/components-internal';
import {basic80, renofiBlue} from '@renofi/theme/src/colors';

export const Content = styled(Flex)({
  [maxSmall]: {
    flexDirection: 'column',
  },
  [minSmall]: {
    flexDirection: 'row',
  },
});

export const Title = styled(Heading)({
  fontSize: 24,
  lineHeight: '32px',
  marginBottom: 24,
});

export const IconWrapper = styled(Flex)({
  width: 60,
  height: 60,
  backgroundColor: renofiBlue,
  borderRadius: 60,
});

export const Buttons = styled(Flex)({
  [maxSmall]: {
    flexDirection: 'column',
  },
});

export const Image = styled(Box)(
  {
    width: 24,
    height: 24,

    [maxSmall]: {
      marginRight: 16,
    },

    [minSmall]: {
      marginBottom: 4,
    },
  },
  ({src}) => ({
    background: `url(${src}) 50% 50% no-repeat`,
  }),
);

const styles = {
  display: 'flex',
  alignItems: 'center',

  padding: '12px 16px 8px 16px',
  border: '1px solid rgba(51, 51, 51, 0.1)',
  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.06)',
  borderRadius: 8,

  color: basic80,
  cursor: 'pointer',

  [maxSmall]: {
    marginBottom: 16,
  },

  [minSmall]: {
    flexDirection: 'column',

    minWidth: 116,
    height: 66,
    marginLeft: 16,
    justifyContent: 'space-between',
  },
};

export const Button = styled(Flex)(styles);

export const Email = styled(Link)(styles);

export const Headshot = styled.img({
  maxWidth: 60,
});
