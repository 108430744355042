import * as React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.578125 19.826C0.578125 8.94758 9.39813 0.125977 20.2781 0.125977C31.1573 0.125977 39.9765 8.94758 39.9765 19.826C39.9765 30.706 31.1581 39.5244 20.2781 39.5244C9.39813 39.5244 0.578125 30.706 0.578125 19.826ZM30.2157 16.4964C30.2157 16.2804 30.2117 16.0668 30.2013 15.854C31.1717 15.154 32.0141 14.2796 32.6781 13.282C31.7885 13.6764 30.8317 13.9436 29.8261 14.0636C30.8517 13.45 31.6381 12.4772 32.0101 11.3172C31.0509 11.886 29.9885 12.3004 28.8573 12.522C27.9517 11.558 26.6621 10.9548 25.2341 10.9548C22.4917 10.9548 20.2693 13.178 20.2693 15.9188C20.2693 16.3076 20.3133 16.6876 20.3981 17.05C16.2717 16.8436 12.6133 14.8668 10.1645 11.8636C9.73732 12.5964 9.49253 13.45 9.49253 14.3596C9.49253 16.0828 10.3685 17.6004 11.7013 18.4924C10.8869 18.466 10.1213 18.2428 9.45253 17.8708C9.45173 17.8924 9.45173 17.9132 9.45173 17.9348C9.45173 20.338 11.1629 22.346 13.4349 22.802C13.0181 22.9164 12.5797 22.9756 12.1269 22.9756C11.8069 22.9756 11.4957 22.946 11.1933 22.8868C11.8253 24.8588 13.6581 26.2948 15.8301 26.3356C14.1317 27.6668 11.9909 28.4604 9.66453 28.4604C9.26373 28.4604 8.86932 28.4372 8.48053 28.3916C10.6773 29.7996 13.2877 30.6212 16.0909 30.6212C25.2221 30.6212 30.2157 23.0572 30.2157 16.4964Z"
      fill="#FF5253"
    />
  </svg>
);
