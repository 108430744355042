import {useApolloClient} from '@apollo/client';

import {GET_PROJECT_TASKS} from '@renofi/graphql';
import {useTasksOverview} from '@renofi/graphql/src/hooks';
import {sendEvent} from '@renofi/utilities/src/analytics';

import logger from '../../logger';

import useChannel from './useChannel';

const QUERY_PARAMS = {fetchPolicy: 'no-cache', lazy: true};

export default function useTaskStatusUpdated(projectId) {
  const client = useApolloClient();
  const {fetch: fetchTasksOverview} = useTasksOverview(QUERY_PARAMS);

  const onTaskStatusUpdated = async (event) => {
    logger.debug('TASK_STATUS_UPDATED', event);
    const {status, task_id: taskId, task_type: taskType} = event || {};

    if (!taskId || !projectId) {
      return;
    }

    const rsp = await fetchTasksOverview({variables: {id: projectId}});
    const {data} = rsp || {};
    const {outstandingTotal = -1} = data?.projectById?.tasksOverview || {};

    if (outstandingTotal === 0) {
      sendEvent('Secure/No-Outstanding-Tasks', {taskType});
    }

    const {projectTasks = []} = client.readQuery({
      query: GET_PROJECT_TASKS,
      variables: {projectId},
    });
    client.writeQuery({
      query: GET_PROJECT_TASKS,
      variables: {projectId},
      data: {
        projectTasks: projectTasks.map((task) =>
          task.id === taskId ? {...task, status} : task,
        ),
      },
    });
  };

  useChannel({
    channelName: projectId && `task_status_updated--project=${projectId}`,
    onUpdate: onTaskStatusUpdated,
  });

  return {};
}
