import {gql} from '@apollo/client';

export default gql`
  mutation submitCreditLiabilityProperties(
    $realEstateOwnedPropertyIds: [ID!]!
    $additionalCreditLiabilityId: ID
    $softCreditCheckId: ID
    $softCreditLiabilityId: ID
  ) {
    submitCreditLiabilityProperties(
      realEstateOwnedPropertyIds: $realEstateOwnedPropertyIds
      additionalCreditLiabilityId: $additionalCreditLiabilityId
      softCreditCheckId: $softCreditCheckId
      softCreditLiabilityId: $softCreditLiabilityId
    ) {
      creditLiabilityProperties {
        id
      }
    }
  }
`;
