export default `
crmName
graphqlName
isCompBid
isConstruction
isHeloc
isHomeEquity
isPersonal
isPurchase
isRefi
isRenofiLoan
legacyCrmNames
legacyNames
name
requiresBeingOnTitle
`;
