import styled from '@emotion/styled';

import {Box, Flex, LenderLogo, Text} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';
import {basic55} from '@renofi/theme/src/colors';

export const Container = styled(Flex)({
  flexDirection: 'row',
  [maxSmall]: {
    flexDirection: 'column',
  },
});

export const WrapperBox = styled(Box)({
  paddingRight: 30,
  [maxSmall]: {
    width: '100%',
    paddingRight: 0,
  },
});

export const List = styled.ul({
  listStyleType: 'none',
  paddingLeft: 0,
});

export const Item = styled.li({
  fontSize: 16,
  lineHeight: '22px',
  color: basic55,
  marginBottom: 16,
});

export const DetailBoxSection = styled(Box)({
  [maxSmall]: {
    width: '100%',
  },
});

export const DetailBox = styled(Box)({
  border: '1px solid #D8D8D8',
  borderRadius: '16px',
  [maxSmall]: {
    width: '100%',
  },
});

export const LogoBox = styled(LenderLogo)({
  marginTop: 4,
  width: 152,
  height: 'auto',
});

export const LogoWrapper = styled(Flex)({
  justifyContent: 'center',
  alignItems: 'center',
  padding: '25px 0',
  borderBottom: '1px solid #D8D8D8',
});

export const ItemWrapper = styled(Flex)({
  flexDirection: 'row',
});

export const ButtonWrapper = styled(Flex)({
  paddingLeft: 35,
  [maxSmall]: {
    paddingLeft: 0,
    justifyContent: 'center',
    marginBottom: 35,
  },
});

export const IconWrapper = styled(Flex)({
  paddingRight: 15,
});

export const ButtonText = styled(Text)({
  [maxSmall]: {
    paddingLeft: 0,
    textAlign: 'center',
  },
});
