import React from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import {Flex, Text} from '@renofi/components-internal';
import Phone from '@renofi/icons/src/Phone';
import {basic03, isMobile} from '@renofi/theme';

import {TASK_STATUSES} from '../../Project/constants';

const SmsConsentDetails = ({task}) => {
  const smsConsent = propOr(null, 'smsConsentRenovationProgress', task);
  const isOutstanding = task?.status === TASK_STATUSES.outstanding;
  const isAccepted = Boolean(smsConsent?.accepted);
  const phoneNumber = smsConsent?.phoneNumber;

  if (isOutstanding) {
    return null;
  }

  return (
    <Flex
      css={{
        backgroundColor: basic03,
        border: '1px solid #E9E9E9',
        borderRadius: 8,
      }}
      width={isMobile() ? 1 : 0.6}
      p={3}
      alignItems="center">
      <Text fontSize={14}>
        SMS reminders {isAccepted ? 'active' : 'inactive'}
      </Text>
      {phoneNumber ? (
        <Flex ml={2} alignItems="center">
          <Text mr={1}>|</Text>
          <Phone width={20} />
          <Text ml={1} fontSize={14}>
            {phoneNumber}
          </Text>
        </Flex>
      ) : null}
    </Flex>
  );
};

SmsConsentDetails.propTypes = {
  task: PropTypes.shape({
    smsConsentRenovationProgress: PropTypes.shape({
      accepted: PropTypes.bool,
      phoneNumber: PropTypes.string,
    }),
    status: PropTypes.string.isRequired,
  }),
};

export default SmsConsentDetails;
