import * as React from 'react';

import PropTypes from 'prop-types';

import {basic20} from '@renofi/theme/src/colors';

const SvgCheck = ({color = basic20, ...props}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="m9.351 18.763 11.64-11.69-1.866-1.865-10.023 9.977-4.456-4.445-1.657 1.666z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

SvgCheck.propTypes = {
  color: PropTypes.string,
};

export default SvgCheck;
