import styled from '@emotion/styled';

import {Heading, Text, Flex} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';
import {maxSmall} from '@renofi/theme/src/breakpoints';

export const Title = styled(Heading)({
  fontSize: 26,
  lineHeight: '32px',
  textAlign: 'center',
});

export const Details = styled(Text)({
  fontSize: 18,
  lineHeight: '24px',
  textAlign: 'center',
  color: basic55,
});

export const StyledFlex = styled(Flex)({
  '@media (max-width: 1400px)': {
    flexDirection: 'column',

    '.wrapper': {
      width: '100%',
    },
    '.innerWrapper': {
      marginLeft: '0',
    },
  },
});

export const StyledTitle = styled.div({
  marginBottom: '32px',
  [maxSmall]: {
    marginBottom: '16px',
  },
});
