import React from 'react';

import PropTypes from 'prop-types';

import {Flex, Text, Button} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';

import thumbsUp from './img/thumbs_up.svg';

const TransitionStep = ({
  borrowerName,
  coBorrowerName,
  goToCoBorrowerAssets,
}) => {
  return (
    <>
      <div style={{position: 'relative'}}>
        <img
          style={{position: 'absolute', right: 0, top: 75}}
          src={thumbsUp}
          alt="Thumbs up"
        />
      </div>

      <Flex
        height="100%"
        css={{gap: '30px'}}
        mx={30}
        py="50%"
        alignItems="center"
        flexDirection="column"
        justifyContent="center">
        <Text fontSize={24}>
          Thanks for adding {borrowerName}’s assets details and supporting
          documents.
        </Text>

        <Text fontSize={24}>
          Next, add {coBorrowerName}’s assets details and documents to complete
          this task.
        </Text>

        <Button css={{width: 250}} onClick={goToCoBorrowerAssets}>
          Continue to {coBorrowerName}’s ASSETs details
        </Button>

        <Text textAlign="center" color={basic55} fontSize={14}>
          Both borrower’s details are required before the task can be reviewed
          by RenoFi.
        </Text>
      </Flex>
    </>
  );
};

TransitionStep.propTypes = {
  borrowerName: PropTypes.string,
  coBorrowerName: PropTypes.string,
  goToCoBorrowerAssets: PropTypes.func,
};

export default TransitionStep;
