import {both, filter, isNil, pipe, prop, propEq, reject} from 'ramda';

export const rejectEmptySoftPull = reject(
  both(
    propEq('taskType', 'soft_credit_check'),
    pipe(prop('softCreditCheck'), isNil),
  ),
);

export const filterOutstanding = pipe(
  filter(propEq('status', 'outstanding')),
  rejectEmptySoftPull,
);
