import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {Redirect, Route, useParams, useRouteMatch} from 'react-router-dom';

import {useToggledBorrowerProject} from '@renofi/graphql/src';

import MortgageDetailsStep from '../MortgageDetailsStep';
import MortgageStatementStep from '../MortgageStatementStep';
import PropertyTaxStep from '../PropertyTaxStep';
import FeesStep from '../FeesStep';
import useStepsLabels from '../hooks/useStepsLabels';
import useTogglePropertySteps from '../hooks/useTogglePropertySteps';
import {findPropertyDocuments} from '../utils';
import InsurancePolicyStep from '../InsurancePolicyStep';

const SubjectProperty = ({
  formData,
  onChange,
  toggleStep,
  updateStep,
  task,
  status,
  subjectPropertyId,
}) => {
  const {propertyId, type, step} = useParams();
  const match = useRouteMatch();
  const {subjectProperty} = formData;
  const {
    taxAndInsuranceIncluded,
    existingMortgageLoan,
    homeownersAssociationFeesIncluded,
    homeownersAssociationFeesExist,
  } = subjectProperty || {};
  const {documentIds} = task.realEstateOwned?.subjectProperty || {};
  const {project} = useToggledBorrowerProject();
  const {loanTypeEnum} = project || {};
  const {isRenofiLoan} = loanTypeEnum || {};
  const isLandLoan = loanTypeEnum?.name === 'land_loan';
  const getStepLabels = useStepsLabels(documentIds, task);
  const togglePropertySteps = useTogglePropertySteps({isSubjectProperty: true});
  const {policyFiles, statementFiles, taxFiles, feesFiles} =
    findPropertyDocuments(documentIds, task);
  useEffect(() => {
    updateWizardSteps();
  }, [
    propertyId,
    type,
    step,
    project?.streetAddressOne,
    JSON.stringify(subjectProperty),
  ]);

  useEffect(() => {
    if (status === 'updated') updateWizardSteps();
  }, [status]);

  function onChangeValue(key, newValue) {
    onChange('subjectProperty', {
      ...subjectProperty,
      [key]: newValue,
    });
  }

  function updateWizardSteps() {
    togglePropertySteps(toggleStep, 'subject-property', {
      ...subjectProperty,
      isLandLoan,
    });
    const labels = getStepLabels('subject-property', taxAndInsuranceIncluded, {
      ...subjectProperty,
      streetAddressOne: project?.streetAddressOne,
    });
    labels.forEach(({id, value}) => {
      updateStep({id, key: 'info', value});
    });
  }

  return (
    <>
      <Route exact path={`${match.path}/:step(subject-property)`}>
        <Redirect to={`${match.url}/mortgage-details`} />
      </Route>
      <Route
        path={`${match.path}/:step(subject-property)/mortgage-details`}
        render={() => (
          <MortgageDetailsStep
            homeownersAssociationFeesIncluded={
              homeownersAssociationFeesIncluded
            }
            taxAndInsuranceIncluded={taxAndInsuranceIncluded}
            homeownersAssociationFeesExist={homeownersAssociationFeesExist}
            existingMortgageLoan={existingMortgageLoan}
            address={project?.streetAddressOne}
            onChange={onChangeValue}
          />
        )}
      />
      <Route
        path={`${match.path}/:step(subject-property)/mortgage-statement`}
        render={() => (
          <MortgageStatementStep
            documents={statementFiles}
            task={task}
            realEstateOwnedPropertyId={subjectPropertyId}
            address={project?.streetAddressOne}
            onUploadComplete={updateWizardSteps}
          />
        )}
      />
      <Route
        path={`${match.path}/:step(subject-property)/tax`}
        render={() => (
          <PropertyTaxStep
            documents={taxFiles}
            task={task}
            address={project?.streetAddressOne}
            onUploadComplete={updateWizardSteps}
            realEstateOwnedPropertyId={subjectPropertyId}
          />
        )}
      />
      <Route
        path={`${match.path}/:step(subject-property)/fees`}
        render={() => (
          <FeesStep
            documents={feesFiles}
            task={task}
            address={project?.streetAddressOne}
            onUploadComplete={updateWizardSteps}
            realEstateOwnedPropertyId={subjectPropertyId}
          />
        )}
      />
      <Route
        path={`${match.path}/:step(subject-property)/insurance`}
        render={() => (
          <InsurancePolicyStep
            documents={policyFiles}
            task={task}
            address={project?.streetAddressOne}
            onUploadComplete={updateWizardSteps}
            realEstateOwnedPropertyId={subjectPropertyId}
            isRenofiLoan={isRenofiLoan}
            isSubjectProperty
          />
        )}
      />
    </>
  );
};

SubjectProperty.propTypes = {
  formData: PropTypes.object,
  names: PropTypes.string,
  status: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  toggleStep: PropTypes.func.isRequired,
  updateStep: PropTypes.func.isRequired,
  task: PropTypes.object,
  subjectPropertyId: PropTypes.string,
};

export default SubjectProperty;
