import React from 'react';

import PropTypes from 'prop-types';
import ManageDocuments from 'modules/tasks-wizard/components/ManageDocuments';

import {Alert} from '@renofi/components-internal';
import {useSubmitProjectDocument} from '@renofi/graphql/src';

import {Content, Title} from '../styled';

const PropertyTaxStep = ({
  task,
  address,
  realEstateOwnedPropertyId,
  documents,
  onUploadComplete,
}) => {
  const {submitProjectDocument} = useSubmitProjectDocument();
  const {id: taskId, facet} = task;

  return (
    <Content>
      <Title>
        Upload the most <strong>recent tax bill</strong> for {address}.
      </Title>

      <Alert icon={null} variant="info" mb={24}>
        If you are exempt from paying property taxes, please upload proof of
        your exemption status.
      </Alert>

      <ManageDocuments
        forceRemoveButton
        customDocuments={documents}
        taskId={taskId}
        facet={facet}
        fileName="property tax"
        customMutation={submitProjectDocument}
        customResponsePath="submitProjectDocument"
        customMutationParams={{
          realEstateOwnedPropertyId,
          documentType: 'property_tax',
          attachedRecordTarget: {
            id: realEstateOwnedPropertyId,
            type: 'real_estate_owned_property',
          },
        }}
        onUploadComplete={onUploadComplete}
      />
    </Content>
  );
};

PropertyTaxStep.propTypes = {
  task: PropTypes.object,
  address: PropTypes.string,
  realEstateOwnedPropertyId: PropTypes.string,
  documents: PropTypes.array,
  onUploadComplete: PropTypes.func,
};

export default PropertyTaxStep;
