import {useApolloClient} from '@apollo/client';

import {GET_PROJECT_TASKS} from '../queries';

export default () => {
  const client = useApolloClient();

  return async ({projectId, task}) => {
    const apolloParams = {
      query: GET_PROJECT_TASKS,
      variables: {projectId},
    };
    const cachedData = client.readQuery(apolloParams);
    const allTasks = cachedData?.projectTasks || [];

    client.writeQuery({
      ...apolloParams,
      data: {
        projectTasks: allTasks.map((t) => {
          return t.id === task.id ? {...t, ...task} : t;
        }),
      },
    });
  };
};
