import {isNil} from 'ramda';

export const UUID_NAME_REGEX =
  /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/i;

export default (props, propName, componentName) => {
  const idValue = props[propName];

  if (isNil(idValue) || !UUID_NAME_REGEX.test(idValue)) {
    return new Error(
      `Invalid prop "${propName}" supplied to "${componentName}". Expected valid UUID value (found: ${idValue})`,
    );
  }
};
