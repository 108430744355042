import React from 'react';

import PropTypes from 'prop-types';

import {
  StyledActionButton,
  MainContent,
  AdditionalContent,
  Wrapper,
  ButtonWrapper,
} from './styled';

const BaseContent = ({
  mainContent,
  sideContent,
  sideContentCss,
  onAccept,
  onDecline,
  withButtons = true,
}) => {
  return (
    <Wrapper>
      <MainContent>{mainContent}</MainContent>
      {sideContent && (
        <AdditionalContent css={sideContentCss}>
          {sideContent}
        </AdditionalContent>
      )}
      {withButtons && (
        <ButtonWrapper>
          <StyledActionButton
            variant="secondary"
            onClick={onAccept}
            position="left">
            Yes
          </StyledActionButton>
          <StyledActionButton
            onClick={onDecline}
            variant="secondary"
            position="right">
            No
          </StyledActionButton>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

BaseContent.propTypes = {
  mainContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  sideContent: PropTypes.node,
  sideContentCss: PropTypes.object,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  withButtons: PropTypes.bool,
};

export default BaseContent;
