import {useMemo} from 'react';

import {useLocation} from 'react-router-dom';

import queryString, {PARSE_OPTIONS} from '../queryString';

export default () => {
  const {search = ''} = useLocation();

  return useMemo(() => queryString.parse(search, PARSE_OPTIONS), [search]);
};
