import React from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';
import {BrowserRouter} from 'react-router-dom';
import {ApolloProvider} from '@apollo/client';

import {ReconfFactory} from '@renofi/components-internal';
import {Provider as ThemeProvider} from '@renofi/theme/src';
import {Provider as ConfigProvider} from '@renofi/utilities/src/config';
import {Provider as StorageProvider} from '@renofi/utilities/src/storage';

import AuthRouter from './components/AuthRouter';
import {getTitleByEnv} from './utils';

if (Boolean(document)) {
  document.title = getTitleByEnv();
}

function AppRoot({children, config = {}, client, storage}) {
  const isLoginApp = config.SERVICE_NAME === 'login-frontend';
  const Router = isLoginApp ? BrowserRouter : AuthRouter;

  return (
    <Box>
      <React.StrictMode>
        <ConfigProvider config={config}>
          <ThemeProvider theme="light">
            <StorageProvider storage={storage}>
              <ApolloProvider client={client}>
                <ReconfFactory>
                  <Router>{children}</Router>
                </ReconfFactory>
              </ApolloProvider>
            </StorageProvider>
          </ThemeProvider>
        </ConfigProvider>
      </React.StrictMode>
    </Box>
  );
}

AppRoot.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.shape({
    ENVIRONMENT: PropTypes.string.isRequired,
    SERVICE_NAME: PropTypes.string.isRequired,
  }),
  client: PropTypes.object.isRequired,
  storage: PropTypes.object.isRequired,
};

export default AppRoot;
