import {isDeployPreviewUrl} from '@renofi/utilities/src/netlify';

const ENVIRONMENT = process.env.REACT_APP_NODE_ENV;
export const PRODUCTION = ENVIRONMENT === 'production';

export const SECURE_FRONTEND_URL = isDeployPreviewUrl()
  ? window.location.origin
  : process.env.REACT_APP_SECURE_FRONTEND_URL;
export const GOOGLE_REVIEWS_URL =
  'https://www.google.com/maps/place/RenoFi:+Home+Renovation+Loans/@40.9824993,-82.0946145,6z/data=!3m1!4b1!4m7!3m6!1s0x80c32bbc64a95555:0xe441df9a7f5703e2!8m2!3d41.0696939!4d-77.6049961!9m1!1b1';
export const FACEBOOK_URL = 'https://www.facebook.com/RenoFiNow';
export const INSTAGRAM_URL = 'https://www.instagram.com/renofinow/';
export const TWITTER_URL = 'https://twitter.com/RenoFiNow';
export const LENDER_RATES_URL =
  'https://www.renofi.com/blog/how-do-lenders-set-rates-for-renofi-loans/';
export const RENOFI_CONTACT_EMAIL = 'hi@renofi.com';
export const SCHEDULE_URL = 'https://www.renofi.com/schedule';
export const MAX_FIRST_TASK_FILE_UPLOAD = 8;

export const PRIVACY_POLICY_URL = 'https://www.renofi.com/notices/privacy/';
export const TERMS_OF_SERVICE_URL = 'https://www.renofi.com/notices/legal/';
export const ELECTRONIC_TRANSACTIONS_SIGNATURES =
  'https://www.renofi.com/notices/electronic-records-and-signatures';

window._config = {
  NODE_ENV: process.env.NODE_ENV,
  PRODUCTION,
};
