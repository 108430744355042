import {gql} from '@apollo/client';

export default gql`
  mutation updateProject(
    $attributes: UpdateProjectInputObject
    $id: ID!
    $opportunityAttributes: UpdateOpportunityInputObject
  ) {
    updateProject(
      id: $id
      attributes: $attributes
      opportunityAttributes: $opportunityAttributes
    ) {
      success
    }
  }
`;
