import React from 'react';

import PropTypes from 'prop-types';

import Text from '../Text';
import Link from '../Link';

import {Wrapper, LicensingLink, LegalMessage} from './styled';
import NotAuthorizedMessage from './NotAuthorizedMessage';

const Footer = ({color = 'white', linkColor, maxWidth, app = 'login'}) => (
  <Wrapper maxWidth={maxWidth}>
    <Text color={color} fontSize={14}>
      &copy; {new Date().getFullYear()} Renovation Finance LLC DBA RenoFi
      <Text display={['none', 'inline']} css={{padding: '0 8px;'}}>
        |
      </Text>
      <LicensingLink
        blank
        css={{color}}
        href="https://www.renofi.com/notices/licenses/">
        Licensing - NMLS # 1802847
      </LicensingLink>
    </Text>

    <LegalMessage color={color} maxWidth={maxWidth}>
      RenoFi operates as Renovation Technologies Holdings Inc. in California
      (NMLS # 2412747), Renovation Technologies LLC in Nebraska, and RenoFi LLC
      in New Mexico.
    </LegalMessage>

    <NotAuthorizedMessage
      app={app}
      css={{color, fontWeight: 'bold', marginBottom: 16}}
      maxWidth={maxWidth}
    />

    <Text color={color} fontSize={14}>
      <Link
        href="#!"
        css={{color: linkColor || color}}
        data-acsb-custom-trigger="true">
        Accessibility
      </Link>
      <span css={{padding: '0 8px;', opacity: 0.5}}>|</span>
      <Link
        blank
        css={{color: linkColor || color}}
        href="https://www.renofi.com/notices/legal/">
        Terms of Service
      </Link>
      <span css={{padding: '0 8px;', opacity: 0.5}}>|</span>
      <Link
        blank
        css={{color: linkColor || color}}
        href="https://www.renofi.com/notices/privacy/">
        Privacy Policy
      </Link>
      <span css={{padding: '0 8px;', opacity: 0.5}}>|</span>
      <Link
        blank
        css={{color: linkColor || color}}
        href="https://www.renofi.com/notices/electronic-records-and-signatures/">
        Electronic Records and Signatures
      </Link>
      <span css={{padding: '0 8px;', opacity: 0.5}}>|</span>
      <Link
        blank
        css={{color: linkColor || color}}
        href="https://www.renofi.com/contact">
        Contact
      </Link>
    </Text>
  </Wrapper>
);

Footer.propTypes = {
  color: PropTypes.string,
  linkColor: PropTypes.string,
  maxWidth: PropTypes.number,
  app: PropTypes.string,
};

export default Footer;
