import styled from '@emotion/styled';
import {Content} from 'modules/layout';

import {Box, Checkbox as CommonCheckbox} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';
import {actionRegular, border} from '@renofi/theme';

import consentFalse from './images/consent-false.svg';
import renovation from './images/renovation.svg';
import renovationDone from './images/renovation-done.svg';

export const Checkbox = styled(CommonCheckbox)(({accepted}) => {
  return {
    border: accepted ? `solid 1px ${actionRegular}` : `solid 1px ${border}`,
    boxShadow: accepted ? `0 0 0 1px ${actionRegular}` : 'none',
    borderRadius: 8,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
    transition: 'border 200ms, box-shadow 200ms',
  };
});

export const Consent = styled(Content)(
  {
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('${consentFalse}')`,
    backgroundSize: '337px 171px',
    backgroundPosition: 'right 64px top 48px',
    transition:
      'opacity 500ms ease-out, height 500ms ease-out, max-height 500ms ease-out',
    [maxSmall]: {
      backgroundImage: 'none',
    },
  },
  ({visible}) => ({
    opacity: visible ? 1 : 0,
    maxHeight: visible ? 600 : 0,
    ...(visible ? {} : {margin: 0, padding: 0}),
    '@media (max-width: 600px)': {
      maxHeight: visible ? '100%' : 0,
    },
  }),
);

export const Complete = styled(Content)({
  backgroundColor: 'white',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url('${renovationDone}')`,
  backgroundSize: '332px 133px',
  backgroundPosition: 'right 34px top 8px',
  [maxSmall]: {
    backgroundImage: 'unset',
  },
});

export const Message = styled(Content)({
  backgroundColor: 'white',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url('${renovation}')`,
  backgroundSize: '332px 95px',
  backgroundPosition: 'right 34px top 32px',
  [maxSmall]: {
    backgroundImage: 'unset',
  },
});

export const StyledBox = styled(Box)({
  [maxSmall]: {
    width: '100%',
  },
});
