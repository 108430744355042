import React from 'react';

import PropTypes from 'prop-types';

import {Flex, Text} from '@renofi/components-internal';
import {getConditionalHomeValue} from '@renofi/utilities/src/product-confirmation';
import formatMoney from '@renofi/utilities/src/formatMoney';
import {basic80} from '@renofi/theme';

import {Card, Label, Value, ItemsWrapper} from '../../styled';

const PropertyDetails = ({productDetailsConfirmation}) => {
  const {address, titleHolders} = productDetailsConfirmation || {};
  const condtionalData = getConditionalHomeValue(productDetailsConfirmation);
  const hasConditionalData = Boolean(condtionalData?.length);

  return (
    <Card width="50%" ml="8px">
      <Text fontSize={14} mb={1} color={basic80} fontWeight="bold">
        Property details
      </Text>
      <ItemsWrapper>
        <Flex>
          <Label>Address:</Label>
          <Value>{address || '-'}</Value>
        </Flex>
      </ItemsWrapper>

      <ItemsWrapper>
        <Flex>
          <Label>Title holders:</Label>
          <Value>{titleHolders || '-'}</Value>
        </Flex>
      </ItemsWrapper>

      {hasConditionalData && (
        <ItemsWrapper>
          <Flex>
            <Label>{condtionalData[1]}:</Label>
            <Value>
              {formatMoney(productDetailsConfirmation[condtionalData[0]], {
                fractional: false,
                defaultValue: 'N/A',
              })}
            </Value>
          </Flex>
        </ItemsWrapper>
      )}
    </Card>
  );
};

PropertyDetails.propTypes = {
  productDetailsConfirmation: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    dtiRatio: PropTypes.number,
    creditScore: PropTypes.string,
  }),
};

export default PropertyDetails;
