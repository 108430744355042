import React, {memo, useCallback, useMemo} from 'react';

import PropTypes from 'prop-types';

import {isMobile} from '@renofi/theme/src/breakpoints';
import MinusCircle from '@renofi/icons/src/MinusCircle';
import PlusCircle from '@renofi/icons/src/PlusCircle';
import {actionRegular, basic20} from '@renofi/theme/src/colors';
import Box from '@renofi/components-internal/src/Box';
import Flex from '@renofi/components-internal/src/Flex';
import ActiveField from '@renofi/components-internal/src/ActiveField';

import {Trigger, Wrapper} from './styled';

const EMPTY_VALUE = 'None';

const Increment = ({
  label,
  large = isMobile(),
  emptyValue = EMPTY_VALUE,
  max = Number.MAX_SAFE_INTEGER,
  min = Number.MIN_SAFE_INTEGER,
  name,
  value,
  onChange,
  disabled = false,
  ...props
}) => {
  const displayValue = useMemo(() => value || emptyValue, [emptyValue, value]);

  const isDecrementDisabled = displayValue === emptyValue || disabled;
  const triggerSize = large ? 44 : 30;

  const onDecrement = useCallback(() => {
    const intValue = parseInt(value);
    const newValue = intValue - 1 >= min ? intValue - 1 : intValue;
    onChange(newValue);
  }, [isDecrementDisabled, min, value]);

  const onIncrement = useCallback(() => {
    const intValue = value ? parseInt(value) : 0;
    const newValue = intValue + 1 <= max ? intValue + 1 : intValue;

    onChange(newValue);
  }, [isDecrementDisabled, max, value]);

  return (
    <Wrapper mb={24} width={1} {...props}>
      <Flex height={44} alignItems="center">
        <Trigger
          large={large}
          disabled={isDecrementDisabled}
          onClick={onDecrement}>
          <MinusCircle
            height={triggerSize}
            width={triggerSize}
            color={isDecrementDisabled ? basic20 : actionRegular}
          />
        </Trigger>
      </Flex>
      <Box px={12} css={{flexGrow: 1}}>
        <ActiveField
          mb={0}
          label={label}
          name={name}
          css={{'& input': {textAlign: 'center'}}}
          onChange={onChange}
          value={displayValue}
        />
      </Box>
      <Flex height={44} alignItems="center">
        <Trigger large={large} onClick={onIncrement} disabled={disabled}>
          <PlusCircle
            size={triggerSize}
            color={disabled ? basic20 : actionRegular}
          />
        </Trigger>
      </Flex>
    </Wrapper>
  );
};

Increment.propTypes = {
  label: PropTypes.node,
  large: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  emptyValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default memo(Increment);
