import React from 'react';

const SecondHome = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_4566_202019"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4566_202019)">
        <path
          d="M17.5 19H18.5V16.5H21V15.5H18.5V13H17.5V15.5H15V16.5H17.5V19ZM18 21C16.6167 21 15.4375 20.5125 14.4625 19.5375C13.4875 18.5625 13 17.3833 13 16C13 14.6167 13.4875 13.4375 14.4625 12.4625C15.4375 11.4875 16.6167 11 18 11C19.3833 11 20.5625 11.4875 21.5375 12.4625C22.5125 13.4375 23 14.6167 23 16C23 17.3833 22.5125 18.5625 21.5375 19.5375C20.5625 20.5125 19.3833 21 18 21ZM4 19V7L12 1L20 7V9.3C19.6833 9.2 19.3583 9.125 19.025 9.075C18.6917 9.025 18.35 9 18 9V8L12 3.5L6 8V17H11.075C11.125 17.35 11.2 17.6917 11.3 18.025C11.4 18.3583 11.525 18.6833 11.675 19H4Z"
          fill="#CCCCCC"
        />
      </g>
    </svg>
  );
};

export default SecondHome;
