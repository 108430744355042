import {useLayoutEffect} from 'react';

import {useHistory} from 'react-router-dom';
import {head, pipe} from 'ramda';

import {Loader} from '@renofi/components-internal';
import {useBorrowerProjects} from '@renofi/graphql';

const sortByCreatedDate = (projects = []) =>
  projects.toSorted((left, right) => {
    return new Date(right?.createdAt) - new Date(left?.createdAt);
  });

const ProjectRedirect = () => {
  const {fetch} = useBorrowerProjects({lazy: true});
  const history = useHistory();

  useLayoutEffect(() => {
    (async () => {
      const rsp = await fetch();
      const projects = rsp?.data?.borrowerProjects || [];
      const mostRecent = pipe(sortByCreatedDate, head)(projects);

      if (mostRecent) {
        history.replace(`/${mostRecent.id}`);
      }
    })();
  }, []);

  return <Loader labelText="Fetching default project" />;
};

export default ProjectRedirect;
