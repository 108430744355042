import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {isMobile} from '@renofi/theme/src/breakpoints';
import {TextField, Flex, Box} from '@renofi/components-internal';
import {sendEvent} from '@renofi/utilities/src/analytics/utils';

import BaseContent from './BaseContent';

const BorrowerInfo = ({info, update}) => {
  useEffect(() => {
    sendEvent('Secure/Onboarding-Coborrower-Name-Presented');
  }, []);

  return (
    <BaseContent
      mainContent={'What is your co-borrower’s legal name?'}
      sideContent={
        <>
          <Flex mt={[0, 16]} flexDirection={['column', 'row']}>
            <TextField
              mr={[0, 16]}
              name="coborrowerFirstName"
              value={info?.coborrowerFirstName}
              label="First name"
              onChange={(v) => update({coborrowerFirstName: v})}
            />
            <TextField
              mr={[0, 16]}
              help={
                <Box as="em" fontSize={12}>
                  Optional
                </Box>
              }
              name="coborrowerMiddleName"
              value={info?.coborrowerMiddleName}
              label="Middle name"
              onChange={(v) => update({coborrowerMiddleName: v})}
            />
          </Flex>

          <Flex flexDirection={['column', 'row']}>
            <TextField
              mr={[0, 16]}
              name="coborrowerLastName"
              value={info?.coborrowerLastName}
              label="Last name"
              onChange={(v) => update({coborrowerLastName: v})}
            />
            <TextField
              help={
                <Box as="em" fontSize={12}>
                  Optional
                </Box>
              }
              mr={[0, 16]}
              name="coborrowerNameSuffix"
              value={info?.coborrowerNameSuffix}
              label="Name suffix"
              onChange={(v) => update({coborrowerNameSuffix: v})}
            />
          </Flex>
        </>
      }
      sideContentCss={{width: isMobile() ? '100%' : '65%'}}
      withButtons={false}
    />
  );
};

BorrowerInfo.propTypes = {
  info: PropTypes.shape({
    coborrowerFirstName: PropTypes.string,
    coborrowerLastName: PropTypes.string,
    coborrowerMiddleName: PropTypes.string,
    coborrowerNameSuffix: PropTypes.string,
  }),
  update: PropTypes.func,
};

export default BorrowerInfo;
