import React from 'react';

import PropTypes from 'prop-types';

import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {Title} from './styled';

const StatementsTitle = ({assetType, borrowerName}) => {
  return (
    <Title mb={3}>
      Next, upload the 2 most recent <strong>statements</strong> for{' '}
      {borrowerName}’s <strong>{humanizeSnakeCase(assetType)} accounts.</strong>
    </Title>
  );
};

StatementsTitle.propTypes = {
  assetType: PropTypes.string,
  borrowerName: PropTypes.string,
};

export default StatementsTitle;
