import {memo} from 'react';

import {Text} from '@renofi/components-internal';
import {basic80} from '@renofi/theme';
import noop from '@renofi/utilities/src/noop';

import HistoryItem from './HistoryItem';

const EmploymentHistory = ({
  areActionsDisabled = true,
  borrowerName,
  borrowerRole,
  employmentHistory,
  internal,
  onAccept = noop,
  onClick = noop,
  onClickDocument = noop,
  onReject = noop,
  showDocuments,
}) => {
  if (!employmentHistory?.length) {
    return null;
  }

  return (
    <>
      <Text mt={2} fontSize={16} color={basic80} mb={2}>
        <strong style={{marginRight: 4, fontFamily: 'Averta-Bold'}}>
          {borrowerName}
        </strong>
        {borrowerRole === 'borrower' ? '(Primary borrower)' : '(Co-borrower)'}
      </Text>
      {employmentHistory.map((history) => (
        <HistoryItem
          areActionsDisabled={areActionsDisabled}
          key={history.id}
          history={history}
          internal={internal}
          onAccept={onAccept}
          onClick={onClick}
          onClickDocument={onClickDocument}
          onReject={onReject}
          showDocuments={showDocuments}
        />
      ))}
    </>
  );
};

export default memo(EmploymentHistory);
