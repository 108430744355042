import {gql} from '@apollo/client';

export default gql`
  mutation assignDocumentToTaskDataSet(
    $documentId: ID!
    $taskDataSetId: ID!
    $taskDataSetType: TaskDataSetWithDocuments!
    $taskId: ID!
  ) {
    assignDocumentToTaskDataSet(
      documentId: $documentId
      taskDataSetId: $taskDataSetId
      taskDataSetType: $taskDataSetType
      taskId: $taskId
    ) {
      success
    }
  }
`;
