import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import {filter, isEmpty, propEq} from 'ramda';
import {hasPasswordProtectedDocs} from 'modules/tasks-wizard';

import {prepareFormData, getIdType} from '../utils';
import {
  INITIAL_ID_DETAILS,
  BORROWERS_TYPES,
  CO_BORROWER_STEP_TYPES,
  GROUPED_STEPS,
} from '../constants';

import IdTypePage from './IdTypePage';
import IdDetailsPage from './IdDetailsPage';
import UploadIdPage from './UploadIdPage';
import CoBorrowerIntro from './CoBorrowerIntro';

const Borrower = ({
  config,
  borrowerGovId,
  documents,
  isEditMode,
  isIntroPage,
  setWizardIsLoading,
  hideIntro,
  setNextButtonDisableState,
  submitWizardData,
  currentStep,
  project,
  setWizardIdType,
  taskId,
}) => {
  const [selectedIdType, setSelectedIdType] = useState(
    isEditMode ? getIdType(borrowerGovId?.idType) : null,
  );
  const [formData, setFormData] = useState(
    isEditMode ? prepareFormData(borrowerGovId) : INITIAL_ID_DETAILS,
  );
  const [emptyFieldError, setEmptyFieldError] = useState(false);
  const [invalidFieldError, setInvalidFieldError] = useState(false);

  const {isIdTypePage, isDetailsPage, isUploadPage} = config;
  const {borrowersAndPropertyInformation: borrowersInfo} = project || {};
  const {firstName, coborrowerFirstName} = borrowersInfo || {};

  const borrowerRole = borrowerGovId?.borrowerRole;
  const isPrimaryBorrower = borrowerRole === BORROWERS_TYPES.PRIMARY_BORROWER;
  const isCoBorrower = borrowerRole === BORROWERS_TYPES.CO_BORROWER;

  const borrowerDocs = filter(propEq('borrowerRole', borrowerRole), documents);
  const borrowerName = isPrimaryBorrower ? firstName : coborrowerFirstName;

  useEffect(() => {
    if (!isCoBorrower || isEmpty(borrowerDocs)) {
      return;
    }

    hideIntro();
  }, [borrowerDocs]);

  useEffect(() => {
    const isBorrowerButtonDisabled =
      isPrimaryBorrower && currentStep < CO_BORROWER_STEP_TYPES.CO_ID_TYPE;
    const isCoBorrowerButtonDisabled =
      isCoBorrower && currentStep > CO_BORROWER_STEP_TYPES.UPLOAD_ID;

    if (!isBorrowerButtonDisabled && !isCoBorrowerButtonDisabled) {
      return;
    }

    const hasValidationError = emptyFieldError || invalidFieldError;
    const isValidationRequired = isDetailsPage && hasValidationError;
    const isNextButtonDisabled =
      !selectedIdType ||
      isValidationRequired ||
      (isUploadPage &&
        (isEmpty(borrowerDocs) || hasPasswordProtectedDocs(borrowerDocs)));

    setNextButtonDisableState(isNextButtonDisabled);
  }, [
    emptyFieldError,
    invalidFieldError,
    isDetailsPage,
    selectedIdType,
    isUploadPage,
    borrowerDocs,
    isPrimaryBorrower,
    isCoBorrower,
    currentStep,
  ]);

  useEffect(() => {
    const doesFormHaveEmptyField = Object.keys(formData).some(
      (key) => !formData[key],
    );

    setEmptyFieldError(doesFormHaveEmptyField);
  }, [formData]);

  useEffect(() => {
    const borrowerType = isCoBorrower
      ? GROUPED_STEPS.CO_BORROWER_STEP
      : GROUPED_STEPS.PRIMARY_BORROWER_STEP;

    setWizardIdType((idTypes) =>
      idTypes?.map((idDetails) =>
        idDetails.id === borrowerType
          ? {...idDetails, label: selectedIdType?.label}
          : idDetails,
      ),
    );
  }, [selectedIdType]);

  const submitData = (data) => {
    setFormData({...data, idType: selectedIdType?.value});
    submitWizardData({...data, selectedIdType});
  };

  return (
    <>
      {!isIntroPage && isIdTypePage && (
        <IdTypePage
          borrowerName={borrowerName}
          selectedIdType={selectedIdType}
          onSelectId={setSelectedIdType}
        />
      )}
      {isDetailsPage && (
        <IdDetailsPage
          borrowerName={borrowerName}
          selectedIdType={selectedIdType}
          formData={formData}
          setFormData={submitData}
          setError={setInvalidFieldError}
          editMode={isEditMode}
        />
      )}
      {isUploadPage && (
        <UploadIdPage
          borrowerName={borrowerName}
          isUploadPage={isUploadPage}
          selectedIdType={selectedIdType}
          borrowerDocuments={borrowerDocs}
          govIdDetails={borrowerGovId}
          setWizardIsLoading={setWizardIsLoading}
          taskId={taskId}
        />
      )}
      {isIntroPage && isCoBorrower && (
        <CoBorrowerIntro hideIntro={hideIntro} projectBorrowers={project} />
      )}
    </>
  );
};

Borrower.propTypes = {
  config: PropTypes.object,
  documents: PropTypes.array,
  taskStatus: PropTypes.string,
  onAcceptFiles: PropTypes.func,
  onRejectFiles: PropTypes.func,
  onShowRejection: PropTypes.func,
  onRemove: PropTypes.func,
  isIntroPage: PropTypes.bool,
  setWizardIsLoading: PropTypes.func,
  hideIntro: PropTypes.func,
  hasDocumentRejected: PropTypes.bool,
  setNextButtonDisableState: PropTypes.func,
  submitWizardData: PropTypes.func,
  borrowerGovId: PropTypes.shape({
    id: PropTypes.string,
    documentIds: PropTypes.array,
    taskId: PropTypes.string,
    idType: PropTypes.string,
    idNumber: PropTypes.string,
    issuingCountry: PropTypes.string,
    issuingState: PropTypes.string,
    borrowerRole: PropTypes.string,
    issueDate: PropTypes.string,
    expirationDate: PropTypes.string,
  }),
  isEditMode: PropTypes.bool,
  currentStep: PropTypes.number,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  setWizardIdType: PropTypes.func,
  taskId: PropTypes.string,
};

export default Borrower;
