import React, {memo, useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {pathOr, pick} from 'ramda';

import {useSignedUrl} from '@renofi/graphql';
import {
  Box,
  Flex,
  Button,
  FileSize,
  Downloads,
} from '@renofi/components-internal';
import {isMobile} from '@renofi/theme/src/breakpoints';
import {MARGIN_KEYS, PADDING_KEYS} from '@renofi/theme';
import {isPasswordProtected} from '@renofi/utilities';
import logger from '@renofi/utilities/src/logger';
import {openTab} from '@renofi/utilities/src/window';
import {taskDocumentStatuses} from '@renofi/utilities/src/enums/taskDocumentEnums';
import noop from '@renofi/utilities/src/noop';
import {validateIsoProp, validateUuidProp} from '@renofi/utilities/src/react';

import DocumentTimestamp from './components/DocumentTimestamp';
import DocumentIconType from './components/DocumentIconType';
import InternalActions from './components/InternalActions';
import PasswordPrompt from './components/PasswordPrompt';
import RemoveDocument from './components/RemoveDocument';
import {HoverLink, ShowDetails, SizeLabel, Wrapper} from './styled';

const detailsTitle = isMobile() ? 'Details' : 'Show details';

const TaskDocument = (props) => {
  const {
    allowedDocumentTypes = [],
    canChangeDocumentType = false,
    canSelect = false,
    areActionsDisabled,
    disabled,
    immutable,
    isFileSelected = false,
    fileName,
    fileSizeBytes,
    objectName,
    openTabOnClick = false,
    passwordProtectedFile,
    disableCancel,
    rejectionReason,
    status: documentStatus,
    onAccept = noop,
    onClick = noop,
    onCancel = noop,
    onChangeDocumentType = noop,
    onDecrypt = noop,
    onFileSelect = noop,
    onRemove,
    forceRemoveButton,
    onShowRejection = noop,
    fileType,
    showDownloadIcon,
    internal = false,
    onReject = noop,
    id: documentId,
    shareWithLenderAllowed = true,
    shareable = false,
    documentType,
    contentType,
    pdfObjectName,
    ...restOfProps
  } = props;
  const [hoverRemove, setHoverRemove] = useState(false);
  const [hover, setHover] = useState(false);
  const [status, setStatus] = useState(documentStatus);

  const {fetch, ...query} = useSignedUrl({lazy: true});
  const failed = status === taskDocumentStatuses.failed;
  const rejected = status === taskDocumentStatuses.rejected;
  const progress = status === taskDocumentStatuses.progress;
  const processing = status === taskDocumentStatuses.processingFile;
  const isAccepted = status === taskDocumentStatuses.accepted;
  const canShareWithLender = Boolean(documentType) && shareWithLenderAllowed;
  const canRemove =
    forceRemoveButton || internal || (!immutable && !progress && !isAccepted);
  const isEncrypted = isPasswordProtected(props);

  const signedUrl = pathOr(null, ['signedUrl', 'url'], query);

  const fetchSignedUrl = useCallback(async () => {
    const response = await fetch({variables: {objectName}});
    return response;
  }, [objectName]);

  useEffect(() => setStatus(documentStatus), [documentStatus]);

  const onAcceptDocument = (...args) => {
    setStatus(taskDocumentStatuses.accepted);
    onAccept(...args);
  };

  const onRejectDocument = (...args) => {
    setStatus(taskDocumentStatuses.rejected);
    onReject(...args);
  };

  const onClickDocument = async (e) => {
    if (!openTabOnClick) {
      return onClick(e);
    }

    if (signedUrl) {
      return openTab(signedUrl);
    }

    if (objectName) {
      const response = await fetchSignedUrl();
      logger.debug('response', response);
      openTab(pathOr(null, ['data', 'signedUrl', 'url'], response));
    }
  };

  const onMouseOver = () => {
    if (disabled) return;

    if (openTabOnClick && !signedUrl && objectName) {
      fetchSignedUrl();
    }
    setHover(true);
  };

  // For now, we only want to allow Borrowers, currently editing tasks
  // To be able to supply a password for this given document.
  if (isEncrypted && !immutable && !internal) {
    return (
      <PasswordPrompt
        {...props}
        hoverRemove={hoverRemove}
        onDecrypt={onDecrypt}
        onMouseOverRemove={() => setHoverRemove(true)}
        onMouseOutRemove={() => setHoverRemove(false)}
        status={status}
      />
    );
  }

  return (
    <Wrapper
      {...pick([...MARGIN_KEYS, ...PADDING_KEYS], restOfProps)}
      error={failed || rejected}
      isAccepted={isAccepted}
      hover={hoverRemove || hover}
      disabled={disabled}
      onClick={onClickDocument}
      onMouseOver={onMouseOver}
      onMouseOut={() => setHover(false)}>
      <Box>
        <DocumentIconType
          canSelect={canSelect}
          disableCancel={disableCancel}
          documentId={documentId}
          fileName={fileType || fileName}
          internal={internal}
          isFileSelected={isFileSelected}
          rejectionReason={rejectionReason}
          onCancel={onCancel}
          onFileSelect={onFileSelect}
          passwordProtectedFile={passwordProtectedFile}
          status={status}
        />
      </Box>

      <DocumentTimestamp {...props} status={status} />

      <Flex flexShrink={1} alignItems="center" justifyContent="space-between">
        {!internal && !(failed || rejected) && fileSizeBytes && (
          <SizeLabel mr="8px">
            <FileSize value={fileSizeBytes} />
          </SizeLabel>
        )}
        {rejected && (
          <ShowDetails flexShrink={0}>
            <Button
              variant="outline"
              onClick={(event) => {
                event.stopPropagation();
                onShowRejection(documentId);
              }}>
              {detailsTitle}
            </Button>
          </ShowDetails>
        )}
        {internal && (
          <InternalActions
            allowedDocumentTypes={allowedDocumentTypes}
            canChangeDocumentType={canChangeDocumentType}
            canAcceptReject={!rejected && !processing}
            canShareWithLender={canShareWithLender}
            disabled={areActionsDisabled || disabled}
            documentId={documentId}
            documentType={documentType}
            isAccepted={isAccepted}
            isRejected={rejected}
            onAccept={onAcceptDocument}
            onChangeDocumentType={onChangeDocumentType}
            onReject={onRejectDocument}
            shareable={shareable}
          />
        )}
        {showDownloadIcon && (
          <Box>
            <HoverLink id="file-download-button">
              <Downloads
                documentType={documentType}
                fileName={fileName}
                id={documentId}
                pdfObjectName={pdfObjectName}
                contentType={contentType}
                objectName={objectName}
              />
            </HoverLink>
          </Box>
        )}
        {canRemove && (
          <RemoveDocument
            documentId={documentId}
            hoverRemove={hoverRemove}
            onClick={onRemove}
            onMouseOver={() => setHoverRemove(true)}
            onMouseOut={() => setHoverRemove(false)}
          />
        )}
      </Flex>
    </Wrapper>
  );
};

TaskDocument.propTypes = {
  id: validateUuidProp,
  addedPrefix: PropTypes.string,
  allowedDocumentTypes: PropTypes.arrayOf(PropTypes.string),
  areActionsDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  canChangeDocumentType: PropTypes.bool,
  canSelect: PropTypes.bool,
  rejectedPrefix: PropTypes.string,
  reviewedPrefix: PropTypes.string,
  error: PropTypes.string,
  status: PropTypes.string,
  isFileSelected: PropTypes.bool,
  createdAt: validateIsoProp,
  reviewedAt: validateIsoProp,
  immutable: PropTypes.bool,
  fileName: PropTypes.string,
  fileSizeBytes: PropTypes.number,
  rejectionReason: PropTypes.string,
  objectName: PropTypes.string,
  disableCancel: PropTypes.bool,
  onAccept: PropTypes.func,
  onChangeDocumentType: PropTypes.func,
  onClick: PropTypes.func,
  onCancel: PropTypes.func,
  onDecrypt: PropTypes.func,
  onFileSelect: PropTypes.func,
  onRemove: PropTypes.func,
  passwordProtectedFile: PropTypes.bool,
  forceRemoveButton: PropTypes.bool,
  onShowRejection: PropTypes.func,
  openTabOnClick: PropTypes.bool,
  fileType: PropTypes.string,
  showDownloadIcon: PropTypes.bool,
  internal: PropTypes.bool,
  onReject: PropTypes.func,
  shareWithLenderAllowed: PropTypes.bool,
  shareable: PropTypes.bool,

  documentType: PropTypes.string,
  contentType: PropTypes.string,
  pdfObjectName: PropTypes.string,
};

export default memo(TaskDocument);
