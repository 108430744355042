import styled from '@emotion/styled';

import {actionExtraLight, actionRegular, basic20} from '@renofi/theme';

import Box from '../Box';
import Text from '../Text';

export const Wrapper = styled(Box)(({button, checked, disabled, noBorder}) => {
  const border = checked ? actionRegular : basic20;
  const borderWidth = checked ? 1 : 1;
  const common = {
    'input:checked ~ svg': {
      color: disabled ? basic20 : '#3786FD',
    },
    'input:focus ~ svg': {
      backgroundColor: 'transparent !important',
    },
    'input ~ svg': {
      marginTop: -4,
      marginLeft: -6,
      width: 20,
    },
    cursor: disabled ? 'default' : 'pointer',
  };
  if (button) {
    return {
      padding: 16,
      border: noBorder ? 'none' : `${borderWidth}px solid ${border}`,
      borderRadius: 4,
      background: checked ? actionExtraLight : 'none',
      ...common,
    };
  }

  return {
    ...common,
  };
});

export const Container = styled.label(
  {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  ({empty}) => ({
    ...(empty ? {display: 'inline-block'} : {}),
  }),
);

export const Label = styled(Text)(
  ({disabled}) => ({
    fontSize: 15,
    lineHeight: 1,
    marginLeft: 5,
    opacity: disabled ? 0.5 : 1,
  }),
  ({small}) => ({
    fontSize: small ? 13 : 15,
  }),
);
