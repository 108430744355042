import React from 'react';

import PropTypes from 'prop-types';

import {Item, ItemTitle, ItemValue} from './styled';

const ListItem = ({title, children, skipComma}) => {
  return (
    <Item>
      <ItemTitle>{title}: </ItemTitle>
      <ItemValue>{children || '-'}</ItemValue>
      {skipComma ? '' : ','}
    </Item>
  );
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  skipComma: PropTypes.bool,
};

export default ListItem;
