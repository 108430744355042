const FILE_NAME_PREFIXES = {
  contractor_info: 'contractor license',
  home_insurance: 'proof of insurance',
  mortgage_statement: 'mortgage statement',
  renovation_contract: 'renovation contract',
  renovation_plan: 'renovation plans',
};

export default function getDropZoneFileNamePrefix(taskType) {
  return FILE_NAME_PREFIXES[taskType] || 'file';
}
