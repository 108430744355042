import React, {useState, useCallback, useContext} from 'react';

import {useParams, useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {propOr} from 'ramda';
import {StickyHeader} from 'modules/layout';
import {
  ProgressToast,
  AdditionalDocuments,
  ContractorV3,
  Renovation,
  ProductConfirmation,
  BorrowerDetails,
  Nothing,
  constants,
  styles,
} from 'modules/project';
import {TasksWizardContainer} from 'modules/tasks-wizard';

import {Flex, Loader} from '@renofi/components-internal';
import {useGetUser, useToggledBorrowerProject} from '@renofi/graphql/src/hooks';
import {Context as ConfigContext} from '@renofi/utilities/src/config';

import {getPageTitleByFacet} from './utils';
import {CONSECUTIVE_IDS_TO_SHOW_TOAST} from './constants';

const {TASK_CATEGORIES} = constants;
const {StyledContainer} = styles;

const Tasks = () => {
  const {user} = useGetUser();
  const config = useContext(ConfigContext);

  const history = useHistory();
  const params = useParams();
  const {facet} = params;

  const {project, loading} = useToggledBorrowerProject();
  const [consecutiveTaskIds, setConsecutiveTaskIds] = useState(new Set());

  const title = getPageTitleByFacet({facet, history, params});
  const isRaas = propOr(false, 'raas', project);
  const projectId = propOr(null, 'id', project);

  const onTaskComplete = useCallback(
    (taskId) => {
      const newConsecutiveTaskIds = new Set(consecutiveTaskIds);
      newConsecutiveTaskIds.add(taskId);

      setConsecutiveTaskIds(newConsecutiveTaskIds);
    },
    [consecutiveTaskIds],
  );

  const numberOfConsecutiveTasks = consecutiveTaskIds.size;
  const canShowToast = CONSECUTIVE_IDS_TO_SHOW_TOAST.includes(
    numberOfConsecutiveTasks,
  );

  if (loading) {
    return <Loader />;
  }

  if (!projectId) {
    return <Nothing />;
  }

  const facetProps = {isRaas, onTaskComplete, projectId};

  const goBack = () => {
    history.push(`/${projectId}/tasks`);
  };

  return (
    <TasksWizardContainer>
      <Flex
        justifyContent="center"
        data-testid={`container-${facet}`}
        width="100%">
        <StyledContainer narrow mt={16}>
          <StickyHeader backButton onBackClick={goBack} title={title} />

          {facet === TASK_CATEGORIES.ADDITIONAL_DOCUMENTS && (
            <AdditionalDocuments config={config} {...facetProps} />
          )}
          {facet === TASK_CATEGORIES.RENOVATION && (
            <Renovation config={config} {...facetProps} />
          )}
          {facet === TASK_CATEGORIES.CONTRACTOR && (
            <ContractorV3 {...facetProps} />
          )}
          {facet === TASK_CATEGORIES.APPRAISAL && (
            <Renovation config={config} {...facetProps} />
          )}
          {facet === TASK_CATEGORIES.INSURANCE && (
            <Renovation config={config} {...facetProps} />
          )}
          {facet === TASK_CATEGORIES.PRODUCT && (
            <ProductConfirmation {...facetProps} />
          )}
          {facet === TASK_CATEGORIES.BORROWERS && (
            <BorrowerDetails config={config} {...facetProps} user={user} />
          )}
          {canShowToast && <ProgressToast stage={numberOfConsecutiveTasks} />}
        </StyledContainer>
      </Flex>
    </TasksWizardContainer>
  );
};

Tasks.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(Tasks);
