import {useQuery, useApolloClient} from '@apollo/client';
import {pathOr} from 'ramda';

import {GET_APPLICATION_STATE} from '../../../api/queries';

const useApplicationState = () => {
  const client = useApolloClient();
  const {data, refetch} = useQuery(GET_APPLICATION_STATE, {
    lazy: true,
  });
  const applicationState = pathOr({}, ['application'], data);

  const readApplicationState = () => {
    const {application} = client.readQuery({
      query: GET_APPLICATION_STATE,
    });
    return application;
  };

  const updateApplicationState = (partial) => {
    const application = readApplicationState();
    client.writeQuery({
      query: GET_APPLICATION_STATE,
      data: {
        application: {
          ...application,
          ...partial,
        },
      },
    });
  };

  const onCloseRejection = async () => {
    const {rejection} = readApplicationState();
    const partial = {documentId: null, taskId: null};

    updateApplicationState({
      rejection: {...rejection, ...partial},
    });
  };

  const onCloseAdditionalRequest = async () => {
    const {additionalRequest} = readApplicationState();
    const partial = {taskId: null};

    updateApplicationState({
      additionalRequest: {...additionalRequest, ...partial},
    });
  };

  const onShowRejection = async ({documentId, taskId}) => {
    const partial = {documentId, taskId};

    const {rejection} = readApplicationState();
    updateApplicationState({rejection: {...rejection, ...partial}});
  };

  return {
    applicationState,
    refetch,
    readApplicationState,
    updateApplicationState,

    onCloseRejection,
    onCloseAdditionalRequest,
    onShowRejection,
  };
};

export default useApplicationState;
