import {gql} from '@apollo/client';

export default gql`
  query application {
    application @client {
      rejection {
        taskId
        documentId
      }
      additionalRequest {
        taskId
      }
      review {
        completed
      }
    }
  }
`;
