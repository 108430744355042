import React from 'react';

import PropTypes from 'prop-types';
import {useAllProjectTasks} from 'lib/projectTasks';

import getCoborrowerName from '@renofi/utilities/src/data/getCoborrowerName';
import {useToggledBorrowerProject} from '@renofi/graphql';

import {Card} from '../../../styled';
import {BADGE_VARIANTS} from '../../../BorrowerDetails/constants';

import IdentityTaskRoleStatus from './IdentityTaskRoleStatus';

const IdentityExtraDetails = ({task}) => {
  const {allTasks} = useAllProjectTasks();
  const {project} = useToggledBorrowerProject();
  const creditCheckTask =
    allTasks.find(
      (task) =>
        task.taskType === 'soft_credit_check' &&
        task?.softCreditCheck?.coborrowerFirstName,
    ) || {};
  const coborrowerName = getCoborrowerName(creditCheckTask?.softCreditCheck);
  const hasCoborrower = coborrowerName?.length > 0;
  const {fullName} = project?.borrower || {};

  return (
    <Card css={{marginTop: 0}} variant={BADGE_VARIANTS.completed}>
      <IdentityTaskRoleStatus
        task={task}
        role="borrower"
        name={fullName}
        isPrimary
      />

      {hasCoborrower ? (
        <IdentityTaskRoleStatus
          task={task}
          role="coborrower"
          name={coborrowerName}
          isPrimary={false}
        />
      ) : null}
    </Card>
  );
};

IdentityExtraDetails.propTypes = {
  task: PropTypes.object,
};

export default IdentityExtraDetails;
