import React from 'react';

import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import {Box, Link} from '@renofi/components-internal';
import {maxSmall} from '@renofi/theme/src/breakpoints';

const StyledBox = styled(Box)({
  [maxSmall]: {
    width: '100%',
  },
});

const HeaderContent = ({
  complete,
  disabled,
  fastTracked,
  isTpo,
  lenderName,
}) => {
  if (complete) {
    return (
      <Box width={1}>
        We are happy to hear you closed the loan with {lenderName}! To make sure
        things run smoothly frequently add renovation updates in the{' '}
        <Link to="/renovation-progress">Renovate!</Link> step.
      </Box>
    );
  }

  if (disabled) {
    return (
      <StyledBox width={600}>
        After your Project Eligibility has been reviewed by the RenoFi team and
        approved by the lender, you’ll be able to apply directly here.
      </StyledBox>
    );
  }

  if (isTpo || (fastTracked && isTpo)) {
    return (
      <StyledBox>
        We have collected all the information necessary to get your formal
        application kicked off with {lenderName}. There is nothing you need to
        do at this time to formally apply.
      </StyledBox>
    );
  }

  return (
    <StyledBox>
      We’ve double checked the documentation that you provided and sent it to{' '}
      {lenderName} and they are now ready for you to formally apply online.
    </StyledBox>
  );
};

HeaderContent.propTypes = {
  lenderName: PropTypes.string,
  complete: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  fastTracked: PropTypes.bool,
  isTpo: PropTypes.bool.isRequired,
};

export default HeaderContent;
