import React from 'react';

import PropTypes from 'prop-types';

import {compose} from '@renofi/recompose/src';
import withImpersonation from '@renofi/utilities/src/withImpersonation';

import Header from './Header';

const Page = ({children}) => (
  <>
    <Header />
    <div>{children}</div>
  </>
);

Page.propTypes = {
  impersonation: PropTypes.bool,
  children: PropTypes.node,
};

export default compose(withImpersonation)(Page);
