import React from 'react';

import PropTypes from 'prop-types';

import {TextBlock} from '@renofi/components-internal';
import {isMobile} from '@renofi/theme';

const FacetIntro = ({children, title}) => {
  const padding = isMobile() ? 16 : 24;

  return (
    <TextBlock p={padding} title={title}>
      {children}
    </TextBlock>
  );
};

FacetIntro.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]),
  title: PropTypes.string,
};

export default FacetIntro;
