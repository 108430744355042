import React from 'react';

import PropTypes from 'prop-types';

const ThumbsUpCircle = ({width, height}) => {
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="68.5" cy="68.9985" r="68.5" fill="#FFF7E9" />
      <rect
        opacity="0.2"
        y="1"
        width="137"
        height="136.222"
        rx="68.1108"
        fill="#FFF7E9"
      />
      <mask
        id="mask0_652_102965"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="137"
        height="137">
        <rect y="1" width="137" height="136.222" rx="68.1108" fill="white" />
      </mask>
      <g mask="url(#mask0_652_102965)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.4949 87.7155L74.5722 88.6495L83.9131 86.7814L87.6495 83.9791L89.5177 63.4291L86.7154 57.8245C86.7154 57.8245 83.9131 56.8905 82.0449 58.7586C79.9327 60.4484 76.4404 62.495 75.5063 59.6927C74.9148 57.9183 72.704 62.495 72.704 62.495L82.0449 70.9018L80.1768 77.4405V80.2427C80.1768 80.2427 82.979 84.9132 79.2427 84.9132C75.5063 84.9132 68.9677 83.045 68.9677 83.045L62.429 84.9132L61.4949 87.7155Z"
          fill="#CCCCCC"
        />
        <g opacity="0.15">
          <path
            d="M4.65953 10.0012L0.500245 15.1237L17.199 21.1026L18.6701 17.1972L4.65953 10.0012Z"
            fill="#FF5253"
          />
          <path
            d="M127.531 15.4435L132.369 19.9308L120.888 25.0314L118.883 21.3715L127.531 15.4435Z"
            fill="#FF5253"
          />
          <path
            d="M99.0106 5.00959L94.8203 2.40652L93.4076 11.6919L96.4425 12.4157L99.0106 5.00959Z"
            fill="#FF5253"
          />
          <path
            d="M44.6832 42.8836L45.8363 36.448L56.2337 43.2927L54.2547 46.9234L44.6832 42.8836Z"
            fill="#FFB430"
          />
          <path
            d="M34.1601 63.6833L35.0479 58.8308L25.8902 60.9165L26.328 64.0055L34.1601 63.6833Z"
            fill="#FF5253"
          />
          <path
            d="M49.2515 10.0703L45.299 12.0404L49.4563 17.4429L51.3594 16.2157L49.2515 10.0703Z"
            fill="#006099"
          />
          <path
            d="M118.527 46.1026L116.8 42.0381L111.156 45.8607L112.265 47.8346L118.527 46.1026Z"
            fill="#006099"
          />
          <path
            d="M28.8991 -8.6644L34.5483 -16.1667L43.4404 -0.653904L38.5504 2.71762L28.8991 -8.6644Z"
            fill="#E7F9E9"
          />
          <path
            d="M78.7879 21.4811L73.0535 19.3659L73.6526 30.9874L77.5163 31.1097L78.7879 21.4811Z"
            fill="#FFB430"
          />
          <path
            d="M29.9877 10.5542L33.8658 6.68465L40.8059 15.6934L35.792 16.3714L29.9877 10.5542Z"
            fill="#FFA8A8"
          />
          <path
            d="M86.594 -5.51986L81.946 -5.08452L84.0727 4.36958L87.247 1.45209L86.594 -5.51986Z"
            fill="#006099"
          />
          <path
            d="M-5.27533 69.3922L-4.49318 77.7583L12.5241 73.9334L7.27383 68.219L-5.27533 69.3922Z"
            fill="#006099"
          />
          <path
            d="M99.3121 31.3829L95.2178 29.1401L91.6842 38.1632L95.948 37.5243L99.3121 31.3829Z"
            fill="#89E193"
          />
          <path
            d="M8.74212 42.6935L9.22779 38.0506L18.6583 40.2795L15.7066 43.422L8.74212 42.6935Z"
            fill="#89E193"
          />
        </g>
        <g opacity="0.15">
          <path
            d="M31.1389 124.348L26.2677 119.897L37.7102 114.711L39.7427 118.355L31.1389 124.348Z"
            fill="#FF5253"
          />
          <path
            d="M59.737 134.568L63.9467 137.139L65.2897 127.844L62.2496 127.143L59.737 134.568Z"
            fill="#FF5253"
          />
          <path
            d="M113.779 96.2883L112.674 102.732L102.226 95.9657L104.178 92.3202L113.779 96.2883Z"
            fill="#FFB430"
          />
          <path
            d="M116.72 79.646L120.502 76.4788L112.123 72.2355L110.487 74.8922L116.72 79.646Z"
            fill="#FF5253"
          />
          <path
            d="M109.457 129.135L113.394 127.135L109.197 121.764L107.303 123.005L109.457 129.135Z"
            fill="#006099"
          />
          <path
            d="M129.949 147.716L124.356 155.261L115.348 139.815L120.213 136.407L129.949 147.716Z"
            fill="#E7F9E9"
          />
          <path
            d="M79.8358 117.946L85.5858 120.018L84.8997 108.401L81.0352 108.308L79.8358 117.946Z"
            fill="#FFB430"
          />
          <path
            d="M128.716 128.506L124.867 132.405L117.86 123.448L122.869 122.733L128.716 128.506Z"
            fill="#FFA8A8"
          />
          <path
            d="M72.232 145.004L76.8766 144.534L74.6792 135.096L71.5268 138.037L72.232 145.004Z"
            fill="#006099"
          />
          <path
            d="M59.2379 108.198L63.3489 110.41L66.8149 101.361L62.556 102.032L59.2379 108.198Z"
            fill="#89E193"
          />
          <path
            d="M143.918 91.4789L143.776 83.798L128 86.1239L132.397 91.6922L143.918 91.4789Z"
            fill="#89E193"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.5608 89.5828C60.5608 89.5828 63.363 88.6487 68.9676 89.5828C74.5721 90.5169 77.3744 91.451 82.9789 89.5828C88.5835 87.7146 88.5835 83.9783 88.5835 83.9783C88.5835 83.9783 91.3858 80.2419 91.3858 78.3737C91.3858 76.5056 90.4517 73.2654 90.4517 70.901C90.4517 68.5366 91.3858 66.2306 91.3858 66.2306C91.3858 66.2306 91.6881 62.8515 90.4517 60.626C89.2153 58.4005 86.7153 57.8237 86.7153 57.8237C86.7153 57.8237 86.7153 60.626 82.9789 61.5601C79.2426 62.4942 75.5062 61.5601 75.5062 59.6919C75.5062 57.8237 77.3744 50.9737 75.5062 46.3033C73.638 41.6328 68.9676 40.6987 68.9676 40.6987L68.0093 41.657L67.0994 42.5669C67.0994 42.5669 70.8358 48.4828 68.9676 53.1533C67.0994 57.8237 65.2312 58.7578 65.2312 63.4283C65.2312 68.0987 57.7585 73.7033 57.7585 73.7033L60.5608 89.5828Z"
          fill="#D8D8D8"
        />
        <mask
          id="mask1_652_102965"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="57"
          y="40"
          width="35"
          height="51">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.5608 89.5828C60.5608 89.5828 63.363 88.6487 68.9676 89.5828C74.5721 90.5169 77.3744 91.451 82.9789 89.5828C88.5835 87.7146 88.5835 83.9783 88.5835 83.9783C88.5835 83.9783 91.3858 80.2419 91.3858 78.3737C91.3858 76.5056 90.4517 73.2654 90.4517 70.901C90.4517 68.5366 91.3858 66.2306 91.3858 66.2306C91.3858 66.2306 91.6881 62.8515 90.4517 60.626C89.2153 58.4005 86.7153 57.8237 86.7153 57.8237C86.7153 57.8237 86.7153 60.626 82.9789 61.5601C79.2426 62.4942 75.5062 61.5601 75.5062 59.6919C75.5062 57.8237 77.3744 50.9737 75.5062 46.3033C73.638 41.6328 68.9676 40.6987 68.9676 40.6987L68.0093 41.657L67.0994 42.5669C67.0994 42.5669 70.8358 48.4828 68.9676 53.1533C67.0994 57.8237 65.2312 58.7578 65.2312 63.4283C65.2312 68.0987 57.7585 73.7033 57.7585 73.7033L60.5608 89.5828Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_652_102965)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.7039 41.0108C72.7039 41.0108 75.5061 46.6153 74.572 51.2858C73.6379 55.9562 71.7698 62.4949 73.6379 62.4949C75.5061 62.4949 81.1107 63.4289 85.7811 60.6267C90.4516 57.8244 83.9129 44.7471 82.0448 44.7471C80.1766 44.7471 78.3084 39.1426 78.3084 39.1426L72.7039 41.0108Z"
            fill="#CCCCCC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.3812 73.392C58.3812 73.392 62.2732 74.1704 66.9437 79.6193C69.766 82.0502 78.7954 79.4164 85.7812 76.5057C87.3882 75.8361 88.6416 75.9223 89.5175 74.1704C90.2959 72.6136 89.5175 69.5 90.2959 67.9432C91.0743 66.3863 91.8527 64.2716 91.8527 64.0511C91.8527 61.3555 95.1221 72.7693 95.1221 72.7693C95.1221 72.7693 96.5809 77.0428 95.1221 81.1761C93.6633 85.3094 89.5175 86.7807 89.5175 88.6488C89.5175 90.517 82.0448 94.2534 80.1766 94.2534C78.3084 94.2534 70.8357 94.2534 70.8357 94.2534H63.363L59.6266 90.517C59.6266 90.517 62.1809 89.1448 63.0516 87.4034C63.9223 85.662 63.7949 80.6236 63.7949 80.6236C63.7949 80.6236 61.4948 76.5057 56.8243 74.1704L58.3812 73.392Z"
            fill="#CCCCCC"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.89172 92.3859C24.131 86.7814 57.7583 73.7041 57.7583 73.7041C57.7583 73.7041 68.0333 77.4405 63.3628 89.5836C39.0765 99.8586 2.64691 111.068 2.64691 111.068C2.64691 111.068 -2.02354 99.8586 -3.89172 92.3859Z"
          fill="#FF5253"
        />
        <mask
          id="mask2_652_102965"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="-4"
          y="73"
          width="69"
          height="39">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-3.89172 92.3859C24.131 86.7814 57.7583 73.7041 57.7583 73.7041C57.7583 73.7041 68.0333 77.4405 63.3628 89.5836C39.0765 99.8586 2.64691 111.068 2.64691 111.068C2.64691 111.068 -2.02354 99.8586 -3.89172 92.3859Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_652_102965)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.2852 86.7813C38.142 91.4518 -2.02386 105.463 -2.02386 105.463L0.778408 114.804L68.967 90.5177L70.8352 84.9132L65.2307 76.5063C65.2307 76.5063 62.4284 82.1109 50.2852 86.7813Z"
            fill="#D54B4D"
          />
        </g>
      </g>
    </svg>
  );
};

ThumbsUpCircle.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

ThumbsUpCircle.defaultProps = {
  height: 137,
  width: 137,
};

export default ThumbsUpCircle;
