import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {sendEvent} from '@renofi/utilities/src/analytics/utils';

import {STEP_MAPPER} from '../constants';

import BaseContent from './BaseContent';

const BorrowerOnLoan = ({setStep, update}) => {
  const nextStep = async (isAccepted) => {
    await update({borrowerIsOnLoan: isAccepted});
    setStep(STEP_MAPPER.CoBorrower);
    sendEvent('Secure/Onboarding-User-Borrower-Selected');
  };

  useEffect(() => {
    sendEvent('Secure/Onboarding-User-Borrower-Presented');
  }, []);

  return (
    <BaseContent
      mainContent="Will you be a borrower on the loan?"
      onAccept={() => nextStep(true)}
      onDecline={() => nextStep(false)}
      sideContentCss={{marginTop: 40}}
    />
  );
};

BorrowerOnLoan.propTypes = {
  update: PropTypes.func,
  setStep: PropTypes.func,
};

export default BorrowerOnLoan;
