import {TASK_STATUSES} from 'modules/project/components/Project/constants';

import {
  INITIAL_GROUPED_STEPS,
  INITIAL_STEPS,
  TAGS,
  STEP_TYPES,
  CO_BORROWER_STEP_TYPES,
  GROUPED_STEPS,
  INITIAL_ID_DETAILS,
  CO_BORROWER_INIT_DETAILS,
} from './constants';

export const updateStepsItems = (
  id,
  wizardIdTypes,
  taskStatus,
  govIdDetails,
) => {
  const isPendingTask = taskStatus === TASK_STATUSES.processing;
  const selectedIdLabel = wizardIdTypes[0]?.label || '';
  const stepsInfo = getStepsInfo(true, govIdDetails);

  return INITIAL_STEPS.map((item) => {
    const isCurrent = item.id === id;
    const isComplete = item.id < id || (isPendingTask && !isCurrent);
    return {
      ...item,
      current: isCurrent,
      complete: isComplete,
      info:
        getChildInfoText(item, isComplete, selectedIdLabel, stepsInfo) || '',
    };
  });
};

export const updateGroupedStepsItems = (
  activeStepId,
  wizardIdTypes,
  taskStatus,
  govIdDetails,
  project,
) => {
  const isPendingTask = taskStatus === TASK_STATUSES.processing;
  return INITIAL_GROUPED_STEPS.map((step, index) => {
    const isPrimaryBorrower = step.id === GROUPED_STEPS.PRIMARY_BORROWER_STEP;
    const isCoBorrower = step.id === GROUPED_STEPS.CO_BORROWER_STEP;

    const isCurrent =
      isPrimaryBorrower ||
      (isCoBorrower && activeStepId > CO_BORROWER_STEP_TYPES.UPLOAD_ID);

    const {borrowersAndPropertyInformation: borrowersInfo, borrower} = project;
    const borrowerInfo =
      step.id === GROUPED_STEPS.PRIMARY_BORROWER_STEP
        ? borrower
        : {
            firstName: borrowersInfo.coborrowerFirstName,
            lastName: borrowersInfo.coborrowerLastName,
          };
    const stepInfo = `${borrowerInfo.firstName} ${borrowerInfo.lastName}`;
    const selectedIdLabel = wizardIdTypes[index]?.label || '';
    const isComplete = isPendingTask && !isCurrent;
    const stepsInfo = getStepsInfo(isPrimaryBorrower, govIdDetails);

    return {
      ...step,
      current: isCurrent,
      info: stepInfo,
      complete: isComplete,
      children: step.children.map((option) => {
        const isCurrent = option.id === activeStepId;
        const isComplete =
          option.id < activeStepId || (isPendingTask && !isCurrent);
        return {
          ...option,
          current: option.id === activeStepId,
          complete: isComplete,
          info:
            getChildInfoText(option, isComplete, selectedIdLabel, stepsInfo) ||
            '',
        };
      }),
    };
  });
};

const getStepsInfo = (isPrimaryBorrower, govIdDetails) => {
  const borrowerDetails = getBorrowerDetails(
    isPrimaryBorrower ? 'primary' : 'co',
    govIdDetails,
  );
  const idNumber = borrowerDetails?.idNumber;
  const filesCount = borrowerDetails?.documentIds?.length;
  const uploadedFiles =
    filesCount > 1 ? `${filesCount} files` : `${filesCount} file`;

  return {idNumber, uploadedFiles};
};

const getChildInfoText = (option, isComplete, selectedIdLabel, stepsInfo) => {
  if (option.value === 'ID type') {
    return selectedIdLabel;
  }
  if (isComplete) {
    const {idNumber, uploadedFiles} = stepsInfo || {};
    const isUploadPage = [
      CO_BORROWER_STEP_TYPES.UPLOAD_ID,
      CO_BORROWER_STEP_TYPES.CO_UPLOAD_ID,
    ].includes(option.id);
    return isUploadPage ? uploadedFiles : idNumber;
  }
};

export const prepareFormData = ({
  idNumber,
  issuingCountry,
  issuingState,
  issueDate,
  expirationDate,
}) => {
  return {
    idNumber: idNumber,
    country: issuingCountry,
    state: issuingState,
    issueDate: issueDate || '',
    expDate: expirationDate || '',
  };
};

export const prepareDataToSubmit = (taskId, idType, formData, borrowerType) => {
  const payload = {
    taskId,
    govIdDetails: {
      idType,
      idNumber: formData?.idNumber?.toString(),
      issuingCountry: formData?.country,
      issuingState: formData?.state,
      issueDate: normalizeDate(formData?.issueDate),
      expirationDate: normalizeDate(formData?.expDate),
      borrowerRole: borrowerType,
    },
  };
  return payload;
};

const normalizeDate = (date) => {
  const currentDate = new Date(date);
  currentDate.setHours(12);
  return new Date(currentDate);
};

export const getIdType = (type) => {
  return TAGS[0].items.find((idType) => idType.value === type);
};

export const getWizardConfig = (hasCoBorrower, currentStep) => {
  const {
    ID_TYPE,
    CO_ID_TYPE,
    ID_DETAILS,
    CO_ID_DETAILS,
    UPLOAD_ID,
    CO_UPLOAD_ID,
  } = CO_BORROWER_STEP_TYPES;

  const isIdTypePage = currentStep === ID_TYPE;
  const isDetailsPage = currentStep === ID_DETAILS;
  const isUploadPage = currentStep === UPLOAD_ID;

  const isCoIdTypePage = currentStep === CO_ID_TYPE;
  const isCoDetailsPage = currentStep === CO_ID_DETAILS;
  const isCoUploadPage = currentStep === CO_UPLOAD_ID;

  const primaryBorrower = {
    type: 'primary',
    isIdTypePage: isIdTypePage,
    isDetailsPage: isDetailsPage,
    isUploadPage: isUploadPage,
  };
  const coBorrower = {
    type: 'co',
    isIdTypePage: isCoIdTypePage,
    isDetailsPage: isCoDetailsPage,
    isUploadPage: isCoUploadPage,
  };

  if (hasCoBorrower) {
    return {
      isFirstStep: isIdTypePage,
      isFinalStep: isCoUploadPage,
      isCoBorrowerIntroPage: isCoIdTypePage,
      finalLabel: 'I’m done with this task',
      nextLabel: isUploadPage ? 'I’m finished uploading' : 'Next',
      borrowersToShow: [primaryBorrower, coBorrower],
    };
  }
  return {
    isFirstStep: currentStep === STEP_TYPES.ID_TYPE,
    isFinalStep: currentStep === STEP_TYPES.UPLOAD_ID,
    finalLabel: 'I’m done with this task',
    borrowersToShow: [primaryBorrower],
  };
};

export const getBorrowerDetails = (type, listOfDetails) => {
  if (type === 'primary') {
    const primaryBorrowerDetails = listOfDetails.find(
      (details) => details.borrowerRole === 'borrower',
    );
    return primaryBorrowerDetails ? primaryBorrowerDetails : INITIAL_ID_DETAILS;
  }
  if (type === 'co') {
    const coBorrowerDetails = listOfDetails.find(
      (details) => details.borrowerRole === 'coborrower',
    );
    return coBorrowerDetails ? coBorrowerDetails : CO_BORROWER_INIT_DETAILS;
  }
};

export const sortBorrowers = (borrowersList) => {
  return borrowersList.sort((a, b) => (a.id > b.id ? 1 : -1));
};
