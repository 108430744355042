import React, {useCallback, useState} from 'react';

import PropTypes from 'prop-types';

import Flex from '../Flex';
import Box from '../Box';

import {Container, Content, Title} from './styled';
import {Close} from './components/Close';

function Toast(props = {}) {
  const {children, duration, loop, onClose, onLoop, title} = props;
  const [show, setShow] = useState(true);
  const onCloseCallback = useCallback(() => {
    onClose();
    setShow(false);
  });

  const onLoopCallback = useCallback(() => {
    if (!loop) {
      return setShow(false);
    }

    onLoop();
  });

  if (!show) {
    return null;
  }

  return (
    <Container {...props}>
      <Flex alignItems="center">
        <Box>
          <Title>{title}</Title>
          {children && <Content>{children}</Content>}
        </Box>

        <Box ml="auto">
          <Close
            duration={duration}
            onClick={onCloseCallback}
            onLoopComplete={onLoopCallback}
          />
        </Box>
      </Flex>
    </Container>
  );
}

Toast.propTypes = {
  children: PropTypes.node,
  duration: PropTypes.number,
  loop: PropTypes.bool,
  onClose: PropTypes.func,
  onLoop: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

Toast.defaultProps = {
  onClose: () => {},
  onLoop: () => {},
};

Toast.displayName = 'Toast';

export default Toast;
