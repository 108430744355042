import React from 'react';

const LenderIcon = (props) => (
  <svg
    width={18}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      className="first"
      clipRule="evenodd"
      d="M2 8v6.016h3.016V8H2ZM.031 19h16.985v-3H.03v3Zm12-11v6.016h3V8h-3Zm-3.41-8L.023 4.06V6h16.993V4.06L8.62 0Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      className="second"
      clipRule="evenodd"
      d="M8.717 10.412c-.99-.266-1.308-.54-1.308-.967 0-.49.44-.832 1.177-.832.776 0 1.064.382 1.09.944h.963c-.03-.773-.488-1.484-1.399-1.713V6.86H7.932v.972c-.845.188-1.525.755-1.525 1.623 0 1.039.832 1.556 2.048 1.857 1.09.27 1.308.665 1.308 1.084 0 .31-.214.805-1.177.805-.898 0-1.25-.414-1.299-.945h-.959c.052.985.767 1.538 1.604 1.722v.976H9.24v-.967c.85-.166 1.526-.674 1.526-1.596 0-1.277-1.06-1.713-2.05-1.978Z"
      fill="currentcolor"
    />
  </svg>
);

export default LenderIcon;
