import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {VALIDATE_EMAIL} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useValidateEmail({
  fetchPolicy = 'no-cache',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const {fetch, data, loading, error} = useToggledQuery({
    lazy,
    query: VALIDATE_EMAIL,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });
  const valid = pathOr(false, ['validateEmail', 'valid'], data);
  return {
    valid,
    fetch,
    data,
    loading,
    error,
  };
}
