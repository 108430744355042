import styled from '@emotion/styled';

import {Container, Flex} from '@renofi/components-internal';

export const CardsContainer = styled(Flex)({
  flexDirection: 'column',
});

export const StatusContainer = styled.div({
  marginBottom: '23px',
});

export const StyledContainer = styled(Container)({
  width: '100%',
  position: 'relative',
  padding: '0 16px',
});
