export default `
id
createdAt
creditorName
lateCount
loanType
monthlyPayment
projectId
unpaidBalance
updatedAt
borrowerRole
adjustmentReason
creditLiabilityProperties {
    id
    realEstateOwnedPropertyId
    additionalCreditLiabilityId
    softCreditCheckId
    softCreditLiabilityId
}
`;
