import {taskTypes} from '@renofi/utilities/src/enums';

export const IGNORE_TASK_TYPES = [taskTypes.borrowers_onboarding];

export const OUTSTANDING_LABELS = {
  soft_credit_check: 'get started',
  personal_info_and_residency: 'add details',
  real_estate_owned: 'add details',
  assets: 'add assets',
};

export const SELF_EMPLOYMENT_INCOME = [
  {
    label: 'Sole Proprietor',
    value: 'sole_proprietor',
  },
  {
    label: 'S-Corp',
    value: 's_corp',
  },
  {
    label: 'C-Corp',
    value: 'c_corp',
  },
  {
    label: 'LLC',
    value: 'llc',
  },
  {
    label: 'Partnership',
    value: 'partnership',
  },
  {
    label: 'Other',
    value: 'other',
  },
  {
    label: 'Bonus or Variable income',
    value: 'bonus_or_variable_income',
  },
];

export const SELF_EMPLOYMENT_ENTITIES = {
  sole_proprietor: ['the past 2 years of 1040 forms'],
  s_corp: ['1040', '1120-S', 'K1', 'W2'],
  c_corp: ['1040', '1120'],
  llc: ['P&L statements', 'Business taxes forms 1120 or 1065', 'K1 forms'],
  other: ['P&L statements', 'Business taxes forms 1120 or 1065', 'K1 forms'],
  partnership: ['1040', '1060', 'K1 forms'],
  bonus_or_variable_income: ['End of year pay stub'],
};

export const PENDING_LABELS = {
  soft_credit_check: 'edit borrowers',
  personal_info_and_residency: 'Edit personal details',
  real_estate_owned: 'edit details',
  assets: 'edit assets',
};

export const CONTINUE_LABELS = {
  personal_info_and_residency: 'continue',
  real_estate_owned: 'continue',
  assets: 'continue',
};

export const BADGE_VARIANTS = {
  completed: 'successInverse',
  blocked: 'danger',
  pending: 'warning',
  default: 'default',
};
