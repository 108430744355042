import {gql} from '@apollo/client';

import {
  advisorFinalCallSchedule,
  certificateAcknowledgement,
  productDetailsConfirmation,
} from '../../partials';

export default gql`
  mutation presentProductOptionsToBorrower(
    $applicationProcess: RenovationReadyFileApplicationProcess
    $autoTransmitToLender: Boolean
    $interestRate: Float
    $interestRateType: InterestRateTypeEnum
    $lenderId: ID!
    $loanProductId: ID
    $loanType: LoanType
    $projectId: ID!
  ) {
    presentProductOptionsToBorrower(
      applicationProcess: $applicationProcess
      autoTransmitToLender: $autoTransmitToLender
      interestRate: $interestRate
      interestRateType: $interestRateType
      lenderId: $lenderId
      loanProductId: $loanProductId
      loanType: $loanType
      projectId: $projectId
    ) {
      productConfirmationTasks {
        id
        additionalExplanation
        advisorFinalCallSchedule {
          ${advisorFinalCallSchedule}
        }
        allDocumentsAccepted
        anyDocumentRejected
        allDocumentsReviewed
        completedAt
        certificateAcknowledgement {
          ${certificateAcknowledgement}
        }
        contractor {
          businessName
          city
          contactName
          contactTitle
          email
          id
          phoneNumber
          rejectionDetails
          rejectionReason
          secondaryAddress
          state
          status
          streetAddress
          website
          zipCode
        }
        details
        documents {
          id
          checksum
          fileName
          objectName
          pdfObjectName
          rejectionDetails
          rejectionReason
          status
        }
        informationKind
        productDetailsConfirmation {
          ${productDetailsConfirmation}
        }
        requirements
        status
        submittedAt
        title
      }
    }
  }
`;
