import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {assoc, dissoc} from 'ramda';
import {DateField} from 'modules/layout';

import {
  CountriesField,
  Flex,
  StatesField,
  TextField,
} from '@renofi/components-internal';
import GlassIcon from '@renofi/icons/src/Glass';

import {
  StyledPlaceholder,
  FormContainer,
  SelectFieldWrapper,
  PageWrapper,
  PageTitle,
} from '../styled';
import {SEARCH_PLACEHOLDER} from '../constants';

const DropdownPlaceholder = () => (
  <StyledPlaceholder>
    <GlassIcon /> Search or choose from the list below
  </StyledPlaceholder>
);

const IdDetailsPage = ({
  borrowerName,
  selectedIdType,
  formData,
  setFormData,
  setError,
  showValidation,
  editMode,
}) => {
  const {country, expDate, issueDate} = formData || {};
  const isUsSelected = country === 'US';
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const stateFieldValidation =
    showValidation && !formData['state'] ? 'Required' : null;

  useEffect(() => {
    if (!isUsSelected) {
      setFormData(dissoc('state', formData));
      return;
    }
    setFormData(assoc('state', editMode ? formData.state : '', formData));
  }, [isUsSelected]);

  const onErrorDate = (key, isValid) => {
    setError(isValid);
    onChangeHandler(key, '');
  };

  const onChangeHandler = (key, value) => {
    const newValue = value ? value.toString() : value;
    setFormData(assoc(key, newValue, formData));
  };

  const mainLabel = `${borrowerName}'s ${selectedIdType?.label.toLowerCase()} details`;

  return (
    <PageWrapper mb={250}>
      <PageTitle>{mainLabel}</PageTitle>
      <FormContainer>
        <TextField
          tabIndex={1}
          type="text"
          name="idNumber"
          label="ID number"
          placeholder="Enter ID number"
          onChange={(value) => onChangeHandler('idNumber', value)}
          value={formData['idNumber']}
          required={showValidation}
        />
        <SelectFieldWrapper>
          <CountriesField
            tabIndex={2}
            label="Issuing country"
            value={formData['country'] || ''}
            placeholder={<DropdownPlaceholder />}
            searchPlaceholder={SEARCH_PLACEHOLDER}
            onChange={(value) => onChangeHandler('country', value)}
            required={showValidation}
            searchable
            searchIcon
          />
        </SelectFieldWrapper>
        {isUsSelected && (
          <SelectFieldWrapper>
            <StatesField
              mt={3}
              tabIndex={3}
              label="Issuing state"
              value={formData['state'] || ''}
              placeholder={<DropdownPlaceholder />}
              searchPlaceholder={SEARCH_PLACEHOLDER}
              onChange={(value) => onChangeHandler('state', value)}
              error={stateFieldValidation}
              upperErrorMsg
              searchable
              searchIcon
            />
          </SelectFieldWrapper>
        )}
        <Flex mt={3} css={{gap: 16}}>
          <DateField
            tabIndex={4}
            label="Issue date"
            onChange={(v) => onChangeHandler('issueDate', v)}
            onError={(isValid) => onErrorDate('issueDate', isValid)}
            value={issueDate}
          />

          <DateField
            tabIndex={5}
            label="Expiration date"
            onChange={(v) => onChangeHandler('expDate', v)}
            onError={(isValid) => onErrorDate('expDate', isValid)}
            value={expDate}
          />
        </Flex>
      </FormContainer>
    </PageWrapper>
  );
};

IdDetailsPage.propTypes = {
  borrowerName: PropTypes.string,
  selectedIdType: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  formData: PropTypes.shape({
    idNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    country: PropTypes.string,
    state: PropTypes.string,
    issueDate: PropTypes.string,
    expDate: PropTypes.string,
  }),
  setFormData: PropTypes.func,
  setError: PropTypes.func,
  showValidation: PropTypes.bool,
  editMode: PropTypes.bool,
};

IdDetailsPage.defaultProps = {
  borrowerName: 'borrower',
};

export default IdDetailsPage;
