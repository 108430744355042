import {gql} from '@apollo/client';

export default gql`
  mutation AddCoborrowerToProject(
    $attributes: CoborrowerInputObject!
    $projectId: ID!
  ) {
    addCoborrowerToProject(attributes: $attributes, projectId: $projectId) {
      project {
        id
      }
    }
  }
`;
