import React from 'react';

import PropTypes from 'prop-types';
import {isEmpty} from 'ramda';
import {ListBox, ListItem, IconBox, Label} from 'lib/styles/ListBox.styles';

import Accept from '@renofi/icons/src/Accept';
import {Icon} from '@renofi/components-internal';

import {PageWrapper, PageTitle, Heading} from '../styled';
import {TAGS} from '../constants';

const IdTypePage = ({borrowerName, selectedIdType, onSelectId}) => {
  const mainLabel = `${borrowerName || 'borrower'}'s ID type`;
  const listLabel = `Select ${mainLabel}`;

  return (
    <PageWrapper>
      <PageTitle>{mainLabel}</PageTitle>
      {TAGS.map(
        ({value, items}) =>
          !isEmpty(items) && (
            <div key={value} css={{width: '100%'}}>
              <Heading>{listLabel}</Heading>
              <ListBox>
                {items.map(({id, icon, label, value}) => (
                  <ListItem
                    key={value}
                    active={selectedIdType?.value === value}
                    onClick={() => onSelectId({id, label, value})}>
                    <IconBox>
                      {selectedIdType?.value === value ? (
                        <Accept color="#00A0FF" />
                      ) : (
                        <Icon name={icon} />
                      )}
                    </IconBox>
                    <Label>{label}</Label>
                  </ListItem>
                ))}
              </ListBox>
            </div>
          ),
      )}
    </PageWrapper>
  );
};

IdTypePage.propTypes = {
  borrowerName: PropTypes.string,
  selectedIdType: PropTypes.object,
  onSelectId: PropTypes.func,
};

export default IdTypePage;
