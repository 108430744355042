export const bathroomTypes = {
  full: 'Full bathroom',
  half: 'Half-bathroom',
};

export const basementTypes = {
  not_present: 'No',
  finished: 'Yes, fully finished',
  partially_finished: 'Yes, partially finished',
  unfinished: 'Yes, unfinished',
};

export const coolingTypes = {
  central_air_conditioning: 'Central Air Conditioning',
  ductless_ini_split_system: 'Ductless Mini-Split System',
  heat_pump: 'Heat Pump',
  evaporative_air_conditioner: 'Evaporative Air Conditioner',
  window_air_conditioners: 'Window Air Conditioners',
  none: 'None',
  i_dont_know: "I don't know",
};

export const fireplaceTypes = {
  wood_burning: 'Wood Burning',
  gas_propane: 'Gas / Propane',
  pellet: 'Pellet',
};

export const garageTypes = {attached: 'Attached', detached: 'Detached'};

export const heatingTypes = {
  forced_air_heating: 'Forced Air Heating',
  gravity_air_heating: 'Gravity Air Heating',
  in_floor_radiant: 'In Floor Radiant',
  boiler_radiator_heat: 'Boiler and Radiator Heat',
  hot_water_baseboard_heat: 'Hot Water Baseboard Heat',
  heat_pump: 'Heat Pump',
  electric_resistance_heat: 'Electric Resistance Heat',
  none: 'None',
  i_dont_know: "I don't know",
};

export const renovationTypes = {
  interior: 'Full interior',
  exterior: 'Full exterior',
  painting: 'Painting',
  hvac: 'HVAC',
  squareFootage: 'Square footage',
  structuralChanges: 'Structural changes',
  basement: 'Basement',
  kitchen: 'Kitchen',
  bathrooms: 'Bathrooms',
  diningRoom: 'Dining room',
  livingRoom: 'Living / Family room',
  fireplaces: 'Fireplaces',
  bedrooms: 'Bedrooms',
  patio: 'Patio',
  deck: 'Deck',
  pool: 'Pool',
  porch: 'Porch',
  garages: 'Garages',
  landscaping: 'Landscaping',
  outerBuildings: 'Outer buildings',
  siding: 'Siding',
  roofing: 'Roofing',
  office: 'Office',
  laundryRoom: 'Laundry room',
  mudRoom: 'Mud room',
  bonusRoom: 'Bonus room',
  windows: 'Windows',
  note: 'Note',
  cooling: 'Cooling',
  heating: 'Heating',
};

export const appraisalTaskTypes = {
  appraisal_comparable_sales: 'comparable sales',
  appraisal_property_details: 'property details',
  appraisal_property_photos: 'property photos',
  appraisal_renovation_details: 'renovation details',
};

export const rejectionReasonTypes = {
  incomplete: 'Incomplete',
  illegible: 'Illegible',
  not_signed: 'Not signed',
  incorrect: 'Incorrect',
  expired: 'Expired',
  other: 'Other',
  malicious_file: 'Malicious file',
  empty_file: 'Empty file',
  password_protected_file: 'Password protected file',
  unsupported_type: 'Unsupported file type',
  failed_upload: 'File upload failed',
};

export const propertyTypes = {
  single_family_residence: 'Single family residence',
  town_row_home: 'Town / Row home',
  semi_detached_duplex: 'Semi-detached / Duplex',
  other: 'Other',
};

export const propertyStructureTypes = {
  single_family: 'Single-family house',
  townhouse: 'Townhouse',
  row_home: 'Row home',
  fnma_warrantable_condo: 'Condo (with FNMA warranty)',
  multi_unit: 'Multi-unit home',
};

export const propertyUseTypeKeys = {
  primary_residence: 'primary_residence',
  secondary_residence: 'secondary_residence',
  investment_property: 'investment_property',
};

export const propertyUseTypes = {
  primary_residence: 'Primary residence',
  secondary_residence: 'Second home or vacation home',
  investment_property: 'Investment property',
};

export const realEstateOwnedPropertyTypes = {
  co_op: 'Co op',
  condo: 'Condo',
  mixed_use: 'Mixed use',
  multi_unit: 'Multi unit home',
  row_home: 'Row home',
  single_family: 'Single family home',
  townhouse: 'Townhouse',
  other: 'Other',
};

export const currentOccupancyTypes = {
  investment_property: 'Investment property',
  not_occupied: 'Not occupied',
  other: 'Other',
  primary_residence: 'Primary residence',
  secondary_residence: 'Second home',
};

export const propertyStatusTypes = {
  not_for_sale: 'Not for sale',
  pending_sale: 'Pending sale',
  rental: 'Rental',
};
