import RenoFiDesignBody from './RenoFiDesignBody';
import {CardWrapper} from './styled';

const RenoFiDesignCard = () => (
  <CardWrapper>
    <RenoFiDesignBody />
  </CardWrapper>
);

export default RenoFiDesignCard;
