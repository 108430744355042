import React from 'react';

import PropTypes from 'prop-types';

import Accept from '@renofi/icons/src/Accept';
import UploadIdIcon from '@renofi/icons/src/UploadId';

import {
  DescriptionContainer,
  DescriptionText,
  ListContainer,
  ListElement,
  IconContainer,
} from '../styled';
import {GOV_ID_TYPES} from '../constants';

function getUploadSteps(selectedIdType) {
  const firstStep =
    selectedIdType?.id === GOV_ID_TYPES.PASSPORT
      ? 'The signature and details pages of a valid passport.'
      : `Both sides of a valid ${selectedIdType?.label.toLowerCase()}.`;

  return [
    firstStep,
    'Must be in color. A photo taken with your phone is perfect.',
    'All content should be clear and easy to read.',
  ];
}

const DescriptionCard = ({selectedIdType}) => {
  const uploadSteps = getUploadSteps(selectedIdType);
  return (
    <DescriptionContainer>
      <div className="image-container">
        <UploadIdIcon />
      </div>
      <DescriptionText>
        <span className="title">What should your upload include?</span>
        <ListContainer>
          {uploadSteps.map((step, key) => (
            <ListElement key={key}>
              <IconContainer>
                <Accept color="#89E193" />
              </IconContainer>
              <span className="item-text">{step}</span>
            </ListElement>
          ))}
        </ListContainer>
      </DescriptionText>
    </DescriptionContainer>
  );
};

DescriptionCard.propTypes = {
  selectedIdType: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};
export default DescriptionCard;
