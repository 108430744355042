import {documentWithAllProps} from '../document';

export default `
adjustedGrossMonthlyIncome
entityStructures
borrowerRole
documents {
  ${documentWithAllProps}
}grossMonthlyIncome
id
incomeType
incomeTypeOtherDetails
incomeTypeNoIncomeDetails
incomeTypeTotal
taskId
rentalIncomeFromProperties {
  id
  incomeSourceId
  rentalIncome
  realEstateOwnedPropertyId
}
`;
