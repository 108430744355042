import {sortBy} from 'lodash';

import {taskTypes} from '@renofi/utilities/src/enums';

export const getCanShowDocuments = (task) => {
  const {canHaveDocument, taskType} = task || {};

  switch (taskType) {
    case taskTypes.income:
    case taskTypes.assets:
    case taskTypes.government_issued_id:
      return false;
    default:
      return canHaveDocument;
  }
};
export const sortByConfig =
  (priority = []) =>
  (tasks = []) => {
    return sortBy(tasks, (task) => {
      return priority.includes(task.taskType)
        ? priority.indexOf(task.taskType) - 1
        : 1;
    });
  };
