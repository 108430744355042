import React from 'react';

const Message = () => (
  <svg width="222px" height="175px" viewBox="0 0 222 175">
    <defs>
      <path
        d="M1.36546168,6.54288247 C0.0503983102,13.5049624 -0.200683788,19.1146843 0.612215383,23.3720479 C2.57530902,33.6532781 1.56167081,51.1801009 1.09231908,70.8249296 C0.744487647,85.3834972 1.00945167,114.666779 1.00945167,116.02296 C1.00945167,117.379141 2.47020705,117.800975 3.10413931,117.800975 C3.73807157,117.800975 5.01037804,117.704653 5.64480263,117.173257 C6.27922723,116.641861 6.15871653,83.8912423 7.07322189,70.8249296 C8.06695472,56.626627 9.52029404,22.8722347 19.7845129,22.8722347 C29.0336231,22.8722347 34.4648567,45.6960557 39.2506975,68.106367 C44.1469342,91.0336212 43.9703135,114.090505 44.3634495,115.73909 C44.4981488,116.236615 45.4283249,116.784891 46.503973,116.940448 C47.6424478,117.10509 48.8381578,116.786043 48.8381578,116.376212 C48.8381578,115.579167 47.7469373,91.4065467 45.1009791,67.6518172 C43.672287,54.825389 41.7581085,41.8018119 39.2506975,31.9626477 C37.2082805,23.9481356 40.1148576,9.49209366 38.4973121,6.54288247 C33.7298639,-2.14943016 21.3525805,-2.14943016 1.36546168,6.54288247 Z"
        id="path-1"></path>
      <path
        d="M14.1213329,29.4215841 C30.6941563,5.3616161 37.6585702,3.8875578 41.6857286,2.78153006 C51.1636609,0.178489628 49.1766628,-0.327434789 58.0427397,0.255793354 C66.9088167,0.839021498 73.0573555,7.30148341 76.9540202,15.0887773 C80.8506849,22.8760713 85.4763853,39.5638008 87.5628001,42.9394025 C90.0526562,47.4431925 98.1996099,37.7536201 100.216777,37.0494135 C100.312406,37.6386536 101.882972,38.4629326 101.813528,39.0990798 C101.04735,42.2190879 89.5233139,54.26781 83.8460585,47.2356201 C80.2279976,40.2037236 76.5224781,28.91104 75.6274306,25.7052567 C74.9987709,23.4535922 70.6446001,13.508597 71.6572744,24.9407157 C72.6699486,36.3728344 72.4101679,52.0069566 70.5181792,58.950006 C68.6261905,65.8930554 64.5301887,71.0840643 49.908009,69.0664334 C40.1598892,67.7213461 34.0745489,63.7583362 31.6519879,57.1774037 C31.3510415,50.0949907 33.2463697,39.349428 37.3379725,24.9407157 C40.2073409,15.8592345 37.2785875,11.5905881 32.5985195,14.7246865 C27.9184514,17.8587848 26.8076339,19.9056522 19.5901024,30.474716 C14.7784147,37.5207586 9.86619229,49.3916965 4.85343522,66.0875298 C1.84379485,67.3062619 0.33897466,67.0164757 0.33897466,65.218171 C0.33897466,62.5207141 9.14689376,36.6433376 14.1213329,29.4215841 Z"
        id="path-3"></path>
    </defs>
    <g
      id="Borrower's-Journey"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="Phone-authentication"
        transform="translate(-2490.000000, -286.000000)">
        <g id="Group-10" transform="translate(2360.000000, 154.000000)">
          <g id="Group-3" transform="translate(128.000000, 132.000000)">
            <rect
              id="Rectangle"
              fill="#F8F8F8"
              x="29.7"
              y="173.7"
              width="187.2"
              height="1"></rect>
            <g id="Not-opted">
              <g transform="translate(0.000000, 0.900000)">
                <g id="Group-104" transform="translate(0.000000, 15.120000)">
                  <g id="Group-103">
                    <g id="phone" transform="translate(106.172726, 59.132659)">
                      <path
                        d="M1.63088831,21.5162697 L1.63088831,21.5162697 L1.63088831,21.5162697 L2.62058294,21.6984095 C3.41833687,21.8452252 4.20170371,21.3793968 4.45366321,20.6083698 L10.2917416,2.74313397 C10.5696534,1.89268972 10.1055242,0.977976392 9.25507993,0.700064571 C9.23388299,0.693137746 9.21254529,0.686649338 9.19108047,0.680603494 L8.27987851,0.423951737 C7.44012751,0.187425063 6.56388626,0.658386125 6.29783835,1.48925622 L0.509236655,19.5671137 C0.253089122,20.3670649 0.693929057,21.223202 1.49388021,21.4793495 C1.53895029,21.4937812 1.58466941,21.5061013 1.63088831,21.5162697 Z"
                        id="Path-34"
                        fill="#C1C1C1"></path>
                      <path
                        d="M9.13057689,0.767456712 L13.6786972,2.03937891 C14.5403386,2.28034458 15.0434958,3.17418424 14.8025301,4.03582567 C14.7945147,4.06448687 14.7857111,4.09292175 14.7761292,4.12109794 L8.92405867,21.3295071 C8.67053294,22.0750166 7.91764793,22.5302474 7.13968998,22.408423 L2.66651892,21.7079462 C1.78258986,21.5695272 1.17823453,20.7407504 1.31665352,19.8568213 C1.32992972,19.7720409 1.34992131,19.6884494 1.37643957,19.606836 L7.15356084,1.82698145 C7.42196946,1.00091843 8.29409594,0.533527395 9.13057689,0.767456712 Z"
                        id="Path-48"
                        fill="#888A8E"></path>
                      <path
                        d="M13.5274559,4.33582697 C13.9748066,4.33582697 14.3374559,3.97317762 14.3374559,3.52582697 C14.3374559,3.07847632 13.9748066,2.71582697 13.5274559,2.71582697 C13.0801053,2.71582697 12.7174559,3.07847632 12.7174559,3.52582697 C12.7174559,3.97317762 13.0801053,4.33582697 13.5274559,4.33582697 Z"
                        id="Oval"
                        fill="#D8D8D8"></path>
                      <path
                        d="M13.6262744,4.39834059 C14.0736251,4.39834059 14.4362744,4.03569124 14.4362744,3.58834059 C14.4362744,3.14098995 14.0736251,2.77834059 13.6262744,2.77834059 C13.1789238,2.77834059 12.8162744,3.14098995 12.8162744,3.58834059 C12.8162744,4.03569124 13.1789238,4.39834059 13.6262744,4.39834059 Z"
                        id="Oval-Copy-8"
                        fill="#333333"></path>
                      <polygon
                        id="Path-49"
                        fill="#D8D8D8"
                        points="8.05826445 0.34887912 12.7037064 1.67698218 13.9289119 2.12347977 8.91739375 0.69775824"></polygon>
                    </g>
                    <path
                      d="M22.68,130.68 C22.4374728,133.010831 20.7410341,134.661011 17.5906838,135.630541 C14.4403334,136.60007 9.99692682,138.122496 4.26046388,140.197816 C1.62,140.763048 0.959884029,140.143144 2.28011597,138.338102 C3.60034791,136.533061 6.78881585,134.836568 11.8455198,133.248621 L18.1901786,130.832742 C18.6668985,131.626427 19.2383424,132.02327 19.9045101,132.02327 C20.5706779,132.02327 21.4958412,131.575514 22.68,130.68 Z"
                      id="Path-40"
                      fill="#C5C5C5"
                      transform="translate(12.150000, 135.540000) rotate(-11.000000) translate(-12.150000, -135.540000) "></path>
                    <g
                      id="Group-2"
                      transform="translate(114.234060, 75.883802) rotate(-11.000000) translate(-114.234060, -75.883802) translate(107.234060, 66.883802)">
                      <path
                        d="M10.392572,7.3734871 C10.956613,6.29707035 11.3759167,5.64450506 11.6504828,5.41579125 C12.5027321,4.70586681 13.2376965,5.07792614 13.4558863,5.39335443 C13.9413314,6.09514278 12.2120444,6.97185514 10.8690697,8.12714334 C9.97375318,8.89733547 9.32690924,9.47790507 8.92853785,9.86885213 L9.32541667,7.82708759 L10.392572,7.3734871 Z"
                        id="Path-50"
                        fill="#ADADAD"></path>
                      <path
                        d="M1.39194136,14.9786248 C1.53848756,13.9274984 2.0066613,12.2304829 1.93229365,11.5799394 C1.63204156,10.6539054 0.58616186,10.1688526 0.30673378,8.94831927 C0.200548494,8.4845052 0.0447496628,8.11386768 0.190628579,7.37036736 C0.268474111,6.97361245 0.691714646,6.70015225 0.804221131,6.38896872 C0.629285818,5.37001137 0.9795812,4.85385637 1.36834854,4.63489362 C1.10367594,3.75521485 1.62380118,3.0113273 2.0363243,2.79508209 C2.21148812,2.1873939 2.53511238,0.522491427 3.6800887,0.954100997 C4.40949962,1.22905929 10.8559979,4.84539767 11.5437577,5.84569796 C11.8686273,6.31819885 11.553424,7.16463474 11.4001244,7.73926728 C10.4946656,11.1333151 4.66620012,16.3948876 2.97886192,16.9051105 C1.0058473,17.5017172 1.84164666,18.270548 0.918819486,17.3899825 C-0.00400768967,16.5094171 0.737609625,19.671925 1.39194136,14.9786248 Z"
                        id="Path-7"
                        fill="#C4C4C4"></path>
                    </g>
                    <g id="Path-8" transform="translate(44.984558, 109.257479)">
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"></use>
                      </mask>
                      <use id="Mask" fill="#D8D8D8" xlinkHref="#path-1"></use>
                      <path
                        d="M28.2293184,3.96 C27.1356336,8.10082808 26.2101894,11.4785341 25.4529859,14.0931182 C24.6957824,16.7077022 23.2614403,19.6913225 21.1499597,23.0439791 L15.7135526,82.9306329 L31.2776219,113.610649 L44.7181976,134.178321 L53.0811313,134.178321 L65.2754283,86.6756173 L48.7276976,57.9079606 L40.7086975,19.1655968 L40.7086975,6.48798916 L28.2293184,3.96 Z"
                        fill="#C6C6C6"
                        mask="url(#mask-2)"></path>
                    </g>
                    <g
                      id="Path-4"
                      transform="translate(61.133086, 90.082853) rotate(-11.000000) translate(-61.133086, -90.082853) translate(10.133086, 55.082853)">
                      <mask id="mask-4" fill="white">
                        <use xlinkHref="#path-3"></use>
                      </mask>
                      <use id="Mask" fill="#FF5253" xlinkHref="#path-3"></use>
                      <path
                        d="M49.908009,1.31965461 C50.2492484,4.67841836 51.5934592,7.17431162 53.9406415,8.8073344 C62.6616564,18.7343048 63.9747556,26.2485485 62.4566205,38.6532626 C60.9384853,51.0579767 56.4029605,60.4933078 53.9406415,68.7974761 C52.2990955,74.3335883 58.2046398,76.6730692 71.6572744,75.8159189 L87.987725,32.8279552 L119.684867,11.662965 L131.390644,-31.3019254 L123.849688,-35.2249238 L100.418652,-36.8579239 L91.0326929,-18.0335387 L82.317354,-1.74849789 L71.6572744,1.31965461 L62.4566205,-1.74849789 L49.908009,1.31965461 Z"
                        fill="#D54B4C"
                        mask="url(#mask-4)"></path>
                      <path
                        d="M1.92478498,66.7480713 C5.77279262,53.0187909 9.84041999,42.0157952 14.1276671,33.7390841 C24.1189511,17.5213627 33.1807049,7.85515928 41.3129283,4.74047373 C44.8948693,4.07375708 47.7598962,2.93348403 49.908009,1.31965461 C50.8158841,-0.341734205 41.4750699,1.32611795 21.8855663,6.32321106 C11.8516431,20.9898126 6.3980334,29.0493795 5.52473731,30.5019118 C-7.19205702,51.6534492 -6.14109348,53.7440111 -6.16455213,56.583549 C-6.23254637,64.8138698 -3.53610067,68.2020439 1.92478498,66.7480713 Z"
                        id="Path-6"
                        fillOpacity="0.3"
                        fill="#FFFFFF"
                        mask="url(#mask-4)"></path>
                    </g>
                    <g
                      id="Group-102"
                      transform="translate(25.380000, 0.000000)">
                      <path
                        d="M40.5958329,47.8034731 C37.0506434,47.7370593 35.2780486,48.7160357 35.2780486,50.7404024 C35.2780486,52.7647691 35.5811681,55.0849142 36.1874071,57.7008377 C36.0175702,59.2060702 35.0298894,59.9586864 33.2243646,59.9586864 C30.5160774,59.9586864 28.083008,57.9488985 28.083008,55.914198 C28.083008,53.8794975 29.956273,47.6373967 28.083008,46.423457 C26.8341646,45.6141639 27.3338788,44.5491659 29.5821505,43.2284628 C32.773177,43.0476679 34.6718097,42.9572705 35.2780486,42.9572705 C35.8842876,42.9572705 36.9967604,43.3372033 38.6154672,44.0970689 L40.5958329,47.8034731 Z"
                        id="Path-38"
                        fill="#D8D8D8"
                        transform="translate(34.004943, 51.457978) rotate(-11.000000) translate(-34.004943, -51.457978) "></path>
                      <g
                        id="Group-6"
                        transform="translate(29.771400, 30.227617) rotate(17.000000) translate(-29.771400, -30.227617) translate(6.771400, 5.727617)">
                        <path
                          d="M37.6833878,18.1923566 C42.2641061,14.3244892 43.1359911,10.8067882 40.2990427,7.63925368 C36.0436202,2.8879519 25.2177936,2.48594148 18.3623487,6.64345411 C11.5069039,10.8009667 14.007816,11.7101034 11.568941,13.060463 C9.13006586,14.4108226 3.08951791,17.5353002 3.84487744,26.1669911 C4.60023697,34.7986819 11.5448216,43.5012189 17.8351603,43.5012189 C22.0287195,43.5012189 22.4384963,40.8707966 19.0644905,35.6099521 L37.6833878,18.1923566 Z"
                          id="Path-36"
                          fill="#333333"
                          transform="translate(22.853282, 23.640609) rotate(-11.000000) translate(-22.853282, -23.640609) "></path>
                        <path
                          d="M14.0358761,16.2496169 C17.7050186,12.3573653 21.0199305,10.4112394 23.9806117,10.4112394 C28.4216334,10.4112394 33.8884274,9.28561027 37.2431417,15.4106673 C39.1537271,18.8990253 40.8108285,21.5774928 41.1706122,26.7981066 C41.4425571,30.744143 40.3590414,34.3189966 38.850318,37.2325835 C36.6159546,41.5474978 33.6016122,44.4128477 29.8072908,45.8286332 C25.3933214,45.4976143 21.3549806,42.6322644 17.6922685,37.2325835 C17.1531353,36.3091955 14.4913223,35.7598368 14.0358761,34.8795045 C10.9714771,28.9563251 10.9714771,22.7463626 14.0358761,16.2496169 Z"
                          id="Path-46"
                          fill="#D8D8D8"
                          transform="translate(26.475050, 28.079282) rotate(-11.000000) translate(-26.475050, -28.079282) "></path>
                        <g
                          id="Eye"
                          transform="translate(29.655870, 25.484313) rotate(-11.000000) translate(-29.655870, -25.484313) translate(27.655870, 23.484313)">
                          <ellipse
                            id="Oval"
                            fill="#000000"
                            cx="1.7757847"
                            cy="2.1502125"
                            rx="1.57847529"
                            ry="1.56379091"></ellipse>
                          <ellipse
                            id="shine"
                            fill="#FFFFFF"
                            opacity="0.400000006"
                            cx="2.65114952"
                            cy="2.1502125"
                            rx="1"
                            ry="1"></ellipse>
                        </g>
                        <g
                          id="Eye-Copy"
                          transform="translate(38.190872, 24.021270) scale(-1, 1) rotate(-11.000000) translate(-38.190872, -24.021270) translate(36.190872, 22.021270)">
                          <ellipse
                            id="Oval"
                            fill="#000000"
                            cx="1.7757847"
                            cy="2.1502125"
                            rx="1.57847529"
                            ry="1.56379091"></ellipse>
                          <ellipse
                            id="shine"
                            fill="#FFFFFF"
                            opacity="0.400000006"
                            cx="1.08520176"
                            cy="2.1502125"
                            rx="1"
                            ry="1"></ellipse>
                        </g>
                        <path
                          d="M32.2429451,29.075093 C34.8170327,28.7381902 36.1040766,28.5697388 36.1040766,28.5697388 C36.1312046,28.4978365 37.3465253,29.8979682 37.6920664,29.9199801 C37.6920664,29.9199801 35.8756926,29.638351 32.2429451,29.075093 Z"
                          id="Path-23"
                          fillOpacity="0.2"
                          fill="#000000"
                          transform="translate(34.967506, 29.243521) rotate(-22.000000) translate(-34.967506, -29.243521) "></path>
                        <path
                          d="M31.9380861,36.9103848 C35.0938556,34.8578878 36.921462,32.6612823 37.4209055,30.3205681 C37.1791802,31.6343554 37.8532643,32.0672663 37.3549234,32.976931 C36.0463757,35.3655363 33.1336713,37.1002957 32.8091971,37.2158894 C32.5105002,37.3222999 32.2936054,37.3232413 32.1585128,37.2187136 L31.9380861,36.9103848 Z"
                          id="Path-15"
                          fill="#333333"
                          opacity="0.600000024"
                          transform="translate(34.740871, 33.808489) rotate(35.000000) translate(-34.740871, -33.808489) "></path>
                        <path
                          d="M38.6252955,20.3243263 C35.7247505,21.3863763 34.1801458,20.766844 33.9914812,18.4657293 C34.4838315,19.5466577 36.0284363,20.16619 38.6252955,20.3243263 Z"
                          id="Path-27-Copy"
                          fill="#333333"
                          transform="translate(36.308388, 19.646565) scale(1, -1) rotate(-14.000000) translate(-36.308388, -19.646565) "></path>
                        <path
                          d="M30.6055418,21.8832085 C27.7049968,22.9452586 26.160392,22.3257262 25.9717275,20.0246115 C26.4640778,21.1055399 28.0086826,21.7250722 30.6055418,21.8832085 Z"
                          id="Path-27-Copy-2"
                          fill="#333333"
                          transform="translate(28.288635, 21.205448) scale(-1, -1) rotate(-38.000000) translate(-28.288635, -21.205448) "></path>
                        <path
                          d="M36.5392892,14.513133 C32.9538587,13.1199362 29.4385222,13.1199362 25.9932798,14.513133 C22.5480375,15.9063297 20.7787399,18.1791165 20.685387,21.3314933 C20.3685197,22.4885363 20.7510223,23.6338267 21.8328949,24.7673642 C23.4557039,26.4676706 21.9373348,30.246898 19.4015087,30.246898 C16.8656826,30.246898 14.7958445,29.8567502 14.1415987,30.8232228 C13.4873529,31.7896954 13.3592977,33.7490019 14.6528218,34.7550165 C15.5151712,35.4256928 16.0646445,35.9964158 16.3012418,36.4671854 C16.6460485,37.5305908 16.9570675,38.2564338 17.2342987,38.6447144 C17.51153,39.0329949 16.960255,39.0944772 15.5804738,38.8291614 C13.4491715,37.5350586 11.6698136,35.7064604 10.2424002,33.3433668 C8.10128013,29.7987263 7.41653736,28.1542471 7.41653736,25.313703 C7.41653736,22.4731589 7.51013026,21.4554846 10.2424002,17.3937683 C12.0639135,14.6859574 14.4602647,12.3633038 17.4314539,10.4258074 C19.6007426,9.52908255 21.5872063,9.08072012 23.3908447,9.08072012 C26.0963024,9.08072012 28.353265,9.05579552 30.8456545,9.60794689 C33.3380441,10.1600983 34.5583659,10.9083099 35.5683978,11.7784736 C36.2417524,12.3585828 36.5653829,13.2701359 36.5392892,14.513133 Z"
                          id="Path-37"
                          fill="#333333"
                          transform="translate(21.978643, 24.035547) rotate(-11.000000) translate(-21.978643, -24.035547) "></path>
                        <path
                          d="M10.546634,16.0878681 C11.2944205,18.4443976 12.0787739,20.2572078 18.1095776,20.459356 C16.9592162,21.3042489 13.8878685,21.2932208 12.0611395,19.8153032 C10.2344104,18.3373855 10.1213473,16.4438227 10.546634,16.0878681 Z"
                          id="Path-39"
                          fill="#646464"
                          transform="translate(14.225266, 18.556896) scale(1, -1) rotate(-54.000000) translate(-14.225266, -18.556896) "></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group-4" transform="translate(119.880000, 0.000000)">
                  <path
                    d="M98.4959972,0 L5.18399969,0 C2.33280012,0 0,2.33280012 0,5.18399969 L0,69.4471786 C1.3854421e-14,70.1629396 0.580238964,70.7431786 1.296,70.7431786 C1.63972064,70.7431786 1.96936337,70.606636 2.21241053,70.3635888 L9.98840974,62.5875873 C10.2314569,62.34454 10.5610996,62.2079975 10.9048203,62.2079975 L98.4959972,62.2079975 L98.4959972,62.2079975 C101.347197,62.2079975 103.68,59.8751977 103.68,57.0239978 L103.68,5.18399969 C103.68,2.33280012 101.347197,0 98.4959972,0 Z"
                    id="Fill-3"
                    fill="#F8F8F8"></path>
                  <g
                    id="Icons/Renofi-house/24x24"
                    transform="translate(41.472000, 18.144000)"
                    fill="#E7E7E7">
                    <path
                      d="M9.93248777,0.120353112 L0.429673161,5.90171576 C0.162979475,6.06428073 0,6.35602539 0,6.67066726 L0,6.67066726 L0,25.4702075 C0,25.7187538 0.199197136,25.92 0.445158269,25.92 L0.445158269,25.92 L5.17794229,25.92 C5.42364619,25.92 5.62279188,25.7187538 5.62279188,25.4709344 L5.62279188,25.4709344 L5.62279188,9.76220536 C5.62279188,9.44128104 5.79194482,9.14470771 6.06728136,8.98416767 L6.06728136,8.98416767 L9.93248777,6.72949387 C10.2074127,6.56905767 10.546799,6.56905767 10.8217754,6.72949387 L10.8217754,6.72949387 L14.6869304,8.98416767 C14.9622669,9.14470771 15.13178,9.44128104 15.13178,9.76220536 L15.13178,9.76220536 L15.13178,25.4709344 C15.13178,25.7187538 15.330617,25.92 15.5762694,25.92 L15.5762694,25.92 L20.2904816,25.92 C20.5364427,25.92 20.736,25.7187538 20.736,25.4702075 L20.736,25.4702075 L20.7355884,6.66999229 C20.7355884,6.35561002 20.572609,6.06428073 20.3062754,5.90171576 L20.3062754,5.90171576 L10.8217754,0.120353112 C10.6841071,0.0401350109 10.5305422,0 10.3769772,0 L10.3769772,0 C10.2234123,0 10.0699503,0.0401350109 9.93248777,0.120353112 L9.93248777,0.120353112 Z"
                      id="↳🎨-Icon-Color"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Message;
