import styled from '@emotion/styled';
import {Content as Card} from 'modules/layout';

import {Text} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';

import thumbsUp from './thumbs-up.svg';

export const Details = styled(Text)({
  fontSize: 18,
  lineHeight: '24px',
  textAlign: 'center',
  color: basic55,
});

export const Content = styled(Card)({
  backgroundColor: 'white',
  backgroundImage: `url('${thumbsUp}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '132px 132px',
  backgroundPosition: 'top 104px center',
});
