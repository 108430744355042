import {Image, Text} from 'rebass';

import {sendEvent} from '@renofi/utilities/src/analytics';
import {Button} from '@renofi/components-internal';

import {ReactComponent as RenoFiDesignLogo} from './img/logo-renofi-design.svg';
import designVisual from './img/renofi-design-visual.png';
import {CardTitle, InnerPaddedWrapper} from './styled';

const RenoFiDesignCard = () => (
  <>
    <InnerPaddedWrapper>
      <RenoFiDesignLogo />
      <CardTitle>Kick start your renovation</CardTitle>
    </InnerPaddedWrapper>
    <Image src={designVisual} width="100%" marginY={17} />
    <InnerPaddedWrapper style={{gap: 20}} lineHeight="19px">
      <Text>
        Get conceptual designs and cost estimates for your renovation project.
      </Text>
      <Text fontWeight={700}>Get started with a free consultation</Text>
      <Button
        onClick={() => {
          sendEvent('Secure/RenoFi-Design-CTA-Clicked');
          window.open(
            `https://${process.env.REACT_APP_RENOFI_TLD}/design?referrer=Secure`,
            '_blank',
          );
        }}
        secondary>
        Find out more
      </Button>
    </InnerPaddedWrapper>
  </>
);

export default RenoFiDesignCard;
