import noop from '@renofi/utilities/src/noop';

import useToggledQuery from '../useToggledQuery';
import {GET_LENDER_BY_CRM_ID} from '../../queries';

export default function useLenderByCrmId({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_LENDER_BY_CRM_ID,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const lender = data?.lenderByCrmId || null;
  return {fetch, lender, data, loading, error};
}
