import styled from '@emotion/styled';
import {Content as Card} from 'modules/layout';

import {Text, Flex} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';
import {maxSmall} from '@renofi/theme/src/breakpoints';

import girlOnLadder from './images/girl-on-ladder.svg';
import star from './images/star.svg';
import window from './images/window.svg';

export const Star = styled.div({
  width: 36,
  height: 33,
  backgroundColor: 'transparent',
  backgroundImage: `url('${star}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  backgroundPosition: 'center center',
});

export const Details = styled(Text)({
  fontSize: 18,
  lineHeight: '24px',
  textAlign: 'center',
  color: basic55,
});

export const Content = styled(Card)({
  backgroundColor: 'white',
  backgroundImage: `url('${girlOnLadder}'), url('${window}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '228px 258px, 140px 98px',
  backgroundPosition: 'left 33px center, right 55px center',

  [maxSmall]: {
    backgroundSize: '153px 286px, 110px 122px',
    backgroundPosition: 'left -8px center,right 23px center',
  },
});

export const StyledFlex = styled(Flex)({
  [maxSmall]: {
    width: 225,
  },
});

export const StyledContainer = styled(Flex)({
  [maxSmall]: {
    width: '100%',
  },
});
