import {useMemo} from 'react';

import {filter, pipe, propEq, values} from 'ramda';
import {camelCase} from 'change-case';

import {useProjectTasks, useToggledBorrowerProject} from '@renofi/graphql';
import {
  useDocumentStatusUpdated,
  useTaskStatusUpdated,
} from '@renofi/utilities/src/pusher';
import {taskDocumentStatuses} from '@renofi/utilities/src/enums';

import {TASK_CATEGORIES} from '../../modules/project';

import {filterOutstanding, rejectEmptySoftPull} from './utils';

const FACET_VALUES = values(TASK_CATEGORIES);

export default function useAllProjectTasks() {
  const {projectId, project} = useToggledBorrowerProject();
  const {loading, refetch, tasks} = useProjectTasks({
    skip: !projectId || project?.status === 'closed',
    variables: {projectId},
  });

  useDocumentStatusUpdated(projectId, taskDocumentStatuses.accepted);
  useDocumentStatusUpdated(projectId, taskDocumentStatuses.rejected);
  useTaskStatusUpdated(projectId, refetch);

  const [tasksByFacet, outstanding] = useMemo(() => {
    const groupedTasks = FACET_VALUES.reduce((obj, facet) => {
      return {
        ...obj,
        [camelCase(facet)]: pipe(
          filter(propEq('facet', facet)),
          rejectEmptySoftPull,
        )(tasks || []),
      };
    }, {});
    return [groupedTasks, filterOutstanding(tasks).length];
  }, [JSON.stringify(tasks || [])]);

  return {
    ...tasksByFacet,
    allTasks: tasks || [],
    loading,
    outstanding,
    refetchProjectTasks: refetch,
  };
}
