import React, {useContext, useMemo} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {anyPass, filter, isEmpty, propEq, propOr, reject} from 'ramda';
import lazy from 'lib/asyncComponent';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import {FacetIntro} from 'modules/layout';

import {Alert, Loader, Text} from '@renofi/components-internal';
import {Card} from '@renofi/components-internal/src/next';
import {useToggledBorrowerProject} from '@renofi/graphql/src/hooks';
import {basic80} from '@renofi/theme';

import useApplicationState from '../../hooks/useApplicationState';
import useTaskHandlers from '../../hooks/useTaskHandlers';
import useDocumentHandlers from '../../hooks/useDocumentHandlers';
import Modals from '../Modals';
import {WizardUploadTask} from '../Task';

import ChecklistHelpWidget from './components/ChecklistHelpWidget';
import InsuranceWizard from './components/InsuranceWizard';
import RenovationWizard from './components/RenovationWizard';
import SmsConsentDetails from './components/SmsConsentDetails';
import {
  RENOVATION_CONTRACT,
  SMS_CONSENT_RENOVATION_PROGRESS,
} from './constants';
import AppraisalTask from './components/AppraisalTask';
import AppraisalOrder from './components/AppraisalOrder';

const Progress = lazy(
  () => import(/* webpackChunkName: "project-progress" */ '../Progress'),
);

const getExtraDetails = (taskType) => {
  switch (taskType) {
    case RENOVATION_CONTRACT:
      return ChecklistHelpWidget;
    case SMS_CONSENT_RENOVATION_PROGRESS:
      return SmsConsentDetails;
    default:
      return null;
  }
};

const homeInsurance = propEq('taskType', 'home_insurance');
const recentTransactions = propEq('taskType', 'appraisal_recent_transactions');
const smsConsent = propEq('taskType', SMS_CONSENT_RENOVATION_PROGRESS);

const anySpecialTasks = anyPass([
  homeInsurance,
  recentTransactions,
  smsConsent,
]);

const Renovation = ({config, onTaskComplete}) => {
  const {facet} = useParams();
  const {project} = useToggledBorrowerProject();
  const projectId = propOr(null, 'id', project);
  const {loading, appraisal, insurance, renovation} =
    useContext(ProjectTasksContext);

  // catch the potential of appraisal task being moved to renovation facet.
  const [appraisalTasks, insuranceTasks, renovationTasks, consentTasks] =
    useMemo(() => {
      const allTasks = [...appraisal, ...insurance, ...renovation];
      const aTasks = filter(recentTransactions, allTasks);
      const iTasks = filter(homeInsurance, allTasks);
      const smsTasks = filter(smsConsent, allTasks);
      return [aTasks, iTasks, reject(anySpecialTasks, allTasks), smsTasks];
    }, [appraisal, insurance, renovation]);

  const tasks = [...appraisalTasks, ...insuranceTasks, ...renovationTasks];

  const {
    onConfirmUpload,
    onShowPrompt,
    onRemoveDocument,
    loading: isSubmitting,
    removedDocument,
    uploadedDocument,
  } = useTaskHandlers({tasks, projectId, facet});

  const {onAcceptFiles} = useDocumentHandlers({tasks, projectId, facet});

  const {
    applicationState,
    onCloseRejection,
    onCloseAdditionalRequest,
    onShowRejection,
  } = useApplicationState();

  if (loading) {
    return <Loader label={false} />;
  }

  if (isEmpty([...tasks, ...consentTasks])) {
    return null;
  }

  return (
    <Modals
      tasks={renovationTasks}
      loading={isSubmitting}
      config={config}
      rejection={applicationState?.rejection}
      removeIds={removedDocument}
      addFilesData={uploadedDocument}
      additionalRequest={applicationState?.additionalRequest}
      onAcceptFiles={onAcceptFiles}
      onConfirmUpload={onConfirmUpload}
      onShowPrompt={onShowPrompt}
      onRemoveDocument={onRemoveDocument}
      onCloseRejection={onCloseRejection}
      onCloseAdditionalRequest={onCloseAdditionalRequest}>
      {Boolean(appraisalTasks.length) ? (
        <Card mb={24} p={0}>
          <AppraisalOrder />

          {appraisalTasks.map((task) => (
            <AppraisalTask key={task.id} task={task} />
          ))}
        </Card>
      ) : null}

      {Boolean(renovationTasks.length) ? (
        <Card p={0}>
          <FacetIntro>
            <Text mb={3} fontWeight={700} color={basic80} fontSize={24}>
              Renovation details
            </Text>
            <Alert variant="info">
              To understand the scope of the project, we will need a copy of the
              contract between you and your contractor(s), including a detailed
              cost estimate, and any renovation plans that you have.
            </Alert>
          </FacetIntro>
          {renovationTasks.map((task) => (
            <WizardUploadTask
              key={task.id}
              onRemoveFile={(value) =>
                onShowPrompt({documentId: value, taskId: task.id})
              }
              onShowRejection={(documentId) =>
                onShowRejection({documentId, taskId: task.id})
              }
              onTaskComplete={onTaskComplete}
              extra={getExtraDetails(task?.taskType)}
              showDetails
              task={task}
              wizard={RenovationWizard}
            />
          ))}
        </Card>
      ) : null}

      {Boolean(insuranceTasks.length) ? (
        <Card mb={24} p={0}>
          <FacetIntro>
            <Text mb={3} fontWeight={700} color={basic80} fontSize={24}>
              Proof of insurance
            </Text>
            <Alert variant="info">
              Most insurance companies will provide this immediately after
              purchasing your policy. They can also provide additional
              confirmation upon request. Depending on the complexity of the
              renovation we might ask you to provide proof of future dwelling
              coverage in a separate task. In the meantime, please inform your
              insurance carrier that you’re doing a renovation as that is a
              prerequisite for applying for the loan with Lender.
            </Alert>
          </FacetIntro>
          {insuranceTasks.map((task) => (
            <WizardUploadTask
              key={task.id}
              onRemoveFile={(value) =>
                onShowPrompt({documentId: value, taskId: task.id})
              }
              onShowRejection={(documentId) =>
                onShowRejection({documentId, taskId: task.id})
              }
              onTaskComplete={onTaskComplete}
              task={{...task, details: undefined}}
              wizard={InsuranceWizard}
            />
          ))}
        </Card>
      ) : null}

      {Boolean(consentTasks.length) ? (
        <Card mb={24} p={0}>
          <FacetIntro>
            <Text mb={3} fontWeight={700} color={basic80} fontSize={24}>
              Renovation updates
            </Text>
            <Alert variant="info">
              After closing the loan we’ll periodically check with you if the
              renovation is running smooth and if anything occurs.
            </Alert>
          </FacetIntro>

          {consentTasks.map((task) => (
            <WizardUploadTask
              key={task.id}
              onRemoveFile={(value) =>
                onShowPrompt({documentId: value, taskId: task.id})
              }
              onShowRejection={(documentId) =>
                onShowRejection({documentId, taskId: task.id})
              }
              onTaskComplete={onTaskComplete}
              extra={getExtraDetails(task?.taskType)}
              showDetails={false}
              task={{...task, details: undefined}}
              wizard={RenovationWizard}
            />
          ))}
        </Card>
      ) : null}

      <Progress />
    </Modals>
  );
};

Renovation.propTypes = {
  onTaskComplete: PropTypes.func.isRequired,
  config: PropTypes.object,
};

export default Renovation;
