import React, {useCallback} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';
import {useProjectOwner} from 'lib/hooks';

import {Link, AlertWithIcon} from '@renofi/components-internal';
import {openTab} from '@renofi/utilities/src/window';

function CompletedTaskDocumentsAlert({
  taskTitle = 'I wish to upload documents to a task',
}) {
  const {owner} = useProjectOwner();
  const ownerEmail = propOr(null, 'email', owner);

  const onClickContact = useCallback(() => {
    openTab(`mailto:advisors@renofi.com?subject=${taskTitle}`);
  }, [owner, ownerEmail]);

  return (
    <AlertWithIcon mt={3} variant="info">
      This task is marked as completed by RenoFi. If you need to upload more
      documents,{' '}
      <Link css={{textDecoration: 'underline'}} onClick={onClickContact}>
        contact us
      </Link>
      .
    </AlertWithIcon>
  );
}

CompletedTaskDocumentsAlert.propTypes = {
  taskTitle: PropTypes.string,
};

export default CompletedTaskDocumentsAlert;
