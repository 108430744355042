import React from 'react';

import PropTypes from 'prop-types';

import taskStatus from '@renofi/utilities/src/taskStatus';

import Badge from './Badge';

const getVariant = (status) => {
  switch (status) {
    case 'completed':
      return 'success';
    case 'pendingReview':
      return 'warning';
    case 'outstanding':
      return 'danger';
    case 'reviewed':
      return 'success';
    default:
      return 'default';
  }
};

const statusMap = {
  outstanding: 'Outstanding',
  reviewed: 'Reviewed',
  pendingReview: 'Pending review',
  completed: 'Completed',
};

const TaskStatus = ({status, allDocumentsReviewed, text, ...props}) => {
  const finalStatus = taskStatus(status, allDocumentsReviewed);
  const message = text || statusMap[finalStatus];
  return message ? (
    <Badge
      bold
      small
      css={{whiteSpace: 'nowrap'}}
      fontWeight="bold"
      variant={getVariant(finalStatus)}
      {...props}>
      {message?.toUpperCase()}
    </Badge>
  ) : null;
};

TaskStatus.propTypes = {
  status: PropTypes.string,
  allDocumentsReviewed: PropTypes.bool,
  text: PropTypes.string,
};

export default TaskStatus;
