import React, {useContext} from 'react';

import PropTypes from 'prop-types';
import lazy from 'lib/asyncComponent';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import {FacetIntro} from 'modules/layout';

import Card from '@renofi/components-internal/src/next/Card';
import {Alert} from '@renofi/components-internal';
import {taskTypes} from '@renofi/utilities/src/enums';
import {useToggledBorrowerProject} from '@renofi/graphql';

import {WizardUploadTask} from '../Task';

import SoftCreditTask from './components/SoftCredit/Task';
import ExtraDetails from './components/ExtraDetails';
import {IdentityVerificationWizard} from './components/IdentityVerificationWizard';
import EditTaskButton from './components/EditTaskButton';

const PersonalInfoAndResidencyTask = lazy(
  () =>
    import(
      /* webpackChunkName: "personalInfo-task" */ './components/PersonalInfoAndResidency/Task'
    ),
);

const Borrowers = ({onTaskComplete, showCoBorrowerIcon, tasks = []}) => {
  const {project} = useToggledBorrowerProject();
  const {borrowerInformation = []} = useContext(ProjectTasksContext);

  const softCreditId = borrowerInformation?.find(
    (task) => task.taskType === taskTypes.soft_credit_check,
  )?.id;

  return Boolean(tasks?.length) ? (
    <Card p={0}>
      <FacetIntro title="Borrower details">
        <Alert>
          Your information will be used only by RenoFi and its lending partners
          as part of your renovation underwriting.
        </Alert>
      </FacetIntro>
      {tasks.map((task) => {
        switch (task.taskType) {
          case taskTypes.personal_info_and_residency:
            return (
              <PersonalInfoAndResidencyTask
                key={task.id}
                showCoBorrowerIcon={showCoBorrowerIcon}
                softCreditId={softCreditId}
                task={task}
              />
            );
          case taskTypes.government_issued_id:
            return (
              <WizardUploadTask
                extra={ExtraDetails}
                customAddEditButton={EditTaskButton}
                key={task.id}
                onTaskComplete={onTaskComplete}
                showCoBorrowerIcon={showCoBorrowerIcon}
                showDetails
                showTaskDocuments={false}
                task={task}
              />
            );
          case taskTypes.soft_credit_check:
            return <SoftCreditTask key={task.id} {...task} project={project} />;
          case taskTypes.identity_verification:
            return (
              <IdentityVerificationWizard
                onTaskComplete={onTaskComplete}
                key={task.id}
                task={task}
              />
            );
          default:
            return null;
        }
      })}
    </Card>
  ) : null;
};

Borrowers.propTypes = {
  onTaskComplete: PropTypes.func,
  showCoBorrowerIcon: PropTypes.bool,
};

export default Borrowers;
