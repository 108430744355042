import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useMutation from '../../useMutation';
import {SOFT_CREDIT_CHECK_FINISHED_BY_PRIMARY_APPLICANT} from '../../../mutations/renovation';

export default function useSoftCreditCheckFinishedByPrimaryApplicant({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [softCreditCheckFinishedByPrimaryApplicant, {data, loading, error}] =
    useMutation(SOFT_CREDIT_CHECK_FINISHED_BY_PRIMARY_APPLICANT, {
      onCompleted,
      refetchQueries,
    });
  const response = propOr(
    null,
    ['softCreditCheckFinishedByPrimaryApplicant'],
    data,
  );
  return {softCreditCheckFinishedByPrimaryApplicant, response, loading, error};
}
