import styled from '@emotion/styled';

import {Heading, Text} from '@renofi/components-internal';
import {basic55} from '@renofi/theme/src/colors';

export const Title = styled(Heading)({
  fontSize: 26,
  lineHeight: '32px',
});

export const Details = styled(Text)({
  fontSize: 18,
  lineHeight: '24px',
  color: basic55,
});
