import {isEmpty, isObject} from 'lodash';

export const isVerificationDone = (identity, borrowerRole) => {
  if (!isObject(identity) || isEmpty(identity)) return false;

  return identity?.identityVerifications?.some(
    (identity) =>
      identity.borrowerRole === borrowerRole && identity.status !== 'pending',
  );
};
