import {propOr} from 'ramda';

import {isTpoApplication} from '@renofi/utilities/src/product-confirmation';

import {LOAN_APPLICATION_STATUSES} from './constants';

export const getContactInformation = (contactInfo) => {
  const details = contactInfo?.split('\n');
  const name = propOr('', 0, details);
  const email = propOr('', 1, details);
  const phone = propOr('', 2, details);

  return {name, email, phone};
};

export const getCreatedDate = (project) => {
  const {fastTracked, renovationReadyFile, timeline} = project || {};
  const isTpo = isTpoApplication(project);
  return isTpo && fastTracked
    ? timeline?.tpoRegistrationCompletedAt
    : renovationReadyFile?.createdAt;
};

export const getIsDisabled = (project, renovationReadyFile = {}) => {
  const loanApplicationStatus = renovationReadyFile?.loanApplicationStatus;

  const {fastTracked, lender} = project || {};
  const isTpo = isTpoApplication(project);
  const hasLender = Boolean(lender);
  if (isTpo && fastTracked && hasLender) {
    return false;
  }

  const isNotWithLender = [
    LOAN_APPLICATION_STATUSES.WITHDRAWN,
    LOAN_APPLICATION_STATUSES.DENIED,
  ].includes(loanApplicationStatus);

  return !hasLender || !renovationReadyFile || isNotWithLender;
};

export const getLender = (project) => {
  const {fastTracked, lender, renovationReadyFile} = project || {};
  const isTpo = isTpoApplication(project);

  return isTpo && fastTracked ? lender : renovationReadyFile?.lender;
};

export const getStatusHeader = ({
  complete,
  disabled,
  fastTracked,
  isTpo,
  lenderName,
  borrowerName,
}) => {
  switch (true) {
    case disabled:
      return 'Applying with Lender';
    case complete:
      return 'Congratulations on closing the loan!';
    case isTpo:
    case isTpo && fastTracked:
      return `Good news ${borrowerName}. Your application is with ${lenderName}`;
    default:
      return `Great news, ${borrowerName}! You are now ready to apply online with ${lenderName}`;
  }
};

export const isComplete = (renovationReadyFile) => {
  const loanApplicationStatus = renovationReadyFile?.loanApplicationStatus;

  return (
    renovationReadyFile &&
    loanApplicationStatus === LOAN_APPLICATION_STATUSES.CLOSED
  );
};
