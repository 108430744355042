import React, {useContext} from 'react';

import PropTypes from 'prop-types';
import {noop} from 'lodash';

import {Button} from '@renofi/components-internal';

import OnboardingContext from '../context';
import {STEP_MAPPER} from '../constants';

//TODO: add nexStepName param and use it instead of nextStepIndex
const NextButton = ({
  children,
  incrementBy = 1,
  loading,
  onClick = noop,
  skipRounding = false,
  nextStepIndex,
}) => {
  const {isNextEnabled, setStep} = useContext(OnboardingContext);

  const onNext = () => {
    const totalSteps = STEP_MAPPER.Finish;

    setStep((step) => {
      if (step === totalSteps) {
        return step;
      }

      if (nextStepIndex) {
        return nextStepIndex;
      }

      if (skipRounding) {
        return step + incrementBy;
      }

      return Math.floor(step + incrementBy);
    });
    onClick();
  };

  return (
    <Button
      variant="danger"
      onClick={onNext}
      disabled={!isNextEnabled}
      loading={loading}>
      {children}
    </Button>
  );
};

NextButton.propTypes = {
  children: PropTypes.node,
  incrementBy: PropTypes.number,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  skipRounding: PropTypes.bool,
  nextStepIndex: PropTypes.number,
};

export default NextButton;
