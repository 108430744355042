import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {ThumbsUpCircle} from 'icons';

import {Flex} from '@renofi/components-internal';
import {sendEvent} from '@renofi/utilities/src/analytics/utils';

import {WelcomeTitle, WelcomeText} from './styled';

const Finish = () => {
  useEffect(() => {
    sendEvent('Secure/Onboarding-Exit-Presented');
  }, []);

  return (
    <Flex flexDirection="column" alignItems="center" padding={40}>
      <WelcomeTitle>You’re all set!</WelcomeTitle>
      <ThumbsUpCircle />

      <WelcomeText>
        Thanks for taking a minute to set the stage. Keep the momentum going and
        knock out a few tasks from your to-do list! We’ll review them after all
        tasks are finished.
      </WelcomeText>
    </Flex>
  );
};

Finish.propTypes = {
  borrowersDetailsId: PropTypes.string,
  projectId: PropTypes.string,
};

export default Finish;
