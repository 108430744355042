import note from './note';

export default `
address
applicants
appraisalReportDocumentId
appraisalReportDocumentObjectName
appraisedValue
createdAt
creditScore
currentHomeValue
declineCommentsPersonalDetails
declineCommentsPropertyDetails
declineCommentsLoanDetails
drawPeriod
dtiRatio
email
estimatedClosingCosts
estimatedMonthlyPayment
estimatedRenovationHomeValue
id
interestRate
latestNote {
  ${note}
}
latestDeclineNote {
	${note}
}
lenderId
lenderName
lienPosition
loanAmount
loanProductType
loanType
name
phone
renovationAmount
renovationServicingFeeAmount
renovationServicingFeeInterval
repaymentPeriod
taskId
titleHolders
interestRateType
applicationProcess
applicantSubmittedAt
updatedAt
autoTransmitToLender
`;
