import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import Pencil from '@renofi/icons/src/Pencil';

import {Container, TimeLeft} from './styled';

const ONE_SECOND_MS = 1000;
const ONE_MINUTE = 1000 * 60;
const FIVE_MINUTES = ONE_MINUTE * 5;

function EditIcon({createdAt, onClick}) {
  const createdAtDate = new Date(createdAt);
  const diff = Date.now() - createdAtDate.getTime();
  const isDateFresh = diff < FIVE_MINUTES;
  const totalTimeLeft = FIVE_MINUTES - diff;

  const [timeLeft, setTimeLeft] = useState(isDateFresh ? totalTimeLeft : 0);

  useEffect(() => {
    if (timeLeft <= 0) {
      return;
    }

    const intervalId = setInterval(
      () => setTimeLeft(timeLeft - ONE_SECOND_MS),
      ONE_SECOND_MS,
    );

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  if (!isDateFresh) {
    return null;
  }

  const minutes = Math.floor((timeLeft / ONE_MINUTE) % 60);
  const seconds = Math.floor((timeLeft / ONE_SECOND_MS) % 60);

  return (
    <Container alignItems="center" flexDirection="column" onClick={onClick}>
      <Pencil />
      {isDateFresh && (
        <TimeLeft>
          {minutes}m{seconds}s
        </TimeLeft>
      )}
    </Container>
  );
}

EditIcon.propTypes = {
  createdAt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EditIcon;
