export const getLatestDocuments = (documents) => {
  const groupedByType = documents.reduce((acc, doc) => {
    acc[doc.documentType] = [...(acc[doc.documentType] || []), doc];
    return acc;
  }, {});

  return Object.values(groupedByType).map((docs) => {
    return docs.reduce((latest, current) => {
      return new Date(current.createdAt) > new Date(latest.createdAt)
        ? current
        : latest;
    });
  });
};
