import {gql} from '@apollo/client';

export default gql`
  query identityVerifications($email: String, $taskId: ID, $projectId: ID) {
    identityVerifications(
      email: $email
      taskId: $taskId
      projectId: $projectId
    ) {
      status
      borrowerRole
      documentFilesIds
      selfieDocumentFileId
      selfiePhotoFileId
      clientReferenceId
      documentIssuedDate
      documentType
      documentFullName
      documentVerificationStatus
      id
      idNumberVerificationStatus
      selfieVerificationStatus
      stripeVerificationReportId
      stripeVerificationSessionId
      address
      verificationSessionStatus
      lastErrorCode
      lastErrorReason
      verificationSessionStartedAt
      verificationSubmissionUrl
    }
  }
`;
