import React from 'react';

import PropTypes from 'prop-types';

import {basic15} from '@renofi/theme/src/colors';

const LocationHome = ({color = basic15, width = 20}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="location_home">
        <mask
          id="mask0_291_22537"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={width}
          height={width}>
          <rect id="Bounding box" width={width} height={width} fill={color} />
        </mask>
        <g mask="url(#mask0_291_22537)">
          <path
            id="location_home_2"
            d="M10.0002 2.5L16.6668 7.5V17.5H3.3335V7.5L10.0002 2.5ZM10.0002 12.5C10.6946 12.5 11.2849 12.2569 11.771 11.7708C12.2571 11.2847 12.5002 10.6944 12.5002 10C12.5002 9.30556 12.2571 8.71528 11.771 8.22917C11.2849 7.74306 10.6946 7.5 10.0002 7.5C9.30572 7.5 8.71544 7.74306 8.22933 8.22917C7.74322 8.71528 7.50016 9.30556 7.50016 10C7.50016 10.6944 7.74322 11.2847 8.22933 11.7708C8.71544 12.2569 9.30572 12.5 10.0002 12.5ZM10.0002 10.8333C9.76405 10.8333 9.56613 10.7535 9.40641 10.5938C9.24669 10.434 9.16683 10.2361 9.16683 10C9.16683 9.76389 9.24669 9.56597 9.40641 9.40625C9.56613 9.24653 9.76405 9.16667 10.0002 9.16667C10.2363 9.16667 10.4342 9.24653 10.5939 9.40625C10.7536 9.56597 10.8335 9.76389 10.8335 10C10.8335 10.2361 10.7536 10.434 10.5939 10.5938C10.4342 10.7535 10.2363 10.8333 10.0002 10.8333ZM10.0002 15C9.43072 15 8.87516 15.0694 8.3335 15.2083C7.79183 15.3472 7.27794 15.5556 6.79183 15.8333H13.2085C12.7224 15.5556 12.2085 15.3472 11.6668 15.2083C11.1252 15.0694 10.5696 15 10.0002 15ZM5.00016 8.33333V15C5.72238 14.4583 6.50711 14.0451 7.35433 13.7604C8.20155 13.4757 9.0835 13.3333 10.0002 13.3333C10.9168 13.3333 11.7988 13.4757 12.646 13.7604C13.4932 14.0451 14.2779 14.4583 15.0002 15V8.33333L10.0002 4.58333L5.00016 8.33333Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

LocationHome.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
};

export default LocationHome;
