import React from 'react';

import {Content, Details} from './styled';

const Complete = () => {
  return (
    <Content
      pt={40}
      pb={60}
      alignItems="center"
      head="Thanks for leaving a review!">
      <Details mt={200} width={630}>
        Your RenoFi journey is now complete. We wish you many happy years in
        your newly renovated home.
        <br />
        <br />
        The RenoFi Team
      </Details>
    </Content>
  );
};

export default Complete;
