import React from 'react';

import PropTypes from 'prop-types';

import {Badge, Flex} from '@renofi/components-internal';

import {BorrowerLabel} from '../GovIdTaskDetails/styled';

const IdentityTaskRoleStatus = ({task = [], role, name, isPrimary}) => {
  const {identityVerifications} = task || {};
  const identityVerification = identityVerifications?.find(
    (verification) => verification.borrowerRole === role,
  );

  const variant = !identityVerification ? 'danger' : 'success';
  const badgeLabel = !identityVerification ? 'OUTSTANDING' : 'READY';

  return (
    <Flex mt={!isPrimary ? 16 : 0}>
      <BorrowerLabel>
        <strong>{name}</strong>
        {` `} ({isPrimary ? 'Primary borrower' : 'Co-borrower'})
      </BorrowerLabel>
      <Badge
        height={18}
        small
        bold
        ml={16}
        mt="4px"
        mb={0}
        pb={0}
        variant={variant}>
        {badgeLabel}
      </Badge>
    </Flex>
  );
};

IdentityTaskRoleStatus.propTypes = {
  task: PropTypes.object,
  role: PropTypes.string,
  name: PropTypes.string,
  isPrimary: PropTypes.bool,
};

export default IdentityTaskRoleStatus;
