import React from 'react';

import PropTypes from 'prop-types';

import {Box, Flex} from '@renofi/components-internal';
import Contact from '@renofi/icons/src/Contact';

import {Details} from './styled';

const Contacts = ({lender}) => (
  <Flex pl={16} pt={24} pb={12}>
    {lender?.contactInformation1 ? (
      <>
        <Box mr={12}>
          <Contact />
        </Box>
        <Details mr={96}>
          <pre css={{margin: 0}}>{lender?.contactInformation1}</pre>
        </Details>
      </>
    ) : null}
    {lender?.contactInformation2 ? (
      <>
        <Box mr={12}>
          <Contact />
        </Box>
        <Details>
          <pre css={{margin: 0}}>{lender?.contactInformation2}</pre>
        </Details>
      </>
    ) : null}
  </Flex>
);

Contacts.propTypes = {
  lender: PropTypes.shape({
    contactInformation1: PropTypes.string,
    contactInformation2: PropTypes.string,
  }),
};

export default Contacts;
