import {gql} from '@apollo/client';

export default gql`
  mutation setNotePinned($id: ID!, $pinned: Boolean!) {
    setNotePinned(id: $id, pinned: $pinned) {
      note {
        id
      }
    }
  }
`;
