import {gql} from '@apollo/client';

export default gql`
  mutation removeDocuments($ids: [ID!]!) {
    removeDocuments(ids: $ids) {
      documents {
        id
      }
      errors {
        id
        message
      }
    }
  }
`;
