import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_SUPPORTED_FILE_EXTENSIONS} from '../../queries';

export default function useSupportedFileExtensions({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_SUPPORTED_FILE_EXTENSIONS,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch, refetch} = response;
  const supportedFileExtensions = propOr([], 'supportedFileExtensions', data);

  return {error, fetch, refetch, supportedFileExtensions, loading};
}
