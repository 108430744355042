import React from 'react';

import PropTypes from 'prop-types';
import {ItemWrapper, ListItem, ListItemTitle} from 'modules/layout';
import {DocumentList} from 'modules/project/components/Task';

import {Box} from '@renofi/components-internal';
import {realEstateOwnedPropertyTypes} from '@renofi/utilities/src/enumTypes';
import {getPropertyFullAddress} from '@renofi/utilities/src/getPropertyFullAddress';

import {findPropertyDocuments} from '../../RealEstateWizard/utils';

const SoldPropertiesItems = ({
  soldProperties = [],
  onClick,
  task,
  borrowerName,
  coborrowerName,
}) => {
  return soldProperties?.map((property) => {
    const {documents} = findPropertyDocuments(property?.documentIds, task);
    return (
      <Box mt={4} key={property.id}>
        <ListItemTitle
          title={getPropertyFullAddress(property)}
          subtitle="Sold property"
          wrapItems={false}
        />
        <ItemWrapper>
          <ListItem title="Property type">
            {realEstateOwnedPropertyTypes[property.propertyType]}
          </ListItem>
          <ListItem title="Date sold" skipComma={!property.coborrowerName}>
            {property?.soldDate}
          </ListItem>

          {property.coborrowerOwned ? (
            <ListItem title="Previous owners" skipComma>
              {property.primaryBorrowerOwned ? `${borrowerName}, ` : ''}
              {coborrowerName}
            </ListItem>
          ) : null}
        </ItemWrapper>

        <DocumentList documents={documents} onClick={onClick} />
      </Box>
    );
  });
};

SoldPropertiesItems.propTypes = {
  soldProperties: PropTypes.array,
  task: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  borrowerName: PropTypes.string,
  coborrowerName: PropTypes.string,
};

export default SoldPropertiesItems;
