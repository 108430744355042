import React from 'react';

const ThumbsIcon = (props) => (
  <svg
    width={81}
    height={64}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m27.8 42.28-11.76.84-8.4-1.68-3.36-2.52L2.6 20.44l2.52-5.04c1.68-.56 3.08-.28 4.2.84 1.9 1.52 5.04 3.36 5.88.84.532-1.596 2.52 2.52 2.52 2.52l-8.4 7.56L11 33.04v2.52c-1.68 2.8-1.4 4.2.84 4.2 2.24 0 5.32-.56 9.24-1.68l5.88 1.68.84 2.52Z"
        fill="#CCC"
      />
      <mask
        id="b"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={45}>
        <path
          d="M28.64 43.96c-1.68-.56-4.2-.56-7.56 0-5.04.84-7.56 1.68-12.6 0-3.36-1.12-5.04-2.8-5.04-5.04C1.76 36.68.92 35 .92 33.88c0-1.68.84-4.594.84-6.72 0-1.418-.28-2.817-.84-4.2-.181-2.026.099-3.706.84-5.04s1.861-2.174 3.36-2.52c0 1.68 1.12 2.8 3.36 3.36 3.36.84 6.72 0 6.72-1.68s-1.68-7.84 0-12.04C16.32 2.24 18.28.56 21.08 0l.862.862.818.818c-2.24 3.547-2.8 6.72-1.68 9.52 1.68 4.2 3.36 5.04 3.36 9.24 0 2.8 2.24 5.88 6.72 9.24l-2.52 14.28Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#b)">
        <path
          d="M28.64 43.96c-1.68-.56-4.2-.56-7.56 0-5.04.84-7.56 1.68-12.6 0-3.36-1.12-5.04-2.8-5.04-5.04C1.76 36.68.92 35 .92 33.88c0-1.68.84-4.594.84-6.72 0-1.418-.28-2.817-.84-4.2-.181-2.026.099-3.706.84-5.04s1.861-2.174 3.36-2.52c0 1.68 1.12 2.8 3.36 3.36 3.36.84 6.72 0 6.72-1.68s-1.68-7.84 0-12.04C16.32 2.24 18.28.56 21.08 0l.862.862.818.818c-2.24 3.547-2.8 6.72-1.68 9.52 1.68 4.2 3.36 5.04 3.36 9.24 0 2.8 2.24 5.88 6.72 9.24l-2.52 14.28Z"
          fill="#D8D8D8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.72.28c-1.68 3.36-2.24 6.44-1.68 9.24.84 4.2 2.52 10.08.84 10.08-1.68 0-6.72.84-10.92-1.68S7.64 3.64 9.32 3.64c1.12 0 2.24-1.68 3.36-5.04L17.72.28ZM30.6 29.4c-2.333.467-4.9 2.333-7.7 5.6-2.538 2.186-10.658-.182-16.94-2.8-1.445-.602-2.572-.525-3.36-2.1-.7-1.4 0-4.2-.7-5.6C1.2 23.1.5 21.198.5 21c0-1.616-.98.997-2.94 7.84-.875 2.562-.875 5.082 0 7.56 1.312 3.717 5.04 5.04 5.04 6.72 0 1.68 6.72 5.04 8.4 5.04H26.12l3.36-3.36c-1.531-.823-2.558-1.756-3.08-2.8-.522-1.044-.745-3.076-.668-6.097C27.11 33.434 29.2 31.5 32 30.1l-1.4-.7Z"
          fill="#CCC"
        />
      </g>
      <mask
        id="c"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={24}
        y={29}
        width={57}
        height={35}>
        <path
          d="M80.83 45.277c-10.177-2.286-26.734-7.485-49.67-15.597-6.16 2.24-7.84 7-5.04 14.28 14.56 6.16 32.797 12.6 54.71 19.32V45.277Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#c)">
        <path
          d="M80.83 45.277c-10.177-2.286-26.734-7.485-49.67-15.597-6.16 2.24-7.84 7-5.04 14.28 14.56 6.16 32.797 12.6 54.71 19.32V45.277Z"
          fill="#FF5253"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.88 41.44c7.28 2.8 22.96 8.4 47.04 16.8l-2.52 8.4L21.08 44.8l-1.68-5.04 5.04-7.56c1.68 3.36 6.16 6.44 13.44 9.24Z"
          fill="#D54B4D"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="matrix(-1 0 0 1 81 0)" d="M0 0h81v64H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default ThumbsIcon;
