import React from 'react';

import PropTypes from 'prop-types';

import Card from '@renofi/components-internal/src/next/Card';

import {Details, Title} from './styled';

const Content = ({children, head, ...props}) => {
  return (
    <Card py={24} {...props}>
      <Title>{head}</Title>
      <Details mt={12}>{children}</Details>
    </Card>
  );
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
  head: PropTypes.node.isRequired,
};

export default Content;
