import styled from '@emotion/styled';

import {maxSmall} from '@renofi/theme';
import {actionRegular, basic03, basic80} from '@renofi/theme/src/colors';
import {Flex, Text} from '@renofi/components-internal';

export const ItemWrapper = styled(Flex)({
  background: basic03,
  border: '1px solid #E9E9E9',
  borderRadius: 8,
  padding: '16px 0 10px 16px',
  flexWrap: 'wrap',
});

export const Item = styled(Flex)({
  marginRight: 10,
  alignItems: 'center',
  paddingBottom: 6,
  [maxSmall]: {
    flexWrap: 'wrap',
  },
});

export const ListItemTitleWrapper = styled(Flex)(({wrapItems}) => {
  return {
    [maxSmall]: {
      alignItems: wrapItems ? 'center' : 'flex-start',
      flexDirection: wrapItems ? 'row' : 'column',
      gap: wrapItems ? 0 : 8,
    },
  };
});

export const ItemTitle = styled(Text)({
  color: basic80,
  fontSize: 14,
  paddingRight: 3,
});

export const ItemValue = styled(Text)({
  color: actionRegular,
  fontSize: 14,
  ':last-child': {
    span: {
      color: basic80,
    },
  },
});
